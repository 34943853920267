// import jwt from 'jsonwebtoken';
// import { AES, enc, mode } from 'crypto-js';
import { isExpired, decodeToken } from "react-jwt";



  // Encrypts a value using AES encryption
  export const encryptValue = (value) => {
    // if(value)
    //   return AES.encrypt(value, process.env.REACT_APP_AES_KEY).toString();
    return value;
  };
  
  // Decrypts a value using AES decryption
  export const decryptValue = (encryptedValue) => {
    // if(encryptedValue){
    //   return AES.decrypt(encryptedValue, process.env.REACT_APP_AES_KEY).toString(enc.Utf8)
    // }
    return encryptedValue

  };
  
  
  
  // get Data from locle storage
  export const getLocalstorage = (key) => {
    // if(key)
    //   return decryptValue(localStorage.getItem(window.btoa(key)));
    if(key)
      return decryptValue(localStorage.getItem(key));

  };
  
  
  
  // set Data from locle storage
  export const setLocalstorage = (key,value) => {
    if (key && value) { // Make sure both key and value are provided
      // const encryptedValue = encryptValue(value.toString());
      // localStorage.setItem(window.btoa(key), encryptedValue);
      // localStorage.setItem(window.btoa(key), value);
      localStorage.setItem(key, value);

    }
    // if(key)
    //   return localStorage.setItem(window.btoa(key), encryptValue(value.toString()));
  };
  
  // remove Data from locle storage
  export const removeLocalstorage = (key) => {
    if(key)
      return     localStorage.removeItem(window.btoa(key));
  
  };
  
  
  
  
  
  // get Data from session storage
  export const getSessionstorage = (key) => {
    if(key)
      return decryptValue(sessionStorage.getItem(window.btoa(key)));
  };
  
  
  
  // set Data from session storage
  export const setSessinstorage = (key,value) => {
    if(key)
      return sessionStorage.setItem(window.btoa(key), encryptValue(value));
  };
  
  
  
  
  
export function JWTDecode(){
  return decodeToken(getLocalstorage('access_token'))

}



/// TOKEN 
export const tokenJWTDecod= JWTDecode();
export const tokenDecoded = tokenJWTDecod?()=>{return tokenJWTDecod.accountInfo}:null
export const tokenDecodedData = tokenDecoded
export const token_access = getLocalstorage('access_token')

// export const id_account_creater=tokenDecodedData?tokenDecodedData.id:null
export const id_account_creater=1



export function abbreviateNumber(number) {
    if (number < 1000) {
      return number; // No abbreviation needed for numbers less than 1000
    }
    
    const abbreviated = ['k', 'M', 'B', 'T'];
    const magnitude = Math.floor(Math.log10(number) / 3);
    const abbreviatedNumber = (number / Math.pow(1000, magnitude)).toFixed(1) + abbreviated[magnitude - 1];
    
    return abbreviatedNumber;
  }

// export function isAccountExpired(){
//     if (!tokenDecodedData || !tokenDecodedData.expiration_date_end) {
//       // If there's no account data or no expiration date, consider the account as expired.
//       return true;
//     }

//     const expirationDate = new Date(tokenDecodedData.expiration_date_end);
//     const currentDate = new Date();

//     return currentDate > expirationDate;
//   };

  ///handleNumberLong

  export function handleNumberLong(num) {
    if (num >= 100) {
      return "99+";
    } else {
      return num;
    }
  }




  
// export function isAccountSuscriptionExpired(){
//   //console.log(tokenDecodedData)
//   if (!tokenDecodedData || !(tokenDecodedData.subscription && tokenDecodedData.subscription && tokenDecodedData.subscription.end_date)) {
//     return true;
//   }

//   const expirationDate = new Date(tokenDecodedData.subscription.end_date);
//   const currentDate = new Date();
//   //console.log(expirationDate)
//   //console.log(currentDate)


//   return currentDate > expirationDate;
// };


export function isTokenExpired (){
  try {
    const decodedToken = getLocalstorage("access_token");
    const currentTime = Date.now() / 1000; // Convert current time to seconds
    return decodedToken.exp < currentTime;
  } catch (error) {
    // Handle invalid tokens
    return true;
  }
};