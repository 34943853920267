import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateSmtp } from './Actions';
import { toast } from 'react-toastify';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import TestSMTP from './TestSMTP';

export default function SMTPInfo(props) {
    const {t}=useTranslation()
    const [AttributesSMTP,setAttributesSMTP]=useState({...props.SmtpData})
    function onchangeAttributes(key,value){
        AttributesSMTP[key]=value;
        setAttributesSMTP({...AttributesSMTP})
    }
   const [ModalShow,setModalShow]=useState(false)

    function saveSmtp(){
      updateSmtp(AttributesSMTP)
      .then((res)=>{
        toast.success(t('Successfyly updated'))

      })
      .catch((err)=>{
        //console.log(err)
        toast.error(t('ERROR'))
      })
    }
    useEffect(()=>{
      setAttributesSMTP({...props.SmtpData})
    },[props.SmtpData])
  return (
    <div>
    <div className="mt-3  ">
       <div pageName="Settings" />

       <div className="grid grid-cols-5 gap-10">
         <div className="col-span-5 xl:col-span-3">
           <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
             <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
               <h3 className="font-medium text-black dark:text-white">
                 {t('SMTP')}
               </h3>
             </div>
             <div className="p-7">
               <div>
                
               <div className=" m-3 flex flex-col gap-10 sm:flex-row">
                 <div className="w-full sm:w-1/2">
                     <label
                       className="mb-3 block text-sm font-medium text-black dark:text-white"
                       htmlFor="name"
                     >
                       {t('name')}
                     </label>
                     <input
                       className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                       type="text" maxLength={255}
                       name="name"
                       id="name"
                       placeholder={t('Name')}
                       defaultValue=""
                       value={AttributesSMTP["name"]?AttributesSMTP["name"]:""}
                       onChange={(e)=>onchangeAttributes('name',e.target.value)}
                 
                     />
                   </div>
                 <div className="w-full sm:w-1/2">

                   <label
                     className="mb-3 block text-sm font-medium text-black dark:text-white"
                     htmlFor="emailAddress"
                   >
                     {t('Email Address')}
                   </label>
                   <div className="relative">
                   
                     <input
                       className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                       type="email" maxLength={255}
                       name="emailAddress"
                       id="emailAddress"
                       placeholder={t('Email Address')}
                       value={AttributesSMTP["email"]?AttributesSMTP["email"]:""}
                       onChange={(e)=>onchangeAttributes('email',e.target.value)}
                 
                     />
                   </div>
                 </div>

                 </div>
                 <div className=" m-3 flex flex-col gap-10 sm:flex-row">
                   <div className="w-full sm:w-1/2">
                     <label
                       className="mb-3 block text-sm font-medium text-black dark:text-white"
                       htmlFor="domainname"
                     >
                       {t('Domaine Name')}
                     </label>
                     <div className="relative">
                     
                       <input
                         className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                         type="text" maxLength={255}
                         name="domainname"
                         id="domainname"
                         placeholder={t('Domaine Name')}
                         defaultValue=""
                         value={AttributesSMTP["domaine"]?AttributesSMTP["domaine"]:""}
                         onChange={(e)=>onchangeAttributes('domaine',e.target.value)}
                       />
                     </div>
                   </div>

                   <div className="w-full sm:w-1/2">
                     <label
                       className="mb-3 block text-sm font-medium text-black dark:text-white"
                       htmlFor="port"
                     >
                       {t('Port')}
                     </label>
                     <input
                       className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                       type="text" maxLength={255}
                       name="port"
                       id="port"
                       placeholder={t('Port')}
                       defaultValue=""
                       value={AttributesSMTP["port"]?AttributesSMTP["port"]:""}
                       onChange={(e)=>onchangeAttributes('port',e.target.value)}
                 
                     />
                   </div>
                 </div>

                 <div className=" m-3">
                   <label
                     className="mb-3 block text-sm font-medium text-black dark:text-white"
                     htmlFor="Password"
                   >
                     {t('Password')}
                   </label>
                   <div className="relative">
                   
                     <input
                       className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                       type="password" maxLength={255}
                       name="Password"
                       id="Password"
                       placeholder={t('Password')}
                       value={AttributesSMTP["password"]?AttributesSMTP["password"]:""}
                       onChange={(e)=>onchangeAttributes('password',e.target.value)}
                 
                     />
                   </div>
                 </div>
                
                

                 <div className="flex justify-end gap-4  m-3">
                 <button
                     className="flex white justify-center rounded bg-green-40 py-2 px-6 font-medium text-gray hover:bg-green-400"
                     onClick={()=>setModalShow(true)}
                   >
                     {t('Test SMTP')}
                   </button>
                   <button
                     className="flex white justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                   onClick={()=>saveSmtp()}
                   >
                     {t('Save')}
                   </button>
                 </div>
               </div>
             </div>
           </div>
         </div>
         </div>
       
         <ModalHelper  
        show={ModalShow} 
        title={t("SMTP Test")}
        size='lg'
        onHide={() => setModalShow(false)} 
        content={<TestSMTP/>} />
     </div>
</div>
  )
}
