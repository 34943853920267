

import React, { useEffect, useState } from 'react'
import image from "Assets/Images/nophoto.png" 
import { useTranslation } from "react-i18next";
import { getToppings } from 'Toppings/Actions';
import { id_account_creater } from 'HelperFunction/token';
import { addToppingsProduct, removeToppingsProduct } from './Actions';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { handleError } from 'Assistances/ErrorHandling';

export default function EditToppings(props) {
  const[ListSelectedtoppings,setListSelectedtoppings]=useState([])
  const[toppings,setToppings]=useState(props.toppings)
  const[id_product,setIdProduct]=useState(getLocalstorage("product_id"))
  const classNameContainerTopping="col-lg-2 col-md-3 col-sm-4 m-2 add-toppings-item-container add-new-toppings-item-container";
  const { t } = useTranslation();

  useEffect(()=>{
    props.Selectedtoppings.map((e,i)=>{
      let selectElement=document.getElementById(e.title+e.id)
      selectElement.classList.add("selected-toppings");

    })
  },[])
  function Selecttoppings(title,id){
    var element=document.getElementById(title+id)
    if(element.classList.contains("selected-toppings")){
         
      const data = new FormData() 
      data.append('id',id_product)
      data.append("id_account_creater",id_account_creater)
      data.append("topping_id",id)
      removeToppingsProduct(data)
        .then(function (response) {
          element.classList.remove("selected-toppings");
          let listRemoveElement=[...ListSelectedtoppings.filter(e=> e!=id)]
          setListSelectedtoppings(listRemoveElement)
          toast.success(t("Succesfully Removed"))


        })
        .catch(function (error) {
          handleError(error,t,t('Removed'));
        });
        
    }
    else{
      const data = new FormData() 
        data.append('id',id_product)
        data.append("id_account_creater",id_account_creater)
        data.append("topping_id",id)
      addToppingsProduct(data)
      .then(function (response) {
        element.classList.add("selected-toppings");
          let listAddElement=[...ListSelectedtoppings, id ]
          setListSelectedtoppings(listAddElement)   
          toast.success(t("Succesfully Added"))
        
      })
      .catch(function (error) {
        handleError(error,t,t('Add'));

      });
    
    
    }
  }
  function testSelectedItem(id){
    var status=false;
    props.Selectedtoppings && props.Selectedtoppings.map(item=>{
      
      if(id===item.id_topping){
        status=true;
        return true;

      }

    })
    return status;


  }
  const langs = useSelector(selectLang);

  return (
    
    <div className='container-fluid'>
     <>
     {toppings.length>0?

     <>
     


        <div className='add-new-toppings-container'>
              <div className='row container-fluid'>
                  {Array.isArray(toppings) && toppings.map(item =>  {

                    return (
                      <div  className={"col-lg-2 col-md-3 col-sm-4 m-2 add-toppings-item-container add-new-toppings-item-container "+(testSelectedItem(item["id"])?"selected-toppings":"")} key={item["id"]} id={item["title"]+item["id"]} onClick={()=>{Selecttoppings(item["title"],item['id']) ;}}
                      data-toggle="tooltip" data-placement="top" 
                      title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                      
                      >
                         <img width="71px" height="71px" src={item["image"]?process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/topping/"+item["image"]:image} onError={(e) => {e.target.src =image}} />
                         <h2 className="title">{getTextByLanguage(item["title"],langs)}</h2>
                          {
                            item?.price?
                            <>
                             <span className="money "><span className="money__currency money__currency_on-the-left"></span><span className="money__value">{item["price"]}</span></span>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="selected-icon"><path fillRule="evenodd" clipRule="evenodd" d="M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" fill="#000"></path><path fillRule="evenodd" clipRule="evenodd" d="M16.602 7.864a1 1 0 01.2 1.4l-4.576 6.101c-.061.082-.146.197-.23.29a1.346 1.346 0 01-.513.366c-.311.121-.656.121-.967 0a1.346 1.346 0 01-.513-.365c-.084-.094-.17-.209-.23-.29l-2.075-2.767a1 1 0 011.6-1.2l1.701 2.269 4.202-5.604a1 1 0 011.4-.2z" fill="#000"></path></svg>
               
                            </>
                            :
                            <></>
                          }
                           </div>
                  )

                  })}
                </div>
              </div>
              
         
      </>
      :
      <></>
      }
      </>

      </div>
  )
}
