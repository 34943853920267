import InfoShow from 'Componnents/Fiche/InfoShow'
import React from 'react'

export default function EditInfoGredien(props) {
  return (
    <div>
        <InfoShow ficheInfo={props.ficheInfo} listToShow={['id','title','unit', 'price','description','created_at','updated_at']}/>

    </div>
  )
}
