import { getLocalstorage } from "Assistances/GlobalFunctions";
import axios from "axios";


export function addImageStore(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/addImageStore',
        data: data, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}
export function deleteImageStore(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/deleteImageStore',
        data: data, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}


