import { isTokenExpired } from 'Assistances/GlobalFunctions';
import {  Navigate, useNavigate } from 'react-router-dom'

const ProtectRoute=({children}) =>{
    const navigate=useNavigate()
    if(isTokenExpired() && window.location.pathname!="/login"){
        // removeLocalstorage('access_token');
       return  <Navigate to="/login" replace />
      }
      else{
        return children;

      }

  
}

export default ProtectRoute; 