import { capitalizeFirstLetter } from 'Assistances/GlobalFunctions'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function SpaceOfDisplay(props) {
    useEffect(()=>{

    },[props.displayItem])
    const {t}=useTranslation( )
  return (
    <div className=''>
        <div className='card min-h-500'>
            <div className='card-header'>
                {props.displayItem?props.displayItem['icon']:""}
                {capitalizeFirstLetter(props.displayItem?props.displayItem['title']:"Select Options")}
            </div>
            <div className='card-body'>
                <div className=''>
                    {props.displayItem?props.displayItem['componnent']:"Select Options to get componnent content"}

                </div>
            </div>
        </div>
        
    </div>
  )
}
