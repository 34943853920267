import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import image from "Assets/Images/nophoto.png" 
import { useSelector } from 'react-redux';
import { selectLang } from '../../../Redux/ReduxSlices/langs/LangsSlicer';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { FaLocationDot } from 'react-icons/fa6';

export default function Header(props) {
   

  const langs = useSelector(selectLang);

  return (

    
    <header className='space'>
       {props.logo_position && props.logo_position=="left"?
       <Row>
            <Col xs={11} sm={11}  lg={11} md={11} xl={11}>
                <div className="header-container">
                    <div className='left'>
                         {
                             props.info['image']?
                             <img  className="header-logo"alt="logo" src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+props.info['image']} onError={(e) => {e.target.src =image}}  />
                             :
                             <></>
                         }
                    </div>   
                    <div className=" header__about ">
                        <div className="header__about-slogan">

       
                            <div className="header__about-slogan-text store_title" data-toggle="tooltip" data-placement="top" 
                            title={getTextByLanguage(props.info['title'],langs)}
                            >
                                {props.info['title'] && props.info['title'].length>50?getTextByLanguage(props.info['title'],langs).substr(0, 50)+"...":getTextByLanguage(props.info['title'],langs)}
                            </div>
                        </div>
                            <div className="header__about-description">
                                <div className="header__about-description-text store_description"
                                 data-toggle="tooltip" data-placement="top" 
                                 title={getTextByLanguage(props.info['sub_title'],langs)}
                                >
                                    {props.info['sub_title'] && props.info['sub_title'].length>70?getTextByLanguage(props.info['sub_title'],langs).substr(0, 70)+"...":getTextByLanguage(props.info['sub_title'],langs)}

                                </div>
                            </div>
                            <div className="header__about-description">
                                <div className="header__about-description-text store_description"
                                    data-toggle="tooltip" data-placement="top" 
                                    title={(getTextByLanguage(props.info.address,langs))
                                        +((props.info.address && props.info.address.length>3)? ",":"")
                                        +(getTextByLanguage(props.info.store_region,langs))
                                        +(( (props.info.store_region && props.info.store_region.length>0)                &&                (props.info.store_country && props.info.store_country.length>0)) && (","))
                                        +(getTextByLanguage(props.info.store_country,langs))
                                    }
                                        
                                    
                                        >
                            {
                             ((props.info.address && props.info.address.length>0)
                             ||
                             (props.info.store_region && props.info.store_region.length>0)
                            ||
                            (props.info.store_country && props.info.store_country.length>0))
                            &&(
                            <><FaLocationDot className='mt-1'/>  
                            {getTextByLanguage(props.info.address,langs)}{(props.info.address && props.info.address.length>3)? ",":""}
                            {getTextByLanguage(props.info.store_region,langs)} {( (props.info.store_region && props.info.store_region.length>0)
                                &&
                                (props.info.store_country && props.info.store_country.length>0)) && (",")} {getTextByLanguage(props.info.store_country,langs)}</>
                             )
                            } 
                            </div>
                            </div>

                    </div>
                </div>
            </Col>
          
        </Row>
        :
        <></>
       }
        
        {props.logo_position && props.logo_position=="top"?
        <Row>
            <Col xs={12} sm={12}  lg={12} md={12} xl={12}>
                <div className="header-container">
                    <div className='center-image-header'>
                         {
                             props.info['image']?
                             <img  className="header-logo"alt="logo" src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+props.info['image']} onError={(e) => {e.target.src =image}}  />
                             :
                             <></>
                         }
                    </div>   
                  
                </div>
            </Col>
            <Col xs={12} sm={12}  lg={12} md={12} xl={12}>
                 <div className=" header__about ">
                     <div className="header__about-slogan">
                            <div className="header__about-slogan-text store_title">
                                {props.info['title'] && props.info['title'].length>50?getTextByLanguage(props.info['title'],langs).substr(0, 50)+"...":getTextByLanguage(props.info['title'],langs)}
                            </div>
                        </div>
                            <div className="header__about-description">
                                <div className="header__about-description-text store_description">
                                    {props.info['sub_title'] && props.info['sub_title'].length>70?getTextByLanguage(props.info['sub_title'],langs).substr(0, 70)+"...":getTextByLanguage(props.info['sub_title'],langs)}

                                </div>
                            </div>
                    </div>
            </Col>
        </Row>
        :
        <></>
        }
{/* 
        {props.logo_position && props.logo_position=="right"?
        <Row>
            <Col xs={10} sm={10}  lg={10} md={10} xl={10}>
                <div className="header-container">
                   
                    <div className=" header__about ">
                        <div className="header__about-slogan">
                            <div className="header__about-slogan-text store_title">
                                {props.info['title'] && props.info['title'].length>50?props.info['title'].substr(0, 50)+"...":props.info['title']}
                            </div>
                        </div>
                            <div className="header__about-description">
                                <div className="header__about-description-text store_description">
                                    {props.info['sub_title'] && props.info['sub_title'].length>70?props.info['sub_title'].substr(0, 70)+"...":props.info['sub_title']}

                                </div>
                            </div>
                    </div>
                </div>
            </Col>
            <Col xs={2} sm={2}  lg={2} md={2} xl={2}>
                <div className='left'>
                         {
                             props.info['image']?
                             <img  className="header-logo" alt="logo" src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+props.info['image']} onError={(e) => {e.target.src =image}}  />
                             :
                             <></>
                         }
                    </div>   
            </Col>
          
        </Row>
        :
        <></>
        }

        {props.logo_position && props.logo_position=="bottom"?
        <Row>
            <Col xs={12} sm={12}  lg={12} md={12} xl={12}>
                 <div className=" header__about ">
                        <div className="header__about-slogan">
                            <div className="header__about-slogan-text store_title">
                                {props.info['title'] && props.info['title'].length>50?props.info['title'].substr(0, 50)+"...":props.info['title']}
                            </div>
                        </div>
                            <div className="header__about-description">
                                <div className="header__about-description-text store_description">
                                    {props.info['sub_title'] && props.info['sub_title'].length>70?props.info['sub_title'].substr(0, 70)+"...":props.info['sub_title']}

                                </div>
                            </div>
                    </div>
            </Col>
            <Col xs={12} sm={12}  lg={12} md={12} xl={12}>
                <div className="header-container">
                    <div className='center-image-header'>
                         {
                             props.info['image']?
                             <img  className="header-logo"alt="logo" src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+props.info['image']} onError={(e) => {e.target.src =image}}  />
                             :
                             <></>
                         }
                    </div>   
                  
                </div>
            </Col>
            
        </Row>
        :
        <></>
        } */}
    </header>

   
  )
}
 