import InfoShow from 'Componnents/Fiche/InfoShow'
import React from 'react'

export default function EditInfoTeam(props) {
  return (
    <div>
        <InfoShow ficheInfo={props.ficheInfo} listToShow={['id','name','login','phone','company','created_at','updated_at']}/>

    </div>
  )
}
