import { useTranslation } from "react-i18next";

export function GenerateColumns(data,columns){
    const { t } = useTranslation();
    let Columns = [];
    if(data.length > 0){
        Object.keys(data[0]).forEach((element, index) => {
            if (columns.includes(element)) {
                // Element exists in the array
                Columns.push({
                    name: t(element.replace("_", ' ').toLowerCase()),
                    selector: (row) => row[element],
                    sortable: true
                });            
            } 
          
        }); 
    }
   
    return Columns;
}

