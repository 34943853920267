import React, { useEffect } from 'react'
import image from "Assets/Images/nophoto.png" 
import { useTranslation } from "react-i18next";
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../../Redux/ReduxSlices/langs/LangsSlicer';
export default function ToppingsProduct(props) {
  
  const { t } = useTranslation();
  const langs = useSelector(selectLang);
  
 
  return (
<div className='mt-3 '>
     
      <h2 className=" feature-title">{t("Product")} {t("Additions")}</h2>

    <div className='add-toppings-container p-1'>
    {Array.isArray(props.ProductToppings) && props.ProductToppings.map((item)=> {

        return(
            <div  className='add-toppings-item-container' id={"topping-"+item["id"]} key={item['id']}  onClick={()=>props.addtoppings(item,item["price"],"topping-"+item["id"])}
            data-toggle="tooltip" data-placement="top" 
            title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
            >
               {/* <img width="71px" height="71px" src={ process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/topping/"+item.image}/> */}
               <img width="71px" height="71px" alt="Topping image" src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/topping/"+item["image"]} onError={(e) => {e.target.src =image}} />

               <h2 className="title">
               {getTextByLanguage(item["title"],langs)}
                 </h2>
                 {
                  item?.price?

                  <>
                  <span className="money "><span className="money__currency money__currency_on-the-left">{props.currency?props.currency:"$"}</span><span className="money__value">{item["price"]}</span></span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="selected-icon"><path fillRule="evenodd" clipRule="evenodd" d="M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" fill="#000"></path><path fillRule="evenodd" clipRule="evenodd" d="M16.602 7.864a1 1 0 01.2 1.4l-4.576 6.101c-.061.082-.146.197-.23.29a1.346 1.346 0 01-.513.366c-.311.121-.656.121-.967 0a1.346 1.346 0 01-.513-.365c-.084-.094-.17-.209-.23-.29l-2.075-2.767a1 1 0 011.6-1.2l1.701 2.269 4.202-5.604a1 1 0 011.4-.2z" fill="#000"></path></svg>
          
                  </>

                  :
                  <>
                  
                  </>
                 }
                </div>
           )
    }
    )
    }

    </div>
</div>

  )
}
