import Modal from 'react-bootstrap/Modal';
import "./Modal.css"
 
export default  function ModalHelper(props) {
    return (
      <>
      
        <Modal 
        {...props}
        size={props.size?props.size:"lg"} 
                // {props.size && props.size!=null ?props.size:"xl"} 
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
          <i className="button-close-modal" onClick={()=>{props.onHide()}}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" >
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.61 12.199L.54 3.129A1.833 1.833 0 113.13.536l9.07 9.07L21.27.54a1.833 1.833 0 012.592 2.592l-9.068 9.068 9.07 9.07a1.833 1.833 0 01-2.59 2.592l-9.072-9.07-9.073 9.073a1.833 1.833 0 01-2.591-2.592L9.61 12.2z" fill="#fff"></path>
                    </svg>
                </i>
        {props.title?
         <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
         </Modal.Header >
        :
        <></>

        }
       
        <Modal.Body>
          <div className='content-scrol'>
          {props.content}

          </div>
          
        </Modal.Body>
       
      </Modal>
      </>
     
    );
  }
  