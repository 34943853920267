import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import image from 'Assets/Images/nophoto.png';

import { toast } from 'react-toastify';
import { editSortingAction, editStorecategories } from './Actions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { handleError } from 'Assistances/ErrorHandling';
import { useNavigate } from 'react-router-dom';
import { setLocalstorage } from 'Assistances/GlobalFunctions';

export default function EditCategoriesStore(props) {
    const { t } = useTranslation();
    const [show,setShow]=useState(0)
    const [showSelected,setShowSelected]=useState(0)
    const [categories,setcategories]=useState(props.categories?props.categories:[])
    const [Store_categories,setStorecategories]=useState(props.store_categories?props.store_categories:[])
    const[id_Store,setIdStore]=useState(props.store_id)
        function addStoreCategorie(Categorie_id){
        editStorecategories(id_Store,Categorie_id)
        .then(function(response){
            let listeAddItem=categories.filter((item)=>item.id===Categorie_id)
            let addItem=listeAddItem.length>0?listeAddItem[0]:{};
            addItem.status=0;
            addItem.cat_id=Categorie_id;
            addItem.st_categorie_id=response.data;
            addItem.st_cat_id=response.data;
            //console.log(addItem)
            //console.log(Store_categories)

            Store_categories[Store_categories.length]=addItem;
            setStorecategories([...Store_categories])
            props.changeStoreCategories([...Store_categories],1,Categorie_id)
            toast.success(t("Succesfully Added"))
        })
        .catch(function(error){
            handleError(error,t);
        })
    }
   
    function RetireCategorieFromStore(Categorie_id){
        editStorecategories(id_Store,Categorie_id)
        .then(function(response){
            let storCat=[...Store_categories.filter((item)=>item.cat_id!==Categorie_id)]
            setStorecategories(storCat)
            // props.changeStoreCategories(storCat)
            props.changeStoreCategories(storCat,0,Categorie_id)
            
            toast.success(t("Succesfully Retired"))

        })
        .catch(function(error){
            handleError(error,t);
        })
    }
    const langs = useSelector(selectLang);
    const history=useNavigate()
    function handleRowClicked(id){
        setLocalstorage('categorie_id',id)
        history("/dashboard/categories/fiche")    }


    function editSorting(type,id,value){
        editSortingAction(type,id,parseInt(value))
        .then(function(response){
         

        })
        .catch(function(error){
            handleError(error,t);
        })
    }
  return (
    <div className='container row'>

        <div className='col-lg-6 col-md-6 col-sm-12'>
            <span className='relative line-clamp-1 block max-w-[150px] overflow-hidden truncate'> {t('List of categories')}</span>
            <div className='border list-items-container'>
                        
                        
                        

                    {
                        categories && categories.map((item)=>{
                            if(Store_categories.filter(itempg=>itempg.cat_id==item.id).length<=0){
                                return <>
                        <div className="flex cursor-pointer flex-col rounded border border-custom-border-200 bg-custom-background-100">
                            <div className=" bg-image-categories relative h-[100px] w-full rounded-t ">
                                <div className="absolute inset-0 z-[1] bg-gradient-to-t from-black/60 to-transparent">
                                </div>
                                 
                                <div className="absolute bottom-4 z-10 flex h-10 w-full items-center justify-between gap-3 px-4">
                                    <div className="flex flex-grow items-center gap-2.5 truncate">
                                        <div className="item-center flex h-14 w-14 flex-shrink-0 justify-center rounded bg-white/90">
                                            <span className="flex items-center justify-center">

                                            <img alt={item.title} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/categories/"+item.image}   onError={(e) => {e.target.src =image}}/>

                                            </span>
                                        </div>
                                        <div className="flex w-full flex-col justify-between gap-0.5 truncate"
                                          data-toggle="tooltip" data-placement="top" 
                                          title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                                          
                                        >
                                            <h3 className="truncate font-semibold text-white">
                                            {getTextByLanguage(item.title,langs)}
                                            </h3>
                                            <span className="flex items-center gap-1.5">
                                                <p className="text-xs font-medium text-white relative line-clamp-1 block max-w-[100px] overflow-hidden truncate">
                                                    {getTextByLanguage(item.description,langs)}
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-shrink-0 items-center gap-2">
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>addStoreCategorie(item.id)} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg>
                                    </button>
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>handleRowClicked(item.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link h-6 w-6 text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                              
                            </>
                            }
                            else{
                                return <>
                                            </>
                            }
                           
                        })
                    }
                   
                 
            </div>
        </div>
        <div className=' col-lg-6 col-md-6 col-sm-12'>
            <span className='relative line-clamp-1 block max-w-[250px] overflow-hidden truncate'> {t('Selected categories')}</span>

            <div className='list-items-container border'>
                    {
                        Store_categories.map((item)=>{
                            return <>
                              
                        <div className="flex cursor-pointer flex-col rounded border border-custom-border-200 bg-custom-background-100">
                            <div className=" bg-image-categories relative h-[100px] w-full rounded-t ">
                                <div className="absolute inset-0 z-[1] bg-gradient-to-t from-black/60 to-transparent">
                                </div>
                                 
                                <div className="absolute bottom-4 z-10 flex h-10 w-full items-center justify-between gap-3 px-4">
                                    <div className="flex flex-grow items-center gap-2.5 truncate">
                                        <div className="item-center flex h-14 w-14 flex-shrink-0 justify-center rounded bg-white/90">
                                            <span className="flex items-center justify-center">

                                                <img alt={item.title} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/categories/"+item.image}   onError={(e) => {e.target.src =image}}/>

                                            </span>
                                        </div>
                                        <div className="flex w-full flex-col justify-between gap-0.5 truncate"
                                          data-toggle="tooltip" data-placement="top" 
                                          title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                                          
                                        >
                                            <h3 className="truncate font-semibold text-white">
                                            {getTextByLanguage(item.title,langs)}
                                            </h3>
                                            <span className="flex items-center gap-1.5">
                                                <p className="text-xs font-medium text-white relative line-clamp-1 block max-w-[100px] overflow-hidden truncate">
                                                {getTextByLanguage(item.description,langs)}
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-shrink-0 items-center gap-2">
                                    <input style={{width:"50px"}} type='number' defaultValue={item.sorting?item.sorting:0} onChange={(e)=>editSorting("categories",item.id, e.target.value)}/>
                                    <button className="flex h-5 w-5 items-center justify-center rounded bg-white/10" onClick={()=>RetireCategorieFromStore(item.cat_id)}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white"><circle cx="12" cy="12" r="10"></circle><path d="m15 9-6 6"></path><path d="m9 9 6 6"></path></svg>
                                    </button>
                                    <button className="flex h-5 w-5 items-center justify-center rounded bg-white/10" onClick={()=>handleRowClicked(item.cat_id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link h-6 w-6 text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                            </>
                        })
                    }
                   
            </div>
      
        </div>
    </div>
  )
}
