
import { getLocalstorage } from "Assistances/GlobalFunctions"
import axios from "axios"
import { id_account_creater } from "HelperFunction/token"

export function getStores(){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAllStores?id_account_creater='+id_account_creater,
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}


export function AddStores(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/addstores',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}

export function ModifyStores(data){

    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/ModifyStores',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}


export function getStoreData(store_id){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getStoreData?id='+store_id,
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}

export function updateModalsStore(store_id,modal){

    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/updateModalsStore',
        data: {store_id,modal}, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}

