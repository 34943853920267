import React, { useEffect, useState } from 'react'

import image from "Assets/Images/nophoto.png";
import { useNavigate } from 'react-router-dom';
import { AiFillStar } from 'react-icons/ai';
import { TfiNewWindow } from "react-icons/tfi";

import { useTranslation } from "react-i18next";
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { BsBox, BsBox2 } from 'react-icons/bs';
import { abbreviateNumber } from 'Assistances/GlobalFunctions';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { getCatalogueImagesStore } from './Actions';
import Spinner from 'Spinner/Spinner';

export default function StoreShow(props) {
  const { t } = useTranslation();
  const langs = useSelector(selectLang);
  const [Rating,setRating]=useState([])
  const [CatalogueImagesStore,setCatalogueImagesStore]=useState([])
  const [spinner,setspinner]=useState(true)
  function calculateRating(){
    var ratingValue=0;
    var numberRating=0;
    var ratingTotalValue=0;
    var productRatings=props.stars.filter(e=>e.id_store==props.Store.id);
    numberRating=productRatings.length;
    for(var i=0;i<(productRatings?productRatings.length:0);i++){
        ratingTotalValue=ratingTotalValue+productRatings[i].value
    }
    ratingValue=numberRating!=0?ratingTotalValue/numberRating:0
    return [numberRating,ratingValue]
}
  useEffect(()=>{
    setRating(calculateRating())

    getCatalogueImagesStore(props.Store.id)
    .then((res)=>{
      setCatalogueImagesStore([...res.data])
      console.log([...res.data])
    })
    .catch((err)=>{
      console.log(err)
    })
    .finally(()=>{
      setspinner(false)
    })
  },[])
  const history=useNavigate ()
    return (
        <div className='container-show-Store'>
          {
            spinner?
            <>
            <Spinner/>
            </>

            :
            <>
              <div className=' text-center justify-center '>
              <div className=' image-col-show-Store'>
                <div className='image-container-show'>
                  <img  src={props.Store.image?process.env.REACT_APP_APP_URL_BACKEND_IMAGES+'/stores/'+props.Store.image:props.Store.logo_meduim?process.env.REACT_APP_APP_URL_BACKEND_IMAGES+'/store/'+props.Store.logo_meduim:props.Store.logo_small?process.env.REACT_APP_APP_URL_BACKEND_IMAGES+'/store/'+props.Store.logo_small:image} 
                  
                  onError={(e) => {e.target.src =image}}
                  
                  width="120px" height="120px"/>

                </div>

              </div>
              <div className='text-center justify-center show-store-head'>
                  {getTextByLanguage(props.Store?.title,langs)}
                  <br/>
                  <span className='sous-title x-small gray-500'>{props.Store.created_at}</span><br/>
                  
                  <div>
                   
                     <div className='Store-description-text  mt-3'>
                     {getTextByLanguage(props.Store?.sub_title,langs)}

                      </div>
                  </div>
              
              
              </div>
            <div className='show-Store-raiting'>
              <div className="start-with-rating-value mr-3" style={{marginRight:"3px"}}>
                <AiFillStar className="start" size={57}>

                </AiFillStar>
               <span className="rating-value-show m-2 meduim">
                    {Rating[1]?.toFixed(1)}
                </span> 
              </div>

            <div className="absolute left-0 top-0  m-3 gray-500 " style={{marginRight:"3px"}}>
                <BsBox2  className="gray-500" color='#737278' size={31} />

               <div className="  gray-500 meduim">
                    ({abbreviateNumber(props.Store.menu_total)})

                </div> 
            </div> 

                 <span className="absolute text-center right-0 top-0 mt-5 mr-5" >{'('+(Rating[0]?Rating[0]:0)+')'}</span>
                 
                 
              </div>

            </div>
      <div className="m-2">     
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        // emulateTouch
        autoPlay={true}
        useKeyboardArrows
        transitionTime={1000}
        // axis="vertical"
        // selectedItem={1}
        
       
      >
           {Array.isArray(CatalogueImagesStore) && CatalogueImagesStore.map( (item,i) =>{
              return(
                <div className="slide-holder" key={i}>
                <img src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+item['image']} className=''  alt="store image"/>
                {/* <img src={      require(process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/annonces/"+item['image'])} height="270px"/> */}
                
                </div>
              )
        })}
        </Carousel>
        </div>     
           <div className='show-Store-fotter-bottom text-right mt-3 mb-3'>
               <button  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={()=>{return history('/store/'+props.Store.url_store) }} >  
              <TfiNewWindow  className="Stores-follow mb-2"  color= "#fff"/> {t("Show")}</button>

        
           </div>
          
           
            </>
          }
          
            
          
        </div>
    )
}

