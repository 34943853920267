import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate} from 'react-router-dom';


import Logo from 'Assets/Images/logo.png'
import "./menuapp.css"
import {Row, Col, Dropdown, DropdownButton, } from 'react-bootstrap';
import { MdDashboard, MdLogin } from "react-icons/md";

import ar_flag from "Assets/flags/sa.svg"
import en_flag from "Assets/flags/gb-eng.svg"
import es_flag from "Assets/flags/es.svg"
import fr_flag from "Assets/flags/fr.svg"


import image from 'Assets/Images/no-profile.png';

import { BiLogIn, BiLogInCircle, BiLogOut, BiSolidLogIn } from 'react-icons/bi';

import { useTranslation } from "react-i18next";
import { BsTranslate } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { selectLang, updateLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import { removeLocalstorage } from 'Assistances/GlobalFunctions';
import { JWTDecode } from 'Assistances/token';
import { getAccountInfo } from 'GeneralSettings/Actions';
import { isTokenExpired } from 'Assistances/GlobalFunctions';
import { AiFillCustomerService } from "react-icons/ai";
import ContactServiceCenter from 'ServiceCenter/ContactServiceCenter';
import ModalHelper from 'Componnents/Modals/ModalHelper';

export default function Menuapp(props) {
  const selectorRef=useRef(null)
   
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const selectorRefOptions = useRef(null);

  const [ShowOptions,setShowOptions]=useState()

  const [ShowModalCustomerServer,setShowModalCustomerServer]=useState(false)


  const [searchdata, setSearchdata] = useState('');

  const langs = useSelector(selectLang);

  function logout(){
    removeLocalstorage('access_token');
    removeLocalstorage('refresh_token');
    navigate('/')
    setShowOptions(false)
  }
  const [AccountInfo,setAccountInfo]=useState({name:'',image:''})
  function getAccountInfoData(){
    getAccountInfo()
    .then((res)=>{
      setAccountInfo(res.data)
      //console.log(res.data)
    })
    .catch((err)=>{
      //console.log(err)
    })

  }
  useEffect(()=>{
    getAccountInfoData()
  },[])
  const [showlangs,setShowLangs]=useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setShowLangs(false);
        setShowOptions(false);

      }
      if (selectorRefOptions.current && !selectorRefOptions.current.contains(event.target)) {
        setShowOptions(false);
        // setIsProfileMenuOpen(false);

      }
    };
   
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
   

  const navigate = useNavigate();
  const dispatch=useDispatch()
  const [modalshowPayment,setModalShowPayement]=useState(false)
    function ModifyLanguage(key){
      setLocalstorage("langs" ,key)
      dispatch(updateLang(key))

    i18n.changeLanguage(key, (err, t) => {
      if (err) return //console.log('something went wrong loading', err);
    });
    }
    return (
      <>
      <div className='nav-container w-full'  ref={ref} >
            <div className='flex'> 
              
               <div  className=''> 
                    <img src={Logo}  className='circle-image-47  ml-3 mt-3 mb-2' alt="React Bootstrap logo" />
               </div>
                    
              
               
                
                <div className='search-input-container w-full ml-3 mt-3'> 
              
                    <div className="search-container">
                        <span className="search-icon" role="presentation" onClick={(e)=>props.filterByTitle(searchdata)}></span>
                        <input type="text" maxLength={255}  name="search" className='search-input'  placeholder={t('Search')} onChange={(e)=>{props.onChangeFilter(e.target.value);}}/>
                    </div>  
                </div> 
              
       
                 <div > 
                    
                 <div className="">
                  {
                    JWTDecode() && !isTokenExpired()?
                    <img className='circle-image-47 ml-3 mt-2' alt="profile image"
                    onClick={()=>setShowOptions(true)}
                    src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/users/"+AccountInfo?.image}     
                    onError={(e) => {e.target.src =image}}/>
                    :
                    <BiLogInCircle size={32}className=' ml-3 mt-3 cursor-pointer' onClick={()=>navigate("/login")}/>
                  }
               
                  </div>

            {ShowOptions &&(
               <div ref={selectorRef} style={{marginTop:"61px",marginRight:"61px"}}className="z-10 text-left absolute top-0 right-0 bg-white border border-gray-200 rounded-md shadow-lg z-10 ">

               <Dropdown className='header-togle' >
                       <span className={langs=="ar"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"}   onClick={()=>{navigate("/login") }} > <MdDashboard/> {t('Dashboard')}                        </span>                        
                        <span className={langs=="fr"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"}   onClick={()=>logout()}><BiLogOut/> {t("Logout")}                        </span>
                        
                     </Dropdown>
              </div>

            )}
                                       
                </div> 
              
             <div >
             <div className='ml-3 mt-3 flex' >
              <span className='m-1'onClick={()=>setShowLangs(!showlangs)}><BsTranslate className='cursor-pointer' size={30}/></span>
              <span className='m-1' onClick={()=>setShowModalCustomerServer(true)}><AiFillCustomerService className='cursor-pointer' size={30}/></span>

            </div>
             {/* <div className='ml-3 mt-3 ' onClick={()=>setShowLangs(!showlangs)}>
            </div> */}

             {showlangs && (
        <div ref={selectorRef} style={{marginTop:"11px",marginRight:"61px"}}className="z-10 absolute top-0 right-0 bg-white border border-gray-200 rounded-md shadow-lg z-10 ">
          
          <Dropdown className='header-togle' >
                  <span className={langs=="ar"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"}  onClick={()=>ModifyLanguage('ar')}> 
                     <img src={ar_flag} width={"21px"} height={"27px"} className='flags'/> {t('Arabic')}
                   </span>                        
                   <span className={langs=="fr"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"}   onClick={()=>{ModifyLanguage('fr') }} >
                     <img src={fr_flag} width={"21px"} height={"27px"} className='flags'/> {t('France')}
                   </span>
                   <span className={langs=="en"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"} onClick={()=>ModifyLanguage('en')}>
                     <img src={en_flag} width={"21px"} height={"27px"} className='flags'/> {t('English')}
                   </span>
                   <span className={langs=="sp"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"}   onClick={()=>{ModifyLanguage('sp') }} >
                     <img src={es_flag} width={"21px"} height={"27px"} className='flags'/> {t('Spanish')}
                   </span>
                </Dropdown>
        </div>)}
            </div>
               
          
            </div>
            </div>
       
  <ModalHelper
    size="xl"
    title={t("Customer Server")}
    show={ShowModalCustomerServer}
    onHide={()=>setShowModalCustomerServer(false)}
    content={<ContactServiceCenter
    onHide={()=>setShowModalCustomerServer(false)}
      
      />}
    />

</>

    );
  }