import React, { useState } from 'react'
import "./Fiche.css"
import image from 'Assets/Images/nophoto.png'
import AlertConfirm, { Button } from "react-alert-confirm";
import { useTranslation } from 'react-i18next';
import { deleteElements } from './Actions';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';    
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector, useDispatch } from 'react-redux';
import { selectLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { handleError } from 'Assistances/ErrorHandling';
// import { updateLang } from 'Redux/ReduxSlices/langs/LangsSlicer';

export default function TopHead(props) {
    const {t}=useTranslation();
    const history=useNavigate();
    const langs = useSelector(selectLang);

    const  handleClickConfirm =async (id=props.id) => {
        //console.log(id)
           const [action] = await AlertConfirm({
               title: t("Confirm"), 
               desc: t("Are you sure that you want to delete the item ?"),
               footer: () => {
                 return (
                   <>
                      <Button  onClick={() =>AlertConfirm.closeAll()} styleType="secondary">
                       {t("Cancel")}
                       </Button>
                     <Button onClick={() => {deleteElement(id,props.table)}} styleType="danger">
                       {t("Delete")}
                     </Button>
                   </>
                 );
               },
             });
           };
    function deleteElement(id,table){
        
        deleteElements(id,table)
        .then(function (response) {
            toast.success(t("Succesfully Deleted"))
            AlertConfirm.closeAll()
            history(props.path)  

        })
        .catch(function (error) {
            //console.log(error)
            handleError(error,t,t('Delete'));
        })
        .finally(() => {

        });
    }
  return (
    <div>
        
        <div className="container">
            <div className="">
                <div className="head-top-fiche">
                    <div className="image-container-fiche">

                        <img alt={props.ficheinfo.title} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+props.pathImage+props.ficheinfo.image} className="image-fiche"  onError={(e) => {e.target.src =image}}/>
                       
                    </div>
                    <div className='info-container-fiche w-full  truncate'>
                    <div className="container-desc-tophead  flex">
                        <div className='container-desc-top-head-content truncate'>
                                {/* {props.ficheinfo.title} */}
                                {getTextByLanguage(props.ficheinfo.title,langs)}
                                
                                                   </div>
                    
                               
                        </div>
                     
                        <div>
                      

                        </div>
                        <div  className=" ">
                            <div className='w-full truncate'>
                                <div   className="text-left ">{getTextByLanguage(props.ficheinfo.description,langs)}</div>
                            </div>
                            <div className='creation-date-fiche'>
                            {props.ficheinfo.created_at}   {
                                props.ficheinfo.in_service==1 || (props.ficheinfo?.statut==1)?
                                <span className="active-fiche status">(Active)</span>
                                :
                                <span className="desactive-fiche status">(Desactive)</span>

                            }
                            </div>
                   
                        </div>
                        <div>
                            <button className="btn btn-sm btn-primary edit_store " type="button" onClick={()=>props.edit()}><i className="fa fa-edit"></i></button>
                            <button className="btn btn-sm btn-danger delete_store" type="button" onClick={()=>handleClickConfirm(props.id)} ><i className="fa fa-trash-alt"></i></button>
                        </div>
                    </div>
                   
                </div>
                
            </div>

        </div>
        <hr/>
    </div>
  )
}
