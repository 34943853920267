import MenuLeftOptions from 'Componnents/Fiche/MenuLeftOptions'
import SpaceOfDisplay from 'Componnents/Fiche/SpaceOfDisplay'
import TopHead from 'Componnents/Fiche/TopHead'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditProductCatalogueImage from './CatalogueImage/EditProductCatalogueImage'
import EditToppings from './ProductsComponnents/EditToppings'
import "./ProductsComponnents/ComponnentsProducts.css"
import { getLocalstorage } from 'Assistances/GlobalFunctions'
import { getProductfichData } from './Actions'
import {  toast } from 'react-toastify';    
import EditInfoProduct from './ProductsComponnents/EditInfoProduct'
import EditGrediens from './ProductsComponnents/EditGrediens'
import EditFeatures from './ProductsComponnents/EditFeaters'
import FormProducts from './FormProducts'
import ModalHelper from 'Componnents/Modals/ModalHelper'
import Spinner from 'Spinner/Spinner'
import { handleError } from 'Assistances/ErrorHandling'

export default function FicheProduct() {
    const [ficheInfo,setficheInfo]=useState({})
    const [CatalogueImage,setCatalogueImage]=useState([])
    const [toppings,setToppings]=useState([])
    const [Selectedtoppings,setSelectedtoppings]=useState([])
    const [Features,setFeatures]=useState([])
    const [grediens,setGrediens]=useState([])
    const [product_grediens,setProductGrediens]=useState([])
    const [ProductFeaturesObjects,setProductFeaturesObjects]=useState([])
    const [GroupByFeature,setGroupByFeature]=useState(0)
    const [ModalShow,setModalShow]=useState(false)
    const { t } = useTranslation();
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
       getProductData()

    },[])
    function getProductData(){
        getProductfichData(getLocalstorage("product_id"))
        .then(function (response) {
            setficheInfo({...response.data['info']})
            setCatalogueImage([...response.data['catalogue']])
            setToppings([...response.data['toppings']])
            setSelectedtoppings([...response.data['Selectedtoppings']])
            
            setFeatures([...response.data['features']])
            setGrediens([...response.data['grediens']])
            setProductGrediens([...response.data['product_grediens']])
            setGroupByFeature(response.data['GroupByFeature'])
            setProductFeaturesObjects([...response.data['ProductFeaturesObjects']])
            //console.log([...response.data['ProductFeaturesObjects']])
            var defaultItem=menuContent.length>0?menuContent[0]:{};
            defaultItem.componnent=<EditInfoProduct ficheInfo={response.data['info']}/>;
            getClieckedItem(defaultItem)
        }) 
        .catch(function (error) {
            //console.log(error);

            handleError(error,t,t('Products'));
        })
        
        .finally(() => {
          setSpinner(false)
        });
    }
    const menuContent=[
        {
            'title':t('Information'),
            'componnent':<EditInfoProduct ficheInfo={ficheInfo}/>,
            'icon':<i className='fa fa-info icon-left-menu'></i>,
            'path':""
        },
        {
            'title':t('Catalogue'),
            'componnent':<EditProductCatalogueImage BringProductsCatalogueImagesData={()=>{}} CatalogueImage={CatalogueImage} />,
            'icon':<i className='fa fa-grip-vertical icon-left-menu'></i>,
            'path':""
        },
        {
            'title':t('Toppings'),
            'componnent':<EditToppings toppings={toppings} Selectedtoppings={Selectedtoppings} BringProductsToppingsData={()=>{}} />,
            'icon':<i className="fa-solid fa-shapes"></i>,
            'path':""
        },
        {
            'title':t('Grediens'),
            'componnent':<EditGrediens grediens={grediens} product_grediens={product_grediens}/>,
            'icon':<i className='fa fa-puzzle-piece icon-left-menu'></i>,
            'path':""
        },
        {
            'title':t('Features'),
            'componnent':<EditFeatures BringProductsFeaturesGroupBy={(e)=>{setGroupByFeature(e)}} BringProductsFeatures={(data)=>{setProductFeaturesObjects([...data])}} ProductFeaturesObjects={ProductFeaturesObjects} Features={Features} GroupByFeature={GroupByFeature} />,
            'icon':<svg viewBox="0 0 24 24" className="h-4 w-4 rotate-180 stroke-2" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 18C13.5913 18 15.1174 17.3679 16.2426 16.2426C17.3679 15.1174 18 13.5913 18 12C18 10.4087 17.3679 8.88258 16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6V18Z" fill="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>,
            'path':""
        },

       
    ];
    const [Clieckeditem,setClieckeditem]=useState({})

    function getClieckedItem(item){
        setClieckeditem(item)
    }

  return (
    <div className=''>
    {spinner?
        <Spinner/> 
        :
        <>
        <TopHead ficheinfo={ficheInfo} table="products" id={ficheInfo.product_id} path="/dashboard/products" type='products' pathImage='/products/' edit={()=>setModalShow(true)}/>
        <div className='row'>
            <div className='col-md-4 col-lg-3 mt-1'>
                <MenuLeftOptions getClieckedItem={getClieckedItem} title={t("Product")+" "+ t("Options")} menuContent={menuContent}/>
            </div>
            <div className='col-md-8 col-lg-9 mt-1' >
                <SpaceOfDisplay displayItem={Clieckeditem}/>
            </div>
        </div>
        </>
    }

        <ModalHelper  
        show={ModalShow} 
        title={t("Modify")+" "+ t("Product")}
        size='lg'
        onHide={() => setModalShow(false)} 
        content={<FormProducts modifycontent={ficheInfo} onHide={() => setModalShow(false)} getProductData={getProductData}/>} />
        
      
    </div>
  )
}
