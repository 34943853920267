

import { capitalizeFirstLetter } from 'Assistances/GlobalFunctions';
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function InfoShow(props) {
    const { t } = useTranslation();
  return (
    <div>
        <div className='row'>

        {
          props.ficheInfo && Object.entries(props.ficheInfo).map(([key, value]) => {
            if(props.listToShow.includes(key))    
            return(
                    <div className='col-lg-12 col-md-12 col-sm-12 m-3' >
                        <span className='key-list'>{t(capitalizeFirstLetter(t(key.replace("_", ' ').toLowerCase())))} : </span> {value}
                    </div>
                )
    
       
              })
        }
        </div>

    </div>
  )
}

