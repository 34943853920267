import React, { useEffect, useState } from 'react'
import { addMessageServiceCenter } from './Actions/ServiceCenterActions'
import { useTranslation } from "react-i18next";
import { JWTDecode } from 'Assistances/token';
import { GetAccountDataInToken } from 'Assistances/GlobalFunctions';
import { toast } from 'react-toastify';
import { handleError } from 'Assistances/ErrorHandling';
import { useNavigate } from 'react-router-dom';

export default function ContactServiceCenter(props) {
    const { t } = useTranslation();
    
    
    const [ServiceCenterContact,setServiceCenterContact]=useState({})
    useEffect(()=>{
            if(GetAccountDataInToken() && GetAccountDataInToken()?.name && GetAccountDataInToken()?.login){
                ServiceCenterContact["name"]=GetAccountDataInToken()?.name;
                ServiceCenterContact["email"]=GetAccountDataInToken()?.login
                ServiceCenterContact["id_account"]=GetAccountDataInToken()?.id

                setServiceCenterContact({...ServiceCenterContact})
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps

    },[])
    function onchangeServiceCenterContact(key,value){
        ServiceCenterContact[key]=value
        setServiceCenterContact({...ServiceCenterContact})
    }
    const history=useNavigate()
    function sendDemand(){
        addMessageServiceCenter(ServiceCenterContact)
        .then(function(response){
            toast.success(t('Successfully')+' '+ t('Send'))
            props.onHide()
        })
        .catch(function(error){
            if(!(GetAccountDataInToken() && GetAccountDataInToken()?.name && GetAccountDataInToken()?.login)){
                toast.success(t('You Should Sign in First'))
                history("/login")
            }
            else{
                  console.log(error.message)
                handleError(error,t);

            }
          
        })
    }
  return (
    <div className='m-2'>
        <div className='row'>
            {
               JWTDecode() && GetAccountDataInToken().name && GetAccountDataInToken().login && GetAccountDataInToken().id ?
               <></>
                :
                <>
                <div className='col-12 m-1'>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" data-size="big">
                        {t("Name")}*
                    </label>

                        <input  value={ServiceCenterContact && ServiceCenterContact['name']?ServiceCenterContact['name']:""} name="name" onChange={(e) => onchangeServiceCenterContact('name', e.target.value)} type="text" maxLength={255} placeholder={t("Name")} required="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   />
                </div>
                <div className='col-12 m-1'>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" data-size="big">
                        {t("Email")}*
                    </label>

                    <input  value={ServiceCenterContact && ServiceCenterContact['email']?ServiceCenterContact['email']:""} name="email" onChange={(e) => onchangeServiceCenterContact('email', e.target.value)} type="text" maxLength={255} placeholder={t("Email")} required="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   />
                </div>
            
            </>
            }
      
        <div className='col-12 m-1'>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" data-size="big">
                {t("Subject")}
                <input  value={ServiceCenterContact && ServiceCenterContact['subject']?ServiceCenterContact['subject']:""} name="subject" onChange={(e) => onchangeServiceCenterContact('subject', e.target.value)} type="text" maxLength={255} placeholder={t("Subject")} required="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   />
            </label>
        </div>
        <div className='col-12 m-1'>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" data-size="big">
                {t("Explanation")}
                <textarea maxLength={3000}  value={ServiceCenterContact && ServiceCenterContact['description']?ServiceCenterContact['description']:""} name="description" onChange={(e) => onchangeServiceCenterContact('description', e.target.value)} type="text"  placeholder={t("Explanation")}required="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   />
            </label>
        </div>
        <div className='col-12 text-center justify-center flex'>
                <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="submit" onClick={()=>sendDemand()}>{t("Send")}</button>


            </div>
        </div>
    </div>
  )
}
