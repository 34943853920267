import React, { useEffect, useState } from 'react'
import {  toast } from 'react-toastify';    
import { useTranslation } from 'react-i18next';
import Datatable from 'Componnents/DataTables/DataTables';
// import FormProfile from './FormProfile';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import { getAllProfiles } from './Actions';
import FormProfiles from './FormProfiles';
import { useNavigate  } from 'react-router-dom';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import Spinner from 'Spinner/Spinner';
import { handleError } from 'Assistances/ErrorHandling';

export default function DataTableProfiles() {
    const [Profiles,setProfiles]=useState([]);
    const { t } = useTranslation();
    const columns=['id','name','login', 'mobile',"company","created_at","updated_at"];
    const [showModalFormProfile,setShowModalFormProfile]=useState(false)
    const history=useNavigate();
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
      getDataProfiles()
    },[])
    function  getDataProfiles(){
      getAllProfiles()
      .then(function (response) {
          setProfiles(response.data)
      }) 
      .catch(function (error) {
          //console.log(error)
          handleError(error,t,t('Profiles'));

      })
       .finally(() => {
        setSpinner(false)
      });

      
     }
    function handleRowClicked(row){
      setLocalstorage('Profile_id',row.id)
      history("/dashboard/profiles/fiche")    }
  return (
    <div>
      {spinner?
      <Spinner/> 
      :

      <Datatable 
      data={Profiles} 
      columns={columns}
      handleRowClicked={handleRowClicked}
      title="Profiles"
      total={Profiles.length}
      addbutton={true}
      ShowModal={() => {setShowModalFormProfile(true)}}
      />
      
    }
      <ModalHelper  
        show={showModalFormProfile} 
        title={t("Add")+" "+ t("Profiles")}
        size='lg'
        onHide={() => setShowModalFormProfile(false)} 
        content={<FormProfiles modifycontent='' getDataProfiles={getDataProfiles} onHide={() => setShowModalFormProfile(false)} />} />
        
      
      </div>
  )
}
