
import { getLocalstorage } from "Assistances/GlobalFunctions"
import axios from "axios"

export function getToppings(data){

    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getToppings',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}



export function getToppingData(topping_id){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getToppingData?id='+topping_id,
        data: {}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}

export function addToppings(data){
    return axios({
    method: 'post',
    url: process.env.REACT_APP_APP_URL_BACKEND+'/addToppings',
    data: data, 
    headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
})
}

export function UpdateToppings(data){
    return axios({
    method: 'post',
    url: process.env.REACT_APP_APP_URL_BACKEND+'/Modifytoppings',
    data: data, 
    headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
})
}
