import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts"; 
 


export default function StackedBarChart(props) {
  // Extract the keys from the first object in the data array, excluding 'name'
  const dataKeys = props.data && props.data.length>0 && Object.keys(props.data[0]).filter(key => key !== 'name');

  // Generate a palette with a sufficient number of colors using chroma-js
//   const colorScale = chroma.scale('Spectral').domain([1,0]).colors(dataKeys.length);
  const colorScale = props.colorScale && props.colorScale.length>0? props.colorScale:[];

  return (
    <div className="w-full">
    {props.title && <h2 style={{ textAlign: "center" }}>{props.title}</h2>}
    {props.data && props.data.length>0?
      <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={props.data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {dataKeys && dataKeys.map((key, index) => (
          <Bar key={key} dataKey={key} stackId="a" fill={props.stars?props.palletsColors[key-1]:props.palletsColors[index % props.palletsColors.length]} />
        ))}
      </BarChart>
      </ResponsiveContainer>

      :
      <></>

    }
   
    </div>
  );
}
