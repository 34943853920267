import React, { useEffect, useRef, useState } from 'react'
import { AiFillEdit } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../../Redux/ReduxSlices/langs/LangsSlicer';

export default function GrediensProduct(props) {
    const { t, i18n } = useTranslation();
    
    
    // useEffect(()=>{
    //     const handleClickOutside = (event) => {
    //         if (ref1.current && !ref1.current.contains(event.target) ) {
    //             show && setshow(false);
    //     }
    // }
    //     document.addEventListener('click', handleClickOutside, true);
  
    //   return () => {
    //     document.removeEventListener('click', handleClickOutside, true);
  
      
    // }},[show])
    const langs = useSelector(selectLang);

  return (
    <>
     <div className='mt-3 '>
               
               <h2 className=" feature-title">{t("Product")} {t("Grediens")}</h2>
                            {
                                Array.isArray(props.grediens) && props.grediens.map((item)=> {
                                    if(item["status"]===1)
                                    return (
                                        <span className='principale-grediens'
                                        data-toggle="tooltip" data-placement="top" 
                                        title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                            
                                        >                                            
                                            {getTextByLanguage(item["title"],langs)} <strong>, </strong> 
                                        </span>
                                    )
                                    else
                                    <></>
                                })

                            }
                             {
                             Array.isArray(props.grediens) && props.grediens.map((item)=> {
                                 if(item["status"]===0){
                                     if(props.annuler.includes(item)){
                                         return <>
                                             <button onClick={()=> item["status"]===0?props.removeannuler(item):null} data-testid="product__option" type="button" className="button-reprendre"
                                                
                                                data-toggle="tooltip" data-placement="top" 
                                                title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                                    
                                                >
                                             {getTextByLanguage(item["title"],langs)}
            
                                                 <i className="sc-1lk7lib-0 VPnyn svg-icon"><svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                     <circle cx="7" cy="7" r="6.6" stroke="#000" strokeWidth="0.8"></circle>
                                                     <path d="M4.687 9.41667C4.687 9.41667 5.58895 9.41667 8.1095 9.41667C10.63 9.41667 10.6303 5.46995 8.1095 5.46995C5.58871 5.46995 5.88146 5.46995 4.82073 5.46995M3.5 5.46995L5.55039 6.90736L4.82073 5.46995M3.5 5.46995L5.55039 4L4.82073 5.46995M3.5 5.46995C3.5 5.46995 4.19855 5.46995 4.82073 5.46995" stroke="#000" stroke-linecap="round" stroke-linejoin="round"></path>
                                             </svg>
                                         </i>
            

                                     </button>
                                     <br/>
                                  </>
                               }
                               else{
                                 return <>
                                 <button onClick={()=>item["status"]===0?props.addannuler(item):null} data-testid="product__option" type="button" className="button-annuler">
                                    <span className='secondary-gredien'  
                                     data-toggle="tooltip" data-placement="top" 
                                        title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                            >
                                    {getTextByLanguage(item["title"],langs)}
                                     </span> 
            
                                          <i className="sc-1lk7lib-0 VPnyn svg-icon"><svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <circle cx="7" cy="7" r="6.6" stroke="#000" strokeWidth="0.8"></circle>
                                              <path d="M5 5L9 9" stroke="#000" strokeWidth="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                                              <path d="M9 5L5 9" stroke="#000" strokeWidth="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                                              </svg>
                                          </i>
                                     
                                       
                                          </button>
                                          </>
                                       }
                                 }
                                 
                             })
                             }

                            </div>
                            
                        
                   
      

    </>
    
  )
}



