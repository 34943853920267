import React from 'react'
import Header from './Header'
import Menu from './Menu'
import EspaceClient from './EspaceClient'
import SeoHemlet from 'SEO/SeoHemlet'
import { getTextByLanguage } from 'Assistances/GlobalFunctions'
import { useSelector } from 'react-redux'
import { selectLang } from './../../../Redux/ReduxSlices/langs/LangsSlicer'
// import  './sass/menu.scss'
// import  './sass/headerMenu.scss'

export default function HeaderMenu(props) {
  const langs = useSelector(selectLang);

  return (
    <>
       <SeoHemlet
        title={`${getTextByLanguage(props.info?.title,langs)} - zselling`}
        description={`${getTextByLanguage(props.info?.sub_title,langs)} `}
        link={`https://www.zselling.com/store/${props.info?.url_store}`}
        keywords="pizza, restaurant, best pizza, food, store, ecommerce, fast food, orders, web, siteweb, selling, achat, vendre, eating"
      />
        <EspaceClient showModuleLogin={props.showModuleLogin}/>
        <Header info={props.info}  logo_position={"left"} />
        <Menu categories={props.categories} info={props.info}  logo_small_position={"left"}/>
    </>
  )
}
