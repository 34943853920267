// import jwt from 'jsonwebtoken';
// import { AES, enc, mode } from 'crypto-js';

import { BsFacebook, BsFillEnvelopeFill, BsFillTelephoneFill, BsGithub, BsGlobe, BsInstagram, BsLinkedin, BsPinterest, BsSkype, BsSnapchat, BsTelegram, BsTwitter, BsWhatsapp } from "react-icons/bs";
import { FaAmazon, FaTiktok } from "react-icons/fa";
import { decodeToken, isExpired, useJwt } from "react-jwt";
import { FaXTwitter } from "react-icons/fa6";


//   // Encrypts a value using AES encryption
//   export const encryptValue = (value) => {
//     if(value)
//       return AES.encrypt(value, process.env.REACT_APP_AES_KEY).toString();
//   };
  
//   // Decrypts a value using AES decryption
//   export const decryptValue = (encryptedValue) => {
//     if(encryptedValue){
//       return AES.decrypt(encryptedValue, process.env.REACT_APP_AES_KEY).toString(enc.Utf8)

//     }
//   };
  
  
  
  // get Data from locle storage
  export const getLocalstorage = (key) => {
    // if(key)
    //   return decryptValue(localStorage.getItem(window.btoa(key)));
      return localStorage.getItem(key);

  };
  
  
  
  // set Data from locle storage
  export const setLocalstorage = (key,value) => {
    // if (key && value) { // Make sure both key and value are provided
    //   const encryptedValue = encryptValue(value.toString());
    //   localStorage.setItem(window.btoa(key), encryptedValue);
    // }
    localStorage.setItem(key, value.toString());

    };
  
  // remove Data from locle storage
  export const removeLocalstorage = (key) => {
    // if(key)
    //   return     localStorage.removeItem(window.btoa(key));
      return     localStorage.removeItem(key);

  };
  
  
  
  
  
  // get Data from session storage
  export const getSessionstorage = (key) => {
    // if(key)
    //   return decryptValue(sessionStorage.getItem(window.btoa(key)));
    return sessionStorage.getItem(window.btoa(key));
  };
  
  
  
  // set Data from session storage
  export const setSessinstorage = (key,value) => {
//     if(key)
//       return sessionStorage.setItem(window.btoa(key), encryptValue(value));
        return sessionStorage.setItem(key,value);
    };
  
  
  
  
  
export function GetAccountDataInToken(){
  return DecodeAccessToken()?DecodeAccessToken().accountInfo:null;
}


export function IsAdmin(){
  return parseInt(GetAccountDataInToken()?GetAccountDataInToken().is_admin:0)==7;
}

export function IsGestionnaire(){
  return parseInt(GetAccountDataInToken()?GetAccountDataInToken().is_admin:0)==1;
}


export function DecodeAccessToken(){
  return JWTDecode();
}

  
function JWTDecode(){
  return decodeToken(getLocalstorage('access_token'))

}
export function abbreviateNumber(number) {
    if (number < 1000) {
      return number; // No abbreviation needed for numbers less than 1000
    }
    
    const abbreviated = ['k', 'M', 'B', 'T'];
    const magnitude = Math.floor(Math.log10(number) / 3);
    const abbreviatedNumber = (number / Math.pow(1000, magnitude)).toFixed(1) + abbreviated[magnitude - 1];
    
    return abbreviatedNumber;
  }

export function isAccountExpired(){
    // if (!tokenDecodedData || !tokenDecodedData.expiration_date_end) {
    //   // If there's no account data or no expiration date, consider the account as expired.
    //   return true;
    // }

    // const expirationDate = new Date(tokenDecodedData.expiration_date_end);
    // const currentDate = new Date();

    // return currentDate > expirationDate;
  };


  export function handleNumberLong(num) {
    if (num >= 100) {
      return "99+";
    } else {
      return num;
    }
  }





export function isTokenExpired (){
  try {
    // const decodedToken = tokenDecoded;
    // const currentTime = Date.now() / 1000; // Convert current time to seconds
    // return decodedToken.exp < currentTime;
    return isExpired(getLocalstorage('access_token'))
  } catch (error) {
    // Handle invalid tokens
    return true;
  }
};





// -------------------------------------------

export function DisplayIconSocial(key,size,color){
  switch (key) {
      case 'Call':
          return (
              <BsWhatsapp  size={size} color={color}/>       
          )
      case 'Email':
          return (
              <BsFillEnvelopeFill size={size} color={color}/>
             )
      case 'Web':
          return (
            <BsGlobe  size={size} color={color}/>   
            )
      case 'FaceBook':
       return (
        <BsFacebook size={size} color={color}/>
          )
      case 'Linkedin':
       return (
        <BsLinkedin  size={size} color={color}/>
          )
      case 'Github':
         return (
          <BsGithub   size={size} color={color}/>
          )
      case 'Instagram':
       return (
        <BsInstagram    size={size} color={color}/>
         )
          case 'Telegram':
       return (
        <BsTelegram   size={size} color={color}/>
            )
      case 'X':
       return (
            <FaXTwitter   size={size} color={color}/>
            
            )
          case 'Skype':
       return (
          <BsSkype   size={size} color={color}/>
          )
          
          case 'Snapchat':
           return (
              <BsSnapchat   size={size} color={color}/>               
             )
           case 'Tiktok':
             return (
            <FaTiktok   size={size} color={color}/>
                )
           case 'Amazon':
           return (
           <FaAmazon   size={size} color={color}/>
           )
           case 'Pinterest':
             return (
              <BsPinterest  size={size}  color={color}/>
               )
      default:
          return (
            <></>
          )
   }
}


export const parseTextString = (textString) => {
  try {
    return JSON.parse(textString);
  } catch (error) {
    // console.error('Error parsing text string:', error);
    return textString;
  }
};

export const getTextByLanguage = (text, lang) => {
  let objectConverted=parseTextString(text)
  if (typeof objectConverted === 'object'){
    return objectConverted ? (objectConverted[lang] || objectConverted['en']) : objectConverted;

  }
  else{
    return objectConverted;
  }
};


export function capitalizeFirstLetter(string) {
  if (string === null || string === undefined || string === '') {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function getDateBackWithoutTime(day = 0) {
  const date = new Date();
  date.setDate(date.getDate() - day);
  const formattedDate = date.toISOString().slice(0, 10); // Slice to get only the date part (YYYY-MM-DD)
  return formattedDate;
}

