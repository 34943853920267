
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import image from 'Assets/Images/nophoto.png';

import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { toast } from 'react-toastify';
import { editStoreCategorieProducts } from './Actions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { handleError } from 'Assistances/ErrorHandling';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import { useNavigate } from 'react-router-dom';

export default function EditCategoriesProductsStore(props) {
    const { t } = useTranslation();
    const [show, setShow] = useState(0)
    const [showSelected, setShowSelected] = useState(0)
    // const [store_products, setStoreproducts] = useState(props.store_products ? props.store_products : [])
    const [CategorieStoreSelected, setCategorieStoreSelected ] = useState(0)
    // const ListImage=[image_bg_1,image_bg_2,image_bg_3,image_bg_4,image_bg_5,image_bg_6,image_bg_7,image_bg_8,image_bg_9,image_bg_10,image_bg_11,image_bg_12,image_bg_13,image_bg_14,image_bg_15]
    
    
    const [store_categorie_products, setStoreCategorieProducts] = useState(props.store_categorie_products ? props.store_categorie_products : [])
    const [id_Store, setIdStore] = useState(getLocalstorage("store_id"))

    function addStoreCategorieProduct(st_cat_id,st_product_id) {
        editStoreCategorieProducts(id_Store, st_cat_id,st_product_id)
            .then(function (response) {
                let store_products_tmp=props.store_products.filter(item=>item.st_product_id==st_product_id);
                if(store_products_tmp.length>0){
                    store_products_tmp=store_products_tmp[0]; 
                }
                let storcateProd=[...store_categorie_products,{...store_products_tmp,'st_cat_id':st_cat_id}]
                setStoreCategorieProducts(storcateProd)
                props.onchangeCategorieProducts(storcateProd)
                toast.success(t("Succesfully Added"))
                
            })
            .catch(function (error) {
                handleError(error,t);
            })
    }

    function RetireCategorieProductFromStore(st_cat_id,st_product_id) {
        editStoreCategorieProducts(id_Store, st_cat_id,st_product_id)
            .then(function (response) {
                let storcateProd=[...store_categorie_products.filter(item=>(item.st_cat_id!=st_cat_id || item.st_product_id!=st_product_id))]
                setStoreCategorieProducts(storcateProd)
                props.onchangeCategorieProducts(storcateProd)

                toast.success(t("Succesfully Retire"))

            })
            .catch(function (error) {
                handleError(error,t);
            })
    }
    function changeCatgorieValue(e){
        setCategorieStoreSelected(parseInt(e))
    }
    const langs = useSelector(selectLang);
    const history=useNavigate()
    function handleRowClicked(id){
        setLocalstorage('product_id',id);
        history("/dashboard/products/fiche")
      }
    return (
    <>
        <div className='list-select-container row'>
            <div className='col-12'>
                <div className='ml-1'>
                    <label for="currency" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Categories")}</label>
                    <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                         
                        onChange={(e)=>{changeCatgorieValue(e.target.value)}}
                              >
                                <option value={0} key={0}></option>

                                {props.store_categories.map((store_categories_item, index) => (
                                  <option value={store_categories_item.st_cat_id} key={index}
                                  data-toggle="tooltip" data-placement="top" 
                                  title={getTextByLanguage(store_categories_item.title,langs)+': '+getTextByLanguage(store_categories_item['description'],langs)}
                                  
                                  >
                                    {/* {store_categories_item.title} */}
                                    {getTextByLanguage(store_categories_item.title,langs)}

                                  </option>
                                ))}
                     </select>                       
                </div>            
            </div>
        </div>
        <br/>                      
        <div className='container row'>
            
            <div className='col-lg-6 col-md-6 col-sm-12'>
                <span className='relative line-clamp-1 block max-w-[150px] overflow-hidden truncate'> {t('List of products')}</span>
                
                <div className='list-items-container border'>


                    {
                       CategorieStoreSelected!=0 &&  props.store_products && props.store_products.map((item) => {
                            if (store_categorie_products.filter(itemstcatpr => itemstcatpr.st_product_id == item.st_product_id && itemstcatpr.st_cat_id==CategorieStoreSelected ).length <= 0) {
                                return <>

                        <div className="flex cursor-pointer flex-col rounded border border-custom-border-200 bg-custom-background-100">
                            <div className="bg-image-products relative h-[100px] w-full rounded-t ">
                                <div className="absolute inset-0 z-[1] bg-gradient-to-t from-black/60 to-transparent">
                                </div>
                                 
                                <div className="absolute bottom-4 z-10 flex h-10 w-full items-center justify-between gap-3 px-4">
                                    <div className="flex flex-grow items-center gap-2.5 truncate">
                                        <div className="item-center flex h-14 w-14 flex-shrink-0 justify-center rounded bg-white/90">
                                            <span className="flex items-center justify-center">

                                                <img alt={item.title} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/products/"+item.image}   onError={(e) => {e.target.src =image}}/>

                                            </span>
                                        </div>
                                        <div className="flex w-full flex-col justify-between gap-0.5 truncate"
                                          data-toggle="tooltip" data-placement="top" 
                                          title={getTextByLanguage(item.title,langs)+': '+getTextByLanguage(item['description'],langs)}
                                     
                                        >
                                            <h3 className="truncate font-semibold text-white">
                                                {getTextByLanguage(item.title,langs)}</h3>
                                            <span className="flex items-center gap-1.5">
                                                <p className="text-xs font-medium text-white relative line-clamp-1 block max-w-[100px] overflow-hidden truncate">
                                                {getTextByLanguage(item.description,langs)}
                                                 </p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-shrink-0 items-center gap-2">
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10"  onClick={() => addStoreCategorieProduct(CategorieStoreSelected,item.st_product_id)}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg>
                                    </button>
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>handleRowClicked(item.product_id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link h-6 w-6 text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                                </>
                            }
                            else {
                                return <></>
                            }

                        })
                    }


                </div>
            </div>
            <div className=' col-lg-6 col-md-6 col-sm-12'>
                <span className='relative line-clamp-1 block max-w-[150px] overflow-hidden truncate'> {t('Selected products')}</span>

                <div className='list-items-container border'>
                    {
                        CategorieStoreSelected!=0 && store_categorie_products.filter(itemstcatpr => itemstcatpr.st_cat_id == CategorieStoreSelected).map((item) => {
                          return <>
                        <div className="flex cursor-pointer flex-col rounded border border-custom-border-200 bg-custom-background-100">
                            <div className=" bg-image-products relative h-[100px] w-full rounded-t ">
                                <div className="absolute inset-0 z-[1] bg-gradient-to-t from-black/60 to-transparent">
                                </div>
                                 
                                <div className="absolute bottom-4 z-10 flex h-10 w-full items-center justify-between gap-3 px-4">
                                    <div className="flex flex-grow items-center gap-2.5 truncate">
                                        <div className="item-center flex h-14 w-14 flex-shrink-0 justify-center rounded bg-white/90">
                                            <span className="flex items-center justify-center">

                                                <img alt={item.title} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/products/"+item.image}   onError={(e) => {e.target.src =image}}/>

                                            </span>
                                        </div>
                                        <div className="flex w-full flex-col justify-between gap-0.5 truncate"
                                           data-toggle="tooltip" data-placement="top" 
                                           title={getTextByLanguage(item.title,langs)+': '+getTextByLanguage(item['description'],langs)}
                                      
                                        >
                                            <h3 className="truncate font-semibold text-white">
                                            {getTextByLanguage(item.title,langs)}
                                            </h3>
                                            <span className="flex items-center gap-1.5">
                                                <p className="text-xs font-medium text-white relative line-clamp-1 block max-w-[100px] overflow-hidden truncate">
                                                {getTextByLanguage(item.description,langs)}
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-shrink-0 items-center gap-2">
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10"  onClick={() => RetireCategorieProductFromStore(item.st_cat_id,item.st_product_id)}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white"><circle cx="12" cy="12" r="10"></circle><path d="m15 9-6 6"></path><path d="m9 9 6 6"></path></svg>
                                   
                                    </button>
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>handleRowClicked(item.product_id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link h-6 w-6 text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                                
                            </>
                        })
                    }

                </div>

            </div>
        </div>
    </>
    )
}
