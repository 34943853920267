
import { getLocalstorage } from "Assistances/GlobalFunctions";
import axios from "axios";

export function EditSocialStore(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/EditSocialStore',
        data: data, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}