import { getLocalstorage } from "Assistances/GlobalFunctions"
import axios from "axios"




export function sendOrderToDashboard(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/sendOrderToDashboard',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    })
}

export function getListOfOrdersClientOnStoreinLast24h(id_store,id_account,type="extern",id_internal_server=null,id_table=null){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getListOfOrdersClientOnStoreinLast24h?id_store='+id_store+"&id_account_creater="+id_account+"&type="+type+"&id_internal_server="+id_internal_server+"&id_table="+id_table,
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    })
}


