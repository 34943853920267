import React, { useEffect, useState } from 'react'

import MenuLeftOptions from 'Componnents/Fiche/MenuLeftOptions'
import SpaceOfDisplay from 'Componnents/Fiche/SpaceOfDisplay'
import TopHead from 'Componnents/Fiche/TopHead'
import { useTranslation } from 'react-i18next'
import { getLocalstorage } from 'Assistances/GlobalFunctions'
import { getToppingData } from './Actions'
import {  toast } from 'react-toastify';    
import ModalHelper from 'Componnents/Modals/ModalHelper'
import EditInfoTopping from './ToppingsComponnents/EditInfoTopping'
import FormToppings from './FormToppings'
import Spinner from 'Spinner/Spinner'
import { handleError } from 'Assistances/ErrorHandling'

export default function FicheTopping() {
    const [ficheInfo,setficheInfo]=useState({})
   
    const [ModalShow,setModalShow]=useState(false)
    const { t } = useTranslation();
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
        getDataToppings()

    },[])
    function getDataToppings(){
        getToppingData(getLocalstorage("Topping_id"))
        .then(function (response) {
            setficheInfo({...response.data['info']})
            var defaultItem=menuContent.length>0?menuContent[0]:{};
            defaultItem.componnent=<EditInfoTopping ficheInfo={response.data['info']}/>;
            getClieckedItem(defaultItem)
            
        }) 
        .catch(function (error) {
            //console.log(error)
            handleError(error,t);
        }) 
        .finally(() => {
           setSpinner(false)
         });
    }
    const menuContent=[
        {
            'title':t('Information'),
            'componnent':<EditInfoTopping ficheInfo={ficheInfo}/>,
            'icon':<i className='fa fa-info icon-left-menu'></i>,
            'path':""
        }
       
       
    ];
    const [Clieckeditem,setClieckeditem]=useState({})

    function getClieckedItem(item){
        setClieckeditem(item)
    }

  return (
    <div className=''>
    {spinner?
        <Spinner/> 
        :
        <>
        <TopHead ficheinfo={ficheInfo} pathImage='/topping/' table="toppings" id={ficheInfo.id} path="/dashboard/toppings" edit={()=>setModalShow(true)}/>
        <div className='row'>
            <div className='col-md-4 col-lg-3 mt-1'>
                <MenuLeftOptions getClieckedItem={getClieckedItem} title={t("Topping")+" "+ t("Options")} menuContent={menuContent}/>
            </div>
            <div className='col-md-8 col-lg-9 mt-1' >
                <SpaceOfDisplay displayItem={Clieckeditem}/>
            </div>
        </div>
        </>
        }

        <ModalHelper  
        show={ModalShow} 
        title={t("Modify")+" "+ t("Topping")}
        size='lg'
        onHide={() => setModalShow(false)} 
        content={<FormToppings modifycontent={ficheInfo} onHide={() => setModalShow(false)} getDataToppings={getDataToppings}/>} />
        
      
    </div>
  )
}
