import InfoShow from 'Componnents/Fiche/InfoShow'
import React from 'react'

export default function EditInfoCategorie(props) {
  return (
    <div>
        <InfoShow ficheInfo={props.ficheInfo} listToShow={['id','title','description','created_at','updated_at']}/>

    </div>
  )
}
