import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateAccountInfoAction } from './Actions';
import { toast } from 'react-toastify';

export default function PersonalInfo(props) {
    const {t}=useTranslation()
    // useEffect(()=>{
    //   //console.log(props.accountInfo)
    // },[props.accountInfo])
    const [AttributesPersonalInfo,setAttributesPersonalInfo]=useState(props.accountInfo)

    useEffect(()=>{
      setAttributesPersonalInfo({...props.accountInfo})
      //console.log(props.accountInfo)
    },[props.accountInfo])
    function onchangeAttributes(key,value){
        AttributesPersonalInfo[key]=value;
        setAttributesPersonalInfo({...AttributesPersonalInfo})
    }

    function updateAccountInfo(){
      updateAccountInfoAction(AttributesPersonalInfo)
      .then((res)=>{
        toast.success(t("Succesfully Updated"))
      })
      .catch((err)=>{
        //console.log(err)
      })
    }
  return (
    <div>
         <div className="mt-3 ">
            <div pageName="Settings" />
    
            <div className="">
              <div className="">
                <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                  <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                    <h3 className="font-medium text-black dark:text-white">
                      {t('Personal Information')}
                    </h3>
                  </div>
                  <div className="p-7">
                    <div>
                      <div className=" m-3 flex flex-col gap-10 sm:flex-row">
                        <div className="w-full sm:w-1/2">
                          <label
                            className="mb-3 block text-sm font-medium text-black dark:text-white"
                            htmlFor="fullName"
                          >
                            {t('Full Name')}
                          </label>
                          <div className="relative">
                          
                            <input
                              className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                              type="text" maxLength={255}
                              id="fullName"
                              placeholder= {t('Full Name')}
                              onChange={(e)=>onchangeAttributes("name",e.target.value)}
                              value={AttributesPersonalInfo["name"]}
                              defaultValue={AttributesPersonalInfo["name"]}
                            
                            />
                          </div>
                        </div>
    
                        <div className="w-full sm:w-1/2">
                          <label
                            className="mb-3 block text-sm font-medium text-black dark:text-white"
                            htmlFor="phoneNumber"
                          >
                            {t('Phone Number')}
                          </label>
                          <input
                            className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                            type="text" maxLength={255}
                            placeholder= {t('Phone Number')}
                            onChange={(e)=>onchangeAttributes("mobile",e.target.value)}
                            value={AttributesPersonalInfo["mobile"]}
                            defaultValue={AttributesPersonalInfo["mobile"]}
                       
                          />
                        </div>
                      </div>
    
                      <div className=" m-3">
                        <label
                          className="mb-3 block text-sm font-medium text-black dark:text-white"
                          htmlFor="emailAddress"
                        >
                          {t('Email Address')}
                        </label>
                        <div className="relative">
                        
                          <input
                            className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                            type="email" maxLength={255}
                            placeholder={t('Email Address')}
                            disabled
                            onChange={(e)=>onchangeAttributes("login",e.target.value)}
                            value={AttributesPersonalInfo["login"]}
                            defaultValue={ AttributesPersonalInfo["login"]}
                       
                          />
                        </div>
                      </div>
    
    
                      <div className="flex justify-end gap-4  m-3">
                        {/* <button
                          className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                          type="submit"
                        >
                          {t('Cancel')}
                        </button> */}
                        <button
                        onClick={()=>updateAccountInfo()}
                          className="flex white justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                        >
                          {t('Save')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            
            
          </div>
    </div>
  )
}
