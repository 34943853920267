import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from 'Spinner/Spinner';
import { enableAccount } from './Actions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function EnableAccount() {
    const { urlVarie } = useParams();
    const history=useNavigate()
    const {t}=useTranslation()

    useEffect(()=>{
        enableAccount(urlVarie)
        .then(function (response) {
            if(response)
                toast.success(t("Account activated try to login"))
        })
        .catch(function (error) {
            console.log(error.message);
        })
        .finally(async ()=>{
            await setTimeout(function(){history('/login')}, 3000);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  return (
    <div>
        <Spinner/>

    </div>
  )
}
