import { getLocalstorage } from "Assistances/GlobalFunctions";
import axios from "axios";


export async function getProductDataForBuyModalAction(id){
    return await axios({
        method: 'get',
        url:  process.env.REACT_APP_APP_URL_BACKEND+'/getProductDataForBuyModal?id='+id,
        data: {}, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

        
    })
}    

