import React, {  useEffect, useState } from 'react'
import currencies from "commonCurrency.json";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import image from "Assets/Images/nophoto.png";
import {  toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css'

import categoriesLists from "categories.json";
import { useTranslation } from "react-i18next";
import { AddStores, ModifyStores } from './Actions';
import { handleError } from 'Assistances/ErrorHandling';
import Spinner from 'Spinner/Spinner';

export default function FormStore(props) {
    const [country, setCountry] = useState(props.modifycontent && props.modifycontent['store_country']?props.modifycontent['store_country']:'');
    const [countrytemp, setCountrytemp] = useState('');
    const [categories,setcategories]=useState(null)
    const { t } = useTranslation();

    const [region, setRegion] = useState(props.modifycontent && props.modifycontent['store_region']?props.modifycontent['store_region']:'');
    
     const [storeAtrributes,setStoreAtrributes]=useState(props.modifycontent?props.modifycontent:{})
    const [spinner,setSpinner]=useState(false)
    function handleSubmit(e){
        e.preventDefault();
        if(storeAtrributes && storeAtrributes['url_store'] && storeAtrributes['url_store'].length>0 && storeAtrributes['title'] && storeAtrributes['title'].length>0  ){
            setSpinner(true)
            
            const data = new FormData() 
            Object.entries(storeAtrributes).map(([key, value]) => {
                data.append(key, value)
            })
          
            AddStores(data)
                .then(function (response) {
                    setStoreAtrributes({})
                    props.onHide()
                    toast.success(t('Succesfully Added'))
                    props.GetStoreListe()
                })
                .catch(error => {
                    // Handle the error response
                    if (error.response && error.response.data && error.response.data.error && error.response.data.message) {
                        toast.error( error.response.data.message);
                    } else {
                        handleError(error,t,t('Add'));
                    }
                  })
                  .finally(()=>{
                    setSpinner(false)

                  })
                  ;
             
        }
        else{
            toast.error(t("The attributes with * are obligatory."))

        }
       
        
    }

    function handleSubmitModify(e){
        e.preventDefault();   
        if(storeAtrributes && storeAtrributes['url_store'] && storeAtrributes['url_store'].length>0 && storeAtrributes['title'] && storeAtrributes['title'].length>0  ){
            setSpinner(true)
        
        const data = new FormData() 
        Object.entries(storeAtrributes).map(([key, value]) => {
            data.append(key, value)
        })
        data.append('id', props.modifycontent.id)
        ModifyStores(data)
        .then(function (response) { 
            // props.getStoresById()
            props.onHide()
            toast.success(t("Succesfully Modified"))
            props.GetStoreDataFunction()
          })
          .catch(function (error) {
              //console.log(error);
              if (error.response && error.response.data && error.response.data.error && error.response.data.message) {
                toast.error( error.response.data.message);
            } else {
                handleError(error,t,t('Add'));
            }
          })
          .finally(()=>{
        setSpinner(false)

          });
        }
        else{
            toast.error(t("The attributes with * are obligatory."))
        }
           

    }

    function generateSubdirectory(str) {
        const specialCharsRegex = /[^a-zA-Z0-9]/g;

        // Remove or replace the forbidden characters with a hyphen
        const sanitizedStr = str.replaceAll(specialCharsRegex, '-').trim();
      
        // If the string starts or ends with a hyphen, remove it
        var finalStr = sanitizedStr.replaceAll(/^-+|-+$/g, '');
        finalStr = sanitizedStr.replaceAll(' ', '');

        // Convert the string to lowercase and return as the subdirectory
        return finalStr.toLowerCase();
      }
    function onchangeStoreAtrributes(key,value){
        if(key==="url_store"){
            value=generateSubdirectory(value)
        }
        storeAtrributes[key]=value      
        setStoreAtrributes({...storeAtrributes})

    }
    function  removefile(key){
        delete storeAtrributes[key];

        setStoreAtrributes({...storeAtrributes})

    }
    const selectCountry =(val) =>{
        setCountry(val);
        if(val!==countrytemp){
            setRegion('');
        }
      onchangeStoreAtrributes("store_country",val)

      }
    
     const selectRegion= (val)=> {
        setRegion(val);
        setCountrytemp(country);
        onchangeStoreAtrributes("store_region",val)

      }

  return (
    <>
    <div className='container '>
        {spinner?
        <Spinner/>
        :
        <form onSubmit={props.modifycontent?handleSubmitModify:handleSubmit} >
                    <div className="grid gap-6 md:grid-cols-2">
                        <div className='mr-1'>
                            <label for="first_title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Title")}*</label>
                            <input type="text" maxLength={255} id="first_title" value={storeAtrributes && storeAtrributes['title']?storeAtrributes['title']:""} name="title" onChange={(e) => onchangeStoreAtrributes('title', e.target.value)} placeholder={t("Title")} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required/>
                        </div>
                        <div className='ml-1'>
                            <label for="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Description")}</label>
                            <input type="text" maxLength={2000} id="description" value={storeAtrributes && storeAtrributes['sub_title']?storeAtrributes['sub_title']:""}  name="sub_title" onChange={(e) => onchangeStoreAtrributes('sub_title',e.target.value)}  placeholder={t("Description")}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   required/>
                        </div>
                    </div>
                    <div className="grid gap-6 md:grid-cols-2">
                        <div className='mr-1'>
                            <label for="url" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Stores")} {t("Url")}*</label>
                            <input type="text" maxLength={255} id="url" 
                            value={storeAtrributes && storeAtrributes['url_store']?storeAtrributes['url_store']:""} name="url_store" onChange={(e) => onchangeStoreAtrributes('url_store', e.target.value)}  placeholder={t("Stores")+" "+t("Url")}                            
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required/>
                        </div>
                        <div className='ml-1'>
                            <label for="currency" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Currency")}</label>
                            <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={storeAtrributes && storeAtrributes['currency']?storeAtrributes['currency']:""} 

                                onChange={(e)=>{onchangeStoreAtrributes("currency",e.target.value);}}

                                      >
                                          <option value={""}>
                                            {t('Select')} {t('Currency')}
                                          </option>
                                        {Object.keys(currencies).map((currency, index) =>{ 
                                          return  storeAtrributes['currency']==currencies[currency].symbol?
                                             (
                                            
                                                <option value={currencies[currency].symbol} key={index} selected>
                                                  {currency} - {currencies[currency].name} ({currencies[currency].symbol})
                                                </option>
                                              )

                                            :
                                            (
                                            
                                          <option value={currencies[currency].symbol} key={index}>
                                            {currency} - {currencies[currency].name} ({currencies[currency].symbol})
                                          </option>
                                        )
                                        
                                        })}
                                      </select>                       
                        </div>
                    </div>
                    <div className="grid gap-6 md:grid-cols-2">
                        <div className='mr-1'>
                            <label for="Country" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Country")}</label>
                            <CountryDropdown className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"      
                            value={country}
                             onChange={(val) =>selectCountry(val)} />
                        </div>
                        <div className='ml-1'>
                            <label for="Region" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Region")}</label>
                            <RegionDropdown className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                 country={country}
                                 value={region}
                                 onChange={(val) =>selectRegion(val)} />  
                        </div>
                    </div>
                    <div className="grid gap-6 md:grid-cols-2">
                    <div className='mr-1'>
                            <label for="url" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Address")}</label>
                            <input type="text" maxLength={255} id="url" 
                            value={storeAtrributes && storeAtrributes['address']?storeAtrributes['address']:""} name="address" onChange={(e) => onchangeStoreAtrributes('address', e.target.value)}  placeholder={t("Address")}                            
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                        </div>
                        <div className='mr-1'>
                            <label for="Categories" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Categories")}</label>

                            <select id="Categories" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={categories} 
                                onChange={(e)=>{onchangeStoreAtrributes('store_categorie',e.target.value)}}
                          required
                          >
                                <option value={""} >
                                   {t("Select")} {t("Categorie")}
                                </option>
                              {Object.keys(categoriesLists['categories']).map((cat, index) => {
                              return (cat==storeAtrributes['store_categorie']?
                              (
                                <option value={cat} key={index} selected >
                                   {t(categoriesLists['categories'][cat])}
                                </option>
                              )
                              :
                              
                              (
                                <option value={cat} key={index}>
                                   {t(categoriesLists['categories'][cat])}
                                </option>
                              )
                              )
                                })}
                            </select>
                        </div>
                    </div>
                           
                <div className=''>  
                    <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-0 xl:gap-0 lg:py-1 lg:grid-cols-1 lg:pt-0"> 
                        <label for="uploadFile1"  className="bg-primary hover:bg-primary text-white text-sm px-4 py-2.5 outline-none rounded w-max cursor-pointer mx-auto block font-[sans-serif]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 mr-2 fill-white inline" viewBox="0 0 32 32">
                              <path
                                d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                                data-original="#000000" />
                              <path
                                d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                                data-original="#000000" />
                            </svg>
{t("Upload")}
                            <input type="file" id='uploadFile1' className="hidden" onChange={(e)=>onchangeStoreAtrributes("image",e.target.files[0])} />
                        </label>
                        
               
                    </div>
                    
                    <div className="grid gap-6 md:grid-cols-1 text-center">

                    {storeAtrributes && storeAtrributes['image'] && storeAtrributes['image']!=="null"?
                         <div className=" text-center image-logo-container" >
                                <div className="cf-u-display-flex cf-u-flex-wrap cf-u-mb-sm">
                                    <div className="cf-c-file-item cf-u-mx-xs cf-u-mb-sm">
                                        <div className="cf-c-file-item__icon-wrap">
                                            <div className="cf-c-icon-wrap cf-c-icon cf-c-icon--lg">
                                                <img alt="Grediens" src={ 
                                                    typeof storeAtrributes['image']  !== 'string' && storeAtrributes && storeAtrributes['image'] && storeAtrributes['image']!==null && storeAtrributes['image']!=="null"?

                                                URL.createObjectURL(storeAtrributes['image'])
                                                :
                                                process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+props.modifycontent['image'] 
                                            
                                            } 
                                                        onError={(e) => {e.target.src =image}}

                                                className='image-Grediens-item'/>
                                                
                                            </div>
                                            <div className="cf-c-file-item__remove cf-u-display-flex cf-c-icon-wrap cf-c-icon cf-c-icon--lg" onClick={()=>removefile('image')}>
                                                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className=" cf-c-icon cf-c-icon--lg svg-remove">
                                                    <path d="M8 7.057l2.828-2.828.943.943L8.943 8l2.828 2.828-.943.943L8 8.943 5.172 11.77l-.943-.943L7.057 8 4.23 5.172l.943-.943L8 7.057z" fill-rule="nonzero">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div title={storeAtrributes['image']['name']} className="cf-c-file-item__name">
                                            <span className="cf-c-file-item__file-name">
                                                {storeAtrributes && storeAtrributes['image']?
                                                storeAtrributes['image']['name']
                                                :
                                                <></>
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='text-center image-center'>
                                <img alt="Grediens" src={image} 
                                className='image-catalogue-item'  
                                   
                                onError={(e) => {e.target.src =image}}/>
                            </div>

                            }
                    </div>
            </div>
            <br/>
                <div className="grid gap-6  mb-6  md:grid-cols-1">
                        <button type="button" Type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">{props.modifycontent?<>{t("Modify")}</>:<>{t("Add")}</>} {t("Stores")}</button>
                    </div>
              


      
        </form>
        
        }
        </div>

    </>
  )
}
