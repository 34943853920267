import React, { useEffect, useState } from 'react'
import './Reactions.css'
import { BsStarFill } from 'react-icons/bs'
import RatStars from './RatStars'
export default function RatingStarsDiagrams(props) {
   const [starsTotals,setstarsTotals]=useState([]);
    useEffect(()=>{
        let start1=props.stars.filter(e=>e.value==1).length
        let start2=props.stars.filter(e=>e.value==2).length
        let start3=props.stars.filter(e=>e.value==3).length
        let start4=props.stars.filter(e=>e.value==4).length
        let start5=props.stars.filter(e=>e.value==5).length

        setstarsTotals([
            start1?start1:0,
            start2?start2:0,
            start3?start3:0,
            start4?start4:0,
            start5?start5:0,
        ])
    },[props.stars])
  return (
    <div  className='container '> 
        
        <RatStars  product_id={props.item?.product_id}  store={props.isstore}   changeRatingStars={props.changeRatingStars}    store_id={props.store_id} my_ratingstart_inProduct={props.my_ratingstart_inProduct} augementratingStartNumbe={props.augementratingStartNumbe}/>   
        
        
        <table className=' '>
        {
            starsTotals.map((item,index)=>{
                return(
                    <tr className='container-raiting-diagram '>
                        <td className='flex'>
                        {
                            Array.from({ length: index+1 }, (_, i) => i + 1).map((num) => (
                                <BsStarFill/>
                              ))
                            
                          
                        }
                            

                        </td>
                        <td>
                        {'('+(item)+')'} 
                        </td>
                        <td>
                            <div className='rating-bar-value-container' ><div className='rating-bar-value' style={{width:((item*100)/props.stars.length)?((item*100)/props.stars.length):0+"%"}}></div></div>

                        </td>
                        <td>
                        <span className='text-value-porcentage'>
                            {((item*100)/props.stars.length?(item*100)/props.stars.length:0).toFixed(1)}%
                        </span> 

                        </td>
                        
                    </tr>
                )
            })
        }
            
        </table>
       
    </div>
  )
}
