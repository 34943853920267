import React, { useEffect, useState } from 'react'
import { getAllComments } from './Actions';
import { useNavigate  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Datatable from 'Componnents/DataTables/DataTables';
import {  toast } from 'react-toastify';    
import Spinner from 'Spinner/Spinner';
import FicheComment from './FicheComment';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import { handleError } from 'Assistances/ErrorHandling';

export default function DataTableComments(props) {
    const [Comments,setComments]=useState([]);
    const { t } = useTranslation();
    const [spinner,setSpinner]=useState(true)

    const columns=['id','name','store_title','product_title','comment',"created_at","updated_at"];
    useEffect(()=>{
      getAllCommentsFunction()
    },[])
    function getAllCommentsFunction(){
      getAllComments()
      .then(function (response) {
          setComments(response.data)
      }) 
      .catch(function (error) {
        handleError(error,t,t('Comments'));
      })
      .finally(() => {
        setSpinner(false)
      });
    }
    const [ModalDetailCommentsShow,setModalDetailCommentsShow]=useState(false);
    const [CommentsItem,setCommentsItem]=useState({})
    function handleRowClicked(row){
      setCommentsItem(row)
      setModalDetailCommentsShow(true)
    }
  return (
    <div>
      {spinner?
      <Spinner/> 
      :
      <Datatable 
      data={Comments} 
      columns={columns}
      handleRowClicked={handleRowClicked}
      title="Comments"
      addbutton={false}
      total={Comments.length}

      ShowModal={() => {}}
      />
      }

  <ModalHelper  
        show={ModalDetailCommentsShow} 
        title={t("Comments")}
        size='lg'
        onHide={() => setModalDetailCommentsShow(false)} 
        content={<FicheComment getAllComments={getAllCommentsFunction} onHide={() => setModalDetailCommentsShow(false)} Comments={CommentsItem}/>} />
        
      
    </div>
  )
}
