

import { getLocalstorage } from "Assistances/GlobalFunctions"
import axios from "axios"

export function getAllCategies(data){

    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAllCategies',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}



export function AddCategories(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/addCategories',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}

export function ModifyCategories(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/ModifyCategories',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}





export function getCategorieData(categorie_id){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getCategorieData?id='+categorie_id,
        data: {}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}