import React, { useEffect, useState } from 'react';
import { BsTrashFill, BsSendPlusFill } from 'react-icons/bs';
import Form from 'react-bootstrap/Form';
import { useTranslation } from "react-i18next";
import { modifyFeaturesProduct } from './Actions';
import { toast } from 'react-toastify';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { id_account_creater } from 'HelperFunction/token';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { selectLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { handleError } from 'Assistances/ErrorHandling';

export default function EditFeatures(props) {
    const [featuresList, setFeaturesList] = useState(props.Features || []);
    const [ListOfFeaturesInsertRows, setListOfFeaturesInsertRows] = useState(props.GroupByFeature !== 0 || props.ProductFeaturesObjects.length > 1 ? [...props.ProductFeaturesObjects, {}] : [...props.ProductFeaturesObjects]);
    const [featuresListSelectedObjects, setFeaturesListSelectedObjects] = useState([]);
    const [defaultFeatures, setDefaultFeatures] = useState([]);
    const [GroupByFeature, setGroupByFeature] = useState(props.GroupByFeature?props.GroupByFeature:0);
    const [modalShow, setModalShow] = useState(false);
    const { t } = useTranslation();
    const [id_product, setIdProduct] = useState(getLocalstorage("product_id"));

    const SelectedData = async () => {
        let featuresListSelectedObjectsTemp = [];
        let featuresListSelecteTmp = [];

        if (props.ProductFeaturesObjects.length > 0) {
            props.ProductFeaturesObjects.map((item,index)=>{
                let ObjectFeature={}

                ObjectFeature[item.id_feature]=item.value
                featuresListSelectedObjectsTemp[item.order_display]={...featuresListSelectedObjectsTemp[item.order_display],...ObjectFeature}
            })
            featuresListSelectedObjectsTemp &&  Object.entries(featuresListSelectedObjectsTemp[0]).forEach(([key, value]) => {
                     props.Features.forEach(item => {
                    if (item['id'] == parseInt(key)) {
                        featuresListSelecteTmp.push(item);
                    }
                });
                
               
            });
            if(props.GroupByFeature){
                featuresListSelectedObjectsTemp[featuresListSelectedObjectsTemp.length]= {}
            }
        }
    
        
        setFeaturesListSelectedObjects([...featuresListSelecteTmp]);
        setDefaultFeatures([...featuresListSelecteTmp.map(item => ({
            ...item,
            value: item.id,
            label: getTextByLanguage(item.title,langs)
        }))]);
        setListOfFeaturesInsertRows(featuresListSelectedObjectsTemp)
  
    };
    useEffect(() => {
       
        SelectedData();
        
    }, [props.Features, props.ProductFeaturesObjects]);
   
    function modifyFeaturesActionAxios() {
        const data = new FormData();
    //     console.log(ListOfFeaturesInsertRows.filter((item) => Object.entries(item).map(([key, value]) => {console.log(key !=  "undefined");return key !=  "undefined"} ) ) // Ensure option is defined and has required properties
    // );

   
    // // ListOfFeaturesInsertRows.filter((item) => Object.entries(item).map(([key, value]) => {console.log(key==undefined);console.log(key=="undefined")}))   
    // console.log(ListOfFeaturesInsertRows)
    //     return 0;
        let ListOfFeaturesInsertRowsTemp= ListOfFeaturesInsertRows.map(ListOfFeaturesInsertRow => {
            let objectTemp={};
            Object.entries(ListOfFeaturesInsertRow).map(([key, value])=>{
    
                if(key !=  "undefined"){
                    objectTemp[key]=value
                }
            
            
                
            })
            return objectTemp;
        
        
        })

        if (ListOfFeaturesInsertRowsTemp.length) {
            data.append('ProductsFeatures', JSON.stringify(ListOfFeaturesInsertRowsTemp));
        }
         data.append('ProductsFeaturesGroupBy', GroupByFeature);

        data.append('id', id_product);
        data.append('id_account', id_account_creater);

        modifyFeaturesProduct(data)
            .then(response => {
                toast.success(t("Succesfully Added"));
            })
            .catch(error => {
                handleError(error,t,t('Add'));
            });
    }


    function chackFeatureEvent(e) {
        let deletedElement=[]
        var ListOfFeaturesInsertRowsTemp = [{}];
        var objectTemp = {};
        var id_feature=-1
        var DeleteGroupBy=true
        var SelectedFeatures=[]
        var EmptyobjectTemp=[]
        e.map(item=>{
            SelectedFeatures.push(parseInt(item.id))
            if(GroupByFeature==item.id){
                DeleteGroupBy=false
            }
            EmptyobjectTemp[item.id]=""

        })
     
        if(ListOfFeaturesInsertRows.length>0){
            ListOfFeaturesInsertRows.forEach((item, index) => {
                objectTemp={}
                objectTemp[item.id]=""

                Object.entries(item).forEach(([key, value]) => {
                    if(SelectedFeatures.includes(parseInt(key)))
                        objectTemp[key] = value;

                });
                ListOfFeaturesInsertRowsTemp[index] = { ...objectTemp };

            });
        }
       else{
        ListOfFeaturesInsertRowsTemp[0]={ ...ListOfFeaturesInsertRowsTemp[0], ...EmptyobjectTemp };
        }
        if(DeleteGroupBy){
            setListOfFeaturesInsertRows([ListOfFeaturesInsertRowsTemp[0]]);
            setGroupByFeature(0)

        }
        else{
            setListOfFeaturesInsertRows([...ListOfFeaturesInsertRowsTemp]);
        }
            props.BringProductsFeatures(ListOfFeaturesInsertRowsTemp)

    }

    function addRowFeaturesToListOfFeatures() {
        if (Object.keys(ListOfFeaturesInsertRows[ListOfFeaturesInsertRows.length - 1]).length != 0) {
            setListOfFeaturesInsertRows([...ListOfFeaturesInsertRows, {}]);
        }
    }

    function deleteFeature(index) {
        ListOfFeaturesInsertRows.splice(index, 1);
        let listWithDeleteElement = [...ListOfFeaturesInsertRows];
        setListOfFeaturesInsertRows(listWithDeleteElement);
    }

    function changeInputFeaturesInListOfObject(index, value, title, id) {
        ListOfFeaturesInsertRows[index][id] = value;
        let listChangeElement = [...ListOfFeaturesInsertRows];
        setListOfFeaturesInsertRows(listChangeElement);
    }

    function onChangeGroupByFeature(value) {
        if((!GroupByFeature || GroupByFeature==0) && value!=0){
            setListOfFeaturesInsertRows([...ListOfFeaturesInsertRows, {}]);

        }
        setGroupByFeature(value);
        props.BringProductsFeaturesGroupBy(value)

        if (value == 0) {
            let KeepJustFirstElement = [ListOfFeaturesInsertRows[0]];
            let listKeepJustFirstElement = [...KeepJustFirstElement];
            setListOfFeaturesInsertRows([...listKeepJustFirstElement]);
        }
    }

  const langs = useSelector(selectLang);

    return (
        <>
            {featuresList && featuresList.length > 0 ?
                <>
                    <div className='row'>
                        <div className='col-sm-8 col-md-8 col-lg-9 col-lx-10 left-text section-title'>
                            {t("Features")}:
                        </div>
                        {/* <div className='col-sm-4 col-md-4 col-lg-3 col-lx-2'>
                            <button type="button" title="" className='btn btn-primary' onClick={() => setModalShow(true)}> + {t("Add")} {t("Features")} </button>
                        </div> */}
                    </div>
                    <div className='m-3'>
                     <Select 
                        options={featuresList.map(featuresItem => ({
                            ...featuresItem,
                            value: featuresItem.id,
                            label: getTextByLanguage(featuresItem.title,langs)
                        }))}
                        value={defaultFeatures}
                        isMulti
                        onChange={(e)=>{
                            // e.map(item=>{
                                chackFeatureEvent(e)

                            // })
                            setDefaultFeatures([...e])
                            setFeaturesListSelectedObjects([...e])
                        }}

                    />
                    </div>
                   
                  
                    {featuresListSelectedObjects.length > 0 &&
                        <div className='row row-container-list-features'>
                            <div className='allow-scroll '>
                                <div className='table-container'>
                                    <table className='pizza-attributs'>
                                        <thead>
                                            <tr>
                                                {featuresListSelectedObjects.length > 0 &&
                                                    <th className='select-fetures-pricipale'>
                                                        <select 
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            onChange={(event) => onChangeGroupByFeature(event.target.value)}
                                                            value={GroupByFeature}
                                                        >
                                                            <option value={0} >None</option>
                                                            {featuresListSelectedObjects.map(item => 
                                                            {
                                                        if(GroupByFeature==item.id)
                                                            return (
                                                                
                                                                <option value={item.id} key={item.id} 
                                                                data-toggle="tooltip" data-placement="top" 
                                                                title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                                                                
                                                                selected>
                                                                    {getTextByLanguage(item.title,langs)}
                                                                </option>
                                                            )
                                                        else{
                                                           return ( 
                                                           <option value={item.id} key={item.id}
                                                           data-toggle="tooltip" data-placement="top" 
                                                           title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                                                           
                                                           >
                                                            {getTextByLanguage(item.title,langs)}
                                                        </option>) 
                                                        }
                                                        }
                                                            
                                                            )}
                                                        </select>
                                                    </th>
                                                }
                                                {featuresListSelectedObjects.map((item, index) => (
                                                    <th key={index} className='prodcut-attributs-th'
                                                    data-toggle="tooltip" data-placement="top" 
                                                    title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                                                    
                                                    >
                                                        {/* {item.title} */}
                                                        {getTextByLanguage(item.title,langs)}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ListOfFeaturesInsertRows.map((item, index) => (
                                                <>
                                                    {ListOfFeaturesInsertRows.length > 1 && ListOfFeaturesInsertRows.length - 1 == index && GroupByFeature == 0 ? null : (
                                                        <tr className={ListOfFeaturesInsertRows.length - 1 > index ? 'added-features' : "added-features add-features"}>
                                                            {featuresListSelectedObjects.length > 0 &&
                                                                <>
                                                                    {ListOfFeaturesInsertRows.length - 1 > index || (ListOfFeaturesInsertRows.length ==1 )? (
                                                                        <td>
                                                                            <button className='btn btn-danger' onClick={() => { deleteFeature(index) }} >
                                                                                <BsTrashFill size={21} color={"#FFFF"} style={{ cursor: "pointer" }} />
                                                                            </button>
                                                                        </td>
                                                                    ) : (
                                                                        <td>
                                                                            <button className='btn btn-primary'  onClick={() => { addRowFeaturesToListOfFeatures(index) }} >
                                                                                <BsSendPlusFill size={21} color={"#FFFF"} style={{ cursor: "pointer" }} />
                                                                            </button>
                                                                        </td>
                                                                    )}
                                                                </>
                                                            }
                                                            {featuresListSelectedObjects.map((item0, key) => (
                                                                <td className='prodcut-attributs-td ' key={key}>
                                                                    <div className="input-container">
                                                                        <input 
                                                                            value={ListOfFeaturesInsertRows[index] && ListOfFeaturesInsertRows[index][item0['id']] ? ListOfFeaturesInsertRows[index][item0['id']] : ''} 
                                                                            type="text" maxLength={255}  
                                                                            placeholder={getTextByLanguage(item0['title'],langs)} 
                                                                            style={{ border: "none", textAlign: "center" }} 
                                                                            onChange={(e) => { changeInputFeaturesInListOfObject(index, e.target.value, item0['title'], item0['id']) }} 
                                                                        />
                                                                    </div>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    )}
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }

                    <div className='button-container'>
                        <button className='btn btn-primary' onClick={() => modifyFeaturesActionAxios()}>{t("Modify")}</button>
                    </div>
                </>
                : null
            }
        </>
    )
}
