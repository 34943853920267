import { getLocalstorage } from "Assistances/GlobalFunctions"
import axios from "axios"
// import { id_account_creater,access_token } from "../../../HelperFunction/token"
// import { id_account_creater, access_token } from "../../../HelperFunction/token"

export function boardtableStatics(){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/boardtableStatics',
        headers: { 'Authorization': 'Bearer ' +getLocalstorage("access_token")}

    }) 
}
export function boardtableStaticsStatus(start,end){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/boardtableStaticsStatus?start='+start+"&end="+end,
        headers: { 'Authorization': 'Bearer ' +getLocalstorage("access_token")}

    }) 
}


