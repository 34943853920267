import React from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { selectLang } from '../../../../Redux/ReduxSlices/langs/LangsSlicer';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';

export default function FeaturesProduct(props) {
    const { t, i18n } = useTranslation();
    const langs = useSelector(selectLang);
    
    
  return (
    <div >
            <div className=" mt-3 container-feature-content-block">
               
                <h2 className=" feature-title">{t("Product")} {t("Features")}</h2>
                {props.productfeatures &&  props.productfeatures.map((item)=>{
                    return(

                        <section className=" feature-ligne "   
                        data-toggle="tooltip" data-placement="top" 
                        title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
            >
                            <div>                    
                            {getTextByLanguage(item.title,langs)}
                            </div>
                            <div>{getTextByLanguage(item.value,langs)} {getTextByLanguage(item.unit,langs)}</div>
                        </section>
                    )
                })
                }

                </div>
        </div>
                  
  )
}
