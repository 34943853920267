import { getLocalstorage } from "Assistances/GlobalFunctions";
import axios from "axios";

export function getAllComments(){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAllComments',
        data: {}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}


export function getSingleAvisOfProduct(avis_products_id){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getSingleAvisOfProduct',
        data: {"avis_products_id":avis_products_id}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}

export function deleteAvis(rowid_avis_product){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/deleteAvis',
        data: {"rowid_avis_product":rowid_avis_product}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}


