import Login from 'Auth/Login';
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes
} from "react-router-dom";
import LayoutSidebarr from './LayoutSidebarr';
import Register from 'Auth/Register';
import FormStore from 'Stores/FormStore';
import DataTablesStores from 'Stores/DataTablesStores';
import DataTableCategories from 'Categories/DataTableCategories';
import FormCategories from 'Categories/FormCategories';
import DataTableAnnonces from 'HeaderAnnonces/DataTableAnnonces';
import DataTablesToppings from 'Toppings/DataTablesToppings';
import DataTableGrediens from 'Grediens/DataTableGrediens';
import DataTableProducts from 'Products/DataTableProducts';
import TopHead from 'Componnents/Fiche/TopHead';
import FicheProduct from 'Products/FicheProduct';
import DataTableFeatures from 'Features/DataTableFeatures';
import FichFeature from 'Features/FichFeature';
import FicheGredien from 'Grediens/FicheGredien';
import FicheHeadAnnonce from 'HeaderAnnonces/FicheHeadAnnonce';
import FicheStore from 'Stores/FicheStore';
import FicheCategorie from 'Categories/FicheCategorie';
import Interface from 'InterfacesStores/Interface';
import FicheTopping from 'Toppings/FicheTopping';
import DataTableComments from 'Comments/DataTableComments';
import DataTablesOrders from 'Orders/DataTablesOrders';
import DataTableTeams from 'Teams/DataTableTeams';
import DataTableProfiles from 'Accounts/Profiles/DataTableProfiles';
import ProfileFiche from 'Accounts/Profiles/ProfileFiche';
import PageNotFound from 'Pages/PageNotFound';
import Statistiques from 'Statics/Statistiques';
import ProtectRoute from './ProtectRoute';
import GeneralSettings from 'GeneralSettings/GeneralSettings';
import Auth from 'Auth/Auth';
import { isTokenExpired } from 'Assistances/GlobalFunctions';
import { GetAccountDataInToken } from 'Assistances/GlobalFunctions';
import { IsAdmin } from 'Assistances/GlobalFunctions';
import RedirectToDashboardBoad from './RedirectToDashboardBoad';
import Acceuil from 'Home/Pages/Acceuil';
import { IsGestionnaire } from 'Assistances/GlobalFunctions';
import FicheTeams from 'Teams/FicheTeams';
import ListingServiceCenterMessages from 'ServiceCenter/ListingServiceCenterMessages';
import EnableAccount from 'Auth/EnableAccount';

export default function MainRoutes() {
  const [AccountInfo,setAccountInfo]=useState()
  const [tokenvalue,setTokenValue]=useState('')
  useEffect(()=>{

  },[tokenvalue])
  function tokenValueChange(tokenValue){
    setTokenValue(tokenValue)
  }
  return ( 
    <>
    <Router  
    // basename="/zselling"
    >
      {/* <CheckAuth/> */}

      <Routes>

        <Route>
        
        {/* <Route element={<LayoutNavbar/>}    > */}
               <Route path="/" element={ <Acceuil />}/>
               {/* <Route path="/contact" element={ <Contact />}/>
               <Route path="/carrier" element={ <Carrier />}/>
               <Route path="/services" element={ <Services />}/> */}

               
               
        {/* </Route>   */}
          <Route path="/store/:urlVarie" element={<Interface />} />
          <Route path="/active-account/:urlVarie" element={<EnableAccount />} />

          <Route path="/login" element={ <RedirectToDashboardBoad><Auth tokenValueChange={tokenValueChange}/></RedirectToDashboardBoad>}/>
         
          
        </Route>
        <Route element={<ProtectRoute><LayoutSidebarr AccountInfo={AccountInfo}/></ProtectRoute>}    >
              
              <Route path="/register" element={ <Register />}/>
              <Route path='/dashboard/settings' element={<GeneralSettings AccountInfo={AccountInfo} ChangeAccountInfo={(e)=>setAccountInfo(e)}/>}/>
              <Route path='/dashboard/orders' element={<DataTablesOrders/>}/> 
              <Route path='/dashboard/statics' element={<Statistiques/>}/>


              {/* <Route path="/dashboard/stores/" element={ <FormStore />}/> */}
              {/* <Route path='/TopHead' element={<TopHead/>}/> */}
              {/* <Route path='/formcategories' element={<FormCategories modifycontent=''/>}/> */}
              {
               IsAdmin() || IsGestionnaire()?
                <>
              <Route path='/dashboard/stores' element={<DataTablesStores/>}/>
              <Route path='/dashboard/stores/fiche' element={<FicheStore/>}/>
              
              
              <Route path='/dashboard/products' element={<DataTableProducts/>}/>
              <Route path="/dashboard/products/fiche" element={<FicheProduct/>}/>

              <Route path='/dashboard/features' element={<DataTableFeatures/>}/>
              <Route path='/dashboard/features/fiche' element={<FichFeature/>}/>
              
              <Route path='/dashboard/grediens' element={<DataTableGrediens/>}/>
              <Route path='/dashboard/grediens/fiche' element={<FicheGredien/>}/>
              
              <Route path='/dashboard/annonces' element={<DataTableAnnonces/>}/>
              <Route path='/dashboard/annonces/fiche' element={<FicheHeadAnnonce/>}/>
              
              <Route path='/dashboard/toppings' element={<DataTablesToppings/>}/>
              <Route path='/dashboard/toppings/fiche' element={<FicheTopping/>}/>

              <Route path='/dashboard/categories' element={<DataTableCategories/>}/>
                 
              <Route path='/dashboard/categories/fiche' element={<FicheCategorie/>}/>
              

              <Route path='/dashboard/comments' element={<DataTableComments/>}/>
              <Route path='/dashboard/teams' element={<DataTableTeams/>}/>
              <Route path='/dashboard/teams/fiche' element={<FicheTeams/>}/>

              
              <Route path='/dashboard/service-center' element={<ListingServiceCenterMessages/>}/>

                </>
                :
                <>

                </>
              }

              {
                IsAdmin()?
                <>
                <Route path='/dashboard/profiles' element={<DataTableProfiles/>}/>
                <Route path='/dashboard/profiles/fiche' element={<ProfileFiche/>}/>
                </>
                :
                <>
                </>
              }
             


          

              


              
          </Route>  
              <Route path="*" element={<PageNotFound />} />

        </Routes>
    </Router>
  
</>
  
  )
}
