import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Index from './Default/Index';
import IndexM000 from './DefaultM000/Index';

import Spinner from 'Spinner/Spinner';
import { getStoreInfoByUrl } from './Actions';
import IndexM002 from './DefaultM002/Index';

export default function Interface() {
    const { urlVarie } = useParams();
    const [modal,setModal]=useState(null)
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
      getStoreInfoByUrl(urlVarie)
      .then(
        (res)=>{
          console.log(res.data)
          setModal(res.data?.info?.modals)
        }
      ).catch((err)=>{
        console.log(err)
      })
      .finally(()=>{
        setSpinner(false)
      })
    },[])
  return (
    <div>
      {
        spinner?
        <Spinner/>
        :
        <>
        {
          modal=="M000" && (
            <IndexM000 url={urlVarie}/>
  
          )
        }
        {
          modal=="M001" &&(
            <Index url={urlVarie}/>
  
          )
        }


        {
          modal=="M002" &&(
            <IndexM002 url={urlVarie}/>
  
          )
        }



        </>
      }
     
    </div>
  )
}
