import React, { useEffect, useState } from 'react'
import PersonalInfo from './PersonalInfo';
import SMTPInfo from './SMTPInfo';
import PasswordModification from './PasswordModification';
import { getAccountInfo, getSmtp } from './Actions';
import { IsGestionnaire } from 'Assistances/GlobalFunctions';
import { IsAdmin } from 'Assistances/GlobalFunctions';
import AccountImage from './AccountImage';

export default function GeneralSettings(props) {
  const [accountInfo,setAccountInfo]=useState({

    name:'',
    login:'',
    mobile:'',
    image:''
  })
  
  const [SmtpData,setSmptData]=useState({})
  useEffect(()=>{
    getAccountInfo()
    .then((res)=>{
      setAccountInfo(res.data)
      //console.log(res.data)
    })
    .catch((err)=>{
      //console.log(err)
    })

    if(IsAdmin() || IsGestionnaire()){
      getSmtp()
      .then((res)=>{
        setSmptData({...res.data})
        //console.log(res.data)
      })
      .catch((err)=>{
        //console.log(err)
      })
    }
    
  },[])
  return (
        <>
         <AccountImage image={accountInfo?.image} ChangeAccountInfo={props.ChangeAccountInfo}/>
         <PersonalInfo accountInfo={accountInfo}/>
         <PasswordModification/>
         {
          IsAdmin() || IsGestionnaire()?
         <SMTPInfo SmtpData={SmtpData}/>
         :
         <></>
         }
        </>
      );
    };
    
    