import React, { useEffect, useState } from 'react'
import {  toast } from 'react-toastify';    
import { useTranslation } from 'react-i18next';
import Datatable from 'Componnents/DataTables/DataTables';
import FormStore from './FormStore';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import { useNavigate  } from 'react-router-dom';
import { getStores } from './Actions';
import Spinner from 'Spinner/Spinner';
import { handleError } from 'Assistances/ErrorHandling';

export default function DataTablesStores() {
    const [stores,setStores]=useState([]);
    const { t } = useTranslation();
    const columns=['url_store','title','sub_title',"created_at","updated_at"];
    const [showModalFormStore,setShowModalFormStore]=useState(false)
    const history=useNavigate();
    const [spinner,setSpinner]=useState(true)
    useEffect(()=>{
      GetStoreListe()
    },[])
    function GetStoreListe(){
      getStores()
      .then(function (response) {
        console.log(response.data)
          setStores(response.data)
          if(response.data){
          }
      }) 
      .catch(function (error) {
        handleError(error,t,t('Stores'));
      })
      .finally(() => {
        setSpinner(false)
      })
      ;
    }
    function handleRowClicked(row){
      setLocalstorage('store_id',row.id)
      history("/dashboard/stores/fiche")
    }
  return (
    <div>
      {spinner?
      <Spinner/> 
      :

     <Datatable 
      data={stores} 
      columns={columns}
      handleRowClicked={handleRowClicked}
      title="Stores"
      total={stores.length}

      addbutton={true}
      ShowModal={() => {setShowModalFormStore(true)}}
      />
      }
     
      
      
      <ModalHelper  
        show={showModalFormStore} 
        title={t("Add")+" "+ t("Stores")}
        size='lg'
        onHide={() => setShowModalFormStore(false)} 
        content={<FormStore onHide={() => setShowModalFormStore(false)} GetStoreListe={GetStoreListe} />} />
        
      
      </div>
  )
}
