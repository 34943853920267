// ReduxStore.js
import { configureStore } from '@reduxjs/toolkit';
import langsReducer from './ReduxSlices/langs/LangsSlicer';

export default configureStore({
  reducer: {
    langs: langsReducer,

  },
});
