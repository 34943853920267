import React, { useEffect, useState } from 'react'

import haha from 'Assets/emojis/haha.svg'
import angry from 'Assets/emojis/angry.svg'
import like from 'Assets/emojis/like.svg'
import love from 'Assets/emojis/love.svg'
import sad from 'Assets/emojis/sad.svg'
import wow from 'Assets/emojis/wow.svg'
import { abbreviateNumber } from 'Assistances/GlobalFunctions'


export default function Frequence(props) {
    const [first,setFirst]=useState(<></>)
    const [second,setSecond]=useState(<></>)
    const [tirth,setTirth]=useState(<></>)

    const reactions=(par1)=>{
        switch (par1) {
          case "like":
             return <img alt='reacton frequency' src={like} id="small-reactions" color= "#3699FF" width="11px"/>
              break;
          case "haha":
              return <img alt='reacton frequency' src={haha} id="small-reactions" color= "#030303" width="11px" />
              break;
          case "love":
              return <img alt='reacton frequency' src={love} id="small-reactions" color= "#EE2D41" width="11px"/>
              break;
          case "angry":
              return <img alt='reacton frequency' src={angry} id="small-reactions" color= "#0BB7AF" width="11px"/>
              break;
         case "sad":
           return <img alt='reacton frequency' src={sad} id="small-reactions" color= "#0BB7AF" width="11px"/>
           break;
         case "wow":
             return <img alt='reacton frequency' src={wow} id="small-reactions" color= "#0BB7AF" width="11px"/>
             break;
          default:
            return <></>
              break;
        }
  
      }

      useEffect(()=>{
        let reactionsAvisLength=props.reactionsAvis.length;
        setFirst(reactions(reactionsAvisLength!=0 && props.reactionsAvis[0]['number']>0?props.reactionsAvis[0]['value']:""))
        setSecond(reactions(reactionsAvisLength>1 && props.reactionsAvis[1]['number']>0 ?props.reactionsAvis[1]['value']:""))
        setTirth(reactions(reactionsAvisLength>2 && props.reactionsAvis[2]['number']>0?props.reactionsAvis[2]['value']:""))
      },[props.reactionsAvis,props.MyreactionsAvis])
  return (
    <>
     
       {/* {reactions(lengthAvisReaction>0?props.reactionsAvis[0]['value']:"",lengthAvisReaction>1?props.reactionsAvis[1]['value']:"",lengthAvisReaction>1?props.reactionsAvis[2]['value']:"")} */}
       <span className='text-small'>
        {abbreviateNumber(props.numbereaction)}
       </span>
       <span>
       {first} 
       {second}     
       {tirth} 
   
        </span> 
      </>
  )
}
