
import React, { useEffect, useState } from 'react'

import MenuLeftOptions from 'Componnents/Fiche/MenuLeftOptions'
import SpaceOfDisplay from 'Componnents/Fiche/SpaceOfDisplay'
import TopHead from 'Componnents/Fiche/TopHead'
import { useTranslation } from 'react-i18next'
import { getLocalstorage } from 'Assistances/GlobalFunctions'
import { getProfileData } from './Actions'
import {  toast } from 'react-toastify';    
import ModalHelper from 'Componnents/Modals/ModalHelper'
import EditInfoProfile from './ProfilesComponnents/EditInfoProfile'
import FormProfiles from './FormProfiles'
import ListingRights from 'Accounts/Rights/ListingRights'
import Spinner from 'Spinner/Spinner'
import { handleError } from 'Assistances/ErrorHandling'

export default function ProfileFiche() {
    const [ficheInfo,setficheInfo]=useState({})
    const [rights_def,setrights_def]=useState([])
    const [right_def_module,setright_def_module]=useState([])

    const [ModalShow,setModalShow]=useState(false)
    const { t } = useTranslation();
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
        getProfilDataFunction()
    },[])
    function getProfilDataFunction(){
        getProfileData(getLocalstorage("Profile_id"))
        .then(function (response) {
            response['data']['info']['title']=response['data']['info']['name']
            response['data']['info']['in_service']=response['data']['info']['statut']

            setficheInfo(response['data']['info'])
                setrights_def(response['data']['permission'])
                setright_def_module(response['data']['right_def_module'])
            var defaultItem=menuContent.length>0?menuContent[0]:{};
            defaultItem.componnent=<EditInfoProfile ficheInfo={response.data['info']}/>;
            getClieckedItem(defaultItem)
            
        }) 
        .catch(function (error) {
            //console.log(error)
            handleError(error,t,t('Profiles'));

        })
        .finally(() => {
            setSpinner(false)
          });
        
        ;
    }
    const menuContent=[
        {
            'title':t('Information'),
            'componnent':<EditInfoProfile ficheInfo={ficheInfo}/>,
            'icon':<i className='fa fa-info icon-left-menu'></i>,
            'path':""
        },
        // {
        //     'title':t('Rights'),
        //     'componnent':<ListingRights ficheInfo={ficheInfo} right_def_module={right_def_module} rights_def={rights_def}/>,
        //     'icon':<i className='fa fa-info icon-left-menu'></i>,
        //     'path':""
        // }
       
       
    ];
    const [Clieckeditem,setClieckeditem]=useState({})

    function getClieckedItem(item){
        setClieckeditem(item)
    }

  return (
    <div className=''>
        {spinner?
            <Spinner/> 
            :

        <>
         <TopHead ficheinfo={ficheInfo} pathImage='/users/' edit={()=>setModalShow(true)}/>
        <div className='row'>
            <div className='col-md-4 col-lg-3 mt-1'>
                <MenuLeftOptions getClieckedItem={getClieckedItem} title={t("Profile")+" "+ t("Options")} menuContent={menuContent}/>
            </div>
            <div className='col-md-8 col-lg-9 mt-1' >
                <SpaceOfDisplay displayItem={Clieckeditem}/>
            </div>
        </div>
        </>
        }
       


        <ModalHelper  
        show={ModalShow} 
        title={t("Modify")+" "+ t("Profile")}
        size='lg'
        onHide={() => setModalShow(false)} 
        content={<FormProfiles modifycontent={ficheInfo} getDataProfiles={getProfilDataFunction} onHide={() => setModalShow(false)} />} />
        
      
    </div>
  )
}
