
import React from 'react'
// import "./sass/carousel.scss"


import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default  function AnnoncesTop (props) {

    

  
  return (
    <div className="Carousl-container">
      <div style={{ textAlign: "center" }}>
      
        <div
          style={{
            padding: "0 0px", 
          }}
        >
          <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        // emulateTouch
        autoPlay={true}
        useKeyboardArrows
        transitionTime={1000}
        // axis="vertical"
        // selectedItem={1}
        
       
      >
         {Array.isArray(props.headannonces) && props.headannonces.map( (item,i) =>{
              return(
                <div className="slide-holder" key={i}>
                <img src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/annonces/"+item['image']} 
                
                
                className='head-annonce-images' 
                
                
                alt="annonce image"/>
                {/* <img src={      require(process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/annonces/"+item['image'])} height="270px"/> */}
                
                </div>
              )
        })}
        </Carousel>
        </div>
      </div>
    </div>
  );
}

