import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "react-alert-confirm/lib/style.css"; 

import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css"
import { ToastContainer } from 'react-toastify';    
import MainRoutes from 'Routing/MainRoutes';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Find all elements with data-tooltip attribute
    const tooltipElements = document.querySelectorAll('[data-tooltip]');

    tooltipElements.forEach(element => {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
    });

    // Cleanup event listeners when the component is unmounted
    return () => {
      tooltipElements.forEach(element => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  const handleMouseEnter = (event) => {
    const tooltipText = event.target.getAttribute('data-tooltip');
    const tooltip = document.createElement('div');
    tooltip.className = 'tooltip';
    tooltip.innerText = tooltipText;
    document.body.appendChild(tooltip);

    const rect = event.target.getBoundingClientRect();
    tooltip.style.left = `${rect.left + window.pageXOffset}px`;
    tooltip.style.top = `${rect.top + window.pageYOffset - tooltip.offsetHeight}px`;

    // Store reference to the tooltip element on the target element
    event.target._tooltip = tooltip;
  };

  const handleMouseLeave = (event) => {
    const tooltip = event.target._tooltip;
    if (tooltip) {
      tooltip.remove();
      delete event.target._tooltip;
    }
  };
  return (
    <div className='App'>

    <MainRoutes/>
    <ToastContainer/>

    </div>
  );
}

export default App;
