import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import logo from "Assets/Images/logo.png"
import { toast } from 'react-toastify';
import "./Login.css"
import { useNavigate } from 'react-router-dom';
import { handleError } from 'Assistances/ErrorHandling';
import { updateAccountPasswordByemail } from './Actions';
export default function ChangePass(props) {
    const { t } = useTranslation();
    const history=useNavigate();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [AttributesPassword,setAttributesPassword]=useState({
        "new_password":"",
        "repeat_new_password":"",
        "login":props.email

    })
    function onchangeAttributes(key,value){
        AttributesPassword[key]=value;
        setAttributesPassword({...AttributesPassword})
    }

    const verifyPass=(e)=>{
        e.preventDefault();
          if(AttributesPassword['login'] && AttributesPassword['new_password']==AttributesPassword['repeat_new_password'] && AttributesPassword['new_password'].length>7){
            updateAccountPasswordByemail(AttributesPassword)
              .then((res)=>{
                  toast.success(t("Succesfully Updated"))
                  props.changeTypeAuth(0)
              })
              .catch((err)=>{
                  //console.log(err)
                  handleError(err,t,t('Edit'));
  
              })
          }
          else{
              if(AttributesPassword['new_password']!=AttributesPassword['repeat_new_password'] ){
                  toast.error(t("The passwords should be equals"))
  
              }
              
              
             if(AttributesPassword['new_password'].length<=7){
              toast.error(t("The passwords should be strong >8"))
             
              }
          }


    
    }

 

  return ( 
    <> 
     <div className="m-3 w-350px min-h-full min-w-md flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm flex text-center">
            <img className="mx-auto w-150px h-150px "  src={logo} alt="Your Company" />
        </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST" onSubmit={verifyPass}>
        <div className="text-center">
          <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">{t("Create new password")}</h1>
        </div>

          <div>
            <div className="flex items-center justify-between">
              <label for="password" className="block text-sm font-medium leading-6 text-gray-900">{t('New Password')}</label>
             
            </div>
            <div className="mt-2">
              <input id="password" name="password" type="password" maxLength={255} value={AttributesPassword['new_password']} placeholder={t('••••••••')} autoComplete="off" onChange={(e) => onchangeAttributes("new_password",e.target.value)} required className="block w-full bx rounded-md  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 border-1 border-indigo-600   sm:text-sm sm:leading-6"/>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between"> 
              <label for="confirm-password" className="block text-sm font-medium leading-6 text-gray-900">{t('Confirm Password')}</label>
             
            </div>
            <div className="mt-2">
              <input id="confirm-password" name="confirm-password" type="password" maxLength={255} value={AttributesPassword['repeat_new_password']} placeholder={t('••••••••')} onChange={(e) => onchangeAttributes("repeat_new_password",e.target.value)}  required className="block w-full bx rounded-md  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 border-1 border-indigo-600   sm:text-sm sm:leading-6"/>
            </div>
          </div>


          <div className='text-center flex justify-center'>
            <button type="submit"                           className="flex white justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
            >{t('Change password')}</button>
          </div>
           
        </form> 
        <p className="mt-10 text-center text-sm text-gray-500 cursor-pointer">
            <a onClick={()=>props.changeTypeAuth(0)}>{t('Return to login')} </a>
        </p>
      </div>
    </div>
    </>
  )
}
