import React from 'react'
import { Navigate } from 'react-router-dom'
import { getLocalstorage } from '../HelperFunction/token';
import { isTokenExpired } from 'Assistances/GlobalFunctions';

const RedirectToDashboardBoad=({children}) =>{
    if (!isTokenExpired()) {
        return <Navigate to="/dashboard/statics" replace />;
    }
    else
    return  children;
  
}

export default RedirectToDashboardBoad; 