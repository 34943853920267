import { getLocalstorage } from 'Assistances/GlobalFunctions';
import axios from 'axios';

export function SigninAction(email , password) {
    
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/signIn',
        data:{"login": email,
        "password": password,
        }
    })
    
}


export  function RegisterAxios(login,password,name){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/signUp',
        data:{"login": login,
        "password": password,
        "name":name,}
    })
}




export function testLoginExistentAction(value){
    return  axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/testLoginExistent?login='+value,
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}


    })
}

export  function enableAccount(id){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/active-account',
        data:{"id":id},
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    })
}

export  function sendVerifictionCode(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/sendVerifictionCode',
        data:data,
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    })
}
export  function VerificationCode(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/VerificationCode',
        data:data,
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    })
}



export function updateAccountPasswordByemail(data){

    return axios({ 
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/updateAccountPasswordByemail',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}
