import React from 'react';
import { Helmet } from 'react-helmet-async';


const SeoHemlet = ({ title, description, link, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={link} />
    </Helmet>
  );
};

export default SeoHemlet;
