


import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import image from 'Assets/Images/nophoto.png'
import { toast } from 'react-toastify';
import { editStoreTeams } from './Actions';

import { handleError } from 'Assistances/ErrorHandling';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import { useNavigate } from 'react-router-dom';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';
import { selectLang } from './../../Redux/ReduxSlices/langs/LangsSlicer';

export default function EditTeamsStore(props) {
    const { t } = useTranslation();
    const [Teams,setTeams]=useState(props.Teams?props.Teams:[])
    const [Store_Teams,setStoreTeams]=useState(props.store_Teams?props.store_Teams:[])
    const[id_Store,setIdStore]=useState(props.store_id)
    const langs = useSelector(selectLang);
    useEffect(()=>{
        console.log(props.Teams?props.Teams:[])
        console.log(props.store_Teams?props.store_Teams:[])

    },[])
    // const ListImage=[image_bg_1,image_bg_2,image_bg_3,image_bg_4,image_bg_5,image_bg_6,image_bg_7,image_bg_8,image_bg_9,image_bg_10,image_bg_11,image_bg_12,image_bg_13,image_bg_14,image_bg_15]
    function addStoreTeam(Team_id){
        editStoreTeams(id_Store,Team_id)
        .then(function(response){
            let listeAddItem=Teams.filter((item)=>item.teams_id===Team_id)
            let addItem=listeAddItem.length>0?listeAddItem[0]:{};
            addItem.status=0;
            addItem.Team_id=Team_id;
            addItem.st_Team_id=response.data;


            Store_Teams[Store_Teams.length]=addItem;
            setStoreTeams([...Store_Teams])
            props.changeStoreTeams([...Store_Teams],1,Team_id)
            toast.success(t("Succesfully Added"))
        })
        .catch(function(error){
            handleError(error,t);
        })
    }
   
    function RetireTeamFromStore(Team_id){
        editStoreTeams(id_Store,Team_id)
        .then(function(response){
            let stTeams=[...Store_Teams.filter((item)=>item.teams_id!==Team_id)]
            setStoreTeams(stTeams)
            props.changeStoreTeams(stTeams,0,Team_id)
            toast.success(t("Succesfully Retired"))

        })
        .catch(function(error){
            handleError(error,t);
        })
    }
    const history=useNavigate()
    function handleRowClicked(id){
        //console.log(row)
        setLocalstorage('teams_id',id)
        history("/dashboard/teams/fiche")    }
  return (
    <div className='container row'>

        <div className='col-lg-6 col-md-6 col-sm-12'>
            <span className='relative line-clamp-1 block max-w-[150px] overflow-hidden truncate'> {t('List of Teams')}</span>

            <div className='list-items-container border'>
                

                    {
                        Teams && Teams.map((item)=>{
                            if(Store_Teams.filter(itempg=>itempg.teams_id==item.teams_id).length<=0){
                                return <>
                        <div className="flex cursor-pointer flex-col rounded border border-custom-border-200 bg-custom-background-100">
                            <div className="bg-image-products relative h-[100px] w-full rounded-t ">
                                <div className="absolute inset-0 z-[1] bg-gradient-to-t from-black/60 to-transparent">
                                </div>
                                <div className="absolute bottom-4 z-10 flex h-10 w-full items-center justify-between gap-3 px-4">
                                    <div className="flex flex-grow items-center gap-2.5 truncate">
                                        <div className="item-center flex h-14 w-14 flex-shrink-0 justify-center rounded bg-white/90">
                                            <span className="flex items-center justify-center">

                                                <img alt={item.name} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/Teams/"+item.image}   onError={(e) => {e.target.src =image}}/>

                                            </span>
                                        </div>
                                        <div className="flex w-full flex-col justify-between gap-0.5 truncate"
                                            data-toggle="tooltip" data-placement="top" 
                                            title={getTextByLanguage(item.title,langs)+': '+getTextByLanguage(item['description'],langs)}
                                       
                                        >
                                            <h3 className="truncate font-semibold text-white">{getTextByLanguage(item.name,langs)} ({getTextByLanguage(item.poste,langs)})</h3>
                                            <span className="flex items-center gap-1.5">
                                                <p className="text-xs font-medium text-white relative line-clamp-1 block max-w-[100px] overflow-hidden truncate">{getTextByLanguage(item.description,langs)}</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-shrink-0 items-center gap-2">
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>addStoreTeam(item.teams_id)}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg>
                                    </button>
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>{handleRowClicked(item.teams_id)}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link h-6 w-6 text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                            
                            </>
                            }
                            else{
                                return <> </>
                            }
                           
                        })
                    }
                   
                 
            </div>
        </div>
        <div className=' col-lg-6 col-md-6 col-sm-12'>
            <span className='relative line-clamp-1 block max-w-[150px] overflow-hidden truncate'> {t('Selected teams')}</span>

            <div className='list-items-container border'>
                    {
                        Store_Teams.map((item)=>{
                            return <>
                             <div className="flex cursor-pointer flex-col rounded border border-custom-border-200 bg-custom-background-100">
                            <div className="bg-image-products relative h-[100px] w-full rounded-t ">
                                <div className="absolute inset-0 z-[1] bg-gradient-to-t from-black/60 to-transparent">
                                </div>
                                <div className="absolute bottom-4 z-10 flex h-10 w-full items-center justify-between gap-3 px-4">
                                    <div className="flex flex-grow items-center gap-2.5 truncate">
                                        <div className="item-center flex h-14 w-14 flex-shrink-0 justify-center rounded bg-white/90">
                                            <span className="flex items-center justify-center">

                                                <img alt={item.nam} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/Teams/"+item.image}   onError={(e) => {e.target.src =image}}/>

                                            </span>
                                        </div>
                                        <div className="flex w-full flex-col justify-between gap-0.5 truncate"
                                            data-toggle="tooltip" data-placement="top" 
                                            title={getTextByLanguage(item.title,langs)+': '+getTextByLanguage(item['description'],langs)}
                                       
                                        >
                                            <h3 className="truncate font-semibold text-white">{getTextByLanguage(item.name,langs)} ({getTextByLanguage(item.poste,langs)})</h3>
                                            <span className="flex items-center gap-1.5">
                                                <p className="text-xs font-medium text-white relative line-clamp-1 block max-w-[100px] overflow-hidden truncate">{getTextByLanguage(item.description,langs)}  </p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-shrink-0 items-center gap-2">
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>RetireTeamFromStore(item.teams_id)}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white"><circle cx="12" cy="12" r="10"></circle><path d="m15 9-6 6"></path><path d="m9 9 6 6"></path></svg>
                                    </button>
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>{handleRowClicked(item.teams_id)}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link h-6 w-6 text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                            
                            </>
                        })
                    }
                   
            </div>
      
        </div>
    </div>
  )
}
