import { getLocalstorage } from "Assistances/GlobalFunctions"
import axios from "axios"

export function getOrders(){

    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getOrders',
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')},

    }) 
}

export function getOrderDetail(data){

    return axios({
        method: 'post',
        data:data,
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getOrderDetail',
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')},

    }) 
}

export function updateOrderStatus(data){

    return axios({
        method: 'post',
        data:data,
        url: process.env.REACT_APP_APP_URL_BACKEND+'/updateOrderStatus',
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')},

    }) 
}
