

import React, { useEffect, useState } from 'react'

import MenuLeftOptions from 'Componnents/Fiche/MenuLeftOptions'
import SpaceOfDisplay from 'Componnents/Fiche/SpaceOfDisplay'
import TopHead from 'Componnents/Fiche/TopHead'
import { useTranslation } from 'react-i18next'
import { getLocalstorage } from 'Assistances/GlobalFunctions'
import { getAnnonceData } from './Actions'
import {  toast } from 'react-toastify';    
import ModalHelper from 'Componnents/Modals/ModalHelper'
import EditInfoAnnonce from './AnnonceComponnents/EditInfoAnnonce'
import FormAnnonces from './FormAnnonces'
import Spinner from 'Spinner/Spinner'
import { handleError } from 'Assistances/ErrorHandling'

export default function FicheHeadAnnonce() {
    const [ficheInfo,setficheInfo]=useState({})
   
    const [ModalShow,setModalShow]=useState(false)
    const { t } = useTranslation();
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
        getAnnoncesData()
    },[])
    function getAnnoncesData(){
        getAnnonceData(getLocalstorage("Annonce_id"))
        .then(function (response) {
            setficheInfo({...response.data['info']})
          
            var defaultItem=menuContent.length>0?menuContent[0]:{};
            defaultItem.componnent=<EditInfoAnnonce ficheInfo={response.data['info']}/>;
            getClieckedItem(defaultItem)
        }) 
        .catch(function (error) {
            //console.log(error)
            handleError(error,t,t('Annonces'));
        })
        
        .finally(() => {
           setSpinner(false)
         });
    }
    const menuContent=[
        {
            'title':t('Information'),
            'componnent':<EditInfoAnnonce ficheInfo={ficheInfo}/>,
            'icon':<i className='fa fa-info icon-left-menu'></i>,
            'path':""
        }
       
       
    ];
    const [Clieckeditem,setClieckeditem]=useState({})

    function getClieckedItem(item){
        setClieckeditem(item)
    }

  return (
    <div className=''>
    {spinner?
        <Spinner/> 
        :
        <>
        <TopHead ficheinfo={ficheInfo} pathImage='/annonces/' path="/dashboard/annonces" id={ficheInfo.id} table="headannonces"  edit={()=>setModalShow(true)}/>
        <div className='row'>
            <div className='col-md-4 col-lg-3 mt-1'>
                <MenuLeftOptions getClieckedItem={getClieckedItem} title={t("Annonces")+" "+ t("Options")} menuContent={menuContent}/>
            </div>
            <div className='col-md-8 col-lg-9 mt-1' >
                <SpaceOfDisplay displayItem={Clieckeditem}/>
            </div>
        </div>
        </>
    }

        <ModalHelper  
        show={ModalShow} 
        title={t("Modify")+" "+ t("Annonce")}
        size='lg'
        onHide={() => setModalShow(false)} 
        content={<FormAnnonces modifycontent={ficheInfo} onHide={() => setModalShow(false)} getAnnoncesData={getAnnoncesData} />} />
        
      
    </div>
  )
}
