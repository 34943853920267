import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import M002 from 'Assets/Images/Modals/modals.png'
import M001 from 'Assets/Images/Modals/modals2.png'
import M000 from 'Assets/Images/Modals/modals3.png'
import { toast } from 'react-toastify'
import { handleError } from 'Assistances/ErrorHandling'
import { updateModalsStore } from 'Stores/Actions'

export default function SettingsStore(props) {
    const [modals,setModals]=useState(props.modals?props.modals:"M000")
    const {t}=useTranslation()
    function changeProductModule(module){
        setModals(module)
        updateModalsStore(props.store_id,module)
        .then((res)=>{

            console.log(res.data)
            toast.success(t("Succesfully Modified"))
        })
        .catch((error)=>{
            handleError(error,t);

        })
    
    }
  return (
    <div>
        <div className='col-12'>
                <span className="label ">{t("Product")}</span><br/>
                <div className='flex'>
                     <div className={`flex m-2    rounded cursor-pointer ${modals=="M000"?" border-indigo-50 ":"hover:border-gray-300"} `} onClick={()=>changeProductModule("M000")}>
                         <img src={M000} className='h-max-200 w-max-200'/>


                     </div>
                     <div className={`flex  m-2   rounded cursor-pointer ${modals=="M001"?" border-indigo-50 ":"hover:border-gray-300"}`} onClick={()=>changeProductModule("M001")}>
                         <img src={ M001} className=' w-max-200 mt-3' style={{width:'340px',height:"150px"} }/>

                     </div>
                     <div className={`flex  m-2   rounded cursor-pointer ${modals=="M002"?" border-indigo-50 ":"hover:border-gray-300"}`} onClick={()=>changeProductModule("M002")}>
                         <img src={M002} className='h-max-200 w-max-200'/>

                     </div>
               
                </div>
                

        </div>
    </div>
  )
}
