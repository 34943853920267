import { getLocalstorage } from "Assistances/GlobalFunctions"
import axios from "axios"

export function getTeams(data){

    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getTeams',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}
export function AddTeams(data){
    return axios({
    method: 'post',
    url: process.env.REACT_APP_APP_URL_BACKEND+'/addTeams',
    data: data, 
    headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
})
}

export function ModifyTeams(data){
    return axios({
    method: 'post',
    url: process.env.REACT_APP_APP_URL_BACKEND+'/ModifyTeam',
    data: data, 
    headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
})
}

export function getTeamData(team_id){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getTeamsData?id='+team_id,
        data: {}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}

