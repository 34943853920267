import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiChevronRight } from 'react-icons/bi'

export default function TotalStaticsItem(props) {
    const {t}=useTranslation()
  return (
    <div>
          <div className="rounded-md bg-white border    border-gray-200   ">
                    <div className="gl-card-body gl-display-flex gl-justify-content-space-between gl-align-items-center gl-p-6 p-3">
                        <span>
                            <div className="d-flex align-items-center">
							    <i className="fa fa-layer-group s16 gl-text-gray-700 m-1"></i>

                                <h3 className="text-3xl">{props.number}</h3>
                            </div>
                            <div className="text-sm text-uppercase m-1">{t(props.title)}</div>
                        </span>
                    </div>
                </div>
            </div>  
  )
}
