import React from 'react'
import QRCode from "react-qr-code";

export default function CodeQR(props) {
  return (
   
     
        <QRCode value={props.data} />
          
  )
}

