import React, { useEffect, useState } from 'react'
import "./orders.css"
import image from "Assets/Images/nophoto.png" 
import empty_shoppin_bag from "Assets/Images/empty_shoppin_bag.png" 

import { BsCheck, BsFillStopwatchFill, BsX } from 'react-icons/bs'
import { sendOrderToDashboard } from './Actions'
import { useTranslation } from "react-i18next";
import Message from './Message'
import ModalHelper from 'Componnents/Modals/ModalHelper'
import { setLocalstorage } from 'Assistances/GlobalFunctions'
import { getLocalstorage } from 'Assistances/GlobalFunctions'
import { tokenDecoded } from 'Assistances/token'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { selectLang } from '../../../../Redux/ReduxSlices/langs/LangsSlicer'
import { getTextByLanguage } from 'Assistances/GlobalFunctions'


export default function OrderList(props) {
    // const [hideblock,setHideBlock]=useState(props.showBlock)
    const { t } = useTranslation();
    const [modalshowMessageOrder,setModalShowMessageOrder]=useState(false)
   
    useEffect(()=>{ 
        calculeTotalPrice()
    },[props.ordersSelected])
    function calculeTotalPrice(){
        var totalPrice=0
        props.ordersSelected && props.ordersSelected.forEach(element => {
            if(element)
            totalPrice=totalPrice+element['Total_Price']*element["how_many"]
        }

        );
        settotal_article_price(totalPrice)
    }
    const hasOrder=false
    // const [ordersSelected,setordersSelected]=useState( [])
    const [total_article_price,settotal_article_price]=useState(0)
    
    function deleteElement(i){      
        props.ordersSelected.splice(i, 1);
        props.updateOrdersSelected([...props.ordersSelected])
        calculeTotalPrice()
        setLocalstorage('order_'+props.ficheInfo.id,JSON.stringify([...props.ordersSelected.filter(e=>e.status==="Added")]).toString())
       
        let orderNumber=[...props.ordersSelected].length
        // props.updateNumberOfOrders(orderNumber);
    }
    function increaseNumberArticle(i){
        props.ordersSelected[i]["how_many"]=props.ordersSelected[i]["how_many"]+1
        props.updateOrdersSelected([...props.ordersSelected])
        calculeTotalPrice()
        setLocalstorage('order_'+props.ficheInfo.id,JSON.stringify([...props.ordersSelected.filter(e=>e.status==="Added")]).toString())

        }
    function descreaseNumberArticle(i){
        props.ordersSelected[i]["how_many"]=props.ordersSelected[i]["how_many"]>1? props.ordersSelected[i]["how_many"]-1:props.ordersSelected[i]["how_many"]
        
        props.updateOrdersSelected([...props.ordersSelected])
        calculeTotalPrice()
        setLocalstorage('order_'+props.ficheInfo.id,JSON.stringify([...props.ordersSelected.filter(e=>e.status==="Added")]).toString())


        }
    function orderSelectedArticle(order_type=(props.type && props.type ==="intern"?"intern":"extern"),phone=null,message=null){
       
        if(getLocalstorage("access_token")){
                      
        
        let fromStorageOrderList = getLocalstorage('order_'+props.ficheInfo.id);
        let listOfOrder = fromStorageOrderList ? JSON.parse(fromStorageOrderList) : [];
        var orderListLenght=listOfOrder.length
        if(orderListLenght>0){
            
           
            const data = new FormData() 
            var orderSelectedTosend=listOfOrder.filter(e=>e.status==="Added")
            if(order_type==="intern"){
                data.append('id_internal_server',getLocalstorage('id_internal_server')?getLocalstorage('id_internal_server'):null)
                data.append('id_table',getLocalstorage('table_id')?getLocalstorage('table_id'):null)
            }
                data.append('id_store',props.ficheInfo.id)
            if(phone){
                data.append('phone',phone);

            }
            
            if(message){
                data.append('message',message);

            }
            
            data.append('order_type',order_type)
            if(tokenDecoded){
                data.append('id_account', tokenDecoded.id)

            }
            else{
                data.append('id_account',getLocalstorage('id_account')?getLocalstorage('id_account'):null)

            }
            var price_orderSelectedTosend=0
            for(let i=0;i<orderSelectedTosend.length;i++){
                price_orderSelectedTosend=price_orderSelectedTosend+orderSelectedTosend[i]['Total_Price']*orderSelectedTosend[i]['how_many']
            
            }
            data.append('total_article',price_orderSelectedTosend )
            data.append('orders', JSON.stringify(orderSelectedTosend))
           
            sendOrderToDashboard(data)
            .then(function(response){
                setLocalstorage('order_'+props.ficheInfo.id,JSON.stringify([]).toString())
                props.orderRefreshForbyProducts()
                toast.success(t("Order Successfully Send"))

            })
            .catch(function(error){
                toast.error(t("Erro in Send"))

                //console.log(error.message)
            })

        }
    }
    else{
      props.showModuleLogin()
    
    }
        
    }

  
        const [modalshow,setModalShow]=useState(false)
        function sendOrderExtern(){
        }

    const langs = useSelector(selectLang);

  return (

    <div className='order-container '>
        {
           props.showBlock? 
           <>
           {props.ordersSelected && props.ordersSelected.length>0?

            <> 
             <div style={{zIndex: "1000", position: "relative"}} className='container'>
            <div className="sc-1v5x861-0 fGsVWz" style={{opacity: "1"}}>
            </div>
            <div className="sc-1v5x861-1 iMYFrd" style={{transform: "translateX(0px)"}}>
                <button className="button-close" onClick={()=>{props.declareHiden()}}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.61 12.199L.54 3.129A1.833 1.833 0 113.13.536l9.07 9.07L21.27.54a1.833 1.833 0 012.592 2.592l-9.068 9.068 9.07 9.07a1.833 1.833 0 01-2.59 2.592l-9.072-9.07-9.073 9.073a1.833 1.833 0 01-2.591-2.592L9.61 12.2z" fill="#fff"></path>
                    </svg>
                </button> 
                <div className="sc-11ezv7x-0 hA-DLvj">
                    <div style={{position: "relative", overflow: "hidden", width: "100%", height: "100%"}}>
                        <div style={{position: "absolute", inset: "0px", overflow: "scroll", marginRight: "-17px", marginBottom: "-17px"}}>
             
                            <main className="xy77b-0 cyXIUI">
                                <section style={{paddingBottom:"62px",paddingRight:"17px"}}>
                                {props.ordersSelected && props.ordersSelected.map((article,i)=>{
                                //    article['product']=article['product'][0]
                                    return (
                                    <article className={"qt41kr-0 exHDmS "} id={"article"+i} >
                                        {
                                            article["status"]!=="Added"?
                                            <></>
                                            :
                                            <button className="qt41kr-9 dNkDzi" onClick={()=>{deleteElement(i)}} disabled={article["status"]!=="Added"?true:false}>
                                            <svg fill="none" viewBox="0 0 24 24">
                                                <path d="M17.3 5.3a1 1 0 111.4 1.4L13.42 12l5.3 5.3a1 1 0 11-1.42 1.4L12 13.42l-5.3 5.3a1 1 0 01-1.4-1.42l5.28-5.3-5.3-5.3A1 1 0 016.7 5.3l5.3 5.28 5.3-5.3z" fill="#000"></path></svg>
                                            </button>
                                        }
                                       
                                        <div className="qt41kr-8 bdavgu">
                                        </div>
                                        <div className="qt41kr-1 gNbnJk">
                                            <picture className="sc-1js33uh-0 gVMUDj qt41kr-3 gqcocB" data-type="1">
                                                <source srcset=""sizes="146px"/>
                                                    <img style={{height:"50px"}} alt={"alt "+article['product']['title']?article['product']['title']:""} title={article['product']['title']?article['product']['title']:""} className="img" src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/products/"+article['product']['image']} onError={(e) => {e.target.src =image}} />
                                            </picture>
                                            <div className="qt41kr-4 bEBxQK">
                                                <h3 className="qt41kr-5 cGnUch"
                                                      data-toggle="tooltip" data-placement="top" 
                                                      title={getTextByLanguage(article['product']['title'],langs)}
                                                      
                                                >
                                                {article['product']['title'].length>20?
                                                getTextByLanguage(article['product']['title'],langs).substr(0, 30)+"...":getTextByLanguage(article['product']['title'],langs)}

                                                    {article["status"]==="waiting"?
                                                         <span className='artical-status-waiting'>
                                                         <BsFillStopwatchFill/>{t(article["status"])}
                                                        </span>
                                                    :
                                                        <>
                                                        {article["status"]==="accept"?
                                                         <span className='artical-status-accept'>
                                                         <BsCheck/> {t(article["status"])}
                                                        </span>
                                                        :
                                                        <>
                                                         {article["status"]==="refuse"?
                                                         <span className='artical-status-refuse'>
                                                         <BsX/>{t(article["status"])}
                                                        </span>
                                                        :
                                                        <></>
                                                        }
                                                        </>

                                                        }
                                                        </>
                                                    }
                                                   
                                                </h3>

                                                <section className="sc-163lfi-0 pKdGv">
                                                    <div className="param">                                
                                                        {article['product']['description'].length>50?
                                                        
                                                        getTextByLanguage(article['product']['description'],langs).substr(0, 30)+"..."
                                                        :
                                                        getTextByLanguage(article['product']['description'],langs)}
                                                        <br/>
                                                        {t("ID")}:{article['artical_order_id']}
                                                    </div>
                                                
                                        </section>
                                        </div> </div>
                                        <div className="qt41kr-2 hJAvQY">
                                            <div className="qt41kr-7 gxeYez">
                                                {
                                                    article['Total_Price']>0?
                                                    <div className="current">
                                                    {props.currency} {article['Total_Price']}
                                                
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                
                                            </div>
                                      
                                        <div className="qt41kr-6 iOABXu">
                                            <div className="sc-1yhsml-0 dnNAbx">
                                            
                                            <button type="button" className="sc-1yhsml-2 iqhyMv" onClick={()=>{if(article['how_many']>1){descreaseNumberArticle(i)}}} disabled={article["status"]!=="Added"?true:false} >
                                                {
                                                article["status"]!=="Added"?
                                                <></>
                                                :
                                                 <svg width="10" height="10" viewBox="0 0 10 10" className="icon"><rect fill="#454B54" y="4" width="10" height="2" rx="1"></rect></svg>
                                                }
                                           
                                                 </button>
                                            <div className="sc-1yhsml-1 dQeTxa">
                                                {article['how_many']}
                                            </div>
                                           
                                                <button type="button" className="sc-1yhsml-2 iqhyMv" onClick={()=>increaseNumberArticle(i)} disabled={article["status"]!=="Added"?true:false} >
                                                
                                                {
                                                article["status"]!=="Added"?
                                                <></>
                                                :
                                                <svg width="10" height="10" viewBox="0 0 10 10" className="icon"><g fill="#454B54"><rect x="4" width="2" height="10" ry="1"></rect><rect y="4" width="10" height="2" rx="1"></rect></g></svg>
                                                }
                                                </button>
                                           
                                        </div>
                                    </div>
                                </div>
                                        </article>
                                    )
                                })
                                }
                                    
                                </section>
                          
                        
                          
                            {/* next to pay */}
                            <div className="sc-97m3rk-2 kvFaGL fixed">
                                        {total_article_price>0?
                                            
                                            
                                            <div className="price">{props.currency}  {total_article_price.toFixed(2)} </div>
                                            :
                                            <></>}
                                        <button data-testid="cart__button_next_sticky" onClick={()=>{
                                            // setModalShow(true);
                                        sendOrderExtern();setModalShowMessageOrder(true)}} type="button" data-type="primary" data-size="big" data-arrow="right" className="sc-1rmt3mq-0 cpUbDl next-button">
                                        {t('Send')} </button>
                                       
                                   
                             
                                </div>
                            
                            
                            
                            </main>
                        </div>
                        <div style={{position: "absolute", height: "6px", right: "2px", bottom: "2px", left: "2px", borderRadius: "3px", visibility: "hidden"}}>
                            <div style={{position: "relative", display: "block", height: "100%", cursor: "pointer", borderRadius: "inherit", backgroundColor: "rgba(0, 0, 0, 0.2)", width: "0px",}}>
                            </div>
                        </div>
                        <div style={{position: "absolute", width: "6px", right: "2px", bottom: "2px", top: "2px", borderRadius: "3px", visibility: "visible"}}>
                            <div className="scroll__scrollbar" style={{position: "relative", display: "block", width: "100%", height: "171px", transform: "translateY(0px)"}}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
            :

            <>
            <div style={{zIndex: "1000", position: "relative"}}>
            <div className="sc-1v5x861-0 fGsVWz" style={{opacity: "1"}}>
            </div>
            <div className="sc-1v5x861-1 iMYFrd" style={{transform: "translateX(0px)"}}>
                <button className="button-close" onClick={()=>{props.declareHiden()}}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.61 12.199L.54 3.129A1.833 1.833 0 113.13.536l9.07 9.07L21.27.54a1.833 1.833 0 012.592 2.592l-9.068 9.068 9.07 9.07a1.833 1.833 0 01-2.59 2.592l-9.072-9.07-9.073 9.073a1.833 1.833 0 01-2.591-2.592L9.61 12.2z" fill="#fff"></path>
                    </svg>
                </button>
                <div className="sc-11ezv7x-0 hA-DLvj">
                    <div style={{position: "relative", overflow: "hidden", width: "100%", height: "100%"}}>
                        <div style={{position: "absolute", inset: "0px", overflow: "scroll", marginRight: "-17px", marginBottom: "-17px"}}>
                            <main className="xy77b-0 cyXIUI">
                                <section className="empty">
                                    <img src={empty_shoppin_bag} className="order-empty-image"  alt='icon' width={"250px"} height={"270px"}/>
                                    <h2>{t('Oops, it’s empty!')}</h2>
                                    {/* <div className="empty-cart-text">
                                        Select the articles you want
                                    </div> */}
                                </section>
                            </main>
                        </div>
                        <div style={{position: "absolute", height: "6px", right: "2px", bottom: "2px", left: "2px", borderRadius: "3px", visibility: "hidden"}}>
                            <div style={{position: "relative", display: "block", height: "100%", cursor: "pointer", borderRadius: "inherit", backgroundColor: "rgba(0, 0, 0, 0.2)", width: "0px",}}>
                            </div>
                        </div>
                        <div style={{position: "absolute", width: "6px", right: "2px", bottom: "2px", top: "2px", borderRadius: "3px", visibility: "visible"}}>
                            <div className="scroll__scrollbar" style={{position: "relative", display: "block", width: "100%", height: "171px", transform: "translateY(0px)"}}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
           }
       </>
        :
        <>
        </>
         }


         {/* <ModalStripPayment 
         show={modalshow}
         content={<Payment/>}
         size="md"
         onHide={()=>setModalShow(false)}
         /> */}
         <ModalHelper 
         show={modalshowMessageOrder}
         content={<Message            
             orderSelectedArticle={orderSelectedArticle}
             setHideBlock={()=>setModalShowMessageOrder(false)}
             declareHiden={props.declareHiden}    customizing={props.customizing} 
             onHide={()=>setModalShowMessageOrder(false)}
             currency={props.currency}
             />}
         size="lg"
         onHide={()=>setModalShowMessageOrder(false)}
         />
    </div>
  )
}
