import React, { useState } from 'react'
import image from "Assets/Images/nophoto.png";
import {  toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css'
import { useTranslation } from "react-i18next";
import { id_account_creater } from 'HelperFunction/token';
import { addToppings,UpdateToppings } from './Actions';
import { handleError } from 'Assistances/ErrorHandling';
import Spinner from 'Spinner/Spinner';
export default function FormToppings(props) {
    const { t } = useTranslation();
    const [ToppingsAtrributes,setToppingsAtrributes]=useState(props.modifycontent?props.modifycontent:{})
    const [spinner,setSpinner]=useState(false)

 
    function handleSubmit(e){
        e.preventDefault();
        const data = new FormData() 
        data.append('title', ToppingsAtrributes['title']?ToppingsAtrributes['title']:"" )
        data.append('description', ToppingsAtrributes['description']?ToppingsAtrributes['description']:"" )
        data.append('id_account_creater', id_account_creater)
        data.append('price',  ToppingsAtrributes['price']?ToppingsAtrributes['price']:"" )
        data.append('in_service',  ToppingsAtrributes['status']?ToppingsAtrributes['status']:0 )

        data.append('image',ToppingsAtrributes['image']?ToppingsAtrributes['image']:null )

      
         if( ToppingsAtrributes['title'].length>0){
            setSpinner(true)

            addToppings(data)
            .then(function (response) {
                setToppingsAtrributes({...{}})
                props.onHide();

                // props.update(response["data"])
                toast.success(t("Succesfully Added"))
                props.getAllToppings()
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.error && error.response.data.message) {
                    toast.error( error.response.data.message);
                } else {
                    handleError(error,t);
                    //console.log(error)
                }

            })  .finally(()=>{
                setSpinner(false)
        
                  });
        }
        else

        toast.error(t("The attributes with * are obligatory."))

    }

    function handleSubmitModify(e){
        e.preventDefault();    
        const data = new FormData() 
        // formData.append('label', label)
        // formData.append('description', description)
        data.append('title', ToppingsAtrributes['title']?ToppingsAtrributes['title']:"" )
        data.append('description', ToppingsAtrributes['description']?ToppingsAtrributes['description']:"" )
        data.append('id_account_creater', id_account_creater)
        data.append('in_service',  ToppingsAtrributes['status']?ToppingsAtrributes['status']:0 )
        data.append('price',  ToppingsAtrributes['price']?ToppingsAtrributes['price']:"" )

        data.append('image',ToppingsAtrributes['image']?ToppingsAtrributes['image']:null )
        data.append('id',props.modifycontent.id)
        if( ToppingsAtrributes['title'].length>0){
            setSpinner(true)

        UpdateToppings(data)
        .then(function (response) { 
            // props.update()
            toast.success(t("Succesfully Modified"))
            props.onHide()
            props.getDataToppings()

            })
            .catch(function (error) {
                //console.log(error)
                handleError(error,t);

            })  .finally(()=>{
                setSpinner(false)
        
                  });
        }
        else
        toast.error(t("The attributes with * are obligatory."))

    }
    function  removefile(key){
        delete ToppingsAtrributes[key];
        setToppingsAtrributes({...ToppingsAtrributes})
    }

    function onchangeToppingsAtrributes(key,value){
        ToppingsAtrributes[key]=value      
        setToppingsAtrributes({...ToppingsAtrributes})
    }
  return (
    <div className='container '>
         {spinner?
        <Spinner/>
        :
        <form onSubmit={props.modifycontent!=''?handleSubmitModify:handleSubmit} >
            <div className='row'>
                 <div className="grid gap-6 md:grid-cols-1">
                        <div className='mr-1'>
                            <label for="first_title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Title")}*</label>
                            <input type="text" maxLength={255} id="first_title" value={ToppingsAtrributes['title']} name="label" onChange={(e) => onchangeToppingsAtrributes("title",e.target.value) }  placeholder={t("Title")}   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  required/>
                        </div>
                        <div className='ml-1'>
                            <label for="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Description")}</label>
                            <input type="text" maxLength={2000} id="description" value={ToppingsAtrributes['description']}  name="description" onChange={(e) => onchangeToppingsAtrributes("description",e.target.value)}  placeholder={t("Description")} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   required/>
                        </div>
                        <div className='ml-1'>
                            <label for="price" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Price")}</label>
                            <input type="number" id="price" value={ToppingsAtrributes['price']}  name="price" onChange={(e) => onchangeToppingsAtrributes("price",e.target.value)}  placeholder={t("Price")} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   />
                        </div>
                        <div className='ml-1'>
                            <label for="status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Status")}</label>
                            {/* <input type="text" maxLength={255} id="status" value={ToppingsAtrributes['status']}  name="description" onChange={(e) => onchangeToppingsAtrributes("status",e.target.value)}  placeholder={t("status")} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   required/> */}
                            <select value={ToppingsAtrributes['status']}  name="description" onChange={(e) =>{onchangeToppingsAtrributes("status",e.target.value)}}  placeholder={t("status")} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   >
                            <option >{t("Select")}</option>
                                
                                <option value={1}>{t("Enable")}</option>
                                <option value={0}>{t("Disable")}</option>

                            </select>
                        </div>
                    </div>
                    <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-0 xl:gap-0 lg:py-1 lg:grid-cols-1 lg:pt-0"> 
                        <label for="uploadFile1"  className="bg-primary hover:bg-primary text-white text-sm px-4 py-2.5 outline-none rounded w-max cursor-pointer mx-auto block font-[sans-serif]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 mr-2 fill-white inline" viewBox="0 0 32 32">
                              <path
                                d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                                data-original="#000000" />
                              <path
                                d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                                data-original="#000000" />
                            </svg>
{t("Upload")}
                            <input type="file" id='uploadFile1' className="hidden" onChange={(e)=>onchangeToppingsAtrributes("image",e.target.files[0])} />
                        </label>
                        
               
                    </div>
                    
                    <div className="  text-center">

                    {ToppingsAtrributes && ToppingsAtrributes['image'] && ToppingsAtrributes['image']!=="null"?
                         <div  >
                                <div className="cf-u-display-flex cf-u-flex-wrap cf-u-mb-sm">
                                    <div className="cf-c-file-item cf-u-mx-xs cf-u-mb-sm">
                                        <div className="cf-c-file-item__icon-wrap">
                                            <div className="cf-c-icon-wrap cf-c-icon cf-c-icon--lg">
                                                <img alt="Toppings" src={ 
                                                    typeof ToppingsAtrributes['image']  !== 'string' && ToppingsAtrributes && ToppingsAtrributes['image'] && ToppingsAtrributes['image']!==null && ToppingsAtrributes['image']!=="null"?

                                                URL.createObjectURL(ToppingsAtrributes['image'])
                                                :
                                                process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/topping/"+props.modifycontent['image'] 
                                            
                                            } 
                                                        onError={(e) => {e.target.src =image}}

                                                className='image-Toppings-item'/>
                                                
                                            </div>
                                            <div className="cf-c-file-item__remove cf-u-display-flex cf-c-icon-wrap cf-c-icon cf-c-icon--lg" onClick={()=>removefile('image')}>
                                                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className=" cf-c-icon cf-c-icon--lg svg-remove">
                                                    <path d="M8 7.057l2.828-2.828.943.943L8.943 8l2.828 2.828-.943.943L8 8.943 5.172 11.77l-.943-.943L7.057 8 4.23 5.172l.943-.943L8 7.057z" fill-rule="nonzero">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div title="cours de S6 2021 1 4 in 1.pdf" className="cf-c-file-item__name">
                                            <span className="cf-c-file-item__file-name">
                                                {ToppingsAtrributes && ToppingsAtrributes['image']?
                                                ToppingsAtrributes['image']['name']
                                                :
                                                <></>
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='text-center image-center'>
                                <img alt="Toppings" src={image} 
                                className='image-catalogue-item'  
                                   
                                onError={(e) => {e.target.src =image}}/>
                            </div>

                            }
                    </div>
                    
                    <div className="grid gap-6  mb-6   mt-6  md:grid-cols-1">
                        <button type="button" Type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">{props.modifycontent!=''?<>{t("Modify")}</>:<>{t("Add")}</>} {t("Toppings")}</button>
                    </div>
                   
            </div>

        </form>
        }

    </div>
  )
}
