// ReduxSlices/langs/LangsSlicer.js
import { createSlice } from '@reduxjs/toolkit';
import { getLocalstorage } from 'Assistances/GlobalFunctions';

export const langsSlice = createSlice({
  name: 'langs',
  initialState: {
    value: getLocalstorage('lang'),
  },
  reducers: {
    updateLang: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateLang } = langsSlice.actions;
export const selectLang = (state) => state.langs.value;
export default langsSlice.reducer;
