
import { getLocalstorage } from "Assistances/GlobalFunctions";
import { id_account_creater } from "HelperFunction/token";
import axios from "axios"

export function getStoresList(){

    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAllActiveStores',
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}

export function getCatalogueImagesStore(store_id){

    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getCatalogueImagesStore?store_id='+store_id,
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}

