import React, {  useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsSkype, BsFillTelephoneFill , BsFillEnvelopeFill,BsLinkedin,BsFacebook,BsGlobe ,BsGithub,BsTelegram,BsTwitter,BsInstagram, BsSnapchat, BsPinterest, BsWhatsapp} from "react-icons/bs";
import { FaAmazon, FaTiktok } from 'react-icons/fa';
import { EditSocialStore } from './Actions';
import { toast } from 'react-toastify';
import { handleError } from 'Assistances/ErrorHandling';
import { FaXTwitter } from 'react-icons/fa6';

export default function SocialForm(props) {
    const [socialItem,setSocialItem]=useState({id:props.socialAll[0]?props.socialAll[0]['id']:'',name:props.socialAll[0]?props.socialAll[0]['name']:"",type:props.socialAll[0]?props.socialAll[0]['type']:''});
    const [Attributessocial,setAttributessocial]=useState(props.social?props.social:{});

    const { t } = useTranslation();
  
    const showSocial=(id,input,type)=>{
        let valueSocial={'id':id,"name":input,"type":type}
        setSocialItem({...valueSocial})
        
    }
    function changeSocialInput(value){
        Attributessocial[socialItem["id"]]=value;
        setAttributessocial({...Attributessocial});
    }
    
    function DisplayIcon(key){
        switch (key) {
            case 'Call':
                return (
                    <BsWhatsapp  size={21} color= "#0BB7AF"/>       
                )
            case 'Email':
                return (
                    <BsFillEnvelopeFill size={21} color= "#EE2D41"/>
                   )
            case 'Web':
                return (
                  <BsGlobe  size={21} color= "#EE9D01"/>   
                  )
            case 'FaceBook':
             return (
              <BsFacebook size={21} color= "#187DE4"/>
                )
            case 'Linkedin':
             return (
              <BsLinkedin  size={21} color= "#187DE4"/>
                )
            case 'Github':
               return (
                <BsGithub   size={21} color= "#000" />
                )
            case 'Instagram':
             return (
              <BsInstagram    size={21} color= "#EE2D41"/>
               )
                case 'Telegram':
             return (
              <BsTelegram   size={21} color= "#187DE4"/>
                  )
                case 'X':
             return (
                  <FaXTwitter   size={21} color= "#030303"/>
                  
                  )
                case 'Skype':
             return (
                <BsSkype   size={21} color= "#187DE4"/>
                )
                
                case 'Snapchat':
                 return (
                    <BsSnapchat   size={21} color= "#EE9D01"/>               
                   )
                 case 'Tiktok':
                   return (
                  <FaTiktok   size={21} color= "#187DE4"/>
                      )
                 case 'Amazon':
                 return (
                 <FaAmazon   size={21} color= "#EE9D01"/>
                 )
                 case 'Pinterest':
                   return (
                    <BsPinterest  size={21}  color= "#EE2D41"/>
                     )
            default:
                return (
                  <></>
                )
         }
    }
    function updateSocialStore(){
        const data = new FormData() 
        data.append('socials', JSON.stringify(Attributessocial))
        data.append('id', props.store_id)

        EditSocialStore(data)
        .then(function(response){
            toast.success(t("Succesfully"))
        })
        .catch(function(error){
            handleError(error,t);

        })
    }

  return (
    <div>
        <div className='container center mb-2'>
            {
                props.socialAll.map((item)=>{
                    return(
                        <a  data-title={item.title} className='listing-in-ligne' onClick={()=>showSocial(item.id,item.title,item.type)}> {DisplayIcon(item.title)}  </a>
                    )
                }) 
            }
               </div>
        
        <div className='container'>
            {t(socialItem["name"])}
            <input maxLength={255}   value={Attributessocial[socialItem["id"]]?Attributessocial[socialItem["id"]]:""} onChange={(e)=>changeSocialInput(e.target.value)} type={t(socialItem["type"])} name={t(socialItem["name"])} placeholder={t(socialItem["name"])}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
        </div>
        <div className='container center'>
            <button className='btn btn-primary mt-3' onClick={()=>updateSocialStore()}> {t('Modify')} </button>

        </div>

      </div>);
}
