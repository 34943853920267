import React from 'react'
import { useTranslation } from 'react-i18next'

export default function ShowMessage(props) {
  const {t}=useTranslation()
  return (
  <div className="p-6 max-w-lg mx-auto bg-gray-50 rounded-lg shadow-lg border border-gray-200">
    <div className="mb-4">
        <h2 className="text-2xl font-bold text-gray-900 mb-1">{props.item['name']}</h2>
        <p className="text-base text-gray-700">{props.item['email']}</p>
    </div>

    <div className="border-t border-gray-300 pt-4">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">{t('Subject')}:</h3>
        <p className="text-gray-700">{props.item['subject']}</p>
    </div>

    <div className="border-t border-gray-300 pt-4">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">{t('Description')}:</h3>
        <p className="text-gray-700">{props.item['description']}</p>
    </div>
</div>


  )
}
