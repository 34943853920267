import { getLocalstorage } from "Assistances/GlobalFunctions"
import axios from "axios"

export function getAllAnnonces(data){

    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAllAnnonces',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}
export function addAnnonces(data){
    return axios({
    method: 'post',
    url: process.env.REACT_APP_APP_URL_BACKEND+'/addAnnonces',
    data: data, 
    headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
})
}


export function ModifyAnnonce(data){
    return axios({
    method: 'post',
    url: process.env.REACT_APP_APP_URL_BACKEND+'/ModifyAnnonce',
    data: data, 
    headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
})
}


export function getAnnonceData(annonce_id){
    return axios({
    method: 'get',
    url: process.env.REACT_APP_APP_URL_BACKEND+'/getAnnonceData?id='+annonce_id,
    data: {}, 
    headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
})
}

