import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";

import image from 'Assets/Images/nophoto.png';

import { toast } from 'react-toastify';
import { editStoreHeadAnnonces } from './Actions';
import { handleError } from 'Assistances/ErrorHandling';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import { useNavigate } from 'react-router-dom';
import { selectLang } from './../../Redux/ReduxSlices/langs/LangsSlicer';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';

export default function EditHeadAnnoncesStore(props) {
    const { t } = useTranslation();
    const [show,setShow]=useState(0)
    const [showSelected,setShowSelected]=useState(0)
    const [HeadAnnonces,setHeadAnnonces]=useState(props.headAnnonces?props.headAnnonces:[])
    const [Store_HeadAnnonces,setStoreHeadAnnonces]=useState(props.headannonces_store?props.headannonces_store:[])
    const[store_id,setIdStore]=useState(props.store_id)
    const langs = useSelector(selectLang);

    // const ListImage=[image_bg_1,image_bg_2,image_bg_3,image_bg_4,image_bg_5,image_bg_6,image_bg_7,image_bg_8,image_bg_9,image_bg_10,image_bg_11,image_bg_12,image_bg_13,image_bg_14,image_bg_15]
    function addStoreHeadAnnonce(headannonce_id){
        editStoreHeadAnnonces(store_id,headannonce_id)
        .then(function(response){
            let listeAddItem=HeadAnnonces.filter((item)=>item.id===headannonce_id)
            let addItem=listeAddItem.length>0?listeAddItem[0]:{};
            // addItem.status=0;
            addItem.headannonce_id=headannonce_id;
            // addItem.st_HeadAnnonce_id=response.data;
            
            Store_HeadAnnonces[Store_HeadAnnonces.length]=addItem;
            setStoreHeadAnnonces([...Store_HeadAnnonces])
            // props.changeStoreHeadAnnonces([...Store_HeadAnnonces],1,HeadAnnonce_id)
            toast.success(t("Succesfully Added"))
        })
        .catch(function(error){
            handleError(error,t);
        })
    }
   
    function RetireHeadAnnonceFromStore(HeadAnnonce_id){
        editStoreHeadAnnonces(store_id,HeadAnnonce_id)
        .then(function(response){
            let headAnn=[...Store_HeadAnnonces.filter((item)=>item.headannonce_id!==HeadAnnonce_id)]
            setStoreHeadAnnonces(headAnn)
            // props.changeStoreHeadAnnonces(headAnn)
            // props.changeStoreHeadAnnonces(headAnn,0,HeadAnnonce_id)
            
            toast.success(t("Succesfully Retired"))

        })
        .catch(function(error){
            handleError(error,t);
        })
    }
    const history=useNavigate()
    function handleRowClicked(id){
        setLocalstorage('Annonce_id',id)
        history("/dashboard/annonces/fiche")
      }
  return (
    <div className='container row'>

        <div className='col-lg-6 col-md-6 col-sm-12'>
            <span className='relative line-clamp-1 block max-w-[150px] overflow-hidden truncate'> {t('List of annonces')}</span>

            <div className='list-items-container border'>
                

                    {
                        HeadAnnonces && HeadAnnonces.map((item)=>{
                            if(Store_HeadAnnonces.filter(itempg=>itempg.headannonce_id==item.id).length<=0){
                                return <>
                     <div className=" flex cursor-pointer flex-col rounded border border-custom-border-200 bg-custom-background-100">
                            <div className="bg-image-head-annonces relative h-[100px] w-full rounded-t ">
                                <div className="absolute inset-0 z-[1] bg-gradient-to-t from-black/60 to-transparent">
                                </div>
                                 
                                <div className="absolute bottom-4 z-10 flex h-10 w-full items-center justify-between gap-3 px-4">
                                    <div className="flex flex-grow items-center gap-2.5 truncate">
                                        <div className="item-center flex h-14 w-14 flex-shrink-0 justify-center rounded bg-white/90">
                                            <span className="flex items-center justify-center">

                                                <img alt={item.title} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/annonces/"+item.image}   onError={(e) => {e.target.src =image}}/>

                                            </span>
                                        </div>
                                        <div className="flex w-full flex-col justify-between gap-0.5 truncate"
                                            data-toggle="tooltip" data-placement="top" 
                                            title={getTextByLanguage(item.title,langs)+': '+getTextByLanguage(item['description'],langs)}
                                       
                                        >
                                            <h3 className="truncate font-semibold text-white">
                                            {getTextByLanguage(item.title,langs)}
                                             </h3>
                                            <span className="flex items-center gap-1.5">
                                                <p className="text-xs font-medium text-white relative line-clamp-1 block max-w-[100px] overflow-hidden truncate">
                                                {getTextByLanguage(item.description,langs)}
                                                    
                                                 </p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-shrink-0 items-center gap-2">
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>addStoreHeadAnnonce(item.id)} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg>
                                    </button>
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>handleRowClicked(item.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link h-6 w-6 text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                            </>
                            }
                            else{
                                return <></>
                            }
                           
                        })
                    }
                   
                 
            </div>
        </div>
        <div className=' col-lg-6 col-md-6 col-sm-12'>
            <span className='relative line-clamp-1 block max-w-[150px] overflow-hidden truncate'> {t('Selected Annonces')}</span>

            <div className='list-items-container'>
                    {
                        Store_HeadAnnonces.map((item)=>{
                            return <>
                            
                        <div className="flex cursor-pointer flex-col rounded border border-custom-border-200 bg-custom-background-100">
                            <div className=" bg-image-head-annonces  relative h-[100px] w-full rounded-t ">
                                <div className="absolute inset-0 z-[1] bg-gradient-to-t from-black/60 to-transparent">
                                </div>
                                 
                                <div className="absolute bottom-4 z-10 flex h-10 w-full items-center justify-between gap-3 px-4">
                                    <div className="flex flex-grow items-center gap-2.5 truncate">
                                        <div className="item-center flex h-14 w-14 flex-shrink-0 justify-center rounded bg-white/90">
                                            <span className="flex items-center justify-center">

                                                <img alt={item.title} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/annonces/"+item.image}   onError={(e) => {e.target.src =image}}/>

                                            </span>
                                        </div>
                                        <div className="flex w-full flex-col justify-between gap-0.5 truncate"
                                            data-toggle="tooltip" data-placement="top" 
                                            title={getTextByLanguage(item.title,langs)+': '+getTextByLanguage(item['description'],langs)}
                                       
                                        >
                                            <h3 className="truncate font-semibold text-white"> {getTextByLanguage(item.title,langs)}</h3>
                                            <span className="flex items-center gap-1.5">
                                                <p className="text-xs font-medium text-white relative line-clamp-1 block max-w-[100px] overflow-hidden truncate"> {getTextByLanguage(item.description,langs)}  </p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-shrink-0 items-center gap-2">
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>RetireHeadAnnonceFromStore(item.headannonce_id)}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white"><circle cx="12" cy="12" r="10"></circle><path d="m15 9-6 6"></path><path d="m9 9 6 6"></path></svg>
                                    </button>
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10"  onClick={()=>handleRowClicked(item.headannonce_id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link h-6 w-6 text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                       
                            </>
                        })
                    }
                   
            </div>
      
        </div>
    </div>
  )
}
