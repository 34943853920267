import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"; 
import image from "Assets/Images/nophoto.png" 
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { tokenDecoded } from 'Assistances/token';
 import './orders.css'
export default function Message(props) {
    const { t, i18n } = useTranslation();
    const [orderSelectedTosend,setorderSelectedTosend]=useState(null)
    useState(()=>{
        let fromStorageOrderList = getLocalstorage('order_'+(props.customizing && props.customizing[0]?props.customizing[0]['id']:getLocalstorage('id_internal_server')?getLocalstorage('id_internal_server'):null));
        let listOfOrder = fromStorageOrderList ? JSON.parse(fromStorageOrderList) : [];
        var orderListLenght=listOfOrder.length

        if(orderListLenght>0){
            setorderSelectedTosend(listOfOrder.filter(e=>e.status==="Added"))

        }
    },[])
    const [MessageOrders,setMessageOrders]=useState({})
    useEffect(()=>{
            if(tokenDecoded ){
               
                MessageOrders["id_account"]=tokenDecoded.id

                setMessageOrders({...MessageOrders})
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps

    },[])
    function onchangeMessageOrders(key,value){
        MessageOrders[key]=value
        setMessageOrders({...MessageOrders})
    }
    function sendDemand(){
        props.orderSelectedArticle('extern',MessageOrders['phone'],MessageOrders['message'])   
        props.setHideBlock()
        props.declareHiden()
        props.onHide()
    }
  return (
    <div className='  service-center-contact-container'>
        <div className='row'>
            {
               tokenDecoded && tokenDecoded.name && tokenDecoded.login ?
               <></>
                :
                <>
                {/* <div className="grid gap-6 md:grid-cols-1">
                        <div className='mr-1'>
                            <label for="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Name")}*</label>
                            <input type="text" maxLength={255} id="first_name" 
                             value={MessageOrders && MessageOrders['name']?MessageOrders['name']:""} 
                             name="name" 
                             onChange={(e) => onchangeMessageOrders('name', e.target.value)}placeholder={t("Name")}   
                             className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  
                             required/>
                        </div>
                        <div className='mr-1'>
                            <label for="first_email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Email")}*</label>
                            <input 
                            id="first_email" 
                             value={MessageOrders && MessageOrders['name']?MessageOrders['email']:""} 
                             name="email"
                             type="email" maxLength={255} 
                             onChange={(e) => onchangeMessageOrders('name', e.target.value)}placeholder={t("Email")} 
                             className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  
                             required/>
                        </div>
                    
                </div> */}
                {/* <div className='col-12'>
                    <label className="t7k2h1-0 " htmlFor="phn-input" data-size="big">
                        <span className="label ">{t("Name")}*</span>
                        <div className="input-container">
                        <input data-testid="checkout-form__phone-input" value={MessageOrders && MessageOrders['name']?MessageOrders['name']:""} name="name" onChange={(e) => onchangeMessageOrders('name', e.target.value)} type="text" maxLength={255} placeholder={t("Name")} required="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   />
                        </div>
                    </label>
                </div>
                <div className='col-12'>
                    <label className="t7k2h1-0 " htmlFor="phn-input" data-size="big">
                        <span className="label ">{t("Email")}*</span>
                        <div className="input-container">
                        <input data-testid="checkout-form__phone-input" value={MessageOrders && MessageOrders['email']?MessageOrders['email']:""} name="email" onChange={(e) => onchangeMessageOrders('email', e.target.value)} type="text" maxLength={255} placeholder={t("Email")} required="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   />
                        </div>
                    </label>
                </div> */}
            
            </>
            }
            <div className="grid gap-6 md:grid-cols-1">
                <div className='mr-1'>
                    <label for="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Phone")}*</label>
                    <input type="tel" id="first_phone" 
                     value={MessageOrders && MessageOrders['phone']?MessageOrders['phone']:""} 
                     name="phone" 
                     onChange={(e) => onchangeMessageOrders('phone', e.target.value)} placeholder={t("Phone")}   
                     className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  
                     required/>
                </div>
        {/* <div className='col-12'>
                    <label className="t7k2h1-0 " htmlFor="phn-input" data-size="big">
                        <span className="label ">{t("Phone")}*</span>
                        <div className="input-container">
                        <input data-testid="checkout-form__phone-input" value={MessageOrders && MessageOrders['phone']?MessageOrders['phone']:""} name="email" onChange={(e) => onchangeMessageOrders('phone', e.target.value)} type="tel" placeholder={t("Phone")} required="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   />
                        </div>
                    </label>
                </div> */}
                 <div className='mr-1'>
                    <label for="first_message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Message")}</label>
                    <textarea 
                     name="message" 
                     onChange={(e) => onchangeMessageOrders('message', e.target.value)} 
                    //  placeholder={t("Message")}   
                     className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  
                     value={MessageOrders && MessageOrders['message']?MessageOrders['message']:""}
                    />
                </div>
        {/* <div className='col-12'>
            <label className="t7k2h1-0 " htmlFor="phn-input" data-size="big">
                <span className="label ">{t("Message")}</span>
                <div className="input-container">
                <textarea data-testid="checkout-form__phone-input" value={MessageOrders && MessageOrders['message']?MessageOrders['message']:""} name="message" onChange={(e) => onchangeMessageOrders('message', e.target.value)} type="text" maxLength={255} placeholder={t("Message")}required="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"   />
                </div>
            </label> */}
        </div>
        
        <div className="grid gap-6 md:grid-cols-1 mt-1">
            {/* <div style={{marginTop:"7px"}}> */}
                <button 
                    data-type="primary"
                    className="btn sc-1rmt3mq-0 cpUbDl next-button" type="submit" onClick={()=>sendDemand()}>{t("Send")}
                </button>
            {/* </div> */}
        </div>
    </div>

        <div className='order-server-table-container '>
        </div>
    </div>
  )
}
