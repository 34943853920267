import { getTextByLanguage } from "Assistances/GlobalFunctions";
import { getLocalstorage } from "Assistances/GlobalFunctions";


export  function convertDataForChart(sourceData,lablel="",datekey="",totals=0){
    const transformedData = [];
  
    // Step 1: Extract all unique site_label values
    const firstLabels = Array.from(new Set(sourceData.map(item => getTextByLanguage(item[lablel],getLocalstorage('lang')) )));
  
    // Step 2: Extract all unique ticket_date values
    const ticketDates = Array.from(new Set(sourceData.map(item => item[datekey])));
  
    // Step 3: Initialize transformedData with all combinations of ticketDate and firstLabel
    ticketDates.forEach(date => {
      const entry = { name: date };
      firstLabels.forEach(label => {
        entry[label] = 0; // Initialize with 0
      });
      transformedData.push(entry);
    });
  
    // Step 4: Populate[totals] for each site_label in each ticket_date entry
    sourceData.forEach(item => {
      const entry = transformedData.find(dataItem => dataItem.name === item[datekey]);
      if (entry && item[lablel]) {
        entry[getTextByLanguage(item[lablel],getLocalstorage('lang'))] += item[totals] || 0; // Add[totals] or default to 0
      }
    });
  
    return transformedData;
  };
  

  export function convertDataForPie(data,label,total,palletsColors=palletsColors){
    
    return data.map((item,index) => ({
        name:getTextByLanguage( item[label],getLocalstorage('lang')) || 'No name', // Use 'Unknown' if statuts_label is null
        value: item[total] || 0,
        fill: palletsColors[index] // Function to determine fill color based on statuts_label
    }));
  }
  export const convertDataForBarChart = (sourceData,firstarg,secondarg,total,palletsColors=palletsColors) => {
    const transformedData = [];
  
    const firstLabels = Array.from(new Set(sourceData.map(item => getTextByLanguage(item[firstarg],getLocalstorage('lang')))));
  
    const secondLabels = Array.from(new Set(sourceData.map(item => getTextByLanguage(item[secondarg],getLocalstorage('lang')))));
  
    // Step 3: Initialize transformedData with all combinations of firstLabel and secondLabel
    firstLabels.forEach(firstLabel => {
      const entry = { name: firstLabel };
      secondLabels.forEach(secondLabel => {
        entry[secondLabel] = 0; // Initialize with 0
      });
      transformedData.push(entry);
    });
  
    // Step 4: Populate total for each motifs_label in each sites_label entry
    sourceData.forEach(item => {
      const entry = transformedData.find(dataItem => dataItem["name"] === getTextByLanguage(item[firstarg],getLocalstorage('lang')));
      if (entry) {
        entry[getTextByLanguage(item[secondarg],getLocalstorage('lang'))] += item[total] || 0; // Add total or default to 0
      }
    });
  
    return transformedData;
  };
 export const palletsColors=[
    "#01B8AA", "#374649", "#FD625E", "#F2C80F", "#5F6B6D",
    "#8AD4EB", "#FE9666", "#A66999", "#73B761", "#4A588A",
    "#ECC846", "#CD4C46", "#71AFE2", "#8D6FD1", "#EE9E64",
    "#95DABB", "#4A8DDC", "#4C5D8A", "#F3C911", "#DC5B57",
    "#33AE81", "#95C8F0", "#DD915F", "#9A64A0", "#074650",
    "#009292", "#FE6DB6", "#FEB5DA", "#480091", "#B66DFF",
    "#B5DAFE", "#6DB6FF", "#118DFF", "#750985", "#C83D95",
    "#FF985E", "#1DD5EE", "#42F7C0", "#3049AD", "#F64F5C",
    "#107C10", "#002050", "#A80000", "#5C2D91", "#004B50",
    "#0078D7", "#D83B01", "#B4009E", "#F17925", "#004753",
    "#CCAA14", "#4B4C4E", "#D82C20", "#A3D0D4", "#536F18",
    "#46ABB0",
    "#01B8AA", "#374649", "#FD625E", "#F2C80F", "#5F6B6D",
    "#8AD4EB", "#FE9666", "#A66999", "#73B761", "#4A588A",
    "#ECC846", "#CD4C46", "#71AFE2", "#8D6FD1", "#EE9E64",
    "#95DABB", "#4A8DDC", "#4C5D8A", "#F3C911", "#DC5B57",
    "#33AE81", "#95C8F0", "#DD915F", "#9A64A0", "#074650",
    "#009292", "#FE6DB6", "#FEB5DA", "#480091", "#B66DFF",
    "#B5DAFE", "#6DB6FF", "#118DFF", "#750985", "#C83D95",
    "#FF985E", "#1DD5EE", "#42F7C0", "#3049AD", "#F64F5C",
    "#107C10", "#002050", "#A80000", "#5C2D91", "#004B50",
    "#0078D7", "#D83B01", "#B4009E", "#F17925", "#004753",
    "#CCAA14", "#4B4C4E", "#D82C20", "#A3D0D4", "#536F18",
    "#46ABB0",
    "#01B8AA", "#374649", "#FD625E", "#F2C80F", "#5F6B6D",
    "#8AD4EB", "#FE9666", "#A66999", "#73B761", "#4A588A",
    "#ECC846", "#CD4C46", "#71AFE2", "#8D6FD1", "#EE9E64",
    "#95DABB", "#4A8DDC", "#4C5D8A", "#F3C911", "#DC5B57",
    "#33AE81", "#95C8F0", "#DD915F", "#9A64A0", "#074650",
    "#009292", "#FE6DB6", "#FEB5DA", "#480091", "#B66DFF",
    "#B5DAFE", "#6DB6FF", "#118DFF", "#750985", "#C83D95",
    "#FF985E", "#1DD5EE", "#42F7C0", "#3049AD", "#F64F5C",
    "#107C10", "#002050", "#A80000", "#5C2D91", "#004B50",
    "#0078D7", "#D83B01", "#B4009E", "#F17925", "#004753",
    "#CCAA14", "#4B4C4E", "#D82C20", "#A3D0D4", "#536F18",
    "#46ABB0"

  ]
  