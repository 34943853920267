import React, { useEffect, useState } from 'react'
import { getToppings } from './Actions.js'
import {  toast } from 'react-toastify';    
import { useTranslation } from 'react-i18next';
import Datatable from 'Componnents/DataTables/DataTables';
import FormToppings from './FormToppings';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import { useNavigate  } from 'react-router-dom';
import { setLocalstorage } from 'Assistances/token.js';
import Spinner from 'Spinner/Spinner.js';
import { handleError } from 'Assistances/ErrorHandling.js';

export default function DataTablesToppings() {
    const [Toppingss,setToppingss]=useState([]);
    const { t } = useTranslation();
    const columns=['id','title','description',"price",'in_service',"created_at","updated_at"];
    const [showModalFormToppings,setShowModalFormToppings]=useState(false)
    const history=useNavigate();
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
      getAllToppings()
    },[])
    function getAllToppings(){
      getToppings()
        .then(function (response) {
            setToppingss(response.data)
        }) 
        .catch(function (error) {
          handleError(error,t);
        })
        .finally(() => {
          setSpinner(false)
        });
    }
    function handleRowClicked(row){
      setLocalstorage('Topping_id',row.id)
      history("/dashboard/toppings/fiche") 
    }
  return (
    <div>
    {spinner?
      <Spinner/> 
      :

      <Datatable 
      data={Toppingss} 
      columns={columns}
      handleRowClicked={handleRowClicked}
      title="Toppings"
      addbutton={true}
      total={Toppingss.length}

      ShowModal={() => {setShowModalFormToppings(true)}}
      />

    }
      
      <ModalHelper  
        show={showModalFormToppings} 
        title={t("Add")+" "+ t("Toppings")}
        size='lg'
        onHide={() => setShowModalFormToppings(false)} 
        content={
        <FormToppings modifycontent=""
        onHide={() => setShowModalFormToppings(false)} 
        getAllToppings={getAllToppings}
        />}         
        />
        
      
      </div>
  )
}
