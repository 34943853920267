import React, { useEffect, useState } from 'react';

import "./../Body.css"
import image from "Assets/Images/nophoto.png" 
import {BsChatLeftTextFill} from "react-icons/bs";

import { AiFillStar } from "react-icons/ai";
// import RatingStartModal from "../reaction/RatingStarModal";
import { useTranslation } from "react-i18next";
import ModalHelper from 'Componnents/Modals/ModalHelper';
import RatingStarsDiagrams from 'Reactions/RatingStarsDiagrams';
import RatStars from 'Reactions/RatStars';
import AvisGroup from 'Avis/AvisGroup';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { JWTDecode } from 'Assistances/token';
import Login from 'Auth/Login';
import { isTokenExpired } from 'Assistances/GlobalFunctions';
import { GetAccountDataInToken } from 'Assistances/GlobalFunctions';
import { parseTextString } from 'Assistances/GlobalFunctions';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../Redux/ReduxSlices/langs/LangsSlicer';
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function Product(props) {
   const [AvisShow, setAvisShow] = useState(false);
   const [avis,setAvis]=useState([])
   const [ratingValueState,setratingValue]=useState(0)
   const [numberRatingState,setnumberRating]=useState(0)
   const [showRatingStarModal,setshowRatingStarModal]=useState(false)
   const { t } = useTranslation();
   const [my_ratingstart_inProduct,setmy_ratingstart_inProduct]=useState(0)
    
    useEffect(()=>{
        if(!isTokenExpired()){
            var myratings=props.stars.filter(e=>e.id_account==GetAccountDataInToken()?.id && e.id_product==props.item.product_id)
            if(myratings.length>0){
                setmy_ratingstart_inProduct(myratings[0].value);
            }
        }
      
        let ratingValusAndTotal=calculateRating()
        setnumberRating(ratingValusAndTotal[0])
        setratingValue(ratingValusAndTotal[1])

    },[props.stars])
    // useEffect(()=>{
    //     setAvis(props.avis)
    // },[props.avis])

    function calculateRating(){
        var ratingValue=0;
        var numberRating=0;
        var ratingTotalValue=0;
        var productRatings=props.stars.filter(e=>e.id_product==props.item.product_id);
        numberRating=productRatings.length;
        for(var i=0;i<(productRatings?productRatings.length:0);i++){
            ratingTotalValue=ratingTotalValue+productRatings[i].value
        }
        ratingValue=numberRating!=0?ratingTotalValue/numberRating:0
        return [numberRating,ratingValue]
    }

    

    function augementratingStartNumbe(value){
        props.augementratingStartNumbeProductStore(value,props.item.product_id,props.store_id)
    }
    function calculatePercentageReduction(originalValue, reducedValue) {
        if (originalValue <= 0) {
          throw new Error('Original value must be greater than zero.');
        }
        const reductionAmount = originalValue - reducedValue;
        const percentageReduction = (reductionAmount / originalValue) * 100;
        return percentageReduction.toFixed(1);
      }
    function changeRatingStars(value){
        setmy_ratingstart_inProduct(value);

    }
    const langs = useSelector(selectLang);
    useEffect(()=>{

    },[])
  return (
   <div style={{position:"relative"}}>  
   
    <div className='productListing-container container'>
        <div className='avis-react-container'  id="avis" data-title='Avis' onClick={()=>{setAvisShow(true)}}>
             <BsChatLeftTextFill className="services-avis"  size={50} color= "#ECAF78" /> 
             {/* <span className="number-of-avis">{'('+(props.avis ?props.avis.filter(e=> e.product_id==props.item.product_id).length:"0") +')'}</span> */}
             <span className="number-of-avis">{'('+(props.TotalavisByProduct && props.TotalavisByProduct.filter(e=> e.product_id==props.item.product_id).length>0 ?props.TotalavisByProduct.filter(e=> e.product_id==props.item.product_id)[0]?.avis_total:"0") +')'}</span>


             
        </div>
        <div className="start-with-rating-value-container">
        
            <div className="start-with-rating-value" onClick={()=>setshowRatingStarModal(true)}>
                <AiFillStar className="start" size={41}>

                </AiFillStar>
               <span className="rating-value">
                    {ratingValueState.toFixed(1)}
                </span> 
            </div>
            <span className="number-of-rating">{'('+numberRatingState+')'}</span>

        </div>
   
        <div className='productListing-img-container'> 
            <Zoom>
            <img src= {props.item.image?process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/products/"+props.item.image:image} className="productListing-img h-full" alt="image product"/>
            </Zoom>
            <span className="rating-start-container">
                
                <RatStars  changeRatingStars={changeRatingStars}  showModuleLogin={()=>props.showModuleLogin()} product_id={props.item.product_id}         store_id={props.store_id} my_ratingstart_inProduct={my_ratingstart_inProduct} augementratingStartNumbe={augementratingStartNumbe}/>   
            </span>
        </div>    
        <div>
            
            <div className='productListing-title'
                  data-toggle="tooltip" data-placement="top" 
                  title={getTextByLanguage(props.item.title,langs)}
            > {getTextByLanguage(props.item.title,langs)}</div>
        </div>
        <div className="container-desc">
            <div className='productListing-desc'
                                    data-toggle="tooltip" data-placement="top" 
                                    title={getTextByLanguage(props.item.description,langs)}
            >
            {getTextByLanguage(props.item.description,langs)}
            </div>
        </div>

        <footer className="footer-container-product">
                {
                    props.item?.promence_price?

                    <div className="product-control-price">
                    <span className='reel-price'>
                        {props.item.promence_price?props.currency:""} {props.item.promence_price }
                        {
                            calculatePercentageReduction(props.item?.reel_price,props.item?.promence_price)>0 &&(
                                <span className="percentage-reduction">
                                {props.item?.reel_price?calculatePercentageReduction(props.item?.reel_price,props.item?.promence_price)>0? - calculatePercentageReduction(props.item?.reel_price ,props.item?.promence_price)+"%":0+"%":""}
                                </span>
                            )
                        }
                    </span>
                    <span className="product-control-oldprice">

                        <span className="price-promenced"><svg viewBox="0 0 75 10" preserveAspectRatio="none" fill="none" className="discount">
                            <path opacity="0.55" d="M1 9c7.346-1.833 34.434-7.5 73-8" stroke="#FF6900" strokeWidth="2">
                                </path></svg>
                                {props.currency} {props.item.reel_price}
                        </span>
                     </span>
                   
                </div>

                    :

                    <></>
                }
                <div className='flex justify-right text-right  w-full'>
                    <button type="button" className="mybutton flex justify-right text-right " onClick={()=>props.showitemModal(props.item)}>{t("Select")}</button>

                </div>
        </footer>
    </div>
   
        <ModalHelper
        show={AvisShow}
        onHide={() => setAvisShow(false)}
        // Name={props.Name}
        // product={props.item.id}
        // store_id={props.store_id}
        content={
        <div className='showAvis-groupavis-scroll-container-scroll'>
            <div className='showAvis-groupavis-scroll-container'>
              <AvisGroup 
                // MyreactionsAvis={props.MyreactionsAvis}
                // modifyInReactionInList={props.modifyInReactionInList}
                // reactionsAvis={props.reactionsAvis}
                insertNewAvis={props.insertNewAvis}
                // avis={avis.filter(e=>e.product_id==props.item.product_id)}  
                store_id={props.store_id} 
                product_id={props.item.product_id?props.item.product_id:null}
                onHide={props.onHide} 
                showModuleLogin={()=>props.showModuleLogin()}
                />

            </div>
        </div>
        }


      />
    <ModalHelper
        show={showRatingStarModal}
        onHide={() => setshowRatingStarModal(false)}
        content={<RatingStarsDiagrams store_id={props.store_id} stars={props.stars.filter(e=>e.id_product==props.item.product_id)} my_ratingstart_inProduct={my_ratingstart_inProduct} onHide={() => setshowRatingStarModal(false)} item={props.item} ratingstart={props.item.ratingstart?props.item.ratingstart:[]} numberRatingState={numberRatingState} changeRatingStars={changeRatingStars} augementratingStartNumbe={augementratingStartNumbe}/>}
        store_id={props.store_id}
        size={"md"}
      />
   
    </div> 
  )
}
