

import axios from 'axios';
import { token_access } from '../../HelperFunction/token';
import { getLocalstorage } from 'Assistances/GlobalFunctions';



// get diagnostic list .js
export function addMessageServiceCenter(data){

    return  axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/addMessageServiceCenter',
        data: data, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}

    })
}

export function madeReadeServiceCenterMessageAction(id){

    return  axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/madeReadeServiceCenterMessages',
        data: {"id":id}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}


    })
}

export function listingMessageServiceCenter(){

    return  axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/listingMessageServiceCenter',
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}


    })
}




