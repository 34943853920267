
import InfoShow from 'Componnents/Fiche/InfoShow'
import React from 'react'

export default function EditInfoStore(props) {
  return (
    <div>
        <InfoShow ficheInfo={props.ficheInfo} listToShow={['id','title', 'url_store','description','created_at','updated_at']}/>

    </div>
  )
}

