import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { BsCursorFill, BsEmojiSmile, BsFillSendArrowUpFill, BsSendArrowUp, BsSendArrowUpFill } from 'react-icons/bs';
import './Write.css';


import { Picker } from 'emoji-mart';
import './emoji-mart.css'

import { useTranslation } from "react-i18next";
import { getLocalstorage } from 'Assistances/GlobalFunctions';
export default function WriteMessage(props) {

  const [affich_emojis,setAffichEmojis]=useState(false);
  const [message,setMessage]=useState("");
  const { t } = useTranslation();
  const [cursor,setCursor]=useState(0);
  const messageinput = useRef();
  const refEmogiesShow = useRef();

  function select_getemoji(e){
    // setAffichEmojis(false);
    if(getLocalstorage("access_token")){
      var msg=message;
      var msg_p1=msg.substr(0, cursor);
      var msg_p2=msg.substr(cursor,);
      setMessage(msg_p1+e.native+msg_p2);
    }
    else{
      props.showModuleLogin()

    }
      
  } 

  useEffect(()=>{
    const handleClickOutside = (event) => {  
      if (refEmogiesShow.current && !refEmogiesShow.current.contains(event.target) ) {
        setAffichEmojis(false)
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[affich_emojis])

  return (

    <div className='container'>
      {
      affich_emojis?
        <div className="piker-container" ref={refEmogiesShow} > 
           <Picker set='facebook'   onSelect={(e)=>select_getemoji(e)} color="#7E8299"   sheetSize={64} emojiSize={16} showPreview={false} showSkinTones={false} className="emoji-bar"/>
        </div>
      :
        <></>
      }

      <Row>
        <Row className="writemessage-container">   
           <Col xs={1} lg={1} md={1} sm={1}   xl={1}> 
             <div className="emojis-list-botton"  >
               <BsEmojiSmile color="#7E8299" size="23px" onClick={()=>setAffichEmojis(!affich_emojis)} />
             </div>
           </Col>
           <Col xs={8} sm={8}  lg={10} md={10} xl={10}>
             <textarea autoFocus ref={messageinput} maxLength={500} id="input" onFocus={(e)=>{}} onBlur={(e)=>{setCursor(e.target.selectionStart)}} type="text"  placeholder={t("Avis")+"..."} className="writemessage-input" value={message} onChange={(e)=>{setMessage(e.target.value);}}></textarea>    
           </Col>
           <Col xs={2} sm={2}  lg={1} md={1} xl={1}>
             <span className="icon-send-writemessage" onClick={()=>{props.addInputToconversation(message);setMessage('');messageinput.current.focus();}}> <BsFillSendArrowUpFill className="bs-send-icon" size="30px" color='rgb(255, 105, 0)' /></span>
           </Col>
        </Row>
       </Row>
     </div>

  )
}
