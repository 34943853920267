import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

export default function ActiveAccount(  props) {
  const { t } = useTranslation();
  const history=useNavigate()
  return (
    <div className="min-h-screen flex flex-col items-center justify-center ">
      <div className="bg-white  rounded-lg p-8 max-w-lg w-full">
        <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">
          {t("Welcome to")} <span className="text-indigo-600">Zselling!</span>
        </h1>
        <p className="text-gray-600 text-center mb-4">
          {t("Thank you for registering! An activation email has been sent to your email address. To access all features, follow these steps")}:
        </p>
        <ol className="list-decimal list-inside text-gray-700 space-y-4 mb-6">
          <li>
            <p className="font-semibold">{t("Check Your Email")}</p>
            <p className="text-gray-600">{t("Please check your inbox for an email from Zselling! with the subject")}: "{t("Activate Your Account Now.")}"</p>
          </li>
          <li>
            <p className="font-semibold">{t("Click the Activation Link")}</p>
            <p className="text-gray-600">{t("Open the email and click the activation link to verify your email address and activate your account.")}</p>
          </li>
          <li>
            <p className="font-semibold">{t("Start Exploring")}!</p>
            <p className="text-gray-600">{t("Once activated, log in and start enjoying everything Zselling! has to offer.")}</p>
          </li>
        </ol>
        <p className="text-sm text-gray-500 mb-4">
          {t("Didn't receive the activation email? Check your spam/junk folder, as it might be filtered.")}</p>
        <p className="text-sm text-gray-500 mb-6">
          {t("Need Help? Contact our support team at [Support Email/Contact]. We're here to assist you!")}</p>
          <span className='register-Link-item' onClick={()=>props.changeTypeAuth(0)}>          {t("Return to Login")}          </span>

      
      </div>
    </div>
  );
}
