import React, { useEffect, useRef, useState } from 'react'
import image_blank from "Assets/Images/no-profile.png"
import { removeLocalstorage } from 'Assistances/GlobalFunctions';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { BsTranslate } from 'react-icons/bs';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import i18n from 'langs';
import { Link } from 'react-router-dom';

import ar_flag from "Assets/flags/sa.svg"
import en_flag from "Assets/flags/gb-eng.svg"
import es_flag from "Assets/flags/es.svg"
import fr_flag from "Assets/flags/fr.svg"
import { useTranslation } from 'react-i18next';
import { MdOutlineLogout, MdSettings } from 'react-icons/md';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { useDispatch } from 'react-redux';
import { updateLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { capitalizeFirstLetter } from 'Assistances/GlobalFunctions';
import { getAccountInfo } from 'GeneralSettings/Actions';
export default function Topmenu(props) {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const history=useNavigate()
  const [showlangs,setShowLangs]=useState(false)
  const selectorRef = useRef(null); 
  const {t}=useTranslation()
  const handleProfileClick = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };
  const [AccountInfo,setAccountInfo]=useState({name:'',image:''})
  const dispatch = useDispatch();
  function getAccountInfoData(){
    getAccountInfo()
    .then((res)=>{
      setAccountInfo(res.data)
      //console.log(res.data)
    })
    .catch((err)=>{
      //console.log(err)
    })

  }
  useEffect(()=>{
    getAccountInfoData()
  },[])
  useEffect(()=>{
    getAccountInfoData()
  },[props.AccountInfo])
  useEffect(()=>{

  },[props.clickedItem])
   // Close language selector when clicking outside of it
   useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setShowLangs(false);
        setIsProfileMenuOpen(false);

      }
    };
    let langtemp=getLocalstorage('lang')
    if(langtemp && langtemp.length>0){
      setCurrentlang(langtemp)
      i18n.changeLanguage(langtemp,(err)=>{
        //console.log("Error langs change "+err)
      })
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function signout(){
    
    removeLocalstorage("access_token")
    history('/login')
  }
  const [Currentlang,setCurrentlang]=useState("fr")
  function ModifyLanguage(key){
    dispatch(updateLang(key))
    setLocalstorage("lang",key)
    setCurrentlang(key)
    i18n.changeLanguage(key,(err)=>{
        //console.log("Error langs change "+err)
    })
  }
 
  return (
    <>
  <div className="relative z-[15] items-center gap-x-2 gap-y-4">
    <div style={{height:"58px"}} className="flex items-center gap-2 p-4 border-b border-custom-border-200 bg-custom-sidebar-background-100">
      <div className="flex w-full flex-grow items-center gap-2 overflow-ellipsis whitespace-nowrap">
        <div className="w-7 h-7 flex-shrink-0 rounded flex justify-center items-center bg-custom-background-80 transition-all hover:bg-custom-background-90 cursor-pointer group md:hidden" onClick={()=>props.ClickChangeisSidebarClosed()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-menu text-custom-text-200 group-hover:text-custom-text-100 transition-all"><line x1="4" x2="20" y1="12" y2="12"></line><line x1="4" x2="20" y1="6" y2="6"></line><line x1="4" x2="20" y1="18" y2="18"></line></svg>
        </div>
        <div>
          <div className="flex items-center space-x-2 overflow-hidden">
            <div className="flex items-center gap-2.5 flex">
              <li className="flex items-center space-x-2" tabindex="-1">
                <div className="flex flex-wrap items-center gap-2.5">
                  <a className="flex items-center gap-1 text-sm font-medium text-custom-text-300 hover:text-custom-text-100" href="/">
                    <div className="flex h-5 w-5 items-center justify-center overflow-hidden !text-[1rem]">
                      <span className="grid h-7 w-7 flex-shrink-0 place-items-center rounded uppercase">📌</span>
                    </div>
                    <div className="relative line-clamp-1 block max-w-[150px] overflow-hidden truncate">{t('ZSelling')}</div>
                  </a>
                </div>
              </li>
            </div>
          <div className="flex items-center gap-2.5" onClick={()=>props.ClickChangeisSidebarClosed()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-right h-3.5 w-3.5 flex-shrink-0 text-custom-text-400" aria-hidden="true"><path d="m9 18 6-6-6-6"></path></svg>
          </div>
          <div className="flex items-center gap-2.5 flex">
            <li className="flex items-center space-x-2" tabindex="-1">
              <div className="flex flex-wrap items-center gap-2.5">
                <div className="flex cursor-default items-center gap-1 text-sm font-medium text-custom-text-100">
                  {/* <div className="flex h-5 w-5 items-center justify-center overflow-hidden"> */}
                    {/* <svg viewBox="0 0 24 24" className="h-4 w-4 text-custom-text-300 stroke-2" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_7258_81938)"><path d="M16.5953 6.69606L16.6072 5.17376L6.85812 8.92381L6.85812 19.4238L9.00319 18.6961" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12.0953 3.69606L12.1072 2.17376L2.35812 5.92381L2.35812 16.4238L4.50319 15.6961" stroke-linecap="round" stroke-linejoin="round"></path><path d="M21.7438 17.9461L21.7511 7.44434L12.0021 11.1944L12.0021 21.6944L21.7438 17.9461Z" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_7258_81938"><rect width="24" height="24" fill="white" transform="translate(24) rotate(90)"></rect></clipPath></defs></svg> */}
                    {/* {props.clickedItem?props.clickedItem.icon:""} */}
                  
                  {/* </div> */}
                  <div className="relative line-clamp-1 block max-w-[150px] overflow-hidden truncate">
                  {/* {props.clickedItem.title} */}
                  {t(capitalizeFirstLetter(window.location.pathname.split('/').length>1?window.location.pathname.split('/')[2]:window.location.pathname.split('/').length>0?window.location.pathname.split('/')[1]:"").replace(/-/g, ' '))}
                  </div>
                </div>
              </div>
            </li>
          </div>
        </div>
      </div>
    </div>
    <div className="items-center gap-2 ">
      <div className='mr-6'>
      <div className=' 'style={{right:"50px"}} onClick={()=>setShowLangs(!showlangs)}>
        <BsTranslate className='cursor-pointer' size={30}/>
      </div>

       
    {showlangs && (
        <div ref={selectorRef} style={{marginTop:"-11px",marginRight:"61px"}}className="absolute top-full right-0 bg-white border border-gray-200 rounded-md shadow-lg z-10 ">
          
          <Dropdown className='header-togle' >
                  <span className={Currentlang=="ar"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"}  onClick={()=>ModifyLanguage('ar')}> 
                     <img src={ar_flag} width={"21px"} height={"27px"} className='flags'/> {t('Arabic')}
                   </span>                        
                   <span className={Currentlang=="fr"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"}   onClick={()=>{ModifyLanguage('fr') }} >
                     <img src={fr_flag} width={"21px"} height={"27px"} className='flags'/> {t('France')}
                   </span>
                   <span className={Currentlang=="en"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"} onClick={()=>ModifyLanguage('en')}>
                     <img src={en_flag} width={"21px"} height={"27px"} className='flags'/> {t('English')}
                   </span>
                   <span className={Currentlang=="sp"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"}   onClick={()=>{ModifyLanguage('sp') }} >
                     <img src={es_flag} width={"21px"} height={"27px"} className='flags'/> {t('Spanish')}
                   </span>
                </Dropdown>
        </div>)}
      </div>
      {/* <div className="flex items-center gap-1 rounded bg-custom-background-80 p-1"> */}
        {/* <button type="button" className="group grid h-[22px] w-7 place-items-center overflow-hidden rounded transition-all hover:bg-custom-background-100 " tabindex="0">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-list h-3.5 w-3.5 text-custom-text-200"><line x1="8" x2="21" y1="6" y2="6"></line><line x1="8" x2="21" y1="12" y2="12"></line><line x1="8" x2="21" y1="18" y2="18"></line><line x1="3" x2="3.01" y1="6" y2="6"></line><line x1="3" x2="3.01" y1="12" y2="12"></line><line x1="3" x2="3.01" y1="18" y2="18"></line></svg>
        </button>
        <button type="button" className="group grid h-[22px] w-7 place-items-center overflow-hidden rounded transition-all hover:bg-custom-background-100 bg-custom-background-100 shadow-custom-shadow-2xs" tabindex="0">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-kanban h-3.5 w-3.5 text-custom-text-100"><path d="M6 5v11"></path><path d="M12 5v6"></path><path d="M18 5v14"></path></svg>
        </button>
        <button type="button" className="group grid h-[22px] w-7 place-items-center overflow-hidden rounded transition-all hover:bg-custom-background-100 " tabindex="0">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-calendar h-3.5 w-3.5 text-custom-text-200"><rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect><line x1="16" x2="16" y1="2" y2="6"></line><line x1="8" x2="8" y1="2" y2="6"></line><line x1="3" x2="21" y1="10" y2="10"></line></svg>
        </button>
        <button type="button" className="group grid h-[22px] w-7 place-items-center overflow-hidden rounded transition-all hover:bg-custom-background-100 " tabindex="0">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-sheet h-3.5 w-3.5 text-custom-text-200"><rect width="18" height="18" x="3" y="3" rx="2" ry="2"></rect><line x1="3" x2="21" y1="9" y2="9"></line><line x1="3" x2="21" y1="15" y2="15"></line><line x1="9" x2="9" y1="9" y2="21"></line><line x1="15" x2="15" y1="9" y2="21"></line></svg>
        </button>
        <button type="button" className="group grid h-[22px] w-7 place-items-center overflow-hidden rounded transition-all hover:bg-custom-background-100 " tabindex="0">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-gantt-chart-square h-3.5 w-3.5 text-custom-text-200"><rect width="18" height="18" x="3" y="3" rx="2"></rect><path d="M9 8h7"></path><path d="M8 12h6"></path><path d="M11 16h5"></path>
        </svg>
        </button> */}
      {/* </div> */}
      <div data-headlessui-state="">
        {/* <button type="button" className="text-custom-text-200 bg-custom-background-100 border border-custom-border-200 hover:bg-custom-background-90 focus:text-custom-text-300 focus:bg-custom-background-90 px-3 py-1.5 font-medium text-xs rounded flex items-center gap-1.5 whitespace-nowrap transition-all justify-center" aria-expanded="false" data-headlessui-state="" id="headlessui-popover-button-:rrp:">
          <div className="text-custom-text-200">
          <span>Filters</span>
          </div>
          <div className="h-3 w-3 flex justify-center items-center overflow-hidden my-0.5 flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-up transition-all rotate-180"><path d="m18 15-6-6-6 6"></path></svg>
          </div>
        </button> */}
      </div>
      {/* <div style={{position: "fixed", top: "1px", left: "1px", width: "1px", height: "0px", padding: "0px", margin: "-1px", overflow: "hidden", clip: "rect(0px, 0px, 0px, 0px)", whiteSpace: "nowrap", borderWidth: "0px", display: "none"}}>
      </div> */}
      <div data-headlessui-state="">
        {/* <button type="button" className="text-custom-text-200 bg-custom-background-100 border border-custom-border-200 hover:bg-custom-background-90 focus:text-custom-text-300 focus:bg-custom-background-90 px-3 py-1.5 font-medium text-xs rounded flex items-center gap-1.5 whitespace-nowrap transition-all justify-center" aria-expanded="false" data-headlessui-state="" id="headlessui-popover-button-:rrr:">
          <div className="text-custom-text-200">
          <span>Display</span>
          </div>
          <div className="h-3 w-3 flex justify-center items-center overflow-hidden my-0.5 flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-up transition-all rotate-180"><path d="m18 15-6-6-6 6"></path></svg>
          </div>
        </button> */}
        
       
      </div>
      {/* <div style={{position: "fixed", top: "1px", left: "1px", width: "1px", height: "0px", padding: "0px", margin: "-1px", overflow: "hidden", clip: "rect(0px, 0px, 0px, 0px)", whiteSpace: "nowrap", borderWidth: "0px", display: "none"}}>
     
      </div> */}
    </div>
    {/* <button type="button" className="text-custom-text-200 bg-custom-background-100 border border-custom-border-200 hover:bg-custom-background-90 focus:text-custom-text-300 focus:bg-custom-background-90 px-3 py-1.5 font-medium text-xs rounded items-center gap-1.5 whitespace-nowrap transition-all justify-center hidden md:block">
      Analytics
    </button>
    <button type="button" className="text-white bg-custom-primary-100 hover:bg-custom-primary-200 focus:text-custom-brand-40 focus:bg-custom-primary-200 px-3 py-1.5 font-medium text-xs rounded flex items-center gap-1.5 whitespace-nowrap transition-all justify-center">
      <div className="h-3 w-3 flex justify-center items-center overflow-hidden my-0.5 flex-shrink-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg>
      </div>
      <div className="hidden sm:block">
        Add
      </div>
      Issue
    </button> */}
    
     <img className='circle-image-37' alt="profile image"
        src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/users/"+AccountInfo?.image}     
        onError={(e) => {e.target.src =image_blank}}
        
        onClick={() => handleProfileClick()}
      />
                
  </div>
  {/* <div className="block md:hidden">
    <div className="flex justify-evenly py-2 border-b border-custom-border-200">
      <div className="relative w-min text-left flex flex-grow justify-center text-custom-text-200 text-sm" data-headlessui-state="">
        {/* <button type="button" className="flex flex-grow justify-center text-custom-text-200 text-sm" tabindex="0" id="headlessui-menu-button-:rrt:" aria-haspopup="menu" aria-expanded="false" data-headlessui-state="">
          <span className="flex flex-grow justify-center text-custom-text-200 text-sm">Layout</span>
        </button> 
      </div>
      <div className="flex flex-grow justify-center border-l border-custom-border-200 items-center text-custom-text-200 text-sm">
        <div data-headlessui-state="">
          {/* <button role="button" aria-expanded="false" data-headlessui-state="" type="button" id="headlessui-popover-button-:rru:">
            <span className="flex items-center text-custom-text-200 text-sm">Filters<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-down text-custom-text-200  h-4 w-4 ml-2"><path d="m6 9 6 6 6-6"></path></svg>
            </span>
          </button> *
        </div>
        <div style={{position: "fixed", top: "1px", left: "1px", width: "1px", height: "0px", padding: "0px", margin: "-1px", overflow: "hidden", clip: "rect(0px, 0px, 0px, 0px)", whiteSpace: "nowrap", borderWidth: "0px", display: "none"}}>
        </div>
      </div>
      <div className="flex flex-grow justify-center border-l border-custom-border-200 items-center text-custom-text-200 text-sm">
        <div data-headlessui-state="">
          {/* <button role="button" aria-expanded="false" data-headlessui-state="" type="button" id="headlessui-popover-button-:rs0:">
            <span className="flex items-center text-custom-text-200 text-sm">
              Display
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-down text-custom-text-200 h-4 w-4 ml-2"><path d="m6 9 6 6 6-6"></path></svg>
            </span>
          </button> 
        </div>
        <div style={{position: "fixed", top: "1px", left: "1px", width: "1px", height: "0px", padding: "0px", margin: "-1px", overflow: "hidden", clip: "rect(0px, 0px, 0px, 0px)", whiteSpace: "nowrap", borderWidth: "0px", display: "none"}}>
        </div>
      </div>
      {/* <button className="flex flex-grow justify-center text-custom-text-200 text-sm border-l border-custom-border-200">Analytics</button> 
    </div>
  </div> */}
  {isProfileMenuOpen && (
        <div  ref={selectorRef}style={{marginTop:"-11px",marginRight:"11px"}}className="absolute top-full right-0 bg-white border border-gray-200 rounded-md shadow-lg z-10">
          {/* Dropdown items */}
          <div className="py-1">
            {/* <span className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">{t('Profile')}</span> */}
            <span className="block px-4 py-2 text-sm text-green-400 hover:bg-gray-100 cursor-pointer"><Link to='/dashboard/settings'><MdSettings/> {t('Settings')}</Link></span>
            <span className="block px-4 py-2 text-sm text-red-500 hover:bg-gray-100 cursor-pointer" onClick={()=>signout()}> <MdOutlineLogout/> {t('Sign out')}</span>
          </div>
        </div>
      )}
</div>
   

    </>
  )
}
