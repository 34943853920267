
import React, { useEffect, useState } from 'react'
import {  toast } from 'react-toastify';    
import { useTranslation } from 'react-i18next';
import Datatable from 'Componnents/DataTables/DataTables';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import FormFeatures from './FormFeatures.js';
import { getAllFeatures } from './Actions.js';
import { setLocalstorage } from 'Assistances/GlobalFunctions.js';
import { useNavigate  } from 'react-router-dom';
import Spinner from 'Spinner/Spinner.js';
import { handleError } from 'Assistances/ErrorHandling.js';

export default function DataTableFeatures() {
    const [Features,setFeatures]=useState([]);
    const { t } = useTranslation();
    const history=useNavigate()

    const columns=['id','title','description','in_service',"created_at","updated_at"];
    const [showModalFormFeature,setShowModalFormFeature]=useState(false)
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
      getFeaturesAll()
    },[])
    function getFeaturesAll(){
      getAllFeatures()
      .then(function (response) {
          setFeatures(response.data)
      }) 
      .catch(function (error) {
          handleError(error,t,t('Features'));

      }) 
      .finally(() => {
        setSpinner(false)
      });

    }
    function handleRowClicked(row){
      setLocalstorage('feature_id',row.id)
      history("/dashboard/features/fiche")

    }
  return (
    <div>
    {spinner?
      <Spinner/> 
      :
      <Datatable 
      data={Features} 
      columns={columns}
      handleRowClicked={handleRowClicked}
      title="Feature"
      total={Features.length}

      addbutton={true}
      ShowModal={() => {setShowModalFormFeature(true)}}
      />
    }
      
      <ModalHelper  
        show={showModalFormFeature} 
        title={t("Add")+" "+ t("Feature")}
        size='lg'
        onHide={() => setShowModalFormFeature(false)} 
        content={
        <FormFeatures modifycontent=""
        onHide={() => setShowModalFormFeature(false)} 
        getFeaturesAll={getFeaturesAll}
        />}         
        />
        
      
      </div>
  )
}

