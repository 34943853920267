import React from 'react';
import image from "Assets/Images/nophoto.png" 
import { DisplayIconSocial } from 'Assistances/GlobalFunctions';
import {  toast } from 'react-toastify';    
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../Redux/ReduxSlices/langs/LangsSlicer';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';

export default function Footer(props) {
  const { t } = useTranslation();
  const langs = useSelector(selectLang);
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
    function copie(value){
        navigator.clipboard.writeText(value);toast.success(t("Successfully copied")+": "+value)
      }
  return (
    <div>
        <footer className="bg-white  dark:bg-gray-800">
            <div className="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">

                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <div className="text-center justify-center truncate ">
                    <a href="#" title='test' className=" items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white">
                        {/* <img src={logo} className="h-6 mr-3 sm:h-9" alt="ztech Logo" /> */}
                        <img  className="h-6 mr-3 sm:h-9"  src={props.info['image'] ?process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+props.info['image']:image}   alt="logo" onError={(e) => {e.target.src =image}} />

                        <div className='grid ml-1 mr-1 w-sm'><span className='flex w-full text-center items-center justify-center text-wrap-content'
                        
                        
                        data-toggle="tooltip" data-placement="top" 
                        title={props.info?props.info.title:""}>
                            
                            
                            {getTextByLanguage(props.info?props.info.title:"",langs)}   </span></div> 
                    </a>
                    <div className="flex w-full text-center items-center justify-center block text-sm text-center text-gray-500 dark:text-gray-400">© {previousYear}-{currentYear}{" "} <div className='grid ml-1 mr-1 w-sm'><span className='text-left text-wrap-content'> {getTextByLanguage(props.info?props.info.title:"",langs)}</span></div>™. {t('All Rights Reserved')}. 
                    </div>

                    <ul className="flex justify-center mt-5 space-x-5">
                        {props.socials && props.socials.map(item=>{ 
                            return (
                                <li>
                                    <a  onClick={()=>copie(item.value)}
                                    
                                    data-toggle="tooltip" data-placement="top" 
                                    title={item.title}
                                    className="truncate pointer text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400" >
                                    {DisplayIconSocial(item.title,27,"rgb(107 114 128)")}

                                    </a>
                                </li>

                            )

                        })}
                      
                    </ul>
                </div>
            </div>
        </footer>
    </div>
      )
    }
