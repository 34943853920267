import { GetAccountDataInToken } from "Assistances/GlobalFunctions"
import { getLocalstorage } from "Assistances/GlobalFunctions"
import axios from "axios"



export function getAvisProductAndReactionAction(id_product,pages,skip,id_store){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAvisProductAndReaction?id_product='+id_product+"&page="+pages+"&skip="+skip+"&id_store="+id_store,
        data: {}, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    })
}
export function getAvisStoreAndReactionAction(id_store,pages,skip){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAvisStoreAndReaction?id_store='+id_store+"&page="+pages+"&skip="+skip,
        data: {}, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    })
}

export function addAvisOnproductAction(id_product,comment,id_store){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/addAvisOnproduct',
        data:{
        "id_product": id_product,
        "comment":comment,
        "id_store":id_store
        },
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    })

}
export function addAvisOnStoreAction(id_store,comment){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/addAvisOnStore',
        data:{
        "id_store": id_store,
        "comment":comment},
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    })

}
export function getAvisANDReaction(id_store,product_id,offset=0){
    console.log({
        "store_id": id_store,
        "product_id":product_id,
        'offset':offset,
        "id_account":GetAccountDataInToken()?GetAccountDataInToken().id:null})
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAvisANDReaction',
        data:{
        "store_id": id_store,
        "product_id":product_id,
        'offset':offset,
        "id_account":GetAccountDataInToken()?.id},
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    })

}



