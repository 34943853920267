

import { getLocalstorage } from "Assistances/GlobalFunctions";
import { id_account_creater } from "HelperFunction/token";
import axios from "axios"

export function getAllProducts(data){

    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAllProducts',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}
export function addProducts(data){
    return axios({
    method: 'post',
    url: process.env.REACT_APP_APP_URL_BACKEND+'/addProducts',
    data: data, 
    headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
})
}

export function ModifyProducts(data){
    return axios({
    method: 'post',
    url: process.env.REACT_APP_APP_URL_BACKEND+'/ModifyProducts',
    data: data, 
    headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
})
}


export function getProductfichData(product_id){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getProductfichData?id='+product_id+"&id_account_creater"+id_account_creater,
        data: {}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}