import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

export default function MenuLeftOptions(props) {
  
    // useEffect(()=>{
    //     props.getClieckedItem(props.menuContent.length>0?props.menuContent[0]:{})
    // },[])
    const [activeItem,setactiveItem]=useState(props.menuContent.length>0?props.menuContent[0]['title']:{})
   function ClickItemMenu(item){
        setactiveItem(item['title'])
        props.getClieckedItem(item)
   }
  return (
    <div className='container'>
        <div className='card'>
            <div className='card-header'>
                {props.title}
            </div>
            <div className='card-body'>
                {
                    props.menuContent.map(item=>{
                        return( 
                            <NavLink to={item['path']} className={activeItem===item['title']?"":""} onClick={()=>ClickItemMenu(item)}>
                                 <span className="my-1 block w-full justify-left" >
                                   <div className={activeItem===item['title']?"group justify-left left relative grid w-full items-left gap-2.5 rounded-md px-3 py-2 text-sm font-medium outline-none text-custom-sidebar-text-200 hover:bg-custom-sidebar-background-80 justify-left bg-custom-sidebar-background-80":"group relative grid w-full items-left gap-2.5 rounded-md px-3 py-2 text-sm font-medium outline-none text-custom-sidebar-text-200 hover:bg-custom-sidebar-background-80 justify-left"} tabindex="0">
                                   <span>{item['icon']} {item['title']}</span>

                                   </div>
                                 </span>
                            </NavLink>
                           )
                       
                })
            }
            </div>
        </div>
        
    </div>
  )
}
