import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { selectLang } from '../../../../Redux/ReduxSlices/langs/LangsSlicer';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
export default function DescripeProduct(props) {
   const {t}=useTranslation()
   const langs = useSelector(selectLang);

  return (
    <>
        <div className=''>
            <div className="  container-feature-content-block">
               
                <h2 className=" feature-title">{t("Product")} {t("Description")}</h2>
                <div className="scrollbar m-1 overflow-y-auto overflow-x-hidden ">
                    
                    <p className='h-full overflow-y-auto overflow-x-hidden '>
                    {/* {props.description} */}
                    {getTextByLanguage(props.description,langs)}

                    </p>
                </div>
            </div>
        </div>       
      

    </>
  )
}
