


import React, { useEffect, useState } from 'react'
import {  toast } from 'react-toastify';    
import { useTranslation } from 'react-i18next';
import Datatable from 'Componnents/DataTables/DataTables';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import { getAllAnnonces } from './Actions';
import FormAnnonces from './FormAnnonces';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import { useNavigate  } from 'react-router-dom';
import Spinner from 'Spinner/Spinner';
import { handleError } from 'Assistances/ErrorHandling';

export default function DataTableAnnonces() {
    const [Annonces,setAnnonces]=useState([]);
    const { t } = useTranslation();
    const history=useNavigate();
    const columns=['id','title','description',"created_at","updated_at"];
    const [showModalFormHeadAnnonces,setShowModalFormHeadAnnonces]=useState(false)
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
      getAnnonceAll()
    },[])
    function getAnnonceAll(){
      getAllAnnonces()
        .then(function (response) {
            setAnnonces(response.data)
        }) 
        .catch(function (error) {
          handleError(error,t,t('Annonces'));
        })
        .finally(() => {
          setSpinner(false)
        }); 
    }
    function handleRowClicked(row){
      setLocalstorage('Annonce_id',row.id)
      history("/dashboard/annonces/fiche")
    }
  return (
    <div>
      

  {spinner?
      <Spinner/> 
      :

      <Datatable 
      data={Annonces} 
      columns={columns}
      handleRowClicked={handleRowClicked}
      title="Annonces"
      total={Annonces.length}

      addbutton={true}
      ShowModal={() => {setShowModalFormHeadAnnonces(true)}}
      />
  }
      
      
      <ModalHelper  
        show={showModalFormHeadAnnonces} 
        title={t("Add")+" "+ t("Annonces")}
        size='lg'
        onHide={() => setShowModalFormHeadAnnonces(false)} 
        content={<FormAnnonces modifycontent='' onHide={() => setShowModalFormHeadAnnonces(false)}  getAnnonceAll={getAnnonceAll}/>} />
        
      
      </div>
  )
}
