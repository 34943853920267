
import { GetAccountDataInToken } from 'Assistances/GlobalFunctions'
import { isTokenExpired } from 'Assistances/GlobalFunctions'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {  BiUserCircle } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import image_blank from "Assets/Images/no-profile.png"
import { getAccountInfo } from 'GeneralSettings/Actions'

export default function EspaceClient(props) {
  const {t}=useTranslation()
  const [AccountInfo,setAccountInfo]=useState({name:'',image:''})
  function getAccountInfoData(){

  getAccountInfo()
    .then((res)=>{
      setAccountInfo(res.data)
      //console.log(res.data)
    })
    .catch((err)=>{
      //console.log(err)
    })

  }
  useEffect(()=>{
    getAccountInfoData()
  },[])
 
  return (
    <div className='z-10 relative   text-align justify-content'>
      {
        isTokenExpired()?
        <div onClick={()=>props.showModuleLogin()} className=' absolute right-0 p-3 cursor-pointer  '>       
          {/* <button className=' inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-gray-900 dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white'> */}
           
             <span className='espace-client'>
           
              
              <span className='name-espace-client'>{t('Espace Client')}</span>
              <img className='circle-image-47 espace-client-image bx' alt="profile image"
              src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/users/"+AccountInfo?.image}     
              onError={(e) => {e.target.src =image_blank}}

            />
              </span>
          </div>


        :
        <Link to={"/dashboard/statics"}>
           <div  className=' absolute right-0 p-3 cursor-pointer  '>       
          {/* <button className=' inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-gray-900 dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white'> */}
           
             <span className='espace-client'>

             <span className='name-espace-client'>{GetAccountDataInToken()?.name}</span>
              <img className='circle-image-47 espace-client-image bx' alt="profile image"
              src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/users/"+AccountInfo?.image}     
              onError={(e) => {e.target.src =image_blank}}

            />
              </span>
          </div>
       
        </Link>
      }
    
    </div>
   
  )
}
