// import { ratingstartAction } from 'Product/Action/ActionsProduct';
import React, { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { ratingstartAction, ratingstartStoreAction } from './Actions';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { isTokenExpired } from 'Assistances/GlobalFunctions';
// import { ratingstartAction } from './Action/ActionsProduct';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function RatStars(props) {
  const [rating, setRating] = useState(0) 
  const {t}=useTranslation()
  const handleRating = (rate) => {
    
   if(getLocalstorage("access_token")){
    setRating(rate)
    if(props.store && props.store==true){
      console.log('rating stare store')
      ratingstartStoreAction(rate,props.store_id)
      .then(function (response) {
          props.augementratingStartNumbe(rate)
          props.changeRatingStars(rate)
        })
        .catch(function (error) {
            console.log(error);
            toast.error(t("Try to Login"))
  
        });  
    }
    else{
      ratingstartAction(props.product_id,rate,props.store_id)
      .then(function (response) {
          props.augementratingStartNumbe(rate)
          props.changeRatingStars(rate)
        })
        .catch(function (error) {
            console.log(error);
            toast.error(t("Try to Login"))
  
        });  
    }
   
      
      
   }
   else{
     props.showModuleLogin()

   }
    
      }
  var size=props.size?props.size:30;
  return (

    <div className=' rating-bottom rating-stars' style={{textAlign:props.align?props.align:"center"}}>
  {!isTokenExpired()?  <Rating 
  onClick={handleRating} 
  ratingValue={rating}
  size={size}
  label
  transition
  fillColor='orange'
  emptyColor='gray' 
  className='foo'  
  initialValue={props.my_ratingstart_inProduct?props.my_ratingstart_inProduct:0}
  />
  :
  <></>
  }
</div>
  );
}
