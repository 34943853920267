import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import image from "Assets/Images/nophoto.png";
import "./teams.css";
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../../Redux/ReduxSlices/langs/LangsSlicer';
import { MdEmail, MdPhone } from 'react-icons/md';
import { toast } from 'react-toastify';

export default function TeamsListing(props) {
  const { t } = useTranslation();
  const langs = useSelector(selectLang);

  const [currentIndex, setCurrentIndex] = useState(0); // Current index for sliding
  const [itemsToShow, setItemsToShow] = useState(3);  // Default: 3 items visible

  // Function to copy email/phone
  function copie(value) {
    navigator.clipboard.writeText(value);
    toast.success(t("Successfully copied") + ": " + value);
  }

  // Adjust the number of visible items based on screen size
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 640) {
        setItemsToShow(1); // Small screens
      } else if (width <= 1024) {
        setItemsToShow(2); // Medium screens
      } else {
        setItemsToShow(3); // Large screens
      }
    };

    handleResize(); // Initial check on component mount
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Rotate through items at a fixed interval
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % props.teams.length);
    }, 3000); // Change item every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [props.teams.length]);

  // Get the items to display based on the current index and itemsToShow
  const displayedItems = props.teams.slice(currentIndex, currentIndex + itemsToShow);
  
  // If there aren't enough items to fill the desired number (e.g., 2 items but only 1 remaining), wrap around
  if (displayedItems.length < itemsToShow) {
    displayedItems.push(...props.teams.slice(0, itemsToShow - displayedItems.length));
  }

  return (
    <>
     {
        props.teams && props.teams.length>0?<h2 className='block-title-team text-center w-full ' style={{textAlign:"center!important"}}>{t("Teams")}</h2>:<></>
      }
    <div className='teams-listing-container m-5'>
      
      
      <TransitionGroup component={null}>
        {
          displayedItems.map((item, index) => (
            <CSSTransition
              key={item.id} // Unique key for transition
              timeout={800}
              classNames="team-item"
            >
              <div className={`team-item team-item-${index}`} key={index}>
                <div className='justify-center text-center teamListing-container'>
                  <div className='teamListing-img-container'>
                    <img 
                      src={`${process.env.REACT_APP_APP_URL_BACKEND_IMAGES}/teams/${item.image}`} 
                      className="teamListing-img" 
                      onError={(e) => { e.target.src = image; }} 
                      alt={getTextByLanguage(item.name, langs)}
                    />
                  </div>
                  <div className='mt-4'>
                    <div 
                      className='teamListing-title mt-2'
                      title={getTextByLanguage(item.name, langs)}
                    >
                      {getTextByLanguage(item.name, langs)}
                    </div>
                    <div 
                      className='teamListing-desc'
                      title={getTextByLanguage(item.poste, langs)}
                    >
                      {getTextByLanguage(item.poste, langs)}
                    </div>
                  </div>
                  <div className='flex text-center justify-center w-full'>
                    {item.login && (
                      <span className='m-2' onClick={() => copie(item.login)} title={item.login}>
                        <MdEmail size={23} color='rgb(236, 175, 120)' />
                      </span>
                    )}
                    {item.mobile && (
                      <span className='m-2' onClick={() => copie(item.mobile)} title={item.mobile}>
                        <MdPhone size={23} color='rgb(236, 175, 120)' />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </CSSTransition>
          ))
        }
      </TransitionGroup>
    </div>
    </>
  );
}
