import React, { useEffect, useState } from 'react'
import TotalsStatics from './TotalsStatics'
import './boardTable.css'
import { boardtableStatics } from './BoardTableActions'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import Spinner from 'Spinner/Spinner'
import Diagrams from './Diagrams/Diagrams'
import { IsAdmin } from 'Assistances/GlobalFunctions'
import { IsGestionnaire } from 'Assistances/GlobalFunctions'
import { handleError } from 'Assistances/ErrorHandling'
export default function BoardTable(props) {
  const [TotalStatics,setTotalStatics]=useState({})
  const {t}=useTranslation()
  const [spinner,setSpinner]=useState(true)
  useEffect(()=>{
    boardtableStatics()
    .then(function(response){
        setTotalStatics(response['data'])
    })
    .catch(function(error){
      handleError(error,t,t('Statistics'));
    })
    .finally(() => {
      setSpinner(false)
    });
    
   },[]) 
  return (
    <div id="app-dashboard" >
    {spinner?
      <Spinner/> 
      :
      <>
        <TotalsStatics TotalStatics={TotalStatics}/>
        {IsAdmin() || IsGestionnaire()?
        <Diagrams/>
        :
        <></>
        }
      </>
     }
    </div>
  )
}
