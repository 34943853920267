import React, { useState } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBCardBody,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';
import logo from "Assets/Images/logo.png" 
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { SigninAction } from './Actions';
import { setLocalstorage } from 'HelperFunction/token';
import { useNavigate } from 'react-router-dom';
import Spinner from 'Spinner/Spinner';
import { getLocalstorage } from 'Assistances/GlobalFunctions';

function Login(props) {
    const { t } = useTranslation();
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')
    const navigate=useNavigate()
    const [spinner,setSpinner]=useState(true)

    function Authentifiaction(e){
      e.preventDefault();
      setSpinner(false)
       

        SigninAction(email,password)
            .then(function (response) {
              if(response.data.access_token){
                setLocalstorage('access_token', response.data.access_token);

                // navigate('/dashboard/statics')   

                toast.success(t("Successfully"))
                window.location.reload();

              }
                
            })
            .catch(function (error) {
              console.log(error)
                if(error.response?.message){
                    toast.error(t(error.response.data.message))
                }
                else{

                if (error.response?.status &&  error.response.status === 500) {
                    toast.error(t("Login Incorrect check your email or password"))
                }
                else{
                    toast.error(t("Login Incorrect check your email or password"))

                }
              }

            })
            .finally(()=>{
              setSpinner(true)
              try{
                props.tokenValueChange(getLocalstorage('access_token'))

              }
              catch(error){
                console.log(error)
              }

              });
    }
  return (
    
    <MDBContainer className='my-5'>
       {!spinner?
      <Spinner/> 
      :

      <form onSubmit={(e)=>Authentifiaction(e)}>
        <MDBContainer className='text-center' style={{maxWidth:"550px"}}>
            
            <MDBCardBody>
                <div className='container w-100 rounded-circle-container' >
                  <img className="rounded-circle" alt="avatar1" src={logo} style={{maxWidth:"200px"}} />
                </div>
                <h2 className='text-left' style={{textAlign:"left",margin:'17px', fontWeight:"500"}}>{t('Sign in')}</h2>

                  <MDBInput wrLoginerclassName='mb-4' label={t('Email')} id='form1' type='email' onChange={(e)=>setEmail(e.target.value)}/>
              <MDBInput wrLoginerclassName='mb-4' label={t('Password')} id='form2' type='password'  onChange={(e)=>setPassword(e.target.value)}/>

              <div className="d-flex justify-content-between mx-4 mb-4">
                <a className="cursor-pointer register-Link-item"onClick={()=>props.changeTypeAuth(2)}>{t('Forgot password?')}</a>
              </div>

              <MDBBtn className="mb-4 w-100" type='submit'> {t("Sign in")}</MDBBtn>
            <div className="register-link">
                    {t("You dont have account?")}  
                <span className='register-Link-item' onClick={()=>props.changeTypeAuth(1)}>{t("Register")}</span>
            </div>    
            </MDBCardBody>

          </MDBContainer>
          </form>
      }
      </MDBContainer>
  );
}

export default Login;