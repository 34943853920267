
import { getLocalstorage } from "Assistances/GlobalFunctions";
import { id_account_creater } from "HelperFunction/token";
import axios from "axios"

export function getAllFeatures(data){

    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAllFeatures?id_account_creater='+id_account_creater,
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}


export function addFeatures(data){
    return axios({
    method: 'post',
    url: process.env.REACT_APP_APP_URL_BACKEND+'/addFeatures',
    data: data, 
    headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
})
}

export function ModifyFeatures(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/ModifyFeatures',
        data: data, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}


export function getFeatureData(feature_id){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getFeatureData?id='+feature_id,
        data: {}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}