import { getLocalstorage } from 'Assistances/token';
import axios from 'axios';

export function getAllProfiles(data){

    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAllProfiles',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}


export function ModifyProfiles(data){

    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/modifyProfile',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}
export function AddProfiles(data){
    
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/adduseraccount',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}




export function getProfileData(profile_id){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getProfileData?id='+profile_id,
        data: {}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}
