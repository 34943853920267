import { getLocalstorage } from "Assistances/GlobalFunctions";
import axios from "axios";

export function getStoreInfoByUrl(url){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getStoreInfoByUrl?url_store='+url,
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}


