import React from 'react'
import "./pagenotfound.css"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export default function PageNotFound() {
    const history=useNavigate()
	const {t}=useTranslation()
    return (
    <div className='notfound'>
        <div id="notfound">
		<div className="notfound">
			<div className="notfound-404">
				<h1>{t('Oops!')}</h1>
			</div>
			<h2>{t('404 - Page not found')}</h2>
			<p>{t('The page you are looking for might have been removed had its name changed or is temporarily unavailable.')}</p>
			<a href="#" onClick={()=>{history('/')}} >{t('Go To Homepage')}</a>
		</div>
	</div>

</div>
      )
}
