import { Col, Row} from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import "../../ProductBuy.css"
import ProductImageBuy from './ProductImageBuy';
import FeaturesProduct from './FeaturesProduct';
import ToppingsProduct from './ToppingsProduct';
import GrediensProduct from './GrediensProduct';
import { getProductDataForBuyModalAction } from './Actions';
import { useTranslation } from "react-i18next";
import { getLocalstorage, setLocalstorage } from 'Assistances/token';
import DescripeProduct from './DescripeProduct';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../../Redux/ReduxSlices/langs/LangsSlicer';
import Spinner from 'Spinner/Spinner';

export default function ProductBuy(props) {
    const { t } = useTranslation();
    
    
    const [ProductToppings,setProductToppings]=useState([])
    const [grediens,setgrediens]=useState()
    const [catalogueImage,setcatalogueImage]=useState([])
    const [productfeatures,setProductFeatures]=useState([])
    const [orderByElement,setorderByElement]=useState("")

    const [SelectedFeaturesOrder,setSelectedFeaturesOrder]=useState(0)
 
    const [annuler,setannuler]=useState([]);
    const [TotalPrice,setTotalPrice]=useState(props.item["promence_price"]?props.item["promence_price"]:0)
    const [TotalPriceToppings,setTotalPriceToppings]=useState(0)
    const [loading,setLoading]=useState(true)
    useEffect(()=>{
        // if(typeof props.item["product_id"]!== 'undefined'){
            getProductDataForBuyModalAction(props.item["product_id"])
            .then(function (response) {

                    setProductToppings(response["data"]['toppings'])

                    setgrediens(response["data"]['grediens'])
                    // setProductFeatures(response["data"]["features"])
                    setcatalogueImage(response["data"]['catalogues'])
                    //console.log(response["data"]["features"])
                    annalyzeFeaturesOfProduct(response["data"]["features"])
                    // setLoading(false)
                    let found = false;
                    console.log("item1.product_featuers_id")
                    console.log(response["data"]["features"])
                    
                    response["data"]["features"] && response["data"]["features"].map((item,index) => {
                            if(item.group_by == item.id_feature && !found){
                                setSelectedFeaturesOrder(item.product_featuers_id);
                                found = true;
                                console.log(item.product_featuers_id)
                            }

                    })

                     

            })
            .catch(function (error) {
                //console.log(error.message);
            })
            .finally(()=>{
                setLoading(false)
            })
            ;
        
        // }





    },[props.item])


     function annalyzeFeaturesOfProduct(productfeaturesArg){
        if(productfeaturesArg.length>0){
            let ListOfArrayOfObjectFeaturesInSeemRow=[]
            let group_by=''
            for(let i=0;i<productfeaturesArg.length;i++){
                let lenOfRow=ListOfArrayOfObjectFeaturesInSeemRow[productfeaturesArg[i]['order_display']]?ListOfArrayOfObjectFeaturesInSeemRow[productfeaturesArg[i]['order_display']].length:0;
                if(productfeaturesArg[i]['group_by']==productfeaturesArg[i]['id'])
                    group_by=productfeaturesArg[i]['title']
                if(lenOfRow==0){
                    ListOfArrayOfObjectFeaturesInSeemRow[productfeaturesArg[i]['order_display']]=[]
                }
                ListOfArrayOfObjectFeaturesInSeemRow[productfeaturesArg[i]['order_display']][lenOfRow?lenOfRow:0]=productfeaturesArg[i]
            }
            setProductFeatures(ListOfArrayOfObjectFeaturesInSeemRow)
            
            setShowListFeatures(ListOfArrayOfObjectFeaturesInSeemRow[0])
            setorderByElement(group_by)
            let priceList=ListOfArrayOfObjectFeaturesInSeemRow[0].filter(e=>getTextByLanguage(e.title,'en')=='Price' || getTextByLanguage(e.title,'fr')==='Prix' || getTextByLanguage(e.title,'ar')==='الثمن')
            let priceProduct=priceList && priceList[0]?!isNaN(priceList[0]['value'])?priceList[0]['value']:TotalPrice:TotalPrice

            setTotalPrice(priceProduct)
        }
       

     }


  function removeannuler(item){
    var index = annuler.indexOf(item);
    var myarray=annuler;
    if (index !== -1) {
        myarray.splice(index, 1);
    }
    setannuler([...myarray])
  }


  function addannuler(item){
      setannuler([...annuler,item])
  }







  
  const [Tabletoppings,setTabletoppings]=useState([])
  const [ShowListFeatures,setShowListFeatures]=useState([])
  const [deplaceBlock,setdeplaceBlock]=useState(0)
  function checked(item,index){
    var elements = document.querySelectorAll(".white-block");
        elements.forEach(function(element) {
            element.style.setProperty("--deplace", index);
        });
       

        setShowListFeatures(productfeatures[index])
        let priceProduct=props.item["reel_price"]?props.item["reel_price"]:0
        let priceList=productfeatures[index].filter(e=>getTextByLanguage(e.title,'en')=='Price' || getTextByLanguage(e.title,'fr')=='Prix' || getTextByLanguage(e.title,'ar')=='الثمن')
        //console.log(productfeatures[index])
        priceProduct=priceList && priceList[0]?!isNaN(priceList[0]['value'])?priceList[0]['value']:priceProduct:priceProduct

        setTotalPrice(priceProduct)
   

  }


  const [type,setType]=useState("Original")




  function addtoppings(item,price,id){
    var element=document.getElementById(id)
    if(element.classList.contains("selected-toppings")){
        element.classList.remove("selected-toppings");
        setTabletoppings([...Tabletoppings.filter(e=>e.id==id)]);
        setTotalPriceToppings(prev=>prev-price)
    }
    else{
        element.classList.add("selected-toppings");
        setTabletoppings([...Tabletoppings,item])
        setTotalPriceToppings(prev=>prev+price)


    }


  }
  function generateIDFromTimeWithRandom() {
    var currentTime = new Date();
    var timestamp = currentTime.getTime().toString();
    var shortID = timestamp.substring(6, 13); // Extract the 7 digits from the timestamp

    var id = shortID;
    return id;
  }
  
  // Example usage
  
  
  function addToOrder(){
    // alert((props.customizing && props.customizing[0]?props.customizing[0]['id']:''))
    let fromStorageOrderList = getLocalstorage('order_'+props.ficheInfo.id);
    let listOfOrder = fromStorageOrderList ? JSON.parse(fromStorageOrderList) : [];
    var generatedID = generateIDFromTimeWithRandom();
    listOfOrder.push({'artical_order_id':generatedID,'how_many':1,'product':props.item,'grediens_rejected':annuler,"toppings":Tabletoppings,"status":"Added","product_featuers_id":SelectedFeaturesOrder?SelectedFeaturesOrder:null,"Total_Price":parseInt(TotalPrice, 10)  + parseInt(TotalPriceToppings, 10)})
    setLocalstorage('order_'+props.ficheInfo.id,JSON.stringify(listOfOrder).toString())
        
    props.orderRefreshForbyProducts(generatedID)      
    
    
    props.IncreaseOrder()
    props.onHide()
  }
  const langs = useSelector(selectLang);

    return (
     <div className='product-buy-modal'>
        {
           loading ?
            <>
                <Spinner/>
                <br/>
            
            </>

            :
            <>
       
        <div className=' '>
            <div className='row'>
                <div  className="col-md-6 col-sm-12 col-lg-7 modal-scroll modal-product-image-container    "  >
                   <ProductImageBuy description={props.item["Description"]} catalogueImage={catalogueImage} productimage={props.item["image"]}/>
                </div>
                <div className='col-md-6 col-sm-12 col-lg-5'>
                    <div className='row'>
                        <div className='col-12'>
                            <div classNamee="productmodal-title-and-soutitle">
                            <div className='productmodal-title Overflow-x-auto'>
                            {/* {props.item["title"]} */}
                            <p className=''>
                            {getTextByLanguage(props.item["title"],langs)}
                            </p>
                        </div>
                        <br/>
                        {orderByElement?
                        <div className="productmodal-soutitle-ption">
                            {getTextByLanguage(orderByElement,langs)}"
                        </div>
                        :
                        <></>
                        }
                    </div>
                    </div>
                    <div>
                        <DescripeProduct description={props.item["description"]} />

                    </div>
                </div>
            </div>
            </div>
          
           
            <div  className=" row " >
               
            <div className='col-lg-6 col-md-6 col-sm-12'>
            {orderByElement?
                        <div className="productmodal-soutitle-ption">
                            {getTextByLanguage(orderByElement,langs)}"
                        </div>
                        :
                        <></>
                        }      
                {productfeatures && productfeatures.length>1?
                        <>
                            <div className="pizzamodal-check-size-container" >

                                <div  style={{ width: 100/productfeatures.length+"%"}} offset="1" id ="white-block"      className="white-block" >
                                </div>
                                
                            { productfeatures && productfeatures.map((item,index) => {

                               return (
                               <>
                               { item.length>0 && item.map((item1,index1)=>{
                                    if(item1.group_by == item1.id_feature){
                                       
                                        return(
                                            <>
                                                <label   className="bolder-label-cheked label-text" id={"check-"+index+"-"+item1['value']} key={index1} onClick={()=>{checked(item1,index);setSelectedFeaturesOrder(item1.product_featuers_id)}}>
                                                    
                                                {getTextByLanguage(item1.value,langs)}
                                                 </label>
                                        
                                              </>
                                            )
                                    }
                                })}

                                </>
                                )

                            })}
                                </div>
                            </>
                            :
                            <>
                            </>
                        }
                       
                        
                {
                ShowListFeatures &&  ShowListFeatures.length>0?
                  <FeaturesProduct productfeatures={ShowListFeatures}/>
                  :
                  <></>
                }
                 
               
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='pizza-overflow-container'>
                    <div>
                        {grediens && grediens.length>0?
                            <GrediensProduct grediens={grediens} annuler={annuler} removeannuler={removeannuler} addannuler={addannuler}/>
                        :
                        <></>
                        }
                    </div>
                   
                        <div><div className="scroll__scrollbar" ></div></div>
                    </div>
                    {
                        ProductToppings.length>0?
                        <ToppingsProduct ProductToppings={ProductToppings} currency={props.currency} addtoppings={addtoppings}/>
                        :
                        <></>
                       }
                </div>
            </div>
            <div className='button-center'>
                        <button type="button" data-type="primary" data-size="big" className="princebtn" onClick={()=>addToOrder()}>
                            {t("Add to basket")}
                            {
                                parseInt(TotalPrice, 10)  + parseInt(TotalPriceToppings, 10) >0?
                                <>
                                 <span className="money "><span className="money__currency money__currency_on-the-left">{props.currency?props.currency:"$"}</span>
                                 <span className="money__value"> {parseInt(TotalPrice, 10)  + parseInt(TotalPriceToppings, 10) }</span></span>
                                </>
                                :<></>
                            }
                            
                        </button>
                    </div>
        </div>
     

        </>
      


        }
        
  </div>
  )
}













