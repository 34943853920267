import { GenerateColumns } from "HelperFunction/Functions";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import './DataTable.css'
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa";
import { BiSolidPencil } from "react-icons/bi";
import { getTextByLanguage } from "Assistances/GlobalFunctions";
import { useSelector } from "react-redux";
import { selectLang } from "./../../Redux/ReduxSlices/langs/LangsSlicer";


const FilterComponent = ({ title, filterText, onFilter, onClear,t }) => (
	<>
	

		<input
			id="search"
			type="text" maxLength={255}
			placeholder={t}
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
      		className="form"
		/>
		<btton className="btn btn-primary" type="button" onClick={onClear}>
			X
		</btton>
	</>
);

export default function Datatable(props) {
  const columns = GenerateColumns(props.data,props.columns);
  const { t } = useTranslation();
  const values = props.data;
  const langs = useSelector(selectLang);

//console.log(props.data)
  const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const filteredItems = values.filter(item => {
    // Convert each property value to lowercase and check if it includes the filterText
    return Object.values(item).some(value => {
        if (typeof value === 'string') {
            return value.toLowerCase().includes(filterText.toLowerCase());
        }
        return false; // Only perform the check for string values
    });
	}).map(item => {
		// Apply the getTextByLanguage function to each item
		const newItem = {};
		for (const key in item) {
		  if (Object.hasOwnProperty.call(item, key)) {
			newItem[key] = getTextByLanguage(item[key], langs);
		  }
		}
		return newItem;
	  });

;

	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent title={t(props.title)} onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} t={t("Filter")}/>
		);
	}, [filterText, resetPaginationToggle]);

  return (
    <div className="App">
		<div className="flex items-center gap-2 p-4  border-custom-border-200 bg-custom-sidebar-background-100">
			<div className="flex w-full flex-grow items-center gap-2 overflow-ellipsis whitespace-nowrap">
				{t(props.title)} ({props.total})

			</div>
			<div className="items-center gap-2 flex">
			{props.addbutton?
			<button onClick={()=>props.ShowModal()} type="button" className="text-white bg-custom-primary-100 hover:bg-custom-primary-200 focus:text-custom-brand-40 focus:bg-custom-primary-200 px-3 py-1.5 font-medium text-xs rounded flex items-center gap-1.5 whitespace-nowrap transition-all justify-center">
  				<div className="h-3 w-3 flex justify-center items-center overflow-hidden my-0.5 flex-shrink-0">
  				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg>
  				</div>
  				<div className="hidden sm:block">{t('Add')}</div> {t(props.title)} 
				</button>
				:
				<></>
			}
			</div>
		</div>
      <DataTable

        
        columns={props.actions?[...columns,props.actions]:columns }
        data={filteredItems}
        defaultSortFieldId
        pagination
        // title={t(props.title)}
        onRowClicked={props.handleRowClicked}
        highlightOnHover
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
		subHeader
		subHeaderComponent={subHeaderComponentMemo}
		persistTableHead
      />
    </div>
  );
}
