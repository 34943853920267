import { getLocalstorage } from "Assistances/GlobalFunctions";
import axios from "axios";



export function editStorecategories(store_id,cat_id){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/editStorecategories',
        data: {'cat_id':cat_id,'store_id':store_id}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}


export function editStoreProducts(store_id,product_id){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/editStoreProducts',
        data: {'product_id':product_id,'store_id':store_id}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}


export function editStoreCategorieProducts(store_id,st_cat_id,st_product_id){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/editStoreCategorieProducts',
        data: {'st_product_id':st_product_id,'st_cat_id':st_cat_id,'store_id':store_id}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}


export function editStoreHeadAnnonces(store_id,headannonce_id){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/editStoreHeadAnnonces',
        data: {'headannonce_id':headannonce_id,'store_id':store_id}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}



export function editStoreTeams(store_id,teams_id){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/editStoreTeams',
        data: {'teams_id':teams_id,'store_id':store_id}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}

export function getStoresVisiters(store_id){
    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/GetStoreVisiters?store_id='+store_id,
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}




export function editSortingAction(type,id,value){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/editSorting',
        data: {'type':type,'id':id,"value":value}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}

