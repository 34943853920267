import React, { useEffect, useState } from 'react';

import Avis from './Avis';
import {addAvisOnStoreAction, addAvisOnproductAction,getAvisANDReaction,getAvisProductAndReactionAction, getAvisStoreAndReactionAction} from "./Actions"
import { useTranslation } from "react-i18next";
import SpinnerBar from 'Spinner/SpinnerBar';
import WriteMessage from 'Componnents/Write/WriteMessage';
import { id_account_creater } from 'HelperFunction/token';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { isTokenExpired } from 'Assistances/GlobalFunctions';
import Spinner from 'Spinner/Spinner';
export default function AvisGroup(props) {
    const { t } = useTranslation();
    const [pages,setPages]=useState(0)
    const [MyreactionsAvis,setMyreactionsAvis]=useState([])

    const [reactionsAvis,setReactionsAvis]=useState([])

    
    const [showMore,setShowMore]=useState(true) 
    const [skip,setSkip]=useState(0)
    const [spinner,setSpinner]=useState(true)
    const [avisList,setavisList]=useState([])
     useEffect(()=>{
        getAvis()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function getAvis(){
        // let showAvis =avisList.slice(pages*10,(pages+1)*10)
        // setPages(pages+1)
        // if(showAvis.length<10){
        //     setShowMore(false)
        // } 

        setShowMore(false)

        getAvisANDReaction(props.store_id,props.product_id,avisList.length)
        .then((res)=>{
            console.log(res.data)
            setavisList([...avisList,...res.data.avis])
            console.log(res.data.my_reactions)
            setMyreactionsAvis([...MyreactionsAvis,...res.data.my_reactions])
            setReactionsAvis([...reactionsAvis,...res.data.reactions])

            if(res.data.avis.length>=10){
                setShowMore(true)
            }
        })
        .catch((err)=>{
            console.log(err)
        })
        .finally(()=>{
            setSpinner(false)
        })
    }
              
    function modifyInReactionInList(id_avis,value){
        let existReactions=false
        let existMyreactionsAvis=false
        let Listemyreaction=MyreactionsAvis.filter(el=>el.id_avis_product==id_avis)
        let myreaction=Listemyreaction.length>0?Listemyreaction[0]['value']:null;
            reactionsAvis.map((e,i) =>{
              if(e.id_avis_product==id_avis ){
                
                  if(value==e.value){
                    existReactions=true
  
                    if(myreaction!=value){
                      reactionsAvis[i]['number']=reactionsAvis[i]['number']+1
                    }
                  }
                  if(myreaction==e.value){
                    reactionsAvis[i]['number']=reactionsAvis[i]['number']-1
                  }
                  if(value==null){
                    reactionsAvis[i]['number']=reactionsAvis[i]['number']-1 
                    return 0;
                  }
                 
              }
            
            });
  
            if(!existReactions && value!=null ){
              reactionsAvis[reactionsAvis.length]={'value':value,'id_avis_product':id_avis,'number':1}
            }
            MyreactionsAvis.map((e,i)=>{
              if(e.id_avis_product==id_avis){
                existMyreactionsAvis=true;
                if(value==null){
                  MyreactionsAvis[i]['value']=null;
  
                }
                else
                  MyreactionsAvis[i]['value']=value;
              }
            })      
            if(!existMyreactionsAvis){
              MyreactionsAvis[MyreactionsAvis.length]={'value':value,'id_avis_product':id_avis}
  
            }
            setReactionsAvis([...reactionsAvis.filter(e=>e.number!=0)])
            setMyreactionsAvis([...MyreactionsAvis])
     }
    async function addInputToconversation(avis){
        if(!isTokenExpired()){
            if(avis.length>0){
                setSkip(skip+1)
                var message={}
                if(props.service){
                 
                   await addAvisOnStoreAction(props.service.id,avis)
                   .then(function (response) {
                      
                       message={
                        "name":response['data']['name'],
                        "updated_at": response['data']['date'],
                        "product_id":props.product_id,
                        "store_id":props.store_id,
                        "comment":avis,
                        "image":response['data']['image'],
                        'rowid_avis_product':-1,
                        'rowid_avis_store':response['data']['rowid_avis_store'],
                       }
                   })
                   .catch(function (error) {
                       //console.log(error.message);
                        
                   });
                }
                if(props.product_id){
                   await addAvisOnproductAction(props.product_id?props.product_id:-1,avis,props.store_id)
                   .then(function (response) {
                       message={
                            "name":response['data']['name'],
                            "updated_at": response['data']['date'],
                            "product_id":props.product_id,
                            "store_id":props.store_id,
                            "comment":avis,
                            "frequent":[null,null,null],
                            "image":response['data']['image'],
                            'rowid_avis_product':response['data']['rowid_avis_product'],
                            'rowid_avis_store':-1
                       }
                   })
                   .catch(function (error) {
                       //console.log(error.message);
                   });
                }
                setavisList([message,...avisList])
                props.insertNewAvis(message);
                // setreactionsAvis(prevAvis => [message, ...prevAvis]);
            }
     
          }
          else{
            props.showModuleLogin()
      
          }
      
         }



  return (
    <div> 
       
    {spinner?
        <>
        <Spinner/>
        </> 

        :
        <>
        {
            avisList && avisList.map((item,i)=>{
                return(
                    <div key={props.service?item.rowid_avis_store:item.rowid_avis_product}>
                        
                        <Avis 
                        key={i}
                        avisItem={item}
                        product_id={props.product_id} 
                        MyreactionsAvis={MyreactionsAvis.filter(e=>e.id_avis_product==item.rowid_avis_product)}
                        reactionsAvis={reactionsAvis.filter(e=>e.id_avis_product==item.rowid_avis_product)}
                        modifyInReactionInList={modifyInReactionInList}
                        store_id={props.store_id}
                        showModuleLogin={props.showModuleLogin}
                        />

                        
                    </div>
                    )

            })
        }
       
        <div className='btnshowMore-center-container'>
        {showMore?
        <button className='btnshowMore' onClick={()=>getAvis()}>
          {t("Show more")} 
        </button>
        :
        <></>
        }
      </div>
        
        
        </> 
        }
       <br/>
            <br/>
            {/* {
                tokenDecodedData && tokenDecodedData.id? */}
                <div className='writeavis-container'>
                <WriteMessage showModuleLogin={props.showModuleLogin} addInputToconversation={addInputToconversation}/>
            </div>
                {/* :
                <>
                </>
            } */}
           
    </div>
  )
}
