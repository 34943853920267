import React, { useEffect, useState } from 'react'
import Spinner from 'Spinner/Spinner';
import { getStoresVisiters } from './Actions';
import { handleError } from 'Assistances/ErrorHandling';
import { useTranslation } from 'react-i18next';
import Datatable from 'Componnents/DataTables/DataTables';

export default function Visiters(props) {
    const [storesVisiters,setStoresVisiters]=useState([]);
    const { t } = useTranslation();
    const columns=['client_name','login','store_title','visited_at'];
    const [spinner,setSpinner]=useState(true)
    useEffect(()=>{
      GetStoreVisiters(props.store_id)
    },[])
    function GetStoreVisiters(store_id){
        getStoresVisiters(store_id)
      .then(function (response) {
          setStoresVisiters(response.data.map(item => ({
            ...item,
            client_name: item.client_name === null ? t('Annonyms') : item.client_name,
            login: item.login === null ? t('Annonyms') : item.login

        })))
         console.log(response.data)
      }) 
      .catch(function (error) {
        handleError(error,t,t('Stores'));
      })
      .finally(() => {
        setSpinner(false)
      })
      ;
    }
 
  return (
    <div>
      {spinner?
      <Spinner/> 
      :

     <Datatable 
      data={storesVisiters} 
      columns={columns}
      handleRowClicked={
        ()=>{}
      }
      title="Visiters"
      total={storesVisiters.length}

      addbutton={false}
      ShowModal={() => {}}
      />
      }
     
    </div>
      
  )
}
