import React, { useEffect, useState } from 'react';
// import './sass/avis.scss';
import image_blank from "Assets/Images/no-profile.png"
import { useTranslation } from "react-i18next";
import Reaction from 'Reactions/Reaction';
import "./Avis.css"

export default function Avis (props) {
    const { t } = useTranslation();
    var chaine=props.avisItem.comment?props.avisItem.comment:'';
    var small_chaine=chaine && chaine.length>200?chaine.substr(0, 200):chaine;
    const [affiche,setAffiche]=useState(false);


     return (
    <div>
        
        <div className='row'>
        <div className='col-2 image-col' >
            <div className="avis-image-circule-decoration">
                <img className='avis-image-circule' alt="profile image"
                 src={props.image || props.avisItem.image?process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/users/"+props.avisItem.image:image_blank} />
                <div className='avis-circul-decuratoin1' ></div>
                <div className='avis-circul-decuratoin2' ></div>
            </div>

        </div>
        <div className='col-9'>
           <div className="avis-content">
            <div  className="avis-name-date">
                <span className="avis-name">
                    {props.avisItem.name}
                 </span>
                 <br/>
                 <span className="avis-date">
                    {props.avisItem.updated_at}
                </span>
            </div>
            
            <div className="avis-text">
                    {!affiche?
                        <p style={{style:"white-space: pre-line"}}  onClick={()=>{setAffiche(true)}}>
                            {small_chaine}
                           {chaine && chaine.length>200?
                                <span className='showless-more'>  ...{t("Show more")}</span>
                            :
                                <></>
                            } 
                        </p>
                    :
                        <p style={{style:"white-space: pre-line"}}  onClick={()=>{setAffiche(false)}}>
                            {chaine} 
                            {chaine.length>200?
                            <span className='showless-more'>  ...{t("Show less")}</span>
                            :
                            <></>
                            }
                        </p>
                    }
            </div>

            <div className="avis-reactions">
                    
                 <Reaction size="11" 
                    key={props.key}
                    rowid_avis_product={props.avisItem.rowid_avis_product} 
                    product_id={props.product_id} 
                    reactionsAvis={props.reactionsAvis}
                    MyreactionsAvis={props.MyreactionsAvis}
                    modifyInReactionInList={props.modifyInReactionInList}
                    store_id={props.store_id}
                    showModuleLogin={props.showModuleLogin}
                 />
                
                    {/* :
                    <></>

                 } */}
                
           </div>
           </div>


        </div>
    </div>
        
       

    </div>
  )
}
