import React, { useEffect, useState } from 'react';
import profile from './../Assets/Images/no-profile.png';
import { useTranslation } from 'react-i18next';
import { tokenDecodedData } from 'Assistances/token';
import { toast } from 'react-toastify';
import { updateProfileImage } from './Actions';
import { getProfileData } from 'Accounts/Profiles/Actions';

export default function AccountImage(props) {
    const { t } = useTranslation();
    const [profileImage, setProfileImage] = useState(null);
    useEffect(()=>{
        // setProfileImage(props.image)
    },[props.image])
    function updateAccountImage() {
        if (profileImage) {
            let formData=new FormData()
            formData.append("image",profileImage)
            updateProfileImage(formData)
            .then((res) => {
                toast.success(t('Successfully'));
                props.ChangeAccountInfo(formData)
            })
            .catch(err => {
                toast.error(t('Error'));
            });
        }
    }

    // function fetchProfileData() {
    //     // Assuming getProfileData is an imported function, not recursive
    //     getProfileData(tokenDecodedData().id)
    //     .then(function (response) {
    //         setProfileImage(response['data']['info']['image']);
    //         //console.log(response['data']['info']);
    //     })
    //     .catch(function (error) {
    //         //console.log(error);
    //         toast.error(t('Error'));
    //     });
    // }

    // useEffect(() => {
    //     fetchProfileData(); // Call the correct function
    // }, []);

    return (
        <div>
            <div className="">
                <div pageName="Settings" />
                <div className="grid grid-cols-5 gap-10">
                    <div className="col-span-5 xl:col-span-3">
                        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                            <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                                <h3 className="font-medium text-black dark:text-white">
                                    {t('Profile Image')}
                                </h3>
                            </div>
                            <div className="p-7">
                                <div>
                                    <div className='flex'>
                                        <div className='container-profile-img'>
                                            <img 
                                            
                                            src={ 
                                                profileImage   && profileImage!=null?
                                            
                                                URL.createObjectURL(profileImage)                                    

                                            :
                                            // process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/users/"+profileImage
                                            process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/users/"+props.image


                                        
                                        } 
                                                    onError={(e) => {e.target.src =profile}}
                                            
                                     className='profile-img' />
                                        </div>
                                        <div className="m-3 pt-10 justify-center text-center"> 
                                            <label htmlFor="uploadFile1" className="bg-primary hover:bg-primary text-white text-sm px-4 py-2.5 outline-none rounded w-max cursor-pointer block font-[sans-serif]">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 mr-2 fill-white inline" viewBox="0 0 32 32">
                                                    <path d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z" data-original="#000000" />
                                                    <path d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z" data-original="#000000" />
                                                </svg>
                                                <br/>
                                                {t('Upload Avatar')}
                                                <input type="file" id='uploadFile1' className="hidden" onChange={(e) => setProfileImage(e.target.files[0])} />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex justify-end gap-4 m-3">
                                        <button onClick={() => updateAccountImage()} className="flex white justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90">
                                            {t('Save')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
