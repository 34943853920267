

import React, { useEffect, useState } from 'react'
// import Pusher from 'pusher-js';
// import {  getLocalstorage, id_account_creater } from '../../../HelperFunction/token';
import {  toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate  } from 'react-router-dom';
import { BiChevronsLeft, BiChevronsRight } from 'react-icons/bi';
// import ServerTableProducts from './ServerTableProducts';
// import ModalHelperProduct from '../../HelperModals/ModalHelperProduct';
import { useTranslation } from "react-i18next";
import Datatable from 'Componnents/DataTables/DataTables';
import { getOrders } from './Actions';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import FicheOrders from './FicheOrders';
import Spinner from 'Spinner/Spinner';
import { useSelector } from 'react-redux';
import { selectLang } from './../Redux/ReduxSlices/langs/LangsSlicer';
import { handleError } from 'Assistances/ErrorHandling';


export default function DataTablesOrders(props) {
   const [Orders,setOrders]=useState([]);
   const { t } = useTranslation();
   const columns=['name','message','title',"created_at","updated_at"];
   const [showModalFormStore,setShowModalFormStore]=useState(false);
   const [ModalDetailOrdersShow,setModalDetailOrdersShow]=useState(false);
   const [orderItem,setorderItem]=useState({})
   const history=useNavigate();
   const [spinner,setSpinner]=useState(true)
   const langs = useSelector(selectLang);

   useEffect(()=>{
    getOrdersList()
  },[])
   function getOrdersList(){
    getOrders()
    .then(function (response) {
        setOrders(response.data)
    }) 
    .catch(function (error) {
      handleError(error,t,t('Orders'));
    })
    .finally(() => {
     setSpinner(false)
   });
   }
   function handleRowClicked(row){
     setorderItem(row)
    //  setLocalstorage('store_id',row.id)
    //  history("/dashboard/Orders/fiche")
    setModalDetailOrdersShow(true)
   }
  return (
    <div id="app-dashboard" className='listing-table-container' >
        
    {spinner?
      <Spinner/> 
      :<Datatable 
      data={Orders} 
      columns={columns}
      handleRowClicked={handleRowClicked}
      title="Orders"
      total={Orders.length}
      addbutton={false}
      ShowModal={() => {}}

      />

        }
        <ModalHelper  
        show={ModalDetailOrdersShow} 
        title={t("Order")+" "+ t("Details")}
        size='lg'
        onHide={() => setModalDetailOrdersShow(false)} 
        content={<FicheOrders onHide={() => setModalDetailOrdersShow(false)} order={orderItem}/>} />
        
      
      
    

    {/* <ModalHelperProduct
    size="xl"
    title={t("Products")}
    show={showModal}
    onHide={()=>setSowModal(false)}
    content={<ServerTableProducts item={orderSelected}  extern={false} getOrders={props.getOrders} pages={pages} Filter={Filter}/>}
    /> */}
    </div>
  )
}
