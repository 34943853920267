
import { getLocalstorage } from "Assistances/GlobalFunctions";
import { id_account_creater } from "HelperFunction/token";
import axios from "axios";



export function addToppingsProduct(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/addToppingsProduct',
        data: data, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}
export function removeToppingsProduct(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/removeToppingsProduct',
        data: data, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}
export function modifyFeaturesProduct(data){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/modifyFeaturesProduct',
        data: data, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}




export function addProductgrediens(id_product,gredien_id,status){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/addProductgrediens',
        data: {'id_product':id_product,'gredien_id':gredien_id,'status':status,'id_account_creater':id_account_creater}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}



export function retireProductgrediens(id_product,gredien_id){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/retireProductgrediens',
        data: {'id_product':id_product,'gredien_id':gredien_id,'id_account_creater':id_account_creater}, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}
    })
}
