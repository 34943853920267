import React from 'react'
import BoardTable from './BoardTable'

export default function Statistiques() {
  return (
    <div>
      <BoardTable/>
    </div>
  )
}
