import React, { useEffect, useState } from 'react'

import MenuLeftOptions from 'Componnents/Fiche/MenuLeftOptions'
import SpaceOfDisplay from 'Componnents/Fiche/SpaceOfDisplay'
import TopHead from 'Componnents/Fiche/TopHead'
import { useTranslation } from 'react-i18next'
import { getLocalstorage } from 'Assistances/GlobalFunctions'
import { getStoreData } from './Actions'
import {  toast } from 'react-toastify';    
import ModalHelper from 'Componnents/Modals/ModalHelper'
import EditInfoStore from './StoresComponnents/EditInfoStore'
import FormStore from './FormStore'
import EditCategoriesStore from './StoresComponnents/EditCategoriesStore'
import EditProductsStore from './StoresComponnents/EditProductsStore'
import EditCategoriesProductsStore from './StoresComponnents/EditCategoriesProductsStore'
import EditStoreCatalogueImage from './CatalogueImage/EditStoreCatalogueImage'
import EditHeadAnnoncesStore from './StoresComponnents/EditHeadAnnonceStore'

import SocialForm from './Socials/SocialForm'
import EditTeamsStore from './StoresComponnents/EditTeamsStore'
import Spinner from 'Spinner/Spinner'
import { handleError } from 'Assistances/ErrorHandling'
import Visiters from './StoresComponnents/Visiters'
import CodeQR from 'Assistances/CodeQR'
import SettingsStore from './StoresComponnents/SettingsStore'

export default function FicheStore() {
    const [ficheInfo,setficheInfo]=useState({})
   
    const [ModalShow,setModalShow]=useState(false)
    const [categories,setCategories]=useState([])
    const [store_categories,setStoreCategories]=useState([])
    const [store_products,setStoreProducts]=useState([])
    const [products,setProducts]=useState([])
    const [store_categorie_products,setStore_categorie_products]=useState([])
    const [catalogue,setcatalogue]=useState([])
    const [headAnnonces,setheadAnnonces]=useState([])
    const [headannonces_store,setheadannoncesStore]=useState([])
    const [socialAll,setsocialAll]=useState([])
    const [social,setsocial]=useState([])
    const [Teams,setTeams]=useState([])
    const [store_Teams,setstore_Teams]=useState([])
    const [SocialObjectIDVALUE,setSocialObjectIDVALUE]=useState({})

    
    
    
    const [store_id,setstore_id]=useState(getLocalstorage("store_id"))
    
    const { t } = useTranslation();
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
        GetStoreDataFunction()
    },[])

    function GetStoreDataFunction(){
        getStoreData(store_id)
        .then(function (response) {
            setficheInfo({...response.data['info']})
            setCategories([...response.data['categories']])
            setStoreCategories([...response.data['store_categories']])
            setProducts([...response.data['products']])
            setStoreProducts([...response.data['store_products']])
            setStore_categorie_products([...response.data['store_categorie_products']])
            setcatalogue([...response.data['catalogue']])
            setheadAnnonces([...response.data['headannonces']])
            setheadannoncesStore([...response.data['headannonces_store']])
            setsocialAll([...response.data['socialAll']])
            setsocial([...response.data['social']])
            setTeams([...response.data['teams']])
            setstore_Teams([...response.data['store_teams']])

            console.log(response.data['teams'])
            response.data['social'].map(item=>{
                SocialObjectIDVALUE[item.id]=item.value
            })
            setSocialObjectIDVALUE({...SocialObjectIDVALUE})
          
            var defaultItem=menuContent.length>0?menuContent[0]:{};
            defaultItem.componnent=<EditInfoStore ficheInfo={response.data['info']}/>;
            getClieckedItem(defaultItem)
            
        }) 
        .catch(function (error) {
            //console.log(error)
            handleError(error,t,t('Store'));
        })
        .finally(() => {
            setSpinner(false)
          });
    }
    function changeStoreCategories(data,type,cat_id){
        setStoreCategories([...data])
        if(type==0)
            setStore_categorie_products([...store_categorie_products.filter(item=>item.cat_id!==cat_id)])
    }
    function changeStoreProducts(data,type,product_id){
        setStoreProducts([...data])
        if(type==0)
            setStore_categorie_products([...store_categorie_products.filter(item=>item.product_id!==product_id)])
       
    }
    function onchangeCategorieProducts(data){
        setStore_categorie_products([...data])
    }

    
    function changeStoreTeams(data,type,teams_id){
        setTeams([...data])
        if(type==0)
            setstore_Teams([...store_Teams.filter(item=>item.teams_id!==teams_id)])
       
    }
    const menuContent=[
        {
            'title':t('Information'),
            'componnent':<EditInfoStore store_id={store_id} ficheInfo={ficheInfo}/>,
            'icon':<i className='fa fa-info icon-left-menu'></i>,
            'path':""
        },
        {
            
            'title':t('Categories'),
            'componnent':<EditCategoriesStore store_id={store_id} changeStoreCategories={changeStoreCategories}  categories={categories} store_categories={store_categories}/>,
            'icon':<i className="fa-solid fa-tags"></i>,
            'path':""
        },
        
        {
            
            'title':t('Products'),
            'componnent':<EditProductsStore store_id={store_id}  changeStoreProducts={changeStoreProducts} products={products} store_products={store_products}/>,
            'icon':<i className='fa fa-box icon-left-menu'></i>,
            'path':""
        },
        {
            
            'title':t('Categoraisation'),
            'componnent':<EditCategoriesProductsStore store_id={store_id} onchangeCategorieProducts={onchangeCategorieProducts} store_categorie_products={store_categorie_products} products={products} store_products={store_products} categories={categories} store_categories={store_categories}/>,
            'icon':<i className='fa fa-list icon-left-menu'></i>,
            'path':""
        },
        {
            'title':t('Head Annonces'),
            'componnent':<EditHeadAnnoncesStore store_id={store_id} headAnnonces={headAnnonces} headannonces_store={headannonces_store} />,
            'icon':<i className='fa fa-image icon-left-menu'></i>,
            'path':""
        },
        {
            
            'title':t('Catalogue Images'),
            'componnent':<EditStoreCatalogueImage store_id={store_id} Catalogue={catalogue} />,
            'icon':<i className='fa fa-images icon-left-menu'></i>,
            'path':""
        },
        
        {
            'title':t('Social Média'),
            'componnent':
            <SocialForm
            // show={modalSocialShow}
            // onHide={() => setModalSocialShow(false)}
            content={{'Email':"sdqdqs","Call":"0644948492"}}
            type="SocialMedia"
            store_id={store_id}
            socialAll={socialAll}
            social={SocialObjectIDVALUE}
          />,
            'icon':<i className="fa-solid fa-hashtag"></i>,
            'path':""
        },
        {
            'title':t('Teams'),
            'componnent':
            <EditTeamsStore store_id={store_id} Teams={Teams} store_Teams={store_Teams} changeStoreTeams={changeStoreTeams}/>,
            'icon':<i className='fa fa-user icon-left-menu'></i>,
            'path':""
        } ,
        {
            'title':t('Visiters'),
            'componnent':
            <Visiters store_id={store_id} />,
            'icon':<i className="fa-solid fa-user-group"></i>,
            'path':""
        } ,

        {
            'title':t('QR Code'),
            'componnent':
            <CodeQR data={window.location.protocol+"//"+window.location.hostname+':'+window.location.port+'/store/'+ficheInfo?.url_store} />,
            'icon':<i class="fa fa-qrcode" aria-hidden="true"></i>            ,
            'path':""
        } ,
        {
            'title':t('Settings'),
            'componnent':
            <SettingsStore store_id={store_id} modals={ficheInfo.modals?ficheInfo.modals:"M000"}/>,
            'icon':<i className="fa fa-cog"></i>,
            'path':""
        } ,
    ];
    const [Clieckeditem,setClieckeditem]=useState({})

    function getClieckedItem(item){
        setClieckeditem(item)
    }
    
  return (
    <div className=''>
    {spinner?
      <Spinner/> 
      :
      <>
        <TopHead ficheinfo={ficheInfo} pathImage='/stores/' path="/dashboard/stores" id={ficheInfo.id} table='stores' edit={()=>setModalShow(true)} />
        <div className='row'>
            <div className='col-md-4 col-lg-3 mt-1'>
                <MenuLeftOptions getClieckedItem={getClieckedItem} title={t("Store")+" "+ t("Options")} menuContent={menuContent}/>
            </div>
            <div className='col-md-8 col-lg-9 mt-1' >
                <SpaceOfDisplay displayItem={Clieckeditem}/>
            </div>
        </div>
        </>
    }

        <ModalHelper  
        show={ModalShow} 
        title={t("Modify")+" "+ t("Store")}
        size='lg'
        onHide={() => setModalShow(false)} 
        content={<FormStore modifycontent={ficheInfo} onHide={() => setModalShow(false)} GetStoreDataFunction={GetStoreDataFunction}/>} />
        
      
    </div>
  )
}
