import { toast } from 'react-toastify';



export function handleError(error, t, action=null) {
  let message;

  if (error.response) {
    switch (error.response.status) {
      case 400:
        message = t("Bad Request. Please check your input.");
        break;
      case 401:
        message = t("Unauthorized. Please log in.");
        // if (error.response.data?.message && /token|expired/i.test(error.response.data.message)) {
        //   message = t("Your session has expired. Please log in again.");
        //   // We Could use  history.push('/login'); // or navigate('/login'); to redirect the user
        // } else {
        //   message = t("Unauthorized. Please log in.");
        // }
        break;
      case 402:
        message = t("Payment Required. Please update your payment method.");
        break;
      case 403:
        message = t("Forbidden. You don't have permission.");
        break;
      case 404:
        message = t("Not Found. The resource doesn't exist.");
        break;
      case 405:
        message = t("Method Not Allowed. The HTTP method is not allowed for this resource.");
        break;
      case 406:
        message = t("Not Acceptable. The requested resource is not available in the requested format.");
        break;
      case 408:
        message = t("Request Timeout. The server timed out waiting for the request.");
        break;
      case 409:
        message = t("Conflict. There was a conflict with the current state of the resource.");
        break;
      case 410:
        message = t("Gone. The resource is no longer available.");
        break;
      case 415:
        message = t("Unsupported Media Type. The request format is not supported by the server.");
        break;
      case 429:
        message = t("Too Many Requests. You have sent too many requests in a given amount of time.");
        break;
      case 500:
        message = t("Internal Server Error. Please try again later.");
        break;
      case 501:
        message = t("Not Implemented. The server does not support the functionality required to fulfill the request.");
        break;
      case 502:
        message = t("Bad Gateway. The server received an invalid response from the upstream server.");
        break;
      case 503:
        message = t("Service Unavailable. The server is currently unable to handle the request.");
        break;
      case 504:
        message = t("Gateway Timeout. The upstream server failed to send a request in the time allowed by the server.");
        break;
      case 505:
        message = t("HTTP Version Not Supported. The server does not support the HTTP protocol version used in the request.");
        break;
      default:
        message = t("An unexpected error occurred. Please try again.");
    }
  } else if (error.message) {
    message = t(error.message);
  } else {
    message = t("An unexpected error occurred. Please try again.");
  }

  const errorActionMessage = t("Error during action:");
  const transAction = t(action);
  if(action)
    toast.error(`${errorActionMessage} ${action}.`);
  toast.error(`${error.response?.status || ''} - ${message}`);

};