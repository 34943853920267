import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TestSendMailAction } from './Actions';

export default function TestSMTP() {
    const {t}=useTranslation()
    const [AttributesSMTP,setAttributesSMTP]=useState({})
    // function onchangeAttributes(key,value){
    //     AttributesSMTP[key]=value;
    //     setAttributesSMTP({...AttributesSMTP})
    // } 
   
    function onchangeAttributes(  key, value) {
        AttributesSMTP[key]=value
     
      
      setAttributesSMTP({...AttributesSMTP})
      // props.changeAttributesSMTP(AttributesSMTP)
    }
    function TestSendMail(){
        TestSendMailAction(AttributesSMTP)
        .then(()=>{
            toast.success(t("Successfully sent"))

        })
        .catch(()=>{
            toast.error(t("Send Error"))
        })
    }

  return (
    <div>
    <div className="m-5  ">
       <div pageName="Settings" />

       <div className="grid  gap-10">
         <div className="col-span-5 xl:col-span-3">
           <div className="">
             <div className="">
           
             </div>
             <div className="p-7">
               <div>
                
               <div className=" m-3">
                   <label
                     className="mb-3 block text-sm font-medium text-black dark:text-white"
                     htmlFor="username"
                   >
                     {t('Send To')}
                   </label>
                   <div className="relative">
                   
                     <input
                       className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                       type="text" maxLength={255}
                       name="send"
                       id="send"
                       placeholder={t('Send To')}
                       value={AttributesSMTP && AttributesSMTP?AttributesSMTP["toAddresses"]:""}
                       onChange={(e)=>onchangeAttributes('toAddresses',e.target.value)}
                 
                     />
                   </div>
                 </div>

                 <div className=" m-3">
                   <label
                     className="mb-3 block text-sm font-medium text-black dark:text-white"
                     htmlFor="username"
                   >
                     {t('Subject')}
                   </label>
                   <div className="relative">
                   
                     <input
                       className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                       type="text" maxLength={255}
                       name="send"
                       id="send"
                       placeholder={t('Subject')}
                       value={AttributesSMTP && AttributesSMTP?AttributesSMTP["subject"]:""}
                       onChange={(e)=>onchangeAttributes('subject',e.target.value)}
                 
                     />
                   </div>
                 </div>
                 <div className=" m-3">
                   <label
                     className="mb-3 block text-sm font-medium text-black dark:text-white"
                     htmlFor="username"
                   >
                     {t('Body')}
                   </label>
                   <div className="relative">
                   
                     <input
                       className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                       type="text" maxLength={255}
                       name="send"
                       id="send"
                       placeholder={t('Body')}
                       value={AttributesSMTP && AttributesSMTP?AttributesSMTP["body"]:""}
                       onChange={(e)=>onchangeAttributes('body',e.target.value)}
                 
                     />
                   </div>
                 </div>
                 
                
                

                 <div className="flex justify-end gap-4  m-3">
                 
                   <button
                     className="flex white justify-center rounded bg-green-40 py-2 px-6 font-medium text-gray hover:bg-green-400"
                     onClick={()=>TestSendMail(AttributesSMTP)}
                   >
                     {t('Test')}
                   </button>
                 </div>
               </div>
             </div>
           </div>
         </div>
         </div>
       
       
     </div>

 
</div>
  )
}
