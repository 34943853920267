import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const data = [
];

export default function LineChartWithXAxisPadding({ title , data,palletsColors}) {
  // Extract the keys from the first object in the data array, excluding 'name'
  const dataKeys = data && data.length>0 && Object.keys(data[0]).filter(key => key !== 'name');

  // Generate a palette with 6 colors
  const colorScale = palletsColors;

  return (
    <div className="w-full">
      {title && <h2 style={{ textAlign: "center" }}>{title}</h2>}
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          height={400}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {dataKeys && dataKeys.map((key, index) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              stroke={colorScale[index]}
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
