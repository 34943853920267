import { getLocalstorage } from "Assistances/GlobalFunctions"
import axios from "axios"



export function ReactionAvisAction(id_avis_product,value,id_store){
    return axios({
        method: 'post',

        url: process.env.REACT_APP_APP_URL_BACKEND+'/reactionAvis',
        data:{
        "id_avis_product": id_avis_product,
        "value":value,
        "id_store":id_store},
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')},

    })

}
export function ReactionAvisStoreAction(){
    
}


export function ratingstartAction(id_product,value,id_store){
    // //console.log(id_account)
    // //console.log(id_product)
    // //console.log(value)
    // //console.log({
    // "id_product": id_product,
    // "value":value,
    // "id_store":id_store});
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/ratingstart',
        data:{
        "id_product": id_product,
        "value":value,
        "id_store":id_store},
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}

    })

}



export function ratingstartStoreAction(value,id_store){

    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/ratingstartstore',
        data:{
        "value":value,
        "id_store":id_store},
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')}

    })

}



