import React, { useEffect, useState } from 'react';
import acceuil_video from "Assets/videos/zselling_acceuill.mp4";
import { getStoresList } from './Actions';
import StoresItem from './StoresItem';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import Menuapp from 'Home/Layouts/Menuapp';
import Spinner from 'Spinner/Spinner.js';
import StoreItemNew from './StoreItemNew';
import { JWTDecode } from 'Assistances/token';
import { GetAccountDataInToken } from 'Assistances/GlobalFunctions';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import StoreShow from './ServiceShow';
import { useSelector } from 'react-redux';
import { selectLang } from './../../Redux/ReduxSlices/langs/LangsSlicer';
import Carousel from 'Componnents/Carousel/Carousel';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Logo from 'Assets/Images/logo.png'
import "./Pages.css"
export default function Acceuil() {
  const [stores, setStores] = useState([]);
  const { t } = useTranslation();
  const [spinner, setSpinner] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [visibleItemsCount, setVisibleItemsCount] = useState(8);
  const [Stars,setStars]=useState([])
  const [showStoreModal,setshowStoreModal]=useState(false)

  useEffect(() => {
    getStoresList()
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          setStores([...res.data?.stores]);
          setFilteredItems([...res.data?.stores]);
          setStars(res.data['stars'])
          console.log(res.data?.stores)
        }
      })
      .catch((err) => {
        //console.log(err)
      })
      .finally(() => {
        setSpinner(false);
      });
  }, []);

  function onChangeFilter(valuefilter) {
    const filtered = stores.filter(item => {
      return Object.values(item).some(value => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(valuefilter.toLowerCase());
        }
        return false;
      });
    });
    setFilteredItems(filtered);
    setVisibleItemsCount(8); // Reset the visible items count when filter changes
  }

  function showMoreItems() {
    setVisibleItemsCount(prevCount => prevCount + 8);
  }
  function augementratingStartNumbeStore(value,store_id) {
      console.log(JWTDecode())
    setStars([
      ...Stars.filter(e=>e.id_account!=GetAccountDataInToken()?.id || e.id_store!=store_id),
      {"created_at":Date(),
      "id_account":GetAccountDataInToken()?.id,
      "id_store": store_id,
      "updated_at": Date(),
      "value": value}
    ])

   
  }
  const [storeData,setStoreData]=useState([])
  function showstoreModal(storevalue){
    setStoreData(storevalue)
    setshowStoreModal(true)
  }
  const langs = useSelector(selectLang);

  return (
    <div className="w-full h-screen bg-white">
      {spinner ? (
        <Spinner />
      ) : (
        <>
          <Menuapp onChangeFilter={onChangeFilter} />
          <div className=" grid w-full">
            {/* <div className="  w-full acceuill ">
              <video src={acceuil_video} autoPlay loop muted className="w-full" />
            </div> */}
            <div className="w-full">
              {/* <h12 className='p-1'>{t('Stores')} ({stores.length})</h12> */}
              <div className="w-full  row rounded">
                {filteredItems.slice(0, visibleItemsCount).map((store, index) => (
                  <div key={index} className=".col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <StoreItemNew store={store}
                    stars={Stars}
                    augementratingStartNumbeStore={augementratingStartNumbeStore}
                    showstoreModal={showstoreModal}

                    />
                  </div>
                ))}
              </div>
              {visibleItemsCount < filteredItems.length && (
                <div className="text-center mt-4">
                  <button onClick={showMoreItems} className=" btnshowMore">
                    {t('Show more')}
                  </button>
                </div>
              )}
            </div>
          </div>
          <Footer />
          <FloatingWhatsApp
          phoneNumber={"+212 644948492"}
          accountName={"Zselling Support"}
          avatar={Logo}
          allowClickAway={true}
          />
        </>
      )}



      <ModalHelper
        show={showStoreModal}
        onHide={() => setshowStoreModal(false)}
        content={
        <StoreShow
        Store={storeData}
        onHide={() => setshowStoreModal(false)}
          stars={Stars}
        />}
        
        
        />

{/* <Carousel/> */}

    </div>
  );
}
