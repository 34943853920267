


import React, { useEffect, useState } from 'react'
import {  toast } from 'react-toastify';    
import { useTranslation } from 'react-i18next';
import Datatable from 'Componnents/DataTables/DataTables';
// import FormCategorie from './FormCategorie';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import { getAllCategies } from './Actions';
import FormCategories from './FormCategories';
import { useNavigate  } from 'react-router-dom';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import Spinner from 'Spinner/Spinner';
import { handleError } from 'Assistances/ErrorHandling';

export default function DataTableCategories() {
    const [Categories,setCategories]=useState([]);
    const { t } = useTranslation();
    const columns=['id','title','description',"created_at","updated_at"];
    const [showModalFormCategorie,setShowModalFormCategorie]=useState(false)
    const history=useNavigate();
    const [spinner,setSpinner]=useState(true)
    useEffect(()=>{
      getCategories()
    },[])
    function getCategories(){
      getAllCategies()
      .then(function (response) {
          setCategories(response.data)
      }) 
      .catch(function (error) {
        handleError(error,t,t('Categories'));
      }) 
      .finally(() => {
        setSpinner(false)
      });
    }
    function handleRowClicked(row){
      setLocalstorage('categorie_id',row.id)
      history("/dashboard/categories/fiche")    }
  return (
    <div>
      
    {spinner?
      <Spinner/> 
      :

      <Datatable 
      data={Categories} 
      columns={columns}
      handleRowClicked={handleRowClicked}
      title="Categories"
      addbutton={true}
      total={Categories.length}

      ShowModal={() => {setShowModalFormCategorie(true)}}
      />
    }
      
      <ModalHelper  
        show={showModalFormCategorie} 
        title={t("Add")+" "+ t("Categories")}
        size='lg'
        onHide={() => setShowModalFormCategorie(false)} 
        content={<FormCategories modifycontent='' onHide={() => setShowModalFormCategorie(false)} getCategories={getCategories} />} />
        
      
      </div>
  )
}
