import React, { useEffect, useState } from 'react';
import { getOrderDetail, updateOrderStatus } from './Actions'; // You need to implement updateOrderStatus in Actions.
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import image from "Assets/Images/nophoto.png";
import image_profile from "Assets/Images/no-profile.png";
import Spinner from 'Spinner/Spinner';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';
import { selectLang } from './../Redux/ReduxSlices/langs/LangsSlicer';
import { handleError } from 'Assistances/ErrorHandling';
import { IsAdmin } from 'Assistances/GlobalFunctions';
import { IsGestionnaire } from 'Assistances/GlobalFunctions';
import { GetAccountDataInToken } from 'Assistances/GlobalFunctions';

export default function FicheOrders(props) {
    const [OrderDetail, setOrderDetail] = useState(null);
    const { t } = useTranslation();
    const [spinner, setSpinner] = useState(true);
    const langs = useSelector(selectLang);
    const [selectedStatus, setSelectedStatus] = useState('');

    useEffect(() => {
        getOrderDetail({ 'order_id': props.order.id })
            .then(function (response) {
                setOrderDetail(response.data);
                console.log(response.data);
                setSelectedStatus(response.data.order.status); // Set initial status

            })
            .catch(function (error) {
                handleError(error, t, t('Orders'));
            })
            .finally(() => {
                setSpinner(false);
            });
    }, []);

    const handleStatusChange = (event) => {
        const newStatus = event.target.value;

        updateOrderStatus({ order_id: OrderDetail.order, status: parseInt(newStatus) })
            .then(response => {
                // Update the OrderDetail state with the new status
                setOrderDetail(prevState => ({
                    ...prevState,
                    order: {
                        ...prevState.order,
                        status: newStatus
                    }
                }));
                toast.success(t('Order status updated successfully'));
            })
            .catch(error => {
                handleError(error, t, t('Orders'));
            });
    };

    return (
        <div>
            {spinner ?
                <div className='h-200 relative justify-center text-center'>
                    <Spinner />
                </div>
                :
                <>
                    {OrderDetail ?
                        <>
                            <div className='grid grid-cols-2'>
                                <div>
                                    {t('Sum')}: {OrderDetail.order.order_sum} {OrderDetail.store.currency}
                                </div>
                                <div>
                                    {t('Date')}: {OrderDetail.order.created_at}
                                </div>
                                <div className='flex'>
                                    <div className='mr-3 mt-3 mb-3'>
                                    {t('Status')}:
                                    </div>
                                    <div>
                                        {
                                            IsAdmin() || (IsGestionnaire() && OrderDetail.store.id_account_creater==GetAccountDataInToken()?.id )?
                                            <select value={OrderDetail.order.status} className='flex mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' onChange={handleStatusChange}>
                                            {
                                             selectedStatus == 0?
                                             <option value="0" selected>
                                             {t('Pending')}
                                              </option>
                                              :
                                              <option value="0">
                                              {t('Pending')}
                                             </option>
                                            }
                                            {
                                             selectedStatus == 1?
                                             <option value="1" selected>
                                             {t('Processing')}
                                             </option>
                                              :
                                              <option value="1" >
                                              {t('Processing')}
                                             </option>
                                            }
                                            
                                            {
                                             selectedStatus == 2?
                                              
                                             <option value="2" selected>
                                                 {t('Completed')}
                                             </option>
                                              :
                                               
                                             <option value="2" >
                                             {t('Completed')}
                                         </option>
                                            }
                                            {
                                             selectedStatus == 3?
                                              
                                             <option value="3" selected>
                                             {t('Canceled')}
                                             </option>
                                              :
                                               
                                              <option value="3" >
                                                 {t('Canceled')}
                                             </option>
                                            }
                                           
                                            
                                         </select>
                                            :

                                            <div className='mt-3'>
                                            {
                                             selectedStatus == 0? <div className='secandary bold' style={{color:"#1890ff",fontWeight:"bold"}}>{t('Pending')}</div>:<></>
                                            }
                                            {
                                             selectedStatus == 1? <div className='yellow bold' style={{color:"rgb(250 204 21)",fontWeight:"bold"}} >{t('Processing')}</div>:<></>
                                            }
                                             {
                                             selectedStatus == 2? <div className='green bold' style={{color:"rgb(14 159 110)",fontWeight:"bold"}}>{t('Completed')}</div>:<></>
                                            }
                                            {
                                             selectedStatus == 3? <div className='red bold' style={{color:"#f43f5e",fontWeight:"bold"}} >{t('Canceled')}</div>:<></>
                                            }
                                         </div>

                                        }
                                   
                                    </div>
                                  
                                </div>
                            </div>
                            <hr />
                            <div className='grid grid-cols-2'>
                                <div className=''>
                                    <div className='m-3'>
                                        <h3 className='m-3 text-3xl'>
                                            {t('Client')}:
                                        </h3>
                                        <div className='grid  grid-cols-5 flex'>
                                            <div className='flex w-70 h-70'>
                                                <img
                                                    className='circle-image bx'
                                                    src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES + "/users/" + OrderDetail.account.image}
                                                    onError={(e) => { e.target.src = image_profile }} />
                                            </div>
                                            <div className='col-span-4 m-2'>
                                                {OrderDetail.account && OrderDetail.account.name ? OrderDetail.account.name : ""}<br />
                                                {OrderDetail.account && OrderDetail.account.login ? OrderDetail.account.login : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='m-3'>
                                        <h3 className='m-3 text-3xl'>
                                            {t('Store')}:
                                        </h3>
                                        <div className='grid  grid-cols-5 flex'>
                                            <div className='flex w-70 h-70'>
                                                <img
                                                    className='circle-image bx'
                                                    src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES + "/stores/" + OrderDetail.store.image}
                                                    onError={(e) => { e.target.src = image }} />
                                            </div>
                                            <div className='col-span-4 m-2'>
                                                {getTextByLanguage(OrderDetail.store ? OrderDetail.store.title : "", langs)}
                                                <br />
                                                {getTextByLanguage(OrderDetail.store ? OrderDetail.store.url_store : "", langs)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h3 className='m-3 text-3xl'>
                                    {t('Products')}:
                                </h3>
                                <div>
                                    {OrderDetail.products && OrderDetail.products.map(item => {
                                        return (
                                            <div className='border border-1 p-3' key={item.artical_order_id}>
                                                <div className='grid grid-cols-2'>
                                                    <div>
                                                        <div className='m-3'>
                                                            {t('ID')}: {item.artical_order_id}<br />
                                                            {t('Feature')}: {getTextByLanguage(item.product_featuers_value, langs)}                                          <br />
                                                            {t('Sum')}: {item.num_products} x  {item.order_prod_sum} {item.currency} = {item.num_products * item.order_prod_sum} {item.currency}
                                                        </div>
                                                    </div>
                                                    <div className='border border-1 flex'>
                                                        <div className=''>
                                                            <img
                                                                className='h-70 w-70'
                                                                src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES + "/products/" + item.image} onError={(e) => { e.target.src = image }} />
                                                        </div>
                                                        <div className='col-span-2'>
                                                               
                                                            <div className='productListing-title'
                                                                  data-toggle="tooltip" data-placement="top" 
                                                                  title={getTextByLanguage(props.title,langs)}
                                                                  style={{marginBottom:'0px'}}
                                                            > 
                                                            {getTextByLanguage(item.title, langs)}
                                                            </div>

                                                              <div className="container-desc z-10">
                                                                <div className='productListing-desc' 
                                                                  data-toggle="tooltip" data-placement="top" 
                                                                  title={getTextByLanguage(props.description,langs)}
                                                            > 
                                                            {getTextByLanguage(item.description, langs)}
                                                            </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-2'>
                                                    <div>
                                                        <div>{t('Rejected Grediens') }</div>
                                                        <div>
                                                            {OrderDetail.grediens.map(gredien => {
                                                                if (gredien.id_OSA === item.id_OSA) {
                                                                    return (
                                                                        <div className='grid grid-cols-5' key={gredien.id}>
                                                                            <div>
                                                                                <img
                                                                                    className='h-40 w-40'
                                                                                    src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES + "/grediens/" + gredien.image} onError={(e) => { e.target.src = image }} />
                                                                            </div>
                                                                            <div className='col-span-4'>
                                                                                {getTextByLanguage(gredien.title, langs)}
                                                                                <br />
                                                                                {getTextByLanguage(gredien.description, langs)}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>{OrderDetail.toppings && OrderDetail.toppings.length > 0 ? t('Toppings') : ""}</div>
                                                        <div>
                                                            {OrderDetail.toppings.map(topping => {
                                                                if (topping.id_OSA === item.id_OSA) {
                                                                    return (
                                                                        <div className='grid grid-cols-5' key={topping.id}>
                                                                            <div>
                                                                                <img
                                                                                    className='h-40 w-40'
                                                                                    src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES + "/topping/" + topping.image} onError={(e) => { e.target.src = image }} />
                                                                            </div>
                                                                            <div className='col-span-4'>
                                                                                {getTextByLanguage(topping.title, langs)}
                                                                                <br />
                                                                                {getTextByLanguage(topping.description, langs)}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }
                </>
            }
        </div>
    );
}
