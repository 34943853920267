import React, { useEffect, useState } from 'react';
import './sidebar.css'; // Assurez-vous que le fichier style.css est dans le même répertoire
import { abbreviateNumber } from 'Assistances/GlobalFunctions';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { IsAdmin } from 'Assistances/GlobalFunctions';
import { IsGestionnaire } from 'Assistances/GlobalFunctions';
import { RiCustomerService2Line } from "react-icons/ri";
import { IoExtensionPuzzleOutline } from "react-icons/io5";

function Sidebarr(props) {
  const [isSidebarClosed, setIsSidebarClosed] = useState(props.isSidebarClosed);
  const { t } = useTranslation();

  const toggleSidebar = () => {
    setIsSidebarClosed(!isSidebarClosed);
  };

  const toggleSubMenu = (e) => {
    const arrowParent = e.target.parentElement.parentElement;
    arrowParent.classList.toggle("showMenu");
  };
  useEffect(()=>{
    setIsSidebarClosed(props.isSidebarClosed)
  },[props.isSidebarClosed])
  function NotificationValue(itemKey){
    if(props.TotalStatics && props.TotalStatics[itemKey]){
      return props.TotalStatics[itemKey];
    }
    return 0;

  }
  const SidebarrDataClient=[
    {
      'title':t('Dashboard'),
      'icon': <svg xmlns="http://www.w3.org/2000/svg"
      width="24" height="24" viewBox="0 0 24 24"
      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
      className="lucide lucide-layout-grid h-4 w-4"><rect width="7" height="7" x="3" y="3" rx="1">
        </rect><rect width="7" height="7" x="14" y="3" rx="1"></rect><rect width="7" height="7" x="14" y="14" rx="1"></rect><rect width="7" height="7" x="3" y="14" rx="1"></rect>
        </svg>,
      'path':'/dashboard/statics',
      'sub':{},
      'notification':0,

    },
    {
      'title':t('Orders'),
      'icon': <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-settings h-4 w-4 stroke-[1.5]"><line x1="18" x2="18" y1="20" y2="10"></line><line x1="12" x2="12" y1="20" y2="4"></line><line x1="6" x2="6" y1="20" y2="14"></line></svg>,

      'path':'/dashboard/orders',
      'sub':{},
      'notification':NotificationValue('My Orders'),

    },
    {
      'title':t('Settings'),
      'icon':  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-settings h-4 w-4 stroke-[1.5]"><path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path><circle cx="12" cy="12" r="3"></circle></svg>,

      // 'icon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-circle-user-round h-4 w-4 stroke-[1.5]"><path d="M18 20a6 6 0 0 0-12 0"></path><circle cx="12" cy="10" r="4"></circle><circle cx="12" cy="12" r="10"></circle></svg>,
      'path':'/dashboard/settings',
      'sub':{},
      'notification':0,

    }

  ];
  const [PathName,setPathName]=useState(window.location.pathname)
  const SidebarrData=[
    {
      'title':t('Dashboard'),
      'icon': <svg xmlns="http://www.w3.org/2000/svg"
      width="24" height="24" viewBox="0 0 24 24"
      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
      className="lucide lucide-layout-grid h-4 w-4"><rect width="7" height="7" x="3" y="3" rx="1">
        </rect><rect width="7" height="7" x="14" y="3" rx="1"></rect><rect width="7" height="7" x="14" y="14" rx="1"></rect><rect width="7" height="7" x="3" y="14" rx="1"></rect>
        </svg>,
      'path':'/dashboard/statics',
      'sub':{},
      'notification':0,

    },
    {
      'title':t('Stores'),
      'icon': <svg viewBox="0 0 24 24"  className="lucide lucide-layout-grid h-4 w-4"  stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><g clip-path="url(#clip0_7258_81938)"><path d="M16.5953 6.69606L16.6072 5.17376L6.85812 8.92381L6.85812 19.4238L9.00319 18.6961" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12.0953 3.69606L12.1072 2.17376L2.35812 5.92381L2.35812 16.4238L4.50319 15.6961" stroke-linecap="round" stroke-linejoin="round"></path><path d="M21.7438 17.9461L21.7511 7.44434L12.0021 11.1944L12.0021 21.6944L21.7438 17.9461Z" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_7258_81938"><rect width="24" height="24" fill="white" transform="translate(24) rotate(90)"></rect></clipPath></defs></svg>,
      'path':'/dashboard/stores',
      'sub':{},
      'notification':NotificationValue('stores'),
    },
    {
      'title': t('Products'),
      'icon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-briefcase h-4 w-4"><rect width="20" height="14" x="2" y="7" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>,
      'path':'/dashboard/products',
      'sub':{},
      'notification':NotificationValue('products'),

    },

    {
      'title':t('Categories'),
      'icon':<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-check-circle h-4 w-4"><path d="M12 2H2v10l9.29 9.29c.94.94 2.48.94 3.42 0l6.58-6.58c.94-.94.94-2.48 0-3.42L12 2Z"></path><path d="M7 7h.01"></path></svg>,
      'path':'/dashboard/categories',
      'sub':{},
      'notification':NotificationValue('categories'),

    },
    {
      'title':t('Annonces'),
      'icon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-check-circle h-4 w-4"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><path d="m9 11 3 3L22 4"></path></svg>,
      'path':'/dashboard/annonces',
      'sub':{},
      'notification':NotificationValue('Annonces'),

    },
    {
      'title':t('Components'),
      'icon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-circle-user-round h-4 w-4 stroke-[1.5]"><IoExtensionPuzzleOutline size={23}/> </svg>,
      'path':'/dashboard/grediens',
      'sub':{},
      'notification':NotificationValue('Components'),

    },
    {
      'title':t('Toppings'),
      'icon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-check-circle h-4 w-4"><rect width="18" height="7" x="3" y="3" rx="1"></rect><rect width="7" height="7" x="3" y="14" rx="1"></rect><rect width="7" height="7" x="14" y="14" rx="1"></rect></svg>,
      'path':'/dashboard/toppings',
      'sub':{},
      'notification':NotificationValue('additions'),

    },
    {
      'title':t('Features'),
      'icon': <svg viewBox="0 0 24 24" className="h-4 w-4 rotate-180 stroke-2" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 18C13.5913 18 15.1174 17.3679 16.2426 16.2426C17.3679 15.1174 18 13.5913 18 12C18 10.4087 17.3679 8.88258 16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6V18Z" fill="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>,
      'path':'/dashboard/features',
      'sub':{},
      'notification':NotificationValue('features'),

    },

   
    {
      'title':t('Comments'),
      'icon':     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-circle-user-round h-4 w-4 stroke-[1.5]"><path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z"></path></svg>
      ,
      'path':'/dashboard/comments',
      'sub':{},
      'notification':NotificationValue('comments'),

    },
    {
      'title':t('Orders'),
      'icon': <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-settings h-4 w-4 stroke-[1.5]"><line x1="18" x2="18" y1="20" y2="10"></line><line x1="12" x2="12" y1="20" y2="4"></line><line x1="6" x2="6" y1="20" y2="14"></line></svg>,

      'path':'/dashboard/orders',
      'sub':{},
      'notification':NotificationValue('My Orders')+NotificationValue('Clients Orders'),

    },
    {
      'title':t('Teams'),
      'icon': <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-settings h-4 w-4 stroke-[1.5]"><path d="M18 19C18 17.4087 17.3679 15.8826 16.2426 14.7574C15.1174 13.6321 13.5913 13 12 13C10.4087 13 8.88258 13.6321 7.75736 14.7574C6.63214 15.8826 6 17.4087 6 19" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M23 18C23 16.636 22.4732 15.3279 21.5355 14.3635C20.5979 13.399 19.3261 12.8571 18 12.8571C18.8841 12.8571 19.7319 12.4959 20.357 11.8529C20.9821 11.21 21.3333 10.3379 21.3333 9.42857C21.3333 8.51926 20.9821 7.64719 20.357 7.00421C19.7319 6.36122 18.8841 6 18 6" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1 18C1 16.636 1.52678 15.3279 2.46447 14.3635C3.40215 13.399 4.67392 12.8571 6 12.8571C5.11595 12.8571 4.2681 12.4959 3.64298 11.8529C3.01786 11.21 2.66667 10.3379 2.66667 9.42857C2.66667 8.51926 3.01786 7.64719 3.64298 7.00421C4.2681 6.36122 5.11595 6 6 6" stroke-linecap="round" stroke-linejoin="round"></path></svg>,
      'path':'/dashboard/teams',
      'sub':{},
      'notification':NotificationValue('teams'),

    },
   

  ]
  const SidebarrDataAdmin=[
    ...SidebarrData,
    {
      'title':t('Service Center'),
      'icon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-circle-user-round h-4 w-4 stroke-[1.5]"><RiCustomerService2Line size={21} /></svg>,

      'path':'/dashboard/service-center',
      'sub':{},
      'notification':NotificationValue('serviceCenter'),

    },
    {
      'title':t('Accounts'),
      'icon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-circle-user-round h-4 w-4 stroke-[1.5]"><path d="M18 20a6 6 0 0 0-12 0"></path><circle cx="12" cy="10" r="4"></circle><circle cx="12" cy="12" r="10"></circle></svg>,

      'path':'/dashboard/profiles',
      'sub':{},
      'notification':NotificationValue('accounts'),

    },
    {
      'title':t('Settings'),
      'icon':  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-settings h-4 w-4 stroke-[1.5]"><path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path><circle cx="12" cy="12" r="3"></circle></svg>,

      // 'icon': <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-circle-user-round h-4 w-4 stroke-[1.5]"><path d="M18 20a6 6 0 0 0-12 0"></path><circle cx="12" cy="10" r="4"></circle><circle cx="12" cy="12" r="10"></circle></svg>,
      'path':'/dashboard/settings',
      'sub':{},
      'notification':0,

    }
  ]

  function onClickItemSidebarr(value){
    props.onClickItemSidebarr(value)
    setPathName(window.location.pathname)
  }
  const history=useNavigate()
  return (
    <div className=''>

      <div  onMouseEnter={()=>setIsSidebarClosed(true)} onMouseLeave={()=>setIsSidebarClosed(false)} className={isSidebarClosed ?'inset-y-0 z-20 flex h-full flex-shrink-0 flex-grow-0 flex-col border-r border-custom-sidebar-border-200 bg-custom-sidebar-background-100 duration-300 fixed md:relative sm: md:ml-0 w-[250px] lg:ml-0 w-[250px]':'inset-y-0 z-20 flex h-full flex-shrink-0 flex-grow-0 flex-col border-r border-custom-sidebar-border-200 bg-custom-sidebar-background-100 duration-300 fixed md:relative      -ml-[280px] sm:-ml-[280px]    md:ml-0 w-[80px]    lg:ml-0 w-[80px]'}>
        <div className='flex h-full w-full flex-1 flex-col'>
        <div className="flex absolute right-0">
      {/* {
        !isSidebarClosed?
<></>
      // <button onClick={()=>props.ChangeisSidebarClosed(true)} type="button" className=" place-items-center rounded-md p-1.5 text-custom-text-200 outline-none hover:bg-custom-background-90 hover:text-custom-text-100 md:grid w-full" tabindex="0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-move-left h-3.5 w-3.5 duration-300 rotate-180"><path d="M6 8L2 12L6 16"></path><path d="M2 12H22"></path></svg></button>
        :
        <button onClick={()=>props.ChangeisSidebarClosed(false)}  type="button" className=" place-items-center rounded-md p-1.5 text-custom-text-200 outline-none hover:bg-custom-background-90 hover:text-custom-text-100 md:grid " tabindex="0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-move-left h-3.5 w-3.5 duration-300 "><path d="M6 8L2 12L6 16">  </path><path d="M2 12H22">  </path></svg></button>
      } */}
      </div>


          {!isSidebarClosed?

          <div className="flex items-center gap-x-3 gap-y-2 px-4 pt-4">
            
            <div className="relative h-full flex-grow truncate text-left" data-headlessui-state="">
              <button onClick={()=>history('/')} className="group/menu-button h-full w-full truncate rounded-md text-sm font-medium text-custom-sidebar-text-200 hover:bg-custom-sidebar-background-80 focus:outline-none" id="headlessui-menu-button-:rk0:" type="button" aria-haspopup="menu" aria-expanded="false" data-headlessui-state="">
                <div className="flex items-center  gap-x-2 truncate rounded p-1 justify-center">
                  <div className="flex items-center gap-2 truncate">
                    <div className="relative grid h-6 w-6 flex-shrink-0 place-items-center uppercase rounded bg-custom-primary-500 text-white">
                      Z
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
          :
          <div className="flex items-center gap-x-3 gap-y-2 px-4 pt-4">
            <div className="relative h-full flex-grow truncate text-left" data-headlessui-state="">
              <button className="group/menu-button h-full w-full truncate rounded-md text-sm font-medium text-custom-sidebar-text-200 hover:bg-custom-sidebar-background-80 focus:outline-none" id="headlessui-menu-button-:rk0:" type="button" aria-haspopup="menu" aria-expanded="false" data-headlessui-state="">
                <div className="flex items-center  gap-x-2 truncate rounded p-1 justify-between">
                  <div className="flex items-center gap-2 truncate">
                    <div className="relative grid h-6 w-6 flex-shrink-0 place-items-center uppercase rounded bg-custom-primary-500 text-white">Z</div>
                      <h4 className="truncate text-base font-medium text-custom-text-100">Zselling</h4>
                    </div>
                 
                  </div>
              </button>
              
            </div>
          </div>
          }



        <div className="w-full cursor-pointer space-y-2 p-4">
          {
            (IsAdmin()?SidebarrDataAdmin:IsGestionnaire()?SidebarrData: SidebarrDataClient).map(item=>{
              if(!isSidebarClosed)

              return(
                <span className="my-1 block w-full" onClick={()=>onClickItemSidebarr(item)}>
                  <Link to={item.path?item.path:"#"}>
                  {/* <div className={`group relative grid w-full items-center gap-2.5 rounded-md px-3 py-2 text-sm font-medium outline-none text-custom-sidebar-text-200  hover:bg-custom-sidebar-background-80 justify-center`} tabindex="0"> */}
                  <div className={`group relative grid w-full items-center gap-2.5 rounded-md px-3 py-2 text-sm font-medium outline-none text-custom-sidebar-text-200 ${PathName==item.path?"bg-custom-sidebar-background-80":""} hover:bg-custom-sidebar-background-80 justify-center`} tabindex="0">
                  
                  {item.icon}
                  {item.notification ?
                      <span className="absolute right-2 top-2 h-2 w-2 rounded-full bg-custom-primary-300"></span>
                    :
                    <></>
                    }
                  </div>
                  </Link>
                </span>)

            else
            return(
                <span className="my-1 block w-full" onClick={()=>onClickItemSidebarr(item)}>
                  <Link to={item.path?item.path:"#"}>

                  <div className={`group flex w-full items-center gap-2.5 rounded-md px-3 py-2 text-sm font-medium outline-none text-custom-sidebar-text-200 ${PathName==item.path?"bg-custom-sidebar-background-80":""} hover:bg-custom-sidebar-background-80 focus:bg-custom-sidebar-background-80 `}    style={{display:"flex"}}      tabindex="0">
                    {item.icon}
                    {item.title}

                    {item.notification ?<span className="ml-auto rounded-full bg-custom-primary-300 px-1.5 text-xs text-white">{abbreviateNumber(item.notification)}</span>:""}

                  </div>
                  </Link>
                </span>)
            })
          }
          </div>

          <div className='h-full space-y-2 overflow-y-auto px-4 vertical-scrollbar scrollbar-md'>

          </div>


          <div className="flex w-full items-center justify-between gap-1 self-baseline border-t border-custom-border-200 bg-custom-sidebar-background-100 px-4 py-2 ">
        <div className="w-1/2 cursor-default rounded-md px-2.5 py-1.5 text-center text-sm font-medium  outline-none">

        </div>
      <div className="grid items-center gap-1 w-1/2 justify-evenly">
      {
        !isSidebarClosed?

      <button onClick={()=>props.ChangeisSidebarClosed(true)} type="button" className=" place-items-center rounded-md p-1.5 text-custom-text-200 outline-none hover:bg-custom-background-90 hover:text-custom-text-100 md:grid w-full" tabindex="0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-move-left h-3.5 w-3.5 duration-300 rotate-180"><path d="M6 8L2 12L6 16"></path><path d="M2 12H22"></path></svg></button>
        :
        <button onClick={()=>props.ChangeisSidebarClosed(false)}  type="button" className=" place-items-center rounded-md p-1.5 text-custom-text-200 outline-none hover:bg-custom-background-90 hover:text-custom-text-100 md:grid " tabindex="0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-move-left h-3.5 w-3.5 duration-300 "><path d="M6 8L2 12L6 16">  </path><path d="M2 12H22">  </path></svg></button>
      }
      </div>

      </div>
      </div>




      </div>
    </div>
  );
}



export default Sidebarr;
