
import React, { useEffect, useState } from 'react'
// import "./../../sass/catalogue.scss"
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { id_account_creater } from 'HelperFunction/token';
import { addImageStore, deleteImageStore } from './Actions';
import image from 'Assets/Images/nophoto.png'
import { handleError } from 'Assistances/ErrorHandling';
export default function EditStoreCatalogueImage(props) {
    const { t } = useTranslation();
   
    const [Catalogue,setCatalogue]=useState(props.Catalogue?props.Catalogue:[])
    function  onChangeCatalogues(value){
        if(Catalogue.length>5)
            toast.info(t("max recommanded is 6"))
        else{
            const attachedFile = document.querySelector('#attachedFile');
            // incidentAttributes['file']=incidentAttributes['file']?incidentAttributes['file']:[];
           if(attachedFile.files[0]){
                const data = new FormData() 
                data.append('id',getLocalstorage('store_id'))
                data.append("id_account_creater",id_account_creater)
                data.append("image",attachedFile.files[0])

                addImageStore(data)
                .then(function(response){
                    let Files= Catalogue
                    Files[Files.length]= {"image":attachedFile.files[0],'image_id':response.data.image_id}
                    setCatalogue([...Files])
                    toast.success(t("Succesfully Added"))
                })
                .catch(function(error){
                    handleError(error,t);

                })
           }
        }
    }
  
    function removefile(id,item){
        const data = new FormData() 
        data.append('id',getLocalstorage('store_id'))
        data.append("id_account_creater",id_account_creater)
        data.append("image_id",item.image_id)
        deleteImageStore(data)
        .then(function(response){
            toast.success(t("Succesfully Deleted"))
            Catalogue.splice(id, 1); 
        
            setCatalogue([...Catalogue])
        })
        .catch(function(error){
            handleError(error,t);

        })
    }
  return (
    <div>
       
    <div >
        <div className='row'>
            <table>
                <tr>
            <td className='col-3 '>
                <span className='title-of-catalogue'>
                    {t("Catalogue")} {t("Images")} (6):
                </span>
                <div className="cf-c-attach-file-wrapper">
                       
                                <label className="cf-c-attach-file">
                                    <input type="file" multiple="multiple" accept="image/*" id="attachedFile" className="cf-c-add-file__input"   onChange={(e)=>onChangeCatalogues(e.target.files[0])}/>
                                    <div className="cf-c-attach-file__ctn">
                                        <div className="cf-u-mr-md cf-c-icon-wrap cf-c-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-7 mr-1 fill-white inline" viewBox="0 0 32 32">
                                              <path
                                                d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                                                data-original="#000000" />
                                              <path
                                                d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                                                data-original="#000000" />
                                            </svg>
                                        </div>
                                    <div className="cf-u-width-min-width-5 cf-u-text-center cf-u-font-size-xs text-left ml-1 "> {t("Images")} </div>
                                    </div>
                                </label>
                            </div>
                   
            </td>
            <td className='col-9' >
            <div  className=' row catalogue-container'>
                    {Catalogue  && Catalogue.map((item,i)=>{
                        return (
                            <div className="col-lx-2 col-lg-3 col-md-4 col-sm-6 image-catalogue-item-container" key={i}>
                                <div className="cf-u-display-flex cf-u-flex-wrap cf-u-mb-sm">
                                    <div className="cf-c-file-item cf-u-mx-xs cf-u-mb-sm">
                                        <div className="cf-c-file-item__icon-wrap">
                                            <div className="cf-c-icon-wrap cf-c-icon cf-c-icon--lg">
                                                {
                                                   item && Object.entries(item).length>2? 
                                                    <img src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+item.image} className='image-catalogue-item' onError={(e) => {e.target.src =image}}/>
                                                   :
                                                   <img src={URL.createObjectURL(item.image)} className='image-catalogue-item' onError={(e) => {e.target.src =image}}/>
                                                }
                                                
                                            </div>
                                            <div className="cf-c-file-item__remove cf-u-display-flex cf-c-icon-wrap cf-c-icon cf-c-icon--lg" onClick={()=>removefile(i,item)}>
                                                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className=" cf-c-icon cf-c-icon--lg svg-remove">
                                                    <path d="M8 7.057l2.828-2.828.943.943L8.943 8l2.828 2.828-.943.943L8 8.943 5.172 11.77l-.943-.943L7.057 8 4.23 5.172l.943-.943L8 7.057z" fill-rule="nonzero">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div title="cours de S6 2021 1 4 in 1.pdf" className="cf-c-file-item__name">
                                            <span className="cf-c-file-item__file-name">
                                                {
                                                   item && Object.entries(item).length>2? 
                                                   <>
                                                   {item.image}
                                                   </>
                                                   :
                                                   <>
                                                   {item.image.name}
                                                   </>
                                                }
                                                
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    
                </div>

            </td>
            </tr>
            </table>

        </div>
       
        </div>
    </div>
  )
}
