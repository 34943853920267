import React, { useEffect, useRef, useState } from 'react'
import HeaderMenu from './MenuHeader/HeaderMenu';
import { getStoresDataByURL} from './Actions';
import "./Header.css"
import Footer from './Footer/Footer';
import Product from './Body/Product';
import AnnoncesTop from './Body/AnnoncesTop';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import ProductBuy from './Body/ProductBuy/ProductBuy';
import OrderList from './Body/Orders/OrderList';
import { FaShoppingBasket } from 'react-icons/fa';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { tokenDecoded } from 'Assistances/token';
import { getListOfOrdersClientOnStoreinLast24h } from './Body/Orders/Actions';
import TeamsListing from './Body/Teams/TeamsListing';
import Login from 'Auth/Login';
import Spinner from 'Spinner/Spinner';
import Auth from 'Auth/Auth';

import { Dropdown } from 'react-bootstrap';
import { BsTranslate } from 'react-icons/bs';
import ar_flag from "Assets/flags/sa.svg"
import en_flag from "Assets/flags/gb-eng.svg"
import es_flag from "Assets/flags/es.svg"
import fr_flag from "Assets/flags/fr.svg"
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import { useTranslation } from 'react-i18next';
import i18n from 'langs';
import PageNotFound from 'Pages/PageNotFound';
import { selectLang, updateLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { useDispatch, useSelector } from 'react-redux';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { JWTDecode } from 'Assistances/token';
import { GetAccountDataInToken } from 'Assistances/GlobalFunctions';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import SeoHemlet from 'SEO/SeoHemlet';

export default function IndexM002(props) {
  const [Categories, SetCategries] = useState([]);
  const [ficheInfo, SetficheInfo] = useState(null);
  const [Socials, SetSocials] = useState([]);
  const [Products, SetProducts] = useState([]);
  const [stars, SetStars] = useState([]);
  const [avis,Setavis]=useState([])
  const [reactionsAvis,SetreactionsAvis]=useState([])
  const [MyreactionsAvis,SetMyreactionsAvis]=useState([])
  const [headannonces,SetHeadannonces]=useState([])
  const [showProductBuyModal,setshowProductBuyModal]=useState(false)
  const [showenItemBuy,setshowenItemBuy]=useState({})
  const [ShowBlock,SetShowBlock]=useState(false)
  const [ordersSelected,setordersSelected]=useState( [])
  const [Teams,SetTeams]=useState( [])
  const [spinner,setSpinner]=useState(true)
  const {t}=useTranslation()
  const [totalOrder,settotalOrder]=useState(0)
  const [storeExist,setStoreExist]=useState(false)
  const [showModuleLogin,setshowModuleLogin]=useState(false)
  const [Currentlang,setCurrentlang]=useState("fr")
  const [showlangs,setShowLangs]=useState(false)
  const selectorRef = useRef(null); 
  const [TotalavisByProduct,setTotalavisByProduct]=useState([])
  const [TotalstarsByProduct,setTotalstarsByProduct]=useState([])
  const [CallValue,setCallValue]=useState(false)
  const findCallValue = (data) => {
    const callElement = data.find(item => item.title === "Call");
    return callElement ? callElement.value : false;
  };
  
  var title=<></>;
  var cat=""
  const dispatch=useDispatch()
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setShowLangs(false);

      }
    };
    let langtemp=getLocalstorage('lang')
    if(langtemp && langtemp.length>0){
      setCurrentlang(langtemp)

    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
   
  function ModifyLanguage(key){
    dispatch(updateLang(key))
    setLocalstorage("lang",key)
    setCurrentlang(key)
    setShowLangs(false)
    i18n.changeLanguage(key,(err)=>{
        //console.log("Error langs change "+err)
    })
  }


  useEffect(()=>
  {
   
    getStoresDataByUrl()
    i18n.changeLanguage(getLocalstorage('lang'),(err)=>{
      //console.log("Error langs change "+err)
    })
  }
  ,[])
  function getStoresDataByUrl(){

     
  getStoresDataByURL(props?.url)
  .then((response) => {
    if(response.data['info']){
      setStoreExist(true)

    }      
      SetficheInfo({...response.data['info']})
      SetCategries(response.data['categories'])
      SetSocials(response.data['socials'])
      SetProducts(response.data['products'])
      SetStars(response.data['stars'])
      // Setavis(response.data['avis'])
      SetreactionsAvis(response.data['reactions'])
      // SetMyreactionsAvis(response.data['my_reactions'])
      SetHeadannonces(response.data["headAnnonces"])
      SetTeams(response.data["teams"])

      setTotalavisByProduct(response.data["TotalavisByProduct"])
      // setTotalstarsByProduct(response.data["TotalstarsByProduct"])
  //console.log(response.data['avis'])
    
  }).catch((err) => {
    console.log(err)
  })
  .finally(()=>{
    setSpinner(false)

  })
  }
  useEffect(()=>{
    listingOrders();

  },[ficheInfo,ShowBlock])

  function augementratingStartNumbeProductStore(value,product_id, store_id){
        //console.log(ficheInfo?ficheInfo.id:-1)
      SetStars([
        ...stars.filter(e=>e.id_account!=GetAccountDataInToken()?.id || e.id_product!=product_id),
        {"created_at":Date(),
        "id_account":GetAccountDataInToken()?.id,
        "id_product": product_id,
        "id_store": store_id,
        "updated_at": Date(),
        "value": value}
      ])

      
      let IndexTotalstarsByProduct=-1;
      let totalAvisTemp=0
      TotalstarsByProduct.map((item,index)=>{
        if(item.id_product==product_id){
          IndexTotalstarsByProduct=index
        }
      })
      if(IndexTotalstarsByProduct>=0){
        totalAvisTemp=TotalstarsByProduct[IndexTotalstarsByProduct].stars_total
        TotalstarsByProduct[IndexTotalstarsByProduct].stars_total=totalAvisTemp+1
      }
      setTotalstarsByProduct([...TotalstarsByProduct])
    }
    function insertNewAvis(comment){
      
      // if(comment.comment.length>0){
      //   Setavis([comment,...avis])
      // }
      let IndexTotalavisByProduct=-1;
      let totalAvisTemp=0
      TotalavisByProduct.map((item,index)=>{
        if(item.product_id==comment.product_id){
          IndexTotalavisByProduct=index
        }
      })
      if(IndexTotalavisByProduct>=0){
        totalAvisTemp=TotalavisByProduct[IndexTotalavisByProduct].avis_total
        TotalavisByProduct[IndexTotalavisByProduct].avis_total=totalAvisTemp+1
      }
      setTotalavisByProduct([...TotalavisByProduct])
    }
   
   function showitemModal(item){
    setshowenItemBuy(item)
    setshowProductBuyModal(true)
   }
   function declareHiden(){
    SetShowBlock(!ShowBlock)
  }
  function listingOrders(order_type="extern"){
   
    var id_store=ficheInfo?ficheInfo.id:-1
    // }

    let fromStorageOrderList = getLocalstorage('order_'+id_store);
    let listOfOrder = fromStorageOrderList ? JSON.parse(fromStorageOrderList) : [];
    setordersSelected([...listOfOrder])
    settotalOrder([...listOfOrder].length?[...listOfOrder].length:0)
  

}
function orderRefreshForbyProducts(){
  listingOrders()
}
function IncreaseOrder(){
  settotalOrder(totalOrder+1)
} 
 function updateOrdersSelected(orderSelected=[]){
  setordersSelected(orderSelected)
  settotalOrder(orderSelected.length)
 }
 const langs = useSelector(selectLang);

  return (
    <div className='full'>
      
      {spinner?
      <Spinner/> 
      :

      <>
      {
        storeExist? 
        <>
      
        <HeaderMenu info={ficheInfo} 
        showModuleLogin={()=>setshowModuleLogin(true)}

        categories={Categories}/>
       <br/>
       <br/>
       <br/>

        <AnnoncesTop headannonces={headannonces}/>
        
        <div className='container-fluid'>
          <div  className='groups-items-container' >
             <div className='row ' >
              {
                Array.isArray(Categories) && Categories.map( (CategorieItem) =>{
                  return (
                    <>
                    <><h2 key={CategorieItem.cat_id} className="block-title truncate" id={CategorieItem['cat_id']}
                        data-toggle="tooltip" data-placement="top" 
                        title={getTextByLanguage(CategorieItem.title,langs)}
                    >{getTextByLanguage(CategorieItem["title"],langs)}</h2> <br/></>

                    {Array.isArray(Products) && Products.map( (item) =>{
                        if(CategorieItem.cat_id==item["cat_id"]){
                     
                     
                           return (
                            <>
                             <br/>
                            {title!=""?
                             title  
                            :
                            <></>
                            }
                               <div  className='col-item col-lg-3 col-md-4 col-xs-12 col-sm-6' >
                                  <Product 
                                  // MyreactionsAvis={MyreactionsAvis} 
                                  // reactionsAvis={reactionsAvis} 
                                  stars={stars}
                                  // avis={avis} 
                                  TotalavisByProduct={TotalavisByProduct}
                                  
                                  currency={ficheInfo?ficheInfo.currency:"$"} 
                                  store_id={ficheInfo?ficheInfo.id:-1} 
                                  showitemModal={showitemModal}  
                                  item={item}
                                  // modifyInReactionInList={modifyInReactionInList} 
                                  insertNewAvis={insertNewAvis}
                                  augementratingStartNumbeProductStore={augementratingStartNumbeProductStore}
                                  showModuleLogin={()=>setshowModuleLogin(true)}
                                  />
                               </div>
                              </>
                           )
                          }
                       })
    
                       }
                       
                       </>)
                  
                }
                
                )
                
              }
               
                   </div>

              </div>
              {/* <div className='container mt-12 justify-center text-center'>
                <TeamsListing teams={Teams}/>
                  
              </div> */}

            </div >
        <OrderList 
          currency={ficheInfo?ficheInfo.currency:"$"} 
          ficheInfo={ficheInfo}
            showBlock={ShowBlock} 
            declareHiden={()=>declareHiden()} 
            listingOrders={listingOrders}
        //  serverTable={props.serverTable?props.serverTable:false}
        //  orderRefresh={props.orderRefresh?props.orderRefresh:true}
        //  orderRefresh1= {orderRefresh1}
        //  customizing={props.customizing}
        ordersSelected={ordersSelected}
        updateOrdersSelected={updateOrdersSelected}
        // type={props.type}
        showModuleLogin={()=>setshowModuleLogin(true)}
        orderRefreshForbyProducts={orderRefreshForbyProducts}
        />
           <div className=" fixed top-0 right-0 z-9999 mt-17  " >

              <div className="orderbtn-container mr-3">
                  <span className='number-of-order'>
                      {totalOrder}
                  </span>
                  <FaShoppingBasket className="myorderbtn " onClick={()=>{declareHiden()}}/>
              </div>
            </div>
        <Footer socials={Socials} info={ficheInfo}/>



        <ModalHelper
        show={showProductBuyModal}
        onHide={() => setshowProductBuyModal(false)}
        content={
            <ProductBuy
            orderRefreshForbyProducts ={orderRefreshForbyProducts }
            item={showenItemBuy}
            currency={ficheInfo?ficheInfo.currency:"$"} 

            IncreaseOrder={IncreaseOrder}
            onHide={() => setshowProductBuyModal(false)}
            ficheInfo={ficheInfo}
            />
          }
        store_id={ficheInfo?ficheInfo.id:-1} 
        size={"lg"}
        />

      <ModalHelper
        show={showModuleLogin}
        onHide={() => setshowModuleLogin(false)}
        content={<Auth />}
        size={"lg"}
      />

        

  {/* ////////Langs */}
  <div className=" ">
      <div className='right-0 bottom-0 fixed mr-3 z-10 mb-3' >
      {showlangs && (
        <div ref={selectorRef} style={{marginTop:"-57px",zIndex:"5", marginRight:"17px", position:"initial"}}className=" top-full right-0 z-10  bg-white border border-gray-200 rounded-md shadow-lg z-10 ">
          
          <Dropdown className='header-togle' style={{zIndex:"2"}} >
                  <span className={Currentlang=="ar"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"}  onClick={()=>ModifyLanguage('ar')}> 
                     <img src={ar_flag} width={"21px"} height={"27px"} className='flags'/> {t('Arabic')}
                   </span>                        
                   <span className={Currentlang=="fr"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"}   onClick={()=>{ModifyLanguage('fr') }} >
                     <img src={fr_flag} width={"21px"} height={"27px"} className='flags'/> {t('France')}
                   </span>
                   <span className={Currentlang=="en"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"} onClick={()=>ModifyLanguage('en')}>
                     <img src={en_flag} width={"21px"} height={"27px"} className='flags'/> {t('English')}
                   </span>
                   <span className={Currentlang=="sp"?"block  cursor-pointer px-4 py-2 text-sm text-gray-700 bg-gray-100":"block  cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"}   onClick={()=>{ModifyLanguage('sp') }} >
                     <img src={es_flag} width={"21px"} height={"27px"} className='flags'/> {t('Spanish')}
                   </span>
                </Dropdown>
        </div>)}
        <BsTranslate className='cursor-pointer mr-3' size={30} onClick={()=>setShowLangs(!showlangs)}/>
        
      </div>
        
     
  </div>
  {
    CallValue?
    <FloatingWhatsApp
    phoneNumber={CallValue}
    accountName={ficheInfo.title}
    avatar={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+ficheInfo?.image}
    allowClickAway={true}
    />
    :

    <></>
  }
 
      </>
        :
        <>
        <PageNotFound/>
        </>  
    }
      </>




  }




    </div>
  )
}
