import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateAccountPassword } from './Actions';
import { toast } from 'react-toastify';
import { handleError } from 'Assistances/ErrorHandling';

export default function PasswordModification() {
    const {t}=useTranslation()
    const [AttributesPassword,setAttributesPassword]=useState({
        "new_password":"",
        "repeat_new_password":""
    })
    function onchangeAttributes(key,value){
        AttributesPassword[key]=value;
        setAttributesPassword({...AttributesPassword})
    }
    function onClickupdateAccountPassword(){
        if(AttributesPassword['new_password']==AttributesPassword['repeat_new_password'] && AttributesPassword['new_password'].length>7){
             updateAccountPassword(AttributesPassword)
            .then((res)=>{
                toast.success(t("Succesfully Updated"))
            })
            .catch((err)=>{
                //console.log(err)
                handleError(err,t,t('Edit'));

            })
        }
        else{
            if(AttributesPassword['new_password']!=AttributesPassword['repeat_new_password'] ){
                toast.error(t("The passwords should be equals"))

            }
            
            
           if(AttributesPassword['new_password'].length<=7){
            toast.error(t("The passwords should be strong >8"))
           
            }
        }
       
    }
  return (
    <div>
         <div className="mt-3">
            <div pageName="Settings" />
    
            <div className="grid grid-cols-5 gap-10">
              <div className="col-span-5 xl:col-span-3">
                <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                  <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                    <h3 className="font-medium text-black dark:text-white">
                      {t('Change password')}
                    </h3>
                  </div>
                  <div className="p-7">
                    <div>
                      
    
                      <div className=" m-3">
                        <label
                          className="mb-3 block text-sm font-medium text-black dark:text-white"
                          htmlFor="new_password"
                        >
                          {t('New Password')}
                        </label>
                        <div className="relative">
                        
                          <input
                            className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                            type="password" maxLength={255}
                            name="password"
                            id="new_password"
                            placeholder={t('New Password')}
                            defaultValue=""
                            onChange={(e)=>onchangeAttributes("new_password",e.target.value)}
                            value={AttributesPassword["new_password"]?AttributesPassword["new_password"]:""}
                       
                          />
                        </div>
                      </div>
                      <div className=" m-3">
                        <label
                          className="mb-3 block text-sm font-medium text-black dark:text-white"
                          htmlFor="repeat_new_password"
                        >
                          {t('Repeat New Password')}
                        </label>
                        <div className="relative">
                        
                          <input
                            className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                            type="password" maxLength={255}
                            name="password"
                            id="repeat_new_password"
                            placeholder={t('Repeat New Password')}
                            defaultValue=""
                            onChange={(e)=>onchangeAttributes("repeat_new_password",e.target.value)}
                            value={AttributesPassword["repeat_new_password"]?AttributesPassword["repeat_new_password"]:""}
                       
                          />
                        </div>
                      </div>
    
    
                      <div className="flex justify-end gap-4  m-3">
                        {/* <button
                          className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                          type="submit"
                        >
                          {t('Cancel')}
                        </button> */}
                        <button
                            onClick={()=>onClickupdateAccountPassword()}
                          className="flex white justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                        >
                          {t('Save')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            
            
          </div>
    </div>
  )
}
