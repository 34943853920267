import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { addProductgrediens, retireProductgrediens } from './Actions';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { toast } from 'react-toastify';
import image from 'Assets/Images/nophoto.png';

import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { handleError } from 'Assistances/ErrorHandling';
import { useNavigate } from 'react-router-dom';
import { setLocalstorage } from 'Assistances/GlobalFunctions';

export default function EditGrediens(props) {
    const { t } = useTranslation();
    const [show,setShow]=useState(0)
    const [showSelected,setShowSelected]=useState(0)
    const [grediens,setGrediens]=useState(props.grediens?props.grediens:[])
    const [product_grediens,setProductGrediens]=useState(props.product_grediens?props.product_grediens:[])
    const[id_product,setIdProduct]=useState(getLocalstorage("product_id"))
    const langs = useSelector(selectLang);

    // const ListImage=[image_bg_1,image_bg_2,image_bg_3,image_bg_4,image_bg_5,image_bg_6,image_bg_7,image_bg_8,image_bg_9,image_bg_10,image_bg_11,image_bg_12,image_bg_13,image_bg_14,image_bg_15]
    function addProductGredien(gredien_id){
        addProductgrediens(id_product,gredien_id,0)
        .then(function(response){
            let listeAddItem=grediens.filter((item)=>item.id===gredien_id)
            let addItem=listeAddItem.length>0?listeAddItem[0]:{};
            addItem.status=0;
            product_grediens[product_grediens.length]=addItem;
            setProductGrediens([...product_grediens])
            // setGrediens([...grediens.filter((item)=>item.id!=gredien_id)])
            toast.success(t("Succesfully Added"))
        })
        .catch(function(error){
            handleError(error,t,t('Add'));
        })
    }
    function addPrincipaleProductGredien(gredien_id){
        addProductgrediens(id_product,gredien_id,1)
        .then(function(response){
            product_grediens.map((item,index)=>{
                if(item.id==gredien_id){
                    product_grediens[index].status=1
                }
            });
            setProductGrediens([...product_grediens])

            toast.success(t("Succesfully Added"))

        })
        .catch(function(error){
            handleError(error,t,t('Add'));
        })
    }
    function RetireGredienFromProduct(gredien_id){
        retireProductgrediens(id_product,gredien_id)
        .then(function(response){
            setProductGrediens([...product_grediens.filter((item)=>item.id!==gredien_id)])
            // setGrediens([...grediens,props.grediens.filter((item)=>item.id===gredien_id)])

            toast.success(t("Succesfully Added"))

        })
        .catch(function(error){
            handleError(error,t,t('Add'));
        })
    }
    function addProductGredienBack(gredien_id){
        addProductgrediens(id_product,gredien_id,0)
        .then(function(response){
            let backlistItems=product_grediens.filter((item)=>item.id==gredien_id);
            let backItem=backlistItems.length>0?backlistItems[0]:{};
            backItem.status=0;
            setProductGrediens([...product_grediens.filter((item)=>item.id!=gredien_id),backItem])

            toast.success(t("Succesfully"))

        })
        .catch(function(error){
            handleError(error,t,t('Add'));
        })
    }
    const history=useNavigate()
    function handleRowClicked(id){
        setLocalstorage('Gredien_id',id)
        history("/dashboard/grediens/fiche")  
      }

  return (
    <div className='container row'>

        <div className='col-lg-4 col-md-6 col-sm-12'>
            <span className='relative line-clamp-1 block max-w-[150px] overflow-hidden truncate'> {t('List of grediens')}</span>

            <div className='list-items-container border'>
                

                    {
                        grediens && grediens.map((item)=>{
                            if(product_grediens.filter(itempg=>itempg.id==item.id).length<=0){
                                return <>
                        <div className="flex cursor-pointer flex-col rounded border border-custom-border-200 bg-custom-background-100">
                            <div className="bg-image-products relative h-[100px] w-full rounded-t ">
                                <div className="absolute inset-0 z-[1] bg-gradient-to-t from-black/60 to-transparent">
                                </div>
                                 
                                <div className="absolute bottom-4 z-10 flex h-10 w-full items-center justify-between gap-3 px-4">
                                    <div className="flex flex-grow items-center gap-2.5 truncate">
                                        <div className="item-center flex h-14 w-14 flex-shrink-0 justify-center rounded bg-white/90">
                                            <span className="flex items-center justify-center">

                                                <img alt={item.image_alt} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/grediens/"+item.image}   onError={(e) => {e.target.src =image}}/>

                                            </span>
                                        </div>
                                        <div className="flex w-full flex-col justify-between gap-0.5 truncate"
                                          data-toggle="tooltip" data-placement="top" 
                                          title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                                          
                                        >
                                            <h3 className="truncate font-semibold text-white">
                                            {getTextByLanguage(item.title,langs)}
                                            </h3>
                                            <span className="flex items-center gap-1.5">
                                                <p className="text-xs font-medium text-white relative line-clamp-1 block max-w-[100px] overflow-hidden truncate">
                                                    {getTextByLanguage(item.description,langs)}
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-shrink-0 items-center gap-2">

                                    
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10"  onClick={()=>addProductGredien(item.id)}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg>

                                    </button>
                                 
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>handleRowClicked(item.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link h-6 w-6 text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                            </>
                            }
                            else{
                                return <></>
                            }
                           
                        })
                    }
                   
                 
            </div>
        </div>
        <div className=' col-lg-4 col-md-6 col-sm-12'>
            <span className='relative line-clamp-1 block max-w-[150px] overflow-hidden truncate'> {t('Selected grediens')}</span>

            <div className='list-items-container border'>
                    {
                        product_grediens.filter((item)=>item.status==0 ).map((item)=>{
                            return <>
                            
                        <div className="flex cursor-pointer flex-col rounded border border-custom-border-200 bg-custom-background-100">
                            <div className="bg-image-products relative h-[100px] w-full rounded-t ">
                                <div className="absolute inset-0 z-[1] bg-gradient-to-t from-black/60 to-transparent">
                                </div>
                                 
                                <div className="absolute bottom-4 z-10 flex h-10 w-full items-center justify-between gap-3 px-4">
                                    <div className="flex flex-grow items-center gap-2.5 truncate">
                                        <div className="item-center flex h-14 w-14 flex-shrink-0 justify-center rounded bg-white/90">
                                            <span className="flex items-center justify-center">

                                                <img alt={item.image_alt} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/grediens/"+item.image}   onError={(e) => {e.target.src =image}}/>

                                            </span>
                                        </div>
                                        <div className="flex w-full flex-col justify-between gap-0.5 truncate"
                                          data-toggle="tooltip" data-placement="top" 
                                          title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                                          
                                        >
                                            <h3 className="truncate font-semibold text-white">
                                                {getTextByLanguage(item.title,langs)}
                                            </h3>
                                            <span className="flex items-center gap-1.5">
                                                <p className="text-xs font-medium text-white relative line-clamp-1 block max-w-[100px] overflow-hidden truncate">
                                                {getTextByLanguage(item.description,langs)}
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-shrink-0 items-center gap-2">

                                    
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>addPrincipaleProductGredien(item.id)}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg>

                                    </button>
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>RetireGredienFromProduct(item.id)}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white"><circle cx="12" cy="12" r="10"></circle><path d="m15 9-6 6"></path><path d="m9 9 6 6"></path></svg>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M12 5v14"></path></svg> */}
                                    
                                    </button>
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>handleRowClicked(item.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link h-6 w-6 text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                       
                            </>
                        })
                    }
                   
            </div>
        </div>
        <div className=' col-lg-4 col-md-6 col-sm-12'>
            <span className='relative line-clamp-1 block max-w-[150px] overflow-hidden truncate'> {t('Principales grediens')}</span>

            <div className='list-items-container border'>
                    {
                        product_grediens && product_grediens.filter((item)=>item.status==1).map((item)=>{
                            return <>

                        <div className="flex cursor-pointer flex-col rounded border border-custom-border-200 bg-custom-background-100">
                            <div className=" bg-image-products relative h-[100px] w-full rounded-t ">
                                <div className="absolute inset-0 z-[1] bg-gradient-to-t from-black/60 to-transparent">
                                </div>
                                 
                                <div className="absolute bottom-4 z-10 flex h-10 w-full items-center justify-between gap-3 px-4">
                                    <div className="flex flex-grow items-center gap-2.5 truncate">
                                        <div className="item-center flex h-14 w-14 flex-shrink-0 justify-center rounded bg-white/90">
                                            <span className="flex items-center justify-center">

                                                <img alt={item.image_alt} src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/grediens/"+item.image}   onError={(e) => {e.target.src =image}}/>

                                            </span>
                                        </div>
                                        <div className="flex w-full flex-col justify-between gap-0.5 truncate"
                                          data-toggle="tooltip" data-placement="top" 
                                          title={getTextByLanguage(item['title'],langs)+': '+getTextByLanguage(item['description'],langs)}
                                          
                                        >
                                            <h3 className="truncate font-semibold text-white">
                                                {getTextByLanguage(item.title,langs)}
                                            </h3>
                                            <span className="flex items-center gap-1.5">
                                                <p className="text-xs font-medium text-white relative line-clamp-1 block max-w-[100px] overflow-hidden truncate">
                                                    {getTextByLanguage(item.description,langs)}

                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex h-full flex-shrink-0 items-center gap-2">
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>addProductGredienBack(item.id)}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white "><path d="M5 12h14"></path><path d="M12 5v14"></path></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-plus h-6 w-6 text-white"><circle cx="12" cy="12" r="10"></circle><path d="m15 9-6 6"></path><path d="m9 9 6 6"></path></svg>
                                    </button>
                                    <button className="flex h-8 w-8 items-center justify-center rounded bg-white/10" onClick={()=>handleRowClicked(item.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link h-6 w-6 text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                            </>
                        })
                    }
                   
            </div>
        </div>
    </div>
  )
}
