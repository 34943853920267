import React, { useEffect, useState } from 'react'

import MenuLeftOptions from 'Componnents/Fiche/MenuLeftOptions'
import SpaceOfDisplay from 'Componnents/Fiche/SpaceOfDisplay'
import TopHead from 'Componnents/Fiche/TopHead'
import { useTranslation } from 'react-i18next'
import { getLocalstorage } from 'Assistances/GlobalFunctions'
import { getFeatureData } from './Actions'
import {  toast } from 'react-toastify';    
import ModalHelper from 'Componnents/Modals/ModalHelper'
import EditInfoFeature from './FeaturesComponnents/EditInfoFeature'
import FormFeatures from './FormFeatures'
import Spinner from 'Spinner/Spinner'
import { handleError } from 'Assistances/ErrorHandling'

export default function FichFeature() {
    const [ficheInfo,setficheInfo]=useState({})
   
    const [ModalShow,setModalShow]=useState(false)
    const { t } = useTranslation();
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
      
        getFeaturesData()
    },[])
    function getFeaturesData(){
        getFeatureData(getLocalstorage("feature_id"))
        .then(function (response) {
            setficheInfo({...response.data['info']})
          
            var defaultItem=menuContent.length>0?menuContent[0]:{};
            defaultItem.componnent=<EditInfoFeature ficheInfo={response.data['info']}/>;
            getClieckedItem(defaultItem)
        }) 
        .catch(function (error) {

            handleError(error,t,t('Features'));
        })
        .finally(() => {
            setSpinner(false)
          });
  
    }
    const menuContent=[
        {
            'title':t('Information'),
            'componnent':<EditInfoFeature ficheInfo={ficheInfo}/>,
            'icon':<i className='fa fa-info icon-left-menu'></i>,
            'path':""
        }
       
       
    ];
    const [Clieckeditem,setClieckeditem]=useState({})

    function getClieckedItem(item){
        setClieckeditem(item)
    }

  return (
    <div className=''>
        
    {spinner?
        <Spinner/> 
        :

        <>
        <TopHead ficheinfo={ficheInfo} pathImage='/features/' path="/dashboard/features"  table="features_descs" id={ficheInfo.id} edit={()=>setModalShow(true)}/>
        <div className='row'>
            <div className='col-md-4 col-lg-3 mt-1'>
                <MenuLeftOptions getClieckedItem={getClieckedItem} title={t("Feature")+" "+ t("Options")} menuContent={menuContent}/>
            </div>
            <div className='col-md-8 col-lg-9 mt-1' >
                <SpaceOfDisplay displayItem={Clieckeditem}/>
            </div>
        </div>
        </>
    }

        <ModalHelper  
        show={ModalShow} 
        title={t("Modify")+" "+ t("Feature")}
        size='lg'
        onHide={() => setModalShow(false)} 
        content={<FormFeatures modifycontent={ficheInfo} onHide={() => setModalShow(false)} getFeaturesData={getFeaturesData}/>} />
        
      
    </div>
  )
}
