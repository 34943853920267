import React, { useEffect, useState } from 'react'
import { deleteAvis, getSingleAvisOfProduct } from './Actions'
import image from "Assets/Images/nophoto.png" 
import { useTranslation } from 'react-i18next'
import image_profile from "Assets/Images/no-profile.png" 
import AlertConfirm, { Button } from "react-alert-confirm";
import { toast } from 'react-toastify'
import Spinner from 'Spinner/Spinner';
import { handleError } from 'Assistances/ErrorHandling'
import { getTextByLanguage } from 'Assistances/GlobalFunctions'
import { useSelector } from 'react-redux'
import { selectLang } from '../Redux/ReduxSlices/langs/LangsSlicer'

export default function FicheComment(props) {
    const {t}=useTranslation()
    const [CommentFiche,setCommentFiche]=useState(null)
    const [spinner,setSpinner]=useState(true)
    const langs = useSelector(selectLang);

    useEffect(()=>{
        getSingleAvisOfProduct(props.Comments.id)
        .then((res)=>{
            setCommentFiche(res.data)
            //console.log(res.data)

        })
        .catch((err)=>{
            //console.log(err)
        })
        .finally(() => {
            setSpinner(false)
          });
    },[])
    const  handleClickConfirm =async (id) => {
                props.onHide()
           const [action] = await AlertConfirm({
               title: t("Confirm"), 
               desc: t("Are you sure that you want to delete the item ?"),
               footer: () => {
                 return (
                   <>
                      <Button  onClick={() =>AlertConfirm.closeAll()} styleType="secondary">
                       {t("Cancel")}
                       </Button>
                     <Button onClick={() => {deleteElement(id,props.table)}} styleType="danger">
                       {t("Delete")}
                     </Button>
                   </>
                 );
               },
             });
           };

           function deleteElement(id,table){
        
            deleteAvis(id)
            .then(function (response) {
                toast.success(t("Succesfully Deleted"))
                AlertConfirm.closeAll()
                props.getAllComments()
            })
            .catch(function (error) {
                //console.log(error)
                handleError(error,t,t('Delete'));
            })
            
        }
  return (
    <div>
              
    {spinner?
      <Spinner/> 
      :
        <>
        {CommentFiche?
        <>
        
      
        <div className='grid grid-cols-2'>
            <div className=''>
                <div className='m-3'>
                    <h3 className='m-3 text-3xl'>
                        {t('Client')}: 
                    </h3>
                    <div className='grid  grid-cols-5 flex'>
                        <div className='flex w-70 h-70'>
                             <img 
                                className='circle-image   bx'
                                src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/accounts/"+CommentFiche.accounts.image} 
                                onError={(e) => {e.target.src =image_profile}}/>
                        </div>
                        <div className='col-span-4 m-2'>
                            {CommentFiche.accounts && CommentFiche.accounts.name?CommentFiche.accounts.name:""}<br/>
                            {CommentFiche.accounts && CommentFiche.accounts.login?CommentFiche.accounts.login:""}

                        </div>
                    </div>


                </div>
            </div>

                <div className=''>
                    <div className='m-3'>
                        <h3 className='m-3 text-3xl'>
                            {t('Store')}: 
                        </h3>
                        <div className='grid  grid-cols-5 flex'>
                            <div className='flex w-70 h-70'>
                                 <img 
                                className='circle-image   bx'
                                src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+CommentFiche.stores.image} 
                                onError={(e) => {e.target.src =image}}/>  
                            </div>
                            <div className='col-span-4 m-2'>
                            {getTextByLanguage(CommentFiche.stores?CommentFiche.stores.title:"",langs)}

                                {/* {CommentFiche.stores?CommentFiche.stores.title:""} */}
                                <br/>
                            {getTextByLanguage(CommentFiche.stores?CommentFiche.stores.url_store:"",langs)}

                                {/* {CommentFiche.stores?CommentFiche.stores.url_store:""} */}
        
                            </div>
                       
                      
                        </div>
                    </div>
                </div>
            
            </div> 

        <hr/>
        <div className='m-3'>
            <h3 className='m-3 text-3xl'>
                {t('Comment')}: 
            </h3>
            <div className=''>
                {CommentFiche?CommentFiche.avis.comment:""}
            </div>

        </div>
        <hr/> 

        <div className='grid justify-center flex text-center'>
           <div className='m-3 justify-center  text-center'>
            <div className='m-1'>
            {t('created at')}: {CommentFiche.avis.created_at} 
            </div>
           <Button onClick={()=>handleClickConfirm(CommentFiche?CommentFiche.avis.rowid_avis_product:-1)} styleType="danger">
                {t("Delete")}
            </Button>
            
           </div>
            
        </div>
        <hr/> 
        
        </>
        :
            <></>
            }
    

        </>
        }
          

    </div>
  )
}
