import React, { useEffect, useState } from 'react'
import image from "Assets/Images/nophoto.png" 
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../Redux/ReduxSlices/langs/LangsSlicer';

export default function Menu(props) {
    function scr(event){
        var navbar = document.getElementById("menu-container");
        var minlogo = document.getElementById("minlogo");
       

        if(navbar && minlogo){
            const winScroll = document.documentElement.scrollTop
            if (winScroll >= 90) {
                minlogo.classList.remove("hiden");

               navbar.classList.add("sticky");
               navbar.classList.add("sticky-after-scroll");

              }
            else {
                minlogo.classList.add("hiden");

               navbar.classList.remove("sticky");
               navbar.classList.remove("sticky-after-scroll");


            }
        }

    }
   
  useEffect(()=>{
    window.addEventListener('scroll', (event) => {
        scr(event)

    });
    window.removeEventListener('scroll', (event) => {
        scr(event)
  
    });   

  },[])
  const [showBlock, SetShowBlock] = useState(false);

  
  function declareHiden(){
    SetShowBlock(false)
  }
  const langs = useSelector(selectLang);

  return (
    <>
    <div>
        <nav id="menu-container" className="menu-container fixed">
            {/* <div className="grid"> */}
                
                <ul className="ul-container">
                    {props.image_position &&
                        langs=="ar"?
                        <>
                        {Array.isArray(props.categories) && props.categories.map(item => {
                            return (
                                <li className="col-link" xs={2} sm={1}  lg={1} md={1} xl={1} key={item["id"]}>
                                    <a className=" link-menu truncate" href={"#"+item["cat_id"]} data-active="false" data-type="primary"
                                        data-toggle="tooltip" data-placement="top" 
                                        title={getTextByLanguage(item.title,langs)}
                                    
                                    >{getTextByLanguage(item["title"],langs)}</a>
                                </li>
                            )
                         })}
                   
                        <li  id="minlogo" className=""   xs={1} sm={1}  lg={1} md={1} xl={1}>
                        <img  id="minlogo"  src={props.info['image'] ?process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+props.info['image']:image}   alt="logo" onError={(e) => {e.target.src =image}} />
                        </li>
                        
                        </>
                        :
                        <>
                         <li  id="minlogo" className=""   xs={1} sm={1}  lg={1} md={1} xl={1}>
                            <img  id="minlogo"  src={props.info['image'] ?process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+props.info['image']:image}   alt="logo" onError={(e) => {e.target.src =image}} />
                        </li>
                        {Array.isArray(props.categories) && props.categories.map(item => {
                            return (
                                <li className="col-link" xs={2} sm={1}  lg={1} md={1} xl={1} key={item["id"]}>
                                    <a className=" link-menu truncate" href={"#"+item["cat_id"]} data-active="false" data-type="primary"
                                    
                                    data-toggle="tooltip" data-placement="top" 
                                    title={getTextByLanguage(item["title"],langs)}
                                    >{getTextByLanguage(item["title"],langs)}</a>

                                    {/* <a className=" link-menu" href={"#"+item["title"]} data-active="false" data-type="primary">{item["title"]}</a> */}
                                </li>
                            )
                         })}
                   
                  
                        </>
                    }
                 
                     
                     
                </ul>
               
        </nav>
    </div>
    </>
  )
}
