import React, { useEffect, useState } from 'react'
import { getGrediens } from './Actions.js'
import {  toast } from 'react-toastify';    
import { useTranslation } from 'react-i18next';
import Datatable from 'Componnents/DataTables/DataTables';
import FormGrediens from './FormGrediens.js';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import { useNavigate  } from 'react-router-dom';
import { setLocalstorage } from 'Assistances/GlobalFunctions.js';
import Spinner from 'Spinner/Spinner.js';
import { handleError } from 'Assistances/ErrorHandling.js';

export default function DataTableGrediens() {
    const [Gredienss,setGredienss]=useState([]);
    const { t } = useTranslation();
    const columns=['id','title','description','in_service',"created_at","updated_at"];
    const [showModalFormGrediens,setShowModalFormGrediens]=useState(false);
    const history=useNavigate();
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
      getAllGrediens()
    },[])
    function getAllGrediens(){
      getGrediens()
      .then(function (response) {
          setGredienss(response.data)
      }) 
      .catch(function (error) {
        handleError(error,t,t('Grediens'));
      })
      .finally(() => {
               setSpinner(false)
             });
    }
    function handleRowClicked(row){
      setLocalstorage('Gredien_id',row.id)
      history("/dashboard/grediens/fiche")  
    }
  return (
    <div>
      {spinner?
      <Spinner/> 
      :
      <Datatable 
      data={Gredienss} 
      columns={columns}
      handleRowClicked={handleRowClicked}
      title="Grediens"
      total={Gredienss.length}
      addbutton={true}
      ShowModal={() => {setShowModalFormGrediens(true)}}
      />
}
      
      <ModalHelper  
        show={showModalFormGrediens} 
        title={t("Add")+" "+ t("Grediens")}
        size='lg'
        onHide={() => setShowModalFormGrediens(false)} 
        content={
        <FormGrediens modifycontent=""
        onHide={() => setShowModalFormGrediens(false)} 
        getAllGrediens={getAllGrediens}
        />}         
        />
        
      
      </div>
  )
}
