import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import {
    MDBBtn,
    MDBContainer,
    MDBCardBody,
    MDBInput,
    MDBCheckbox
  }
  from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';

import logo from "Assets/Images/logo.png" 
import "./Login.css"
import Spinner from 'Spinner/Spinner';
import { RegisterAxios } from './Actions';
export default function Register(props) {
    const { t, i18n } = useTranslation();
    const [accountAttributes,setaccountAttributes]=useState({})
    const [spinner,setSpinner]=useState(true)

    function onchangeAttributes(key,value){
        setaccountAttributes({ ...accountAttributes, [key]: value })
    }
    function RegisterAccount(){
      setSpinner(false)
     
      if(accountAttributes && accountAttributes['name'] && accountAttributes['name'].length>3 &&  accountAttributes['login'] && accountAttributes['login'].length>7  && accountAttributes['password'] && accountAttributes['password'].length>= 8 && accountAttributes && accountAttributes['password']==accountAttributes['password_repeat'])
        {
          //console.log("passe")
        RegisterAxios(accountAttributes['login'],accountAttributes['password'],accountAttributes['name'])
        .then(function (response) {
            //console.log(response)
          
                toast.success(t("Registration passed correctly"))
                props.changeTypeAuth(3)
                setSpinner(true)

        })
        .catch(function (error) {
            toast.error(t("Registration didn't passe correctly"))
            toast.error(error.error)

        
           
        })
        .finally(()=>{
        setSpinner(true)

        });}
        else{
          
          if(!accountAttributes['name'] || accountAttributes['name'].length<3){
            toast.error(t("The name less then 3 charachters"))
            setSpinner(true)
    
          }
          if(!accountAttributes['login'] || accountAttributes['login'].length<7){
            toast.error(t("The email incorrect"))
            setSpinner(true)
    
          }
          if(!accountAttributes['password'] || accountAttributes['password'].length<8){
            toast.error(t("The password should be more than 8 charachers"))
            setSpinner(true)
    
          }
          if(accountAttributes['password']!=accountAttributes['password_repeat']){
            toast.error(t("The repeated password incorrect"))
            setSpinner(true)
    

          }
          // toast.error(t("Register Error"))

          setSpinner(true)

        }
    }
  return (
    <>
    <MDBContainer className='my-5'>

   


    <MDBContainer className='text-center' style={{maxWidth:"550px"}}>
    {!spinner?
      <Spinner/> 
      :
      <>
        <MDBCardBody>
            <img className="rounded-circle" alt="avatar1" src={logo} style={{maxWidth:"200px"}} />
            <h2 className='text-left' style={{textAlign:"left",margin:'17px', fontWeight:"500"}}>{t("Sign up")}</h2>
          <MDBInput wrLoginerclassName='mb-4' label={t("Name")} id='form1' type='name' onChange={(e)=>{onchangeAttributes("name",e.target.value);}}/>
          <MDBInput wrLoginerclassName='mb-4' label={t("Email")} id='form2' type='email'  onChange={(e)=>{onchangeAttributes("login",e.target.value);}}/>
          <MDBInput wrLoginerclassName='mb-4' label={t('Password')} id='form2' type='password'  onChange={(e)=>{onchangeAttributes("password",e.target.value);}}/>
          <MDBInput wrLoginerclassName='mb-4' label={t("Repeat password")} id='form2' type='password'     onChange={(e)=>{onchangeAttributes("password_repeat",e.target.value);}}/>

         
          <MDBBtn className="mb-4 w-100" onClick={()=>RegisterAccount()}>
            {t("Sign up")}
          </MDBBtn>
          <div className="d-flex justify-content-between mx-4 mb-4">
            {t("You have an account?")}
            <span className='register-Link-item' onClick={()=>props.changeTypeAuth(0)}>{t("Login")}</span>
          </div>

        
        </MDBCardBody>
      </>
       
    }
      </MDBContainer>
   
</MDBContainer>

    </>

  )
}
