import React, { useEffect, useState } from 'react';

// import "./../Body.css"
import image from "Assets/Images/nophoto.png" 
import {BsChatLeftTextFill, BsFillQuestionCircleFill, BsQuestion, BsQuestionCircle, BsQuestionCircleFill} from "react-icons/bs";

import { AiFillStar } from "react-icons/ai";
// import RatingStartModal from "../reaction/RatingStarModal";
import { useTranslation } from "react-i18next";
import ModalHelper from 'Componnents/Modals/ModalHelper';
import RatingStarsDiagrams from 'Reactions/RatingStarsDiagrams';
import RatStars from 'Reactions/RatStars';
import AvisGroup from 'Avis/AvisGroup';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { JWTDecode } from 'Assistances/token';
import Login from 'Auth/Login';
import { isTokenExpired } from 'Assistances/GlobalFunctions';
import { GetAccountDataInToken } from 'Assistances/GlobalFunctions';
import { parseTextString } from 'Assistances/GlobalFunctions';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { FaLocationDot } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
export default function StorestoreNew(props) {
   const [AvisShow, setAvisShow] = useState(false);
   const [avis,setAvis]=useState([])
   const [ratingValueState,setratingValue]=useState(0)
   const [numberRatingState,setnumberRating]=useState(0)
   const [showRatingStarModal,setshowRatingStarModal]=useState(false)
   const { t } = useTranslation();
   const [my_ratingstart_inProduct,setmy_ratingstart_inProduct]=useState(0)
   const history=useNavigate()
    // useEffect(()=>{
    //     setAvis(props.avis)
    // },[props.avis])

    function calculateRating(){
        var ratingValue=0;
        var numberRating=0;
        var ratingTotalValue=0;
        var productRatings=props.stars.filter(e=>e.id_store==props.store.id);
        numberRating=productRatings.length;
        for(var i=0;i<(productRatings?productRatings.length:0);i++){
            ratingTotalValue=ratingTotalValue+productRatings[i].value
        }
        ratingValue=numberRating!=0?ratingTotalValue/numberRating:0
        return [numberRating,ratingValue]
    }

    

    function augementratingStartNumbe(value){
        props.augementratingStartNumbeStore(value,props.store.id)
    }

    function changeRatingStars(value){
        setmy_ratingstart_inProduct(value);

    }
    const langs = useSelector(selectLang);
    
    useEffect(()=>{
        if(!isTokenExpired()){
            var myratings=props.stars.filter(e=>e.id_account==GetAccountDataInToken()?.id && e.id_store==props.store.id)
            if(myratings.length>0){
                setmy_ratingstart_inProduct(myratings[0].value);
            }
        }
      
        let ratingValusAndTotal=calculateRating()
        setnumberRating(ratingValusAndTotal[0])
        setratingValue(ratingValusAndTotal[1])

    },[props.stars])

    
  return (
   <div style={{position:"relative"}}>  
   
    <div className='productListing-container-service container'>
        <div className='absolute m-2' style={{zIndex:999}} onClick={()=>props.showstoreModal(props.store)} >
            <BsQuestionCircle  size={23} className='z-10 cursor-pointer'/>
        </div>
        <div className="start-with-rating-value-container m-2">
        
            <div className="start-with-rating-value" onClick={()=>setshowRatingStarModal(true)}>
                <AiFillStar className="start" size={41}>

                </AiFillStar>
               <span className="rating-value">
                    {ratingValueState.toFixed(1)}
                </span> 
            </div>
            
            <span className="number-of-rating-service">{'('+numberRatingState+')'}</span>

        </div>
   
        <div className='productListing-img-container' 
            onClick={()=>{return history('/store/'+props.store.url_store) }} 
        
        > 
            <img src= {props.store?.image?process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/stores/"+props.store.image:image} className="productListing-img" alt="image product"
                                                                    onError={(e) => {e.target.src =image}}

            />
            {/* <span className="rating-start-container text-center">
               
            </span> */}
        </div>    
        <div className='mt-3' >
            
            <div className='productListing-title text-center' 
            
            onClick={()=>{return history('/store/'+props.store.url_store) }} 
            data-toggle="tooltip" data-placement="top" 
            title={getTextByLanguage(props.store['title'],langs)}
            
            >  
            
                {getTextByLanguage(props.store.title,langs)}
              
            </div>
            <RatStars 
                size={22}
                store={true} 
                changeRatingStars={changeRatingStars}  
                showModuleLogin={()=>props.showModuleLogin()}         
                store_id={props.store.id} 
                my_ratingstart_inProduct={my_ratingstart_inProduct} 
                augementratingStartNumbe={augementratingStartNumbe}/>   
        </div>
        <div className="container-desc text-center mt-2 ml-2 mr-2" >
            <div className='productListing-desc text-center'
            onClick={()=>{return history('/store/'+props.store.url_store) }} 
            
            data-toggle="tooltip" data-placement="top" 
            title={getTextByLanguage(props.store['sub_title'],langs)}
            
            >
            {getTextByLanguage(props.store.sub_title,langs)}
            </div>
        </div>

        <footer  className="footer-container-product text-center justify-center">
             
                <div className=' small text-gray-500   text-center'
                
                   
            onClick={()=>{return history('/store/'+props.store.url_store) }} 
            data-toggle="tooltip" data-placement="top" 
            title={(getTextByLanguage(props.store.address,langs))
                +((props.store.address && props.store.address.length>3)? ",":"")
                +(getTextByLanguage(props.store.store_region,langs))
                +(( (props.store.store_region && props.store.store_region.length>0)                &&                (props.store.store_country && props.store.store_country.length>0)) && (","))
                +(getTextByLanguage(props.store.store_country,langs))
            }
                
            
                >
                 {
                 ((props.store.address && props.store.address.length>0)
                 ||
                 (props.store.store_region && props.store.store_region.length>0)
                ||
                (props.store.store_country && props.store.store_country.length>0))
                &&(
                <><FaLocationDot className='mb-2'/>  
                {getTextByLanguage(props.store.address,langs)}{(props.store.address && props.store.address.length>3)? ",":""}
                {getTextByLanguage(props.store.store_region,langs)} {( (props.store.store_region && props.store.store_region.length>0)
                    &&
                    (props.store.store_country && props.store.store_country.length>0)) && (",")} {getTextByLanguage(props.store.store_country,langs)}</>
                 )
                } 
                 
                 
                </div>
          

                {/* <div className='center'>
                    <button type="button" className="mybutton" >{t("Select")}</button>
                </div> */}
        </footer>
    </div>
{/*    
        <ModalHelper
        show={AvisShow}
        onHide={() => setAvisShow(false)}
        // Name={props.Name}
        // product={props.store.id}
        // store_id={props.store_id}
        content={
        <div className='showAvis-groupavis-scroll-container-scroll'>
            <div className='showAvis-groupavis-scroll-container'>
              <AvisGroup 
                // MyreactionsAvis={props.MyreactionsAvis}
                // modifyInReactionInList={props.modifyInReactionInList}
                // reactionsAvis={props.reactionsAvis}
                insertNewAvis={props.insertNewAvis}
                // avis={avis.filter(e=>e.product_id==props.store.product_id)}  
                store_id={props.store_id} 
                product_id={props.store.product_id?props.store.product_id:null}
                onHide={props.onHide} 
                showModuleLogin={()=>props.showModuleLogin()}
                />

            </div>
        </div>
        }


      />
    <ModalHelper
        show={showRatingStarModal}
        onHide={() => setshowRatingStarModal(false)}
        content={<RatingStarsDiagrams store_id={props.store_id} stars={props.stars.filter(e=>e.id_product==props.store.product_id)} my_ratingstart_inProduct={my_ratingstart_inProduct} onHide={() => setshowRatingStarModal(false)} store={props.store} ratingstart={props.store.ratingstart?props.store.ratingstart:[]} numberRatingState={numberRatingState} changeRatingStars={changeRatingStars} augementratingStartNumbe={augementratingStartNumbe}/>}
        store_id={props.store_id}
        size={"md"}
      /> */}
        <ModalHelper
        show={showRatingStarModal}
        onHide={() => setshowRatingStarModal(false)}
        content={<RatingStarsDiagrams
            isstore={true}
             store_id={props.store.id} 
             stars={props.stars.filter(e=>e.id_store==props.store.id)} 
             my_ratingstart_inProduct={my_ratingstart_inProduct}
             onHide={() => setshowRatingStarModal(false)}
             store={props.store} 
             ratingstart={props.store.ratingstart?props.store.ratingstart:[]} 
             numberRatingState={numberRatingState} 
             changeRatingStars={changeRatingStars} 
             augementratingStartNumbe={augementratingStartNumbe}/>}
        store_id={props.store.id}
        size={"md"}
      />
    </div> 
  )
}



