import { getLocalstorage } from "Assistances/GlobalFunctions";
import axios from "axios";




export function deleteElements(id,table){
    return axios({
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/deleteElements',
        data: {"element_id":id,"table":table}, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}
