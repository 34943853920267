
import { GetAccountDataInToken } from "Assistances/GlobalFunctions"
import { getLocalstorage } from "Assistances/GlobalFunctions"
import axios from "axios"

export function getStoresDataByURL(url){

    if(getLocalstorage('access_token'))
        return axios({
            method: 'post',
            url: process.env.REACT_APP_APP_URL_BACKEND+'/getStoresDataByURL',
            data: {'url_store':url,"id_account":GetAccountDataInToken()?.id}, 
            headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')},

        }) 
    else
        return axios({
            method: 'post',
            url: process.env.REACT_APP_APP_URL_BACKEND+'/getStoresDataByURL',
            data: {'url_store':url}, 

        })  
        
}