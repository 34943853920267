


import React, { useEffect, useState } from 'react'
import {  toast } from 'react-toastify';    
import { useTranslation } from 'react-i18next';
import Datatable from 'Componnents/DataTables/DataTables';
// import FormTeams from './FormTeams';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import { getTeams } from './Actions';
import FormTeams from './FormTeams';
import { useNavigate  } from 'react-router-dom';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import Spinner from 'Spinner/Spinner';
import { handleError } from 'Assistances/ErrorHandling';

export default function DataTableTeams() {
    const [Teams,setTeams]=useState([]);
    const { t } = useTranslation();
    const columns=['id','name',"poste","description",'login',"phone","civility_title","gender","updated_at"];
    const [showModalFormTeams,setShowModalFormTeams]=useState(false)
    const history=useNavigate();
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
      getAllTeams()
    },[])
    function getAllTeams(){
      getTeams()
      .then(function (response) {
          setTeams(response.data)
      }) 
      .catch(function (error) {
        handleError(error,t);
      })
      .finally(() => {
        setSpinner(false)
      });
    }
    function handleRowClicked(row){
      //console.log(row)
      setLocalstorage('teams_id',row.id)
      history("/dashboard/teams/fiche")    }
  return (
    <div>
      

    {spinner?
      <Spinner/> 
      :

      <Datatable 
      data={Teams} 
      columns={columns}
      handleRowClicked={handleRowClicked}
      title="Teams"
      total={Teams.length}

      addbutton={true}
      ShowModal={() => {setShowModalFormTeams(true)}}
      />
    }
      
      <ModalHelper  
        show={showModalFormTeams} 
        title={t("Add")+" "+ t("Teams")}
        size='lg'
        onHide={() => setShowModalFormTeams(false)} 
        content={<FormTeams modifycontent='' getAllTeams={getAllTeams} onHide={() => setShowModalFormTeams(false)} />} />
        
      
      </div>
  )
}
