

import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import logo from "Assets/Images/logo.png"

import "./Login.css";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendVerifictionCode, VerificationCode } from './Actions';
import ChangePass from './ChangepPass';

export function ForgotenPass(props) {
    const { t } = useTranslation();
    const history = useNavigate();
    const [otp, setOtp] = useState(["", "", "", "","",""]);  
    const [issend,setIsSend]=useState(false)
    const [Email,setEmail]=useState("")
    const [ChangePasswordComponnetShow,setChangePasswordComponnetShow]=useState(false)

    const handleOtpChange = (value, index) => {
        let newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
    };

    const verifyOtp = (e) => {
        e.preventDefault();
        
        const enteredOtp = otp.join("");

        VerificationCode({"login":Email})
        .then((res)=>{
            if (enteredOtp == res.data) {  
                toast.success(t('OTP verified successfully'));
                setChangePasswordComponnetShow(true);  
                
            } else {
                toast.error(t('Invalid OTP, please try again'));
            }
        })
        .catch(()=>{
            toast.error(t("The Email doesn't exist"));
        })
       
    };
   function sendOptToEmail(e){
    e.preventDefault();
   }
   
   function onSendVerificationCode(){
    sendVerifictionCode({"login":Email})
    .then(()=>{
        setIsSend(true)
        toast.success(t("The OTP Code sent successfully"));

    })
    .catch(()=>{
        toast.error(t("The Email doesn't exist"));
    })
   }
    return (
        
        <div className='container flex justify-center'> 

        {
            ChangePasswordComponnetShow?
                <>
                    <ChangePass email={Email} changeTypeAuth={props.changeTypeAuth}/>
                </>
            :

            <>
              <div className="m-3 w-350px min-h-full min-w-md flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm flex text-center">
                    <img className="mx-auto w-150px h-150px "  src={logo} alt="Your Company" />
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                {
                    issend?

                    <form className="space-y-6" onSubmit={verifyOtp}>
                    <div className="text-center">
                        <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
                            {t('Enter The OTP Code')}
                        </h1>
                        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                            {t('Enter the 6-digit code sent to your email')}
                        </p>
                    </div>

                    <div className="flex justify-center space-x-2">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                type="text" 
                                maxLength={1}
                                value={digit}
                                onChange={(e) => handleOtpChange(e.target.value, index)}
                                className="w-12 h-12 text-center text-lg rounded-md border border-gray-300 focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                                required
                            />
                        ))}
                    </div>

                    <div>
                        <button 
                            type="submit" 
                            className="btn btn-primary flex primary w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold bx leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                       >
                            {t('Verify OTP')}
                        </button>
                    </div>
                    <span className='register-Link-item' onClick={()=>onSendVerificationCode()}                    >{t("Resend Code")}</span>


                    
                </form>

                    :
                    
                    <form className=" space-y-6" onSubmit={sendOptToEmail}>
                        <div className="text">
                            <h1 className="block text-1xl font-bold text-gray-800 dark:text-white">
                                {t('Email')}
                            </h1>
                        </div>

                        <div className="flex justify-center space-x-2">
                                <input
                                    type="text" maxLength={255}
                                    value={Email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className=" rounded-md border border-gray-300 focus:ring-2 w-full focus:ring-indigo-600 focus:border-indigo-600"
                                    required
                                />
                            
                        </div>

                        <div>
                            <button 
                                type="submit" 
                                className="btn btn-primary flex w-full justify-center rounded-md primary px-3 py-1.5 text-sm font-semibold bx leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={()=>onSendVerificationCode()}
                            >
                                {t('Send')}
                            </button>
                        </div>
                    </form>

                        }
                    <p className="mt-10 text-center text-sm text-gray-500 cursor-pointer">
                        <a onClick={()=>props.changeTypeAuth(0)}>{t('Return to login')}</a>
                    </p>
                </div>
            </div>
            
            </>
        }
 
       
          
        </div>
    );
}
