import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import Topmenu from 'Layouts/Menus/Topmenu';
import Sidebarr from 'Layouts/Sidebar/Sidebar';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from 'Spinner/Spinner';
import { boardtableStatics } from 'Statics/BoardTableActions';

export default function LayoutSidebarr(props) {
  const [isSidebarClosed,setisSidebarClosed]=useState(false)
  const { t } = useTranslation();

  const [clickedItem,setclickedItem]=useState({})

  function ChangeisSidebarClosed(value){
    setisSidebarClosed(value)
  }
  function ClickChangeisSidebarClosed(){
    setisSidebarClosed(!isSidebarClosed)

  }
  
  function onClickItemSidebarr(item){
    // setclickedItem({...item})
    // setLocalstorage('menu-icon',safeStringify(item))
        // setLocalstorage('menu-icon',safeStringify(item))
    // // setLocalstorage('menu-icon',JSON.stringify(item.icon))
    // setLocalstorage('menu-path',item.path)
    // setLocalstorage('menu-title',item.title)
   
    // setclickedItem({...{"path":item.path?item.path:"/dashboard","title":item.title?item.title:'Dashboard'}})

   
  }
  const [TotalStatics,setTotalStatics]=useState({})
  const [spinner,setSpinner]=useState(true)

  
  useEffect(()=>{
    // // let icon=JSON.parse(getLocalstorage('menu-icon'));
    // // let path=getLocalstorage('menu-path');
    // // let title=getLocalstorage('menu-title');
    // // let menuItem={"path":path?path:"/dashboard","title":title?title:'Dashboard'}
    // // setclickedItem({...menuItem})
    // boardtableStatics()
    // .then(function(response){
    //     setTotalStatics(response['data'])
    // })
    // .catch(function(error){
    // })
    // .finally(() => {
      setSpinner(false)
    // });
  },[])
  return (
    <>
    {
      spinner?
      <Spinner/>
      :
      <>
       <div className='h-screen w-full overflow-hidden'>
    <div className='relative flex h-screen w-full overflow-hidden'>
      <Sidebarr onClickItemSidebarr={onClickItemSidebarr} ChangeisSidebarClosed={ChangeisSidebarClosed} isSidebarClosed={isSidebarClosed} TotalStatics={TotalStatics}/>

      <main className='relative flex h-full w-full flex-col overflow-hidden bg-custom-background-100'>
      <Topmenu clickedItem={clickedItem} ClickChangeisSidebarClosed={ClickChangeisSidebarClosed} TotalStatics={TotalStatics} AccountInfo={props.AccountInfo}/>

        <div  className='h-full w-full overflow-hidden m2' >
          <div className='relative h-full w-full overflow-x-hidden overflow-y-scroll'>
            <div className='h-full w-full'>
                <div className='relative flex h-full w-full flex-col overflow-hidden'>
                  <div className='relative h-full w-full overflow-auto bg-custom-background-90'>
                    <div style={{margin:"3px"}}>
                      <Outlet />
                    </div> 
                  </div>
                </div>
            </div>
          </div>
            
        </div>
        <div className='container-sidebar'>
            
        </div>
        </main> 
            </div>
          </div>
      
      </>
    }
 
</> )
  
}
