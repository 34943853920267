import React, { useEffect, useState } from 'react'
import { boardtableStaticsStatus } from 'Statics/BoardTableActions'
import { convertDataForBarChart, convertDataForChart, convertDataForPie, palletsColors } from './ConvertDataFunctions'
import LineChartWithXAxisPadding from './LineChartWithXAxisPadding'
import { useTranslation } from 'react-i18next'
import CustomActiveShapePieChart from './CustomActiveShapePieChart'
import StackedBarChart from './StackedBarChart'
import { getDateBackWithoutTime } from 'Assistances/GlobalFunctions'
import Spinner from 'Spinner/Spinner'

export default function Diagrams() {
  const {t}=useTranslation()
  const [OrdersInStores,setOrdersInstores]=useState([])
  const [GlobalOrdersInStores,setGlobalOrdersInstores]=useState([])
  const [avisByproduct,setavisByproduct]=useState([])
  const [GlobalStarsBystores,setGlobalStarsBystores]=useState([])
  const [GlobalStarsByproducts,setGlobalStarsByproducts]=useState([])


function getData(start=getDateBackWithoutTime(0),end=getDateBackWithoutTime(0)){
  setSpinnerShow(true)
  boardtableStaticsStatus(start, end)
  .then((res)=>{
    console.log(res.data)
    let convertData=convertDataForChart(res.data['orders'],'title',"order_server_date","total")
    setOrdersInstores(convertData)
    setavisByproduct(convertDataForPie(res.data['avisByproduct'],"title",'total',palletsColors)) 
    setGlobalOrdersInstores(convertDataForPie(res.data['global_orders'],"title",'total',palletsColors))
    setGlobalStarsBystores(convertDataForBarChart(res.data['ratingstarsBystore'],"title",'value','total',palletsColors))
    setGlobalStarsByproducts(convertDataForBarChart(res.data['ratingstarsByproduct'],"title",'value','total',palletsColors))

    
  })
  .catch((err)=>{

  })
  .finally(() => {
    setSpinnerShow(false)

  });
}
const [start_date,setStartDate]=useState(getDateBackWithoutTime(0))
const [end_date,setEndDate]=useState(getDateBackWithoutTime(0))


  useEffect(()=>{
    getData()
  },[])
  const paletColorBadGood=['#FF0000', '#FFA500', '#FFFF00', '#9ACD32', '#008000']
  const [spinnershow,setSpinnerShow]=useState(true)
  return (
    <>
    {
      spinnershow?
      <Spinner/>
      :

      <>
      
      <div className='flex m-3'>
        <div className='m-3'>
          <h6 className="text-1xl m-1 sm:text-1xl">{t('Start date')}</h6>
          <div className="relative max-w-sm">
            
            <input datepicker id="default-datepicker" type="date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Start date")} value={start_date}  onChange={(e)=>setStartDate(e.target.value)}/>
          </div>
        </div>
        <div className='m-3'>
          <h6 className="m-1 text-1xl sm:text-1xl">{t('End date')}</h6>
          <div className="relative max-w-sm">
           
            <input datepicker id="default-datepicker" type="date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("End date")} value={end_date}  onChange={(e)=>setEndDate(e.target.value)}/>
          </div>
        </div>
        <div className='mt-8'>
          <button className=" mt- text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 mt-3 sm:px-5 sm:py-2.5" onClick={()=>{getData(start_date,end_date)}}>
            {t('Search')}
          </button>
        </div>
      </div>
    
    <div className='grid '>
        <div className='grid lg:grid-cols-3  md:grid-cols-2 sm:grid-cols-1 grid-rows-4'>
          <div className='row-span-2 col-span-2 flex bg-white rounded-xl bx m-1 p-3 overflow-x-scroll'>
              <LineChartWithXAxisPadding title={t("Number of Orders by Stores")}  data={OrdersInStores} palletsColors={palletsColors}/>

           
          </div>
          <div className='row-span-2 flex bg-white rounded-xl bx m-1  p-3 overflow-x-scroll' >
          <CustomActiveShapePieChart title={t("Global Orders In Stores")} data={GlobalOrdersInStores}/>
          </div>
       

        </div>
        <div className='grid lg:grid-cols-1  md:grid-cols-2 sm:grid-cols-1 grid-rows-4'>
          <div className='row-span-2  flex bg-white rounded-xl bx m-1 p-3 overflow-x-scroll'>
              <CustomActiveShapePieChart title={t("Avis by products")}  data={avisByproduct} palletsColors={palletsColors}/>

           
          </div>
         

        </div>
        <div className='grid lg:grid-cols-1  md:grid-cols-1 sm:grid-cols-1 grid-rows-4'>
          <div className='row-span-2  flex bg-white rounded-xl bx m-1 p-3 overflow-x-scroll'>
              <StackedBarChart title={t("Stars by stores")}  data={GlobalStarsBystores} stars={true} palletsColors={paletColorBadGood}/>

           
          </div>
         

        </div>
        <div className='grid lg:grid-cols-1  md:grid-cols-1 sm:grid-cols-1 grid-rows-4'>
          <div className='row-span-2  flex bg-white rounded-xl bx m-1 p-3 overflow-x-scroll'>
              <StackedBarChart title={t("Stars by products")}  data={GlobalStarsByproducts} palletsColors={palletsColors}/>

           
          </div>
         

        </div>
    </div>
      
      </>
    }
       
    </>

  )
}
