import React, { useEffect, useState } from 'react'

import {  toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css'
import { listingMessageServiceCenter, madeReadeServiceCenterMessageAction } from './Actions/ServiceCenterActions';
import ShowMessage from './ShowMessage';
import { useTranslation } from "react-i18next";
import Spinner from 'Spinner/Spinner';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import Datatable from 'Componnents/DataTables/DataTables';



export default function ListingServiceCenterMessages(props) {
    const { t } = useTranslation();

    const [ListingServiceCenterMessages,setListingServiceCenterMessages]=useState([])
    const [MessageSelected,setMessageSelected]=useState([])
    const [showModal,setShowModal]=useState(false)
    const [loading,setLoading]=useState(true)
    const columns=['name','email','subject','description'];

    useEffect(()=>{
        getServiceCenterMessages()
    },[])
    function getServiceCenterMessages(){
            
            listingMessageServiceCenter()
            .then(function (response) {
                setListingServiceCenterMessages([...response['data']])
              
            })
            .catch(function (error) {
                toast.error(t("Error"))
            })
            .finally(()=>{
                setLoading(false)

            });
        
    
        
       
    }
   function madeReade(id){
    madeReadeServiceCenterMessageAction(id)
    .then(function (response) {
       

    })
    .catch(function (error) {
        toast.error(t("Error"))
    });
   }
  return (
    <div id="app-dashboard" className='listing-table-container' >

      {loading?

        <>
        <Spinner/>
        </>
         :
         <>
        <Datatable 
      data={ListingServiceCenterMessages} 
      columns={columns}
      handleRowClicked={
        (row)=>{setShowModal(true);setMessageSelected(row)}
      }
      title="Messages"
      total={ListingServiceCenterMessages.length}

      addbutton={false}
      ShowModal={() => {}}
      />
    

    <ModalHelper
    size="md"
    title={t("Message")}
    show={showModal}
    onHide={()=>setShowModal(false)}
    content={<ShowMessage item={MessageSelected}  />}
    />
    </>
    }
    </div>
  )
}
