
import InfoShow from 'Componnents/Fiche/InfoShow';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

export default function EditInfoFeature(props) {
    const { t } = useTranslation();
  return (
    <div>
       <InfoShow ficheInfo={props.ficheInfo} listToShow={['id','title','unit','description','created_at','updated_at']}/>

    </div>
  )
}
