import React, { useState } from 'react'
import Login from './Login'
import Register from './Register'
import { ForgotenPass } from './ForgotenPass'
import ActiveAccount from './ActiveAccount'

export default function Auth(props) {
    const [type,setType]=useState(0)
  return (
    <div>
        {
            type==0?
            <Login changeTypeAuth={(type)=>setType(type)} tokenValueChange={props.tokenValueChange}/>
            :
            type==1?
            <Register changeTypeAuth={(type)=>setType(type)}/>
            :
            type==2?
            <ForgotenPass changeTypeAuth={(type)=>setType(type)}/>

            :
            type==3?
            <ActiveAccount changeTypeAuth={(type)=>setType(type)}/>

            :

            <></>

        }
    </div>
  )
}
