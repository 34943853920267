




import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    add: {
      





    },
    sp: {
      translation: {
          "Pending":"Pendiente",
          "Processing":"En proceso",
          "Completed":"Completado",
          "Canceled":"Cancelado",
          "Profile Image":"Imagen de perfil",
          "Upload Avatar":"Subir avatar",
          "Service Center": "Centro de Servicio",
          "Service center": "Centro de Servicio",

          "List of Teams": "Lista de Equipos",
          "Selected teams": "Equipos Seleccionados",
          "Visiters": "Visitantes",
          "client name": "Nombre del cliente",
          "visited at": "Visitado en",
          "List of grediens": "Lista de ingredientes",
          "Selected grediens": "Ingredientes Seleccionados",
          "Principales grediens": "Ingredientes Principales",
          "store title": "Título de la tienda",
          "comment": "Comentario",
          "store title": "Título de la tienda",
          "civility title": "Título de cortesía",
          "email": "Correo electrónico",
          "subject": "Asunto",          
          "filtring": "Filtrado",
          Country: "País",
          Region: "Región",
          Categories: "Categorías",
          Rating: "Valoración",
          Currency: "Moneda",
          Filters: "Filtros",
          Clear: "Limpiar",
          Dashboard: "Tablero",
          Login: "Iniciar sesión",
          Logout: "Cerrar sesión",
          Search: "Buscar",
          Avis: "Opiniones",
          Show: "Mostrar",
          Subscripe: "Suscribirse",
          Details: "Detalles",
          "Additions":"Adiciones",
          "You have an account?":"Tienes una cuenta?",
          Description: "Descripción",
          description: "descripción",

          Stores: "Tiendas",
          Products: "Productos",
          products: "productos",

          Characteristics: "Características",
          Configurations: "Configuraciones",
          Filter: "Filtrar",
          Name: "Nombre",
          name: "nombre",
          "Oops, it’s empty! Select the articles you want": "¡Ups, está vacío! Seleccione los artículos que desea",
          "Oops, it’s empty!": "¡Ups, está vacío!",
          "sub title": "sub título",
          "create at": "creado en",
          "updated at": "actualizado en",
          "url store": "URL de la tienda",
          "clients orders": "órdenes de clientes",
          "Clients Orders": "Órdenes de Clientes",
          "comments": "comentarios",
          "Comments": "Comentarios",
          "My Orders": "Mis Órdenes",
          "my orders": "mis órdenes",
          "Teams": "Equipos",
          "teams": "equipos",
          "Number of Orders by Stores": "Número de Órdenes por Tiendas",
          "Global Orders In Stores": "Órdenes Globales en Tiendas",
          "Personal Information": "Información Personal",
          "Full Name": "Nombre Completo",
          "Phone Number": "Número de Teléfono",
          "Email Address": "Dirección de Correo Electrónico",
          "Save": "Guardar",
          "Change password": "Cambiar contraseña",
          "New Password": "Nueva Contraseña",
          "Repeat New Password": "Repetir Nueva Contraseña",
          "SMTP": "SMTP",
          "Domaine Name": "Nombre de Dominio",
          "Email Address": "Dirección de Correo Electrónico",
          "Port": "Puerto",
          "Settings": "Configuraciones",
          "Sign out": "Cerrar Sesión",
          "mobile": "móvil",
          "company": "compañía",
          "There are no records to display": "No hay registros para mostrar",
          "message": "mensaje",
          "Feature": "Característica",
          "feature": "característica",
          "Features": "Características",
          "Toppings": "Ingredientes",
          "Grediens": "Ingredientes",
          "Categoraisation": "Categorización",
          "Catalogue Images": "Imágenes de Catálogo",
          "Social Média": "Redes Sociales",
          "Information": "Información",
          Password:"Contraseña",
          "Sign up":"Registrarse",
          "Components":"Componentes",
          "Internal Servers":"Servidores internos",
          "Add Account":"Agregar Cuenta",
          "created at": "creado en",
          "Select": "Seleccionar",
          "select": "seleccionar",
          "Avis": "Avis",
          "Add to basket": "Agregar al carrito",
          "send": "enviar",
          "Send": "Enviar",
          "Phone": "Teléfono",
          "Message": "Mensaje",
          Register: "Registrarse",
          Account:"Cuenta",
          "Sign in": "Iniciar sesión",
          "Password incorrect! try again": "¡Contraseña incorrecta! Inténtalo de nuevo",
          "Email incorrect!": "¡Correo electrónico incorrecto!",
          "Login Incorrect check your email or password": "Inicio de sesión incorrecto, verifica tu correo electrónico o contraseña",
          "You dont have account?": "¿No tienes una cuenta?",
          "Password not equal to password!": "¡La contraseña no coincide con la contraseña!",
          "Again": "Otra vez",
          "Try strong password for more security!": "¡Prueba una contraseña fuerte para mayor seguridad!",
          "Name incorrect!": "¡Nombre incorrecto!",
          "Creater": "Creador",
          "Admin": "Admin",
          "Level": "Nivel",
          "Status": "Estado",
          "Last": "Último",
          "Date": "Fecha",
          "Super": "Super",
          "User": "Usuario",
          "Permissions": "Permisos",
          "Disable": "Desactivar",
          "Enable": "Habilitar",
          "All": "Todo",
          "None": "Ninguno",
          "Rights": "Derechos",
          "Passwords don't equals": "Las contraseñas no coinciden",
          "Profile": "Perfil",
          "Profiles": "Perfils",

          "This form allows you to Modify your account , use the 'Modify Profile' button found on the profile card.": "Este formulario te permite modificar tu cuenta. Usa el botón 'Modificar perfil' que se encuentra en la tarjeta de perfil.",
          "Click to update": "Haz clic para actualizar",
          "Save Changes": "Guardar cambios",
          "Email": "Correo electrónico",
          "Change": "Cambiar",
          "Try strong password for more security!": "¡Prueba una contraseña fuerte para mayor seguridad!",
          "Repeat Password": "Repetir contraseña",
          "Doesn't equal to password!": "No coincide con la contraseña",
          "Subsribtion": "Suscripción",
          "Type": "Tipo",
          "The next date of payment": "La próxima fecha de pago",
          "Show less": "Mostrar menos",
          "availability": "disponibilidad",
          "A new order internal": "Un nuevo pedido interno",
          "A new order external": "Un nuevo pedido externo",
          "Units": "Unidades",
          "units": "Unidades",
          "unit": "Unidade",
          "Unit": "Unidade",

          "Sum": "Suma",
          "Total": "Total",
          "Refus": "Rechazar",
          "Accept": "Aceptar",
          "Rejected": "Rechazado",
          "How many": "Cuántos",
          "waiting": "esperando",
          "List of internal manage": "Lista de gestión interna",
          "Add an internal manage": "Agregar una gestión interna",
          "Table": "Mesa",
          "Link Expired. Try To Create Another.": "Enlace caducado. Intenta crear otro.",
          "QR Code": "Código QR",
          "Ref": "Referencia",
          "Annoncces": "Anuncios",
          "id": "id",
          "Welcome to": "Bienvenido a",
          "Thank you for registering! An activation email has been sent to your email address. To access all features, follow these steps": "Gracias por registrarte. Se ha enviado un correo de activación a tu dirección de correo electrónico. Para acceder a todas las funciones, sigue estos pasos",
          "Check Your Email": "Verifica tu correo electrónico",
          "Please check your inbox for an email from Zselling! with the subject": "Por favor, revisa tu bandeja de entrada para un correo de Zselling! con el asunto",
          "Activate Your Account Now.": "Activa tu cuenta ahora",
          "Click the Activation Link": "Haz clic en el enlace de activación",
          "Open the email and click the activation link to verify your email address and activate your account.": "Abre el correo electrónico y haz clic en el enlace de activación para verificar tu dirección de correo electrónico y activar tu cuenta.",
          "Start Exploring": "Empieza a explorar",
          "Once activated, log in and start enjoying everything Zselling! has to offer.": "Una vez activada, inicia sesión y comienza a disfrutar de todo lo que Zselling! tiene para ofrecer.",
          "Didn't receive the activation email? Check your spam/junk folder, as it might be filtered.": "¿No recibiste el correo de activación? Revisa la carpeta de spam, ya que podría estar filtrado.",
          "Need Help? Contact our support team at [Support Email/Contact]. We're here to assist you!": "¿Necesitas ayuda? Contacta a nuestro equipo de soporte en [Correo de soporte/Contacto]. ¡Estamos aquí para ayudarte!",
          "click to show more option in the list": "haz clic para mostrar más opciones en la lista",
          "Retire": "Retirar",
          "Products Blongs To Categorie": "Productos pertenecen a la categoría",
          "Retired Successfully": "Retirado exitosamente",
          "Url": "URL",
          "Logo": "Logotipo",
          "Add Products with Categorie to Store": "Agregar productos con categoría a la tienda",
          "Add Head Annonces to Store": "Agregar anuncios principales a la tienda",
          "Add Categorie to Store": "Agregar categoría a la tienda",
          "Successfully copied": "Copiado exitosamente",
          "Order Article": "Ordenar artículo",
          "Order From Stores": "Ordenar desde tiendas",
          "Order From Table": "Ordenar desde la mesa",
          "Back": "Volver",
          "Principales": "Principales",
          "Secondaries": "Secundarios",
          "Customize": "Personalizar",
          "Message": "Mensaje",
          "Add to basket": "Agregar al carrito",
          "Subject": "Asunto",
          "Explanation": "Explicación",
          "Send": "Enviar",
          "Date": "Fecha",
          "Close": "Cerrar",
          "Principals": "Principales",
          "Catalogue": "Catálogo",
          "Images": "Imágenes",
          "Image": "Imagen",
          "Successfully Deleted": "Eliminado exitosamente",
          "This action will delete the additons and the relation in products!": "¡Esta acción eliminará las adiciones y la relación en los productos!",
          "Confirm": "Confirmar",
          "max recommended is 6": "máximo recomendado es 6",
          "Features": "Características",
          "New": "Nuevo",
          "Successfully": "Exitosamente",
          "Added": "Agregado",
          "Modified": "Modificado",
          "Error": "Error",
          "Succesfully Added": "Añadido con éxito",
          "Succesfully Modified": "Modificado con éxito",
          "The attributes with * are obligatory.": "Los atributos con * son obligatorios.",
          "Show more": "Mostrar más",
          "Available": "Disponible",
          "Reel Price": "Precio real",
          "Price": "Precio",
          "Promotion Price": "Precio promocional",
          "Attributs": "Atributos",
          "Components": "Componentes",
          "Are you sure that you want to delete the item ?": "¿Estás seguro de que deseas eliminar el elemento?",
          "Social Media": "Redes sociales",
          "Delete": "Eliminar",
          "Copy": "Copiar",
          "big": "grande",
          "medium": "mediano",
          "small": "pequeño",
          "Listing": "Listado",
          "Orders don't read": "Los pedidos no se leen",
          "Orders": "Pedidos",
          "Modify": "Modificar",
          "Add": "Agregar",
          "Annonces": "Anuncios",
          "Title": "Título",
          "title": "título",

          "Accounts": "Cuentas",
          "Add Accounts": "Agregar cuentas",
          "Messages box": "Buzón de mensajes",
          "Head Annonces": "Anuncios principales",
          "Account Manager": "Administrador de cuentas",
          "Manage Products": "Gestionar productos",
          "Manage stores": "Gestionar tiendas",
          "Services center": "Centro de servicios","Principals": "Principales",
          "Catalogue": "Catálogo",
          "Images": "Imágenes",
          "Image": "Imagen",
          "Successfully Deleted": "Eliminado con éxito",
          "This action will delete the additons and the relation in products!": "¡Esta acción eliminará las adiciones y la relación en los productos!",
          "Confirm": "Confirmar",
          
          "max recommended is 6": "máximo recomendado es 6",
          "Features":"Características",
          "New": "Nuevo",
          "Successfully": "Exitosamente",
          "Added": "Añadido",
          "Modified": "Modificado",
          "Error": "Error",
          "Succesfully Added":"Añadido con éxito",
          "Succesfully Modified":"Modificado con éxito",
          "The attributes with * are obligatory.": "Los atributos con * son obligatorios.",
          "Show more": "Mostrar más",
          
          "Available": "Disponible",
          "Reel Price": "Precio real",
          "Price":"Precio",
          "Promotion Price": "Precio Promocional",
          "Attributs":"Atributos",
          "Components":"Componentes",
          "Are you sure that you want to delete the item ?": "¿Estás seguro de que quieres eliminar el elemento?",
          "Social Media": "Redes sociales",
          "Delete": "Eliminar",
          "Copy": "Copiar",
          "big": "grande",
          "medium": "mediano",
          "small": "pequeño",
          "Listing": "Listado",
          "Orders don't read": "Las órdenes no se leen",
          "Orders": "Pedidos",
          "Modify": "Modificar",
          "Add": "Agregar",
          "Annonces": "Anuncios",
          "Title": "Título",
          "Accounts":"Cuentas",
          "Add Accounts":"Agregar Cuentas",
          "Messages box":"Bandeja de mensajes",
          "Head Annonces":"Encabezado Anuncios",
          "Account Manager":"Gerente de cuenta",
          "Manage Products":"Gestionar productos",
          "Manage stores":"Gestionar tiendas",
          "Services center":"Centro de servicios",
          "Electronics" : "Electrónica",
          "Clothing" : "Ropa",
          "Groceries" : "Comestibles",
          "Home Improvement" : "Mejoras para el hogar",
          "Beauty" : "Belleza",
          "Sports and Outdoors" : "Deportes y aire libre",
          "Books and Stationery" : "Libros y papelería",
          "Toys and Games" : "Juguetes y juegos",
          "Health and Wellness" : "Salud y bienestar",
          "Automotive" : "Automoción",
          "Furniture" : "Muebles",
          "Jewelry" : "Joyas",
          "Pet Supplies" : "Suministros para mascotas",
          "Music and Instruments" : "Música e instrumentos",
          "Art and Craft Supplies" : "Arte y manualidades",
          "Electrical Appliances" : "Electrodomésticos",
          "Kitchenware" : "Utensilios de cocina",
          "Baby and Maternity" : "Bebé y maternidad",
          "Party Supplies" : "Suministros para fiestas",
          "Travel and Luggage" : "Viajes y equipaje",
          "Office Supplies" : "Suministros de oficina",
          "Movies and Entertainment" : "Películas y entretenimiento",
          "Tools and Hardware" : "Herramientas y ferretería",
          "Tech Gadgets" : "Gadgets tecnológicos",
          "Fitness and Exercise" : "Fitness y ejercicio",
          "Food and Beverages" : "Alimentos y bebidas",
          "Footwear" : "Calzado",
          "Hobbies and Collectibles" : "Pasatiempos y coleccionables",
          "Home Decor" : "Decoración del hogar",
          "Outdoor Equipment" : "Equipo al aire libre",
          "Vintage and Antiques" : "Antigüedades y vintage",
          "School Supplies" : "Material escolar",
          "Outdoor Furniture" : "Muebles de exterior",
          "Bedding and Linens" : "Ropa de cama y textiles",
          "DIY Materials" : "Materiales de bricolaje",
          "Camping Gear" : "Equipo de camping",
          "Cookware" : "Utensilios de cocina",
          "Personal Care Products" : "Productos de cuidado personal",
          "Gardening Supplies" : "Suministros de jardinería",
          "Appliances" : "Electrodomésticos",
          "Party Decorations" : "Decoraciones para fiestas",
          "Baby Gear" : "Equipo para bebés",
          "Watches" : "Relojes",
          "Board Games" : "Juegos de mesa",
          "Musical Instruments" : "Instrumentos musicales",
          "Smart Home Devices" : "Dispositivos para el hogar inteligente",
          "Fitness Equipment" : "Equipo de fitness",
          "Puzzles and Brainteasers" : "Rompecabezas y acertijos",
          "Bathroom Essentials" : "Artículos esenciales para el baño",
          "Craft Kits" : "Kits de manualidades",
          "Wearable Technology" : "Tecnología ponible",
          "Collectible Toys" : "Juguetes coleccionables",
          "Baking Supplies" : "Suministros de repostería",
          "Auto Parts" : "Piezas de automóviles",
          "Educational Toys" : "Juguetes educativos",
          "Outdoor Toys" : "Juguetes al aire libre",
          "Cycling Gear" : "Equipo de ciclismo",
          "Home Office Supplies" : "Suministros de oficina en casa",
          "Cleaning Supplies" : "Suministros de limpieza",
          "Skincare Products" : "Productos para el cuidado de la piel",
          "Haircare Products" : "Productos para el cuidado del cabello",
          "Pet Food" : "Comida para mascotas",
          "Cooking Utensils" : "Utensilios de cocina",
          "Fashion Accessories" : "Accesorios de moda",
          "Camping Supplies" : "Suministros de camping",
          "Fitness Apparel" : "Ropa de fitness",
          "Party Favors" : "Recuerdos de fiesta",
          "Novels" : "Novelas",
          "DIY Kits" : "Kits de bricolaje",
          "Outdoor Clothing" : "Ropa de exterior",
          "Home Security Systems" : "Sistemas de seguridad para el hogar",
          "Laptop Accessories" : "Accesorios para portátiles",
          "Camera Gear" : "Equipo de cámara",
          "Home Entertainment Systems" : "Sistemas de entretenimiento para el hogar",
          "Swimwear" : "Ropa de baño",
          "Plant Care Supplies" : "Suministros para el cuidado de las plantas",
          "Coffee and Tea" : "Café y té",
          "DIY Tools" : "Herramientas de bricolaje",
          "Collectible Cards" : "Cartas coleccionables",
          "Baby Clothes" : "Ropa de bebé",
          "Outdoor Games" : "Juegos al aire libre",
          "Camping Accessories" : "Accesorios de camping",
          "Cookbooks" : "Libros de cocina",
          "Car Accessories" : "Accesorios para coches",
          "Collectible Figurines" : "Figuras coleccionables",
          "Painting Supplies" : "Suministros de pintura",
          "Sewing Supplies" : "Suministros de costura",
          "Patio Furniture" : "Muebles de patio",
          "Hiking Gear" : "Equipo de senderismo",
          "Fishing Gear" : "Equipo de pesca",
          "Archery Supplies" : "Suministros de tiro con arco",
          "Costumes" : "Disfraces",
          "Yoga Accessories" : "Accesorios de yoga",
          "Board Game Accessories" : "Accesorios de juegos de mesa",
          "Photography Equipment" : "Equipo de fotografía",
          "Outdoor Lighting" : "Iluminación exterior",
          "Vintage Clothing" : "Ropa vintage",
          "Running Gear" : "Equipo de carrera",
          "Television Accessories" : "Accesorios de televisión",
          "Board Game Expansions" : "Expansiones de juegos de mesa",
          "Fencing Equipment" : "Equipo de esgrima",
          "Model Kits" : "Kits de modelos",
          "Audio Equipment" : "Equipo de audio",
          "Pottery Supplies" : "Suministros de cerámica",
          "Astrology Services" : "Servicios de astrología",
          "Building Blocks" : "Bloques de construcción",
          "Comic Books" : "Cómics",
          "Stuffed Animals" : "Peluches",
          "Scuba Diving Gear" : "Equipo de buceo",
          "Virtual Reality Gear" : "Equipo de realidad virtual",
          "Coin Collecting Supplies" : "Suministros para la colección de monedas",
          "Magic Supplies" : "Suministros de magia",
          "Quilting Supplies" : "Suministros de acolchado",
          "Aviation Gear" : "Equipo de aviación",
          "Robotics Kits" : "Kits de robótica",
          "Model Trains" : "Trenes en miniatura",
          "Rock Climbing Equipment" : "Equipo de escalada en roca",
          "Skiing and Snowboarding Gear" : "Equipo de esquí y snowboard",
          "Leatherworking Supplies" : "Suministros para trabajar el cuero",
          "Home Brewing Kits" : "Kits de elaboración de cerveza casera",
          "Plant Seeds and Bulbs" : "Semillas y bulbos de plantas",
          "Chess Sets" : "Juegos de ajedrez",
          "Golf Equipment" : "Equipo de golf",
          "Wine and Spirits" : "Vino y licores",
          "Cosplay Supplies" : "Suministros de cosplay",
          "Tarot Cards" : "Cartas del tarot",
          "Candle Making Supplies" : "Suministros para hacer velas",
          "Metalworking Tools" : "Herramientas para trabajar metales",
          "Stargazing Telescopes" : "Telescopios para observar las estrellas",
          "Calligraphy Kits" : "Kits de caligrafía",
          "Beekeeping Supplies" : "Suministros de apicultura",
          "Bonsai Tree Kits" : "Kits de bonsái",
          "Muscle Building Supplements" : "Suplementos para la construcción muscular",
          "Cancel":"Cancelar",
          "By":"Por",
          "Bronze Package":"Paquete Bronce",
          "Gold Package":"Paquete Oro",
          "Platinum Package":"Paquete Platino",
          "Month":"Mes",
          "Year":"Año",
          "Arabic": "Árabe",
          "France": "Francia",
          "English": "Inglés",
          "Spanish": "Español",
          "List of products": "Lista de productos",
          "Selected products": "Productos seleccionados",
          "List of categories": "Lista de categorías",
          "Selected categories": "Categorías seleccionadas",
          "web": "web",
          "Espace Client": "Espacio Cliente",
          "All Rights Reserved": "Todos los Derechos Reservados",
          "Product Options": "Opciones de Producto",
          "statics": "estáticos",
          "Statics": "Estáticos",

          "Store Options": "Opciones de Tienda",
          "Product Options": "Opciones de Producto",
          "Call": "Llamar",
          "Categorie Options": "Opciones de Categoría",
          "Store": "Tienda",
          "Options": "Opciones",
          "Product": "Producto",
          "Gredien": "Ingrediente",
          "Topping": "Cobertura",
          "Annonce": "Anuncio",
          "Feature": "Característica",
          "Categorie": "Categoría",
          "Order": "Orden",
          "Client": "Cliente",
          "Articles": "Artículos",
          "Rejected Grediens": "Ingredientes rechazados",
          "login": "Iniciar sesión",
          "Civility title": "Título de civilidad",
          "Gender": "Género",
          "Upload": "Subir",
          "Mal": "Masculino",
          "Femal": "Femenino",
          "Enable": "Habilitar",
          "Disable": "Deshabilitar",
          "Team": "Equipo",
          "Poste": "Puesto",
          "Sell price":"Precio de venta",
          "Avis by products": "Opiniones por productos",
          "Stars by stores": "Estrellas por tiendas",
          "Stars by products": "Estrellas por productos",
          
          "Start date": "Fecha de inicio",
          "End date": "Fecha de fin",
          "List of annonces": "Lista de anuncios",
          "Selected Annonces": "Anuncios seleccionados",
          "Promence price": "Precio promocional",
          "Reel price": "Precio real",
          "Messages":"Mensajes", 
          "Send To": "Enviar a",
          "Body": "Cuerpo",
          "Test": "Prueba",
          "SMTP Test": "Prueba SMTP",
          "Successfully sent": "Enviado con éxito",
          "Send Error": "Error al enviar",
          "Repeat password": "Repetir contraseña",
          "Forgot password?":"¿Olvidaste tu contraseña?",
          
          "Return to login":          "Volver al inicio de sesión",
          
          "Account activated try to login":          "Cuenta activada, intenta iniciar sesión",
          
          "OTP verified successfully":          "Código OTP verificado con éxito",
          
          "Invalid OTP, please try again":          "OTP no válido, por favor inténtalo de nuevo",
          
          "The Email doesn't exist":          "El correo electrónico no existe",
          
          "The OTP Code sent successfully":          "El código OTP se envió con éxito",
          
          "Enter The OTP Code":          "Introduce el código OTP",
          
          "Enter the 6-digit code sent to your email":          "Introduce el código de 6 dígitos enviado a tu correo electrónico",
          
          "Verify OTP":          "Verificar OTP",
          
          "Resend Code":          "Reenviar código"
                    
          
      }
  },
    
      ar: {
        translation: {
          "Pending":"قيد الانتظار",
          "Processing":"جارٍ المعالجة",
          "Completed":"مكتمل",
          "Canceled":"ملغى",
          "Profile Image":"صورة الملف الشخصي",
          "Upload Avatar":"رفع الصورة الرمزية",
            filtring: "تصفية",
            Country: "بلد",
            Region: "منطقة",
            Categories: "فئات",
            Rating: "تقييم",
            Currency: "عملة",
            Filters: "فلاتر",
            Filter: "فلتر",
            "Filter":"فلتر",
            Clear: "مسح",
            Dashboard: "لوحة القيادة",
            Login: "تسجيل الدخول",
            Logout: "تسجيل الخروج",
            Search: "بحث",
            Avis: "آراء",
            Show: "عرض",
            Subscripe: "اشتراك",
            Details: "تفاصيل",
            Description: "وصف",
            description: "وصف",

            Stores: "متاجر",
            Store: "متجر",

            stores: "متاجر",

            Products: "منتجات",
            products: "منتجات",

            Characteristics: "خصائص",
            Configurations: "تكوينات",
            Configuration: "تكوينات",
            "created at": "تم الإنشاء في",
            "Select": "اختيار",
            "select": "اختيار",
            "Avis": "آراء",
            "Add to basket": "إضافة إلى السلة",
            "send": "إرسال",
            "Send": "إرسال",
            "Phone": "هاتف",
            "Message": "رسالة",
            Name: "اسم",
            name: "اسم",
            Password:"كلمة السر",
            Register: "تسجيل",
            Account:"حساب",
            "Add Account":"إضافة حساب",
            "Oops, it’s empty! Select the articles you want": "عذرًا، هذا فارغ! حدد المقالات التي ترغب فيها",
            "Oops, it’s empty!": "عذرًا، هذا فارغ!",
            "sub title": "العنوان الفرعي",
            "create at": "تم الإنشاء في",
            "updated at": "تم التحديث في",
            "url store": "رابط المتجر",
            "clients orders": "طلبات العملاء",
            "Clients Orders": "طلبات العملاء",
            "comments": "تعليقات",
            "Comments": "تعليقات",
            "My Orders": "طلباتي",
            "my orders": "طلباتي",
            "Teams": "الفرق",
            "teams": "الفرق",
            "Number of Orders by Stores": "عدد الطلبات حسب المتاجر",
            "Global Orders In Stores": "الطلبات العالمية في المتاجر",
            "Personal Information": "المعلومات الشخصية",
            "Full Name": "الاسم الكامل",
            "Phone Number": "رقم الهاتف",
            "Email Address": "عنوان البريد الإلكتروني",
            "Save": "حفظ",
            "Change password": "تغيير كلمة المرور",
            "New Password": "كلمة مرور جديدة",
            "Repeat New Password": "تكرار كلمة المرور الجديدة",
            "SMTP": "SMTP",
            "Domaine Name": "اسم النطاق",
            "Email Address": "عنوان البريد الإلكتروني",
            "Port": "منفذ",
            "Settings": "الإعدادات",
            "Sign out": "تسجيل الخروج",
            "mobile": "موبايل",
            "company": "شركة",
            "There are no records to display": "لا توجد سجلات لعرضها",
            "message": "رسالة",
            "Feature": "ميزة",
            "feature": "ميزة",
            "Features": "ميزات",
            "Toppings": "إضافات",
            "Grediens": "مكونات",
            "Categoraisation": "تصنيف",
            "Catalogue Images": "صور الكتالوج",
            "Social Média": "وسائل التواصل الاجتماعي",
            "Information": "معلومات",
            "max recommanded is 6":"الحد الأقصى الموصى به هو 6",
            "Internal Servers":"الخوادم الداخلية",
          "Additions":"إضافات",
          "Features":"ميزات ",
            "You have an account?":"هل لديك حساب؟",
            "Sign up":"سجل",
            "Services center":"مركز الخدمات",
            "Sign in": "تسجيل الدخول",
            "Password incorrect! try again": "كلمة مرور غير صحيحة! حاول مرة أخرى",
            "Email incorrect!": "بريد إلكتروني غير صحيح!",
            "Login Incorrect check your email or password": "تسجيل الدخول غير صحيح، تحقق من بريدك الإلكتروني أو كلمة المرور",
            "You dont have account?": "لا تمتلك حساب؟",
            "Password not equal to password!": "كلمة المرور لا تتطابق مع كلمة المرور!",
            "Again": "مرة أخرى",
            "Try strong password for more security!": "جرب كلمة مرور قوية لمزيد من الأمان!",
            "Name incorrect!": "اسم غير صحيح!",
            "Creater": "منشئ",
            "Admin": "مشرف",
            "Level": "مستوى",
            "Status": "حالة",
            "Last": "الأخير",
            "Date": "تاريخ",
            "Super": "سوبر",
            "User": "مستخدم",
            "Permissions": "صلاحيات",
            "Disable": "تعطيل",
            "Enable": "تمكين",
            "All": "الكل",
            "None": "لا شيء",
            "Rights": "حقوق",
            "Passwords don't equals": "كلمات المرور غير متطابقة",
            "Profile": "ملف",
            "Profiles": "الملفات الشخصية",

            "This form allows you to Modify your account , use the 'Modify Profile' button found on the profile card.": "هذا النموذج يسمح لك بتعديل حسابك، استخدم زر 'تعديل الملف الشخصي' الموجود على بطاقة الملف.",
            "Click to update": "انقر للتحديث",
            "Save Changes": "حفظ التغييرات",
            "Email": "البريد الإلكتروني",
            "Change": "تغيير",
            "Try strong password for more security!": "جرب كلمة مرور قوية لمزيد من الأمان!",
            "Repeat Password": "تكرار كلمة المرور",
            "Doesn't equal to password!": "لا يتطابق مع كلمة المرور",
            "Subsribtion": "اشتراك",
            "Type": "نوع",
            "The next date of payment": "تاريخ الدفع التالي",
            "Show less": "عرض أقل",
            "availability": "توفر",
            "A new order internal": "طلب داخلي جديد",
            "A new order external": "طلب خارجي جديد",
            "Units": "وحدات",
            "units": "وحدات",
            "unit": "وحدة",
            "Unit": "وحدة",

            "Sum": "مجموع",
            "Total": "الإجمالي",
            "Refus": "رفض",
            "Accept": "قبول",
            "Rejected": "مرفوض",
            "How many": "كم",
            "waiting": "انتظار",
            "List of internal manage": "قائمة إدارة داخلية",
            "Add an internal manage": "إضافة إدارة داخلية",
            "Table": "طاولة",
            "Link Expired. Try To Create Another.": "انتهت صلاحية الرابط. حاول إنشاء رابط آخر.",
            "QR Code": "رمز الاستجابة السريعة",
            "Ref": "الرقم المرجعي",
            "Annoncces": "إعلانات",
            "ID": "الهوية",
            "id": "الهوية",
            "Error during action:":"خطأ أثناء ",
            "Bad Request. Please check your input.": "طلب غير صالح. يرجى التحقق من الإدخال الخاص بك.",
            "Unauthorized. Please log in.":"غير مصرّح لك. يرجى تسجيل الدخول.",
            "Payment Required. Please update your payment method.": "الدفع مطلوب. يرجى تحديث طريقة الدفع الخاصة بك.",
            "Forbidden. You don't have permission.":"محظور. ليس لديك الصلاحية.",
            "Not Found. The resource doesn't exist.": "غير موجود. المورد غير موجود.",
            "Method Not Allowed. The HTTP method is not allowed for this resource.":"الطريقة غير مسموح بها لهذا المورد",
            "Not Acceptable. The requested resource is not available in the requested format.": "غير مقبول. المورد المطلوب غير متوفر في الصيغة المطلوبة.",
            "Request Timeout. The server timed out waiting for the request.":"انتهت مهلة الطلب. تجاوز الخادم الوقت المسموح لانتظار الطلب.",
            "Conflict. There was a conflict with the current state of the resource.": "تعارض. حدث تعارض مع الحالة الحالية للمورد",
            "Gone. The resource is no longer available.":"غير متوفر. لم يعد المورد متاحًا",
            "Unsupported Media Type. The request format is not supported by the server.": "نوع الوسائط غير مدعوم. الصيغة المطلوبة غير مدعومة من قبل الخادم",
            "Too Many Requests. You have sent too many requests in a given amount of time.":"الكثير من الطلبات. لقد قمت بإرسال الكثير من الطلبات في فترة زمنية معينة", 
            "Internal Server Error. Please try again later.": "خطأ داخلي في الخادم. يرجى المحاولة مرة أخرى لاحقًا",
            "Not Implemented. The server does not support the functionality required to fulfill the request.": "غير مُنفَّذ. الخادم لا يدعم الوظيفة المطلوبة لإكمال الطلب",
            "Bad Gateway. The server received an invalid response from the upstream server.": "بوابة غير صالحة. تلقى الخادم استجابة غير صالحة من الخادم الأعلى",
            "Service Unavailable. The server is currently unable to handle the request.": "الخدمة غير متاحة. الخادم غير قادر حاليًا على معالجة الطلب",
            "Gateway Timeout. The upstream server failed to send a request in the time allowed by the server.": "انتهاء مهلة البوابة. فشل الخادم العلوي في إرسال الطلب في الوقت المسموح به من قبل الخادم",
            "HTTP Version Not Supported. The server does not support the HTTP protocol version used in the request.": "إصدار غير مدعوم. الخادم لا يدعم إصدار البروتوكول المستخدم في الطلب",
            "An unexpected error occurred. Please try again.": "حدث خطأ غير متوقع. الرجاء المحاولة مرة أخرى",
            "- Network Error": "خطأ في الشبكة-",
            "Network Error": "خطأ في الشبكة-",
    
            "Welcome to": ":مرحبًا بك في",
            "Thank you for registering! An activation email has been sent to your email address. To access all features, follow these steps": "شكرًا لتسجيلك! تم إرسال رسالة تفعيل إلى عنوان بريدك الإلكتروني. للوصول إلى جميع الميزات، اتبع هذه الخطوات",
            "Check Your Email": "تحقق من بريدك الإلكتروني",
            "Please check your inbox for an email from Zselling! with the subject": "يرجى التحقق من صندوق الوارد الخاص بك للعثور على رسالة من Zselling! بالموضوع",
            "Activate Your Account Now.": "قم بتفعيل حسابك الآن.",
            "Click the Activation Link": "انقر فوق رابط التفعيل",
            "Open the email and click the activation link to verify your email address and activate your account.": "افتح البريد الإلكتروني وانقر فوق رابط التفعيل للتحقق من عنوان بريدك الإلكتروني وتفعيل حسابك.",
            "Start Exploring": "ابدأ الاستكشاف",
            "Once activated, log in and start enjoying everything Zselling! has to offer.": "بمجرد التفعيل، قم بتسجيل الدخول وابدأ في الاستمتاع بكل ما يقدمه Zselling!",
            "Didn't receive the activation email? Check your spam/junk folder, as it might be filtered.": "لم تستلم البريد الإلكتروني للتفعيل؟ تحقق من مجلد البريد المزعج أو الملفات المؤرشفة، حيث قد يتم تصفية البريد.",
            "Need Help? Contact our support team at [Support Email/Contact]. We're here to assist you!": "بحاجة إلى مساعدة؟ اتصل بفريق الدعم لدينا على [البريد الإلكتروني للدعم / جهة الاتصال]. نحن هنا لمساعدتك!",
            "click to show more option in the list": "انقر لعرض المزيد من الخيارات في القائمة",
            "Retire": "تقاعد",
            "Products Blongs To Categorie": "المنتجات تنتمي إلى الفئة",
            "Retired Successfully": "تم التقاعد بنجاح",
            "Url": "رابط",
            "Logo": "شعار",
            "Add Products with Categorie to Store": "أضف منتجات مع الفئة إلى المتجر",
            "Add Head Annonces to Store": "أضف إعلانات رئيسية إلى المتجر",
            "Head Annonces":"إعلانات رئيسية",
            "Add Categorie to Store": "أضف فئة إلى المتجر",
            "Successfully copied": "تم النسخ بنجاح",
            "Order Article": "هوية الطلب",
            "Order From Stores": "طلب من المتاجر",
            "Order From Table": "طلب من الجدول",
            "Back": "رجوع",
            "Principales": "رئيسيات",
            "Secondaries": "ثوانويات",
            "Customize": "تخصيص",
            "Message": "رسالة",
            "Add to basket": "إضافة إلى السلة",
            "Subject": "موضوع",
            "Explanation": "شرح",
            "Send": "إرسال",
            "Date": "تاريخ",
            "Close": "إغلاق",
            
            
            
            
            "Principals": "رؤساء",
            "Catalogue": "كتالوج",
            "Images": "صور",
            "Image": "صورة",
            "Successfully Deleted": "تم الحذف بنجاح",
            "This action will delete the additons and the relation in products!": "هذا الإجراء سيحذف الإضافات والعلاقة في المنتجات!",
            "Confirm": "تأكيد",
            
            "max recommended is 6": "الحد الأقصى الموصى به هو 6",
            "Features": "ميزات",
            "New": "جديد",
            "Successfully": "بنجاح",
            "Added": "تمت الإضافة",
            "Modified": "تم التعديل",
            "Error": "خطأ",
            "Succesfully Added": "تمت الإضافة بنجاح",
            "Succesfully Modified": "تم التعديل بنجاح",
            "The attributes with * are obligatory.": "السمات التي تحمل * إلزامية.",
            "Show more": "عرض المزيد",
            
            "Available": "متاح",
            "Reel Price": "السعر الحقيقي",
            "Price": "السعر",
            "Promotion Price": "سعر الترويج",
            "Attributs": "السمات",
            "Components": "المكونات",
            "Are you sure that you want to delete the item ?": "هل أنت متأكد أنك تريد حذف العنصر؟",
            "Social Media": "وسائل التواصل الاجتماعي",
            "Delete": "حذف",
            "Copy": "نسخ",
            "big": "كبير",
            "medium": "متوسط",
            "small": "صغير",
            "Listing": "عرض",
            "Orders don't read": "الطلبات لا تُقرأ",
            "Orders": "الطلبات",
            "Modify": "تعديل",
            "Add": "إضافة",
            "Annonces": "إعلانات",
            "Title": "العنوان",
            "title": "العنوان",

            "Accounts": "الحسابات",
            "Add Accounts": "إضافة حسابات",
            "Messages box": "صندوق الرسائل",
            "Head Annonces": "رأس الإعلانات",
            "Account Manager": "مدير الحساب",
            "Manage Products": "إدارة المنتجات",
            "Manage stores": "إدارة المتاجر",
            "Services center": "مركز الخدمات",
            "Electronics": "الإلكترونيات",
            "Clothing": "الملابس",
            "Groceries": "مستلزمات المطبخ",
            "Home Improvement": "تحسين المنزل",
            "Beauty": "الجمال",
            "Sports and Outdoors": "الرياضة والهواء الطلق",
            "Books and Stationery": "الكتب والقرطاسية",
            "Toys and Games": "الألعاب والألعاب",
            "Health and Wellness": "الصحة والعافية",
            "Automotive": "السيارات",
            "Furniture": "الأثاث",
            "Jewelry": "المجوهرات",
            "Pet Supplies": "مستلزمات الحيوانات الأليفة",
            "Music and Instruments": "الموسيقى والآلات الموسيقية",
            "Art and Craft Supplies": "مستلزمات الفن والحرف اليدوية",
            "Electrical Appliances": "الأجهزة الكهربائية",
            "Kitchenware": "أدوات المطبخ",
            "Baby and Maternity": "الأطفال والأمومة",
            "Party Supplies": "مستلزمات الحفلات",
            "Travel and Luggage": "السفر والأمتعة",
            "Office Supplies": "لوازم المكتب",
            "Movies and Entertainment": "الأفلام والترفيه",
            "Tools and Hardware": "الأدوات والأجهزة",
            "Tech Gadgets": "أدوات التكنولوجيا",
            "Fitness and Exercise": "اللياقة وممارسة التمارين",
            "Food and Beverages": "الطعام والمشروبات",
            "Footwear": "الأحذية",
            "Hobbies and Collectibles": "الهوايات والتحف",
            "Home Decor": "ديكور المنزل",
            "Outdoor Equipment": "معدات الهواء الطلق",
            "Vintage and Antiques": "العتيقات والتحف",
            "School Supplies": "لوازم المدرسة",
            "Outdoor Furniture": "أثاث الهواء الطلق",
            "Bedding and Linens": "ملاءات السرير والأقمشة",
            "DIY Materials": "مواد العمل اليدوي",
            "Camping Gear": "معدات التخييم",
            "Cookware": "أواني الطهي",
            "Personal Care Products": "منتجات العناية الشخصية",
            "Gardening Supplies": "مستلزمات الحدائق",
            "Appliances": "الأجهزة المنزلية",
            "Party Decorations": "زينة الحفلات",
            "Baby Gear": "معدات الأطفال",
            "Watches": "الساعات",
            "Board Games": "ألعاب اللوحة",
            "Musical Instruments": "الآلات الموسيقية",
            "Smart Home Devices": "أجهزة المنزل الذكيّة",
            "Fitness Equipment": "معدات اللياقة",
            "Puzzles and Brainteasers": "ألغاز وألعاب ذهنية",
            "Bathroom Essentials": "مستلزمات الحمام",
            "Craft Kits": "مجموعات الحرف اليدوية",
            "Wearable Technology": "تكنولوجيا الارتداء",
            "Collectible Toys": "ألعاب جمعية",
            "Baking Supplies": "مستلزمات الخبز",
            "Auto Parts": "قطع السيارات",
            "Educational Toys": "ألعاب تعليمية",
            "Outdoor Toys": "ألعاب الهواء الطلق",
            "Cycling Gear": "معدات ركوب الدراجات",
            "Home Office Supplies": "لوازم مكتبية منزلية",
            "Cleaning Supplies": "مستلزمات التنظيف",
            "Skincare Products": "منتجات العناية بالبشرة",
            "Haircare Products": "منتجات العناية بالشعر",
            "Pet Food": "طعام الحيوانات الأليفة",
            "Cooking Utensils": "أدوات الطهي",
            "Fashion Accessories": "إكسسوارات الموضة",
            "Camping Supplies": "مستلزمات التخييم",
            "Fitness Apparel": "ملابس اللياقة البدنية",
            "Party Favors": "هدايا الحفلات",
            "Novels": "روايات",
            "DIY Kits": "مجموعات العمل اليدوي",
            "Outdoor Clothing": "ملابس الهواء الطلق",
            "Home Security Systems": "أنظمة الأمان المنزلي",
            "Laptop Accessories": "إكسسوارات الكمبيوتر المحمول",
            "Camera Gear": "معدات التصوير",
            "Home Entertainment Systems": "أنظمة الترفيه المنزلي",
            "Swimwear": "ملابس السباحة",
            "Plant Care Supplies": "مستلزمات العناية بالنباتات",
            "Coffee and Tea": "القهوة والشاي",
            "DIY Tools": "أدوات العمل اليدوي",
            "Collectible Cards": "بطاقات جمعية",
            "Baby Clothes": "ملابس الأطفال",
            "Outdoor Games": "ألعاب الهواء الطلق",
            "Camping Accessories": "إكسسوارات التخييم",
            "Cookbooks": "كتب الطهي",
            "Car Accessories": "إكسسوارات السيارات",
            "Collectible Figurines": "تماثيل جمعية",
            "Painting Supplies": "مستلزمات الرسم",
            "Sewing Supplies": "مستلزمات الخياطة",
            "Patio Furniture": "أثاث الفناء",
            "Hiking Gear": "معدات رياضة المشي",
            "Fishing Gear": "معدات الصيد",
            "Archery Supplies": "مستلزمات رماية السهام",
            "Costumes": "الأزياء",
            "Yoga Accessories": "إكسسوارات اليوغا",
            "Board Game Accessories": "إكسسوارات ألعاب اللوحة",
            "Photography Equipment": "معدات التصوير",
            "Outdoor Lighting": "إضاءة الهواء الطلق",
            "Vintage Clothing": "ملابس عتيقة",
            "Running Gear": "معدات الجري",
            "Television Accessories": "إكسسوارات التلفزيون",
            "Board Game Expansions": "توسيعات ألعاب اللوحة",
            "Fencing Equipment": "معدات السياج",
            "Model Kits": "مجموعات النماذج",
            "Audio Equipment": "معدات الصوت",
            "Pottery Supplies": "مستلزمات الفخار",
            "Astrology Services": "خدمات الفلك",
            "Building Blocks": "مكعبات البناء",
            "Comic Books": "كتب الكوميديا",
            "Stuffed Animals": "حيوانات محشوة",
            "Scuba Diving Gear": "معدات الغوص",
            "Virtual Reality Gear": "معدات الواقع الافتراضي",
            "Coin Collecting Supplies": "مستلزمات جمع العملات",
            "Magic Supplies": "مستلزمات السحر",
            "Quilting Supplies": "مستلزمات الخياطة",
            "Aviation Gear": "معدات الطيران",
            "Robotics Kits": "مجموعات الروبوتيات",
            "Model Trains": "قطارات نموذجية",
            "Rock Climbing Equipment": "معدات تسلق الجبال",
            "Skiing and Snowboarding Gear": "معدات التزلج وركوب الثلج",
            "Leatherworking Supplies": "مستلزمات العمل الجلدي",
            "Home Brewing Kits": "مجموعات تخمير المنزل",
            "Plant Seeds and Bulbs": "بذور ومصباح النباتات",
            "Chess Sets": "مجموعات شطرنج",
            "Golf Equipment": "معدات الجولف",
            "Wine and Spirits": "النبيذ والمشروبات الروحية",
            "Cosplay Supplies": "مستلزمات التأليف",
            "Tarot Cards": "أوراق التاروت",
            "Candle Making Supplies": "مستلزمات صنع الشموع",
            "Metalworking Tools": "أدوات العمل المعدني",
            "Stargazing Telescopes": "تلسكوبات مشاهدة النجوم",
            "Calligraphy Kits": "مجموعات الخط العربي",
            "Beekeeping Supplies": "مستلزمات تربية النحل",
            "Bonsai Tree Kits": "مجموعات زراعة البونساي",
            "Muscle Building Supplements": "مكملات بناء العضلات",
            "Cancel":"إلغاء",
            "By":"من خلال",
            "Bronze Package":"حزمة برونزية",
            "Gold Package":"حزمة ذهبية",
            "Platinum Package":"حزمة بلاتينية",
            "Month":"شهر",
            "Year":"عام",
            "Arabic": "العربية",
            "France": "الفرنسية",
            "English": "الإنجليزية",
            "Spanish": "الإسبانية",
            "List of products": "قائمة المنتجات",
            "Selected products": "المنتجات المختارة",
            "List of categories": "قائمة الفئات",
            "Selected categories": "الفئات المختارة",
            "web": "ويب",
            "Espace Client": "مساحة العميل",
            "All Rights Reserved": "جميع الحقوق محفوظة",
            "Product Options": "خيارات المنتج",
            "statics": "إحصائيات",
            "Statics": "إحصائيات",

            "Store Options": "خيارات المتجر",
            "Product Options": "خيارات المنتج",
            "Call": "اتصال",
            "Categorie Options": "خيارات الفئة",
            "Store": "متجر",
            "Options": "خيارات",
            "Product": "منتج",
            "Gredien": "مكوّن",
            "Topping": "طبقة",
            "Annonce": "إعلان",
            "Feature": "ميزة",
            "Categorie": "فئة",
            "Order": "طلب",
            "Client": "عميل",
            "Articles": "مقالات",
            "Rejected Grediens": "مكونات مرفوضة",
            "login": "تسجيل الدخول",
            "Civility title": "عنوان الاحترام",
            "Gender": "النوع",
            "Upload": "رفع",
            "Mal": "ذكر",
            "Femal": "أنثى",
            "Enable": "تمكين",
            "Disable": "تعطيل",
            "Team": "فريق",
            "Poste": "منصب",
            "Sell price":"سعر البيع",
            "Service Center":"مركز الخدمة",
            "Service center":"مركز الخدمة",

            "List of Teams":"قائمة الفرق",
            "Selected teams":"الفرق المختارة",
            "Visiters": "الزوار",
            "client name":"اسم العميل",
            "visited at":"تمت الزيارة في",
            "List of grediens":" قائمة المكونا",
            "Selected grediens":"المكونات المختارة",
            "Principales grediens":" المكونات الرئيسي",
            "comment":"تعليق",
            "store title":"عنوان المتجر",
            "civility title": "اللقب",
            "email":"البريد الإلكتروني",
            "subject": "الموضوع",
            "Avis by products":"تقييمات حسب المنتجات",
            "Stars by stores":"نجوم حسب المتاجر",
            "Stars by products":"نجوم حسب المنتجات",
            "Start date":"تاريخ البدء",
            "End date":"تاريخ الانتهاء",
            "List of annonces":"قائمة الإعلانات",
            "Selected Annonces":"الإعلانات المختارة",
            "Promence price":"السعر الترويجي",
            "Reel price":"السعر الفعلي",
            "price":"السعر",
            "Reel":"الفعلي",
            "Messages":"الرسائل",
            "Send To":"إرسال إلى",
            "Body": "النص",
            "Test": "اختبار",
            "SMTP Test":"اختبار SMTP",
            "Successfully sent":"تم الإرسال بنجاح",
            "Send Error":"خطأ في الإرسال",
            "Repeat password":"أعد كلمة المرور",
            "Forgot password?":            "هل نسيت كلمة المرور؟",
            
            "Return to login":            "العودة إلى تسجيل الدخول",
            
            "Account activated try to login":            "تم تفعيل الحساب، حاول تسجيل الدخول",
            
            "OTP verified successfully":            "تم التحقق من الرمز بنجاح",
            
            "Invalid OTP, please try again":            "الرمز غير صحيح، يرجى المحاولة مرة أخرى",
            
            "The Email doesn't exist":            "البريد الإلكتروني غير موجود",
            
            "The OTP Code sent successfully":            "تم إرسال الرمز بنجاح",
            
           "Enter The OTP Code":            "أدخل الرمز",
            
            "Enter the 6-digit code sent to your email":            "أدخل الرمز المكون من 6 أرقام المرسل إلى بريدك الإلكتروني",
            
            "Verify OTP":            "تحقق من الرمز",
            
            "Resend Code":            "إعادة إرسال الرمز"
            

            
            
        }
    },
    
      fr: {
        translation: {
          "Pending":"En attente",
          "Processing":"En cours de traitement",
          "Completed":"Terminé",
          "Canceled":"Annulé",
          "Profile Image":"Image de profil",
          "Upload Avatar":"Télécharger un avatar",
            filtring: "Filtrage",
            Country:"Pays",
            Region:"Région",
            Categories:"Catégories",
            Rating:"Évaluation",
            Currency:"Devise",
            Filters:"Filtres",
            Clear:"Clair",
            Dashboard:"Tableau de bord",
            Login:"Connexion",
            Logout:"Déconnexion",
            Search:"Recherche",
            Avis:"Reviews",
            Show:"Afficher",
            Password:"Mot de passe",
            "Additions":"Additions",
            "Internal Servers":"Serveurs internes",

            Subscripe:"S'abonner",
            Details:"Détails",
            Description:"Description",
            description:"description",
            "created at": "créé à",
            "Select": "Sélectionner",
            "select": "sélectionner",
            "Avis": "Avis",
            "Add to basket": "Ajouter au panier",
            "send": "envoyer",
            "Send": "Envoyer",
            "Phone": "Téléphone",
            "Message": "Message",
            Stores:"Magasins",
            Products:"Produits",
            products:"produits",

            Characteristics:"Caractéristiques", 
            Configurations:"Configurations",
            Account:"Compte",
            "Add Account":"Ajouter un Compte",
            "Filter": "Filtrer",
            "You have an account?":"Vous avez un compte ?",
            "Sign up":"S'inscrire",
            "Name": "Nom",
            "name": "nom",

            "Register": "S'inscrire",
            "Sign in": "Se connecter",
            "Password incorrect! try again": "Mot de passe incorrect ! réessayez",
            "Email incorrect!": "Adresse e-mail incorrecte !",
            "Login Incorrect check your email or password": "Identifiant incorrect, vérifiez votre adresse e-mail ou votre mot de passe",
            "You dont have account?": "Vous n'avez pas de compte ?",
            "Password not equal to password!": "Le mot de passe ne correspond pas au mot de passe !",
            "Again": "À nouveau",
            "Try strong password for more security!": "Essayez un mot de passe fort pour plus de sécurité !",
            "Name incorrect!": "Nom incorrect !",
            "Creater": "Créateur",
            "Admin": "Admin",
            "Level": "Niveau",
            "Status": "Statut",
            "Last": "Dernier",
            "Date": "Date",
            "Super": "Super",
            "User": "Utilisateur",
            "Permissions": "Permissions",
            "Disable": "Désactiver",
            "Enable": "Activer",
            "All": "Tous",
            "None": "Aucun",
            "Rights": "Droits",
            "Passwords don't equals": "Les mots de passe ne correspondent pas",
            "Profile": "Profil",
            "Profiles": "Profils",

            "This form allows you to Modify your account , use the 'Modify Profile' button found on the profile  card.": "Ce formulaire vous permet de modifier votre compte. Utilisez le bouton 'Modifier le profil' situé sur la carte de profil.",
            "Click to update": "Cliquez pour mettre à jour",
            "Save Changes": "Enregistrer les modifications",
            "Email": "E-mail",
            "Change": "Changer",
            "Try strong password for more security!": "Essayez un mot de passe fort pour plus de sécurité !",
            "Repeat Password": "Répéter le mot de passe",
            "Doesn't equal to password!": "Ne correspond pas au mot de passe !",
            "Subsribtion": "Abonnement",
            "Type": "Type",
            "The next date of payment": "La prochaine date de paiement",
            "Show less": "Montrer moins",
            "availability": "disponibilité",
            "A new order internal": "Une nouvelle commande interne",
            "A new order external": "Une nouvelle commande externe",
            "Units": "Unités",
            "units": "Unités",
            "Units": "unité",

            "Sum": "Somme",
            "Total": "Total",
            "Refus": "Refus",
            "Accept": "Accepter",
            "Rejected": "Rejeté",
            "How many": "Combien",
            "waiting": "en attente",
            "List of internal manage": "Liste de gestion interne",
            "Add an internal manage": "Ajouter une gestion interne",
            "Table": "Table",
            "Link Expired. Try To Create Another.": "Lien expiré. Essayez d'en créer un autre.",
            "QR Code": "Code QR",
            "Ref": "Ref",
            "Annoncces": "Annonces",
            "ID": "ID",
            "id": "id",

            "Welcome to": "Bienvenue sur",
            "Thank you for registering! An activation email has been sent to your email address. To access all features, follow these steps": "Merci de vous être inscrit ! Un e-mail d'activation a été envoyé à votre adresse e-mail. Pour accéder à toutes les fonctionnalités, suivez ces étapes",
            "Check Your Email": "Vérifiez votre e-mail",
            "Please check your inbox for an email from Zselling! with the subject": "Veuillez vérifier votre boîte de réception pour un e-mail de Zselling ! avec pour objet",
            "Activate Your Account Now.": "Activez votre compte maintenant.",
            "Click the Activation Link": "Cliquez sur le lien d'activation",
            "Open the email and click the activation link to verify your email address and activate your account.": "Ouvrez l'e-mail et cliquez sur le lien d'activation pour vérifier votre adresse e-mail et activer votre compte.",
            "Start Exploring": "Commencez à explorer",
            "Once activated, log in and start enjoying everything Zselling! has to offer.": "Une fois activé, connectez-vous et commencez à profiter de tout ce que Zselling ! a à offrir.",
            "Didn't receive the activation email? Check your spam/junk folder, as it might be filtered.": "Vous n'avez pas reçu l'e-mail d'activation ? Vérifiez votre dossier de courrier indésirable, car il pourrait être filtré.",
            "Need Help? Contact our support team at [Support Email/Contact]. We're here to assist you!": "Besoin d'aide ? Contactez notre équipe d'assistance à [Support Email/Contact]. Nous sommes là pour vous aider !",
            "click to show more option in the list": "cliquez pour afficher plus d'options dans la liste",
            "Retire": "Retirer",
            "Products Blongs To Categorie": "Les produits appartiennent à la catégorie",
            "Retired Successfully": "Retiré avec succès",
            "Url": "URL",
            "Logo": "Logo",
            "Add Products with Categorie to Store": "Ajouter des produits avec une catégorie au magasin",
            "Add Head Annonces to Store": "Ajouter des annonces principales au magasin",
            "Add Categorie to Store": "Ajouter une catégorie au magasin",
            "Successfully copied": "Copié avec succès",
            "Order Article": "Commander l'article",
            "Order From Stores": "Commander depuis les magasins",
            "Order From Table": "Commander depuis la table",
            "Back": "Retour",
            "Principales": "Principales",
            "Secondaries": "Secondaires",
            "Customize": "Personnaliser",
            "Message": "Message",
            "Add to basket": "Ajouter au panier",
            "Subject": "Sujet",
            "Explanation": "Explication",
            "Send": "Envoyer",
            "Date": "Date",
            "Close": "Fermer",
            
            
            "Principals": "Principaux",
            "Catalogue": "Catalogue",
            "Images": "Images",
            "Image": "Image",
            "Successfully Deleted": "Supprimé avec succès",
            "This action will delete the additons and the relation in products!": "Cette action supprimera la additions et la relation dans les produits !",
            "Confirm": "Confirmer",
            "Error during action:": "Erreur lors de l'action :",

            "max recommended is 6": "maximum recommandé est 6",
              "Features":"Caractéristiques",
            "New": "Nouveau",
            "Successfully": "Avec succès",
            "Added": "Ajouté",
            "Modified": "Modifié",
            "Error": "Erreur",
            "Succesfully Added":"Ajouté avec succès",
            "Succesfully Modified":"Modifié avec succès",
            "The attributes with * are obligatory.": "Les attributs avec * sont obligatoires.",
            "Show more": "Afficher plus",

            "Available": "Disponible",
            "Reel Price": "Prix réel",
            "Price":"Prix",
            "Promotion Price": "Prix Promotionnel",
            "Attributs":"Attributes",
            "Components":"Composants",
            "Are you sure that you want to delete the item ?": "Êtes-vous sûr(e) de vouloir supprimer cet élément ?",
            "Social Media": "Réseaux sociaux",
            "Delete": "Supprimer",
            "Copy": "Copier",
            "big": "grand",
            "medium": "moyen",
            "small": "petit",
            "Listing": "Afficher",
            "Orders don't read": "Les commandes ne lisent pas",
            "Orders": "Commandes",
            "Modify": "Modifier",
            "Add": "Ajouter",
            "Annonces": "Announcements",
            "Title": "Titre",
            "title": "titre",

            "Accounts":"Comptes",
            "Add Accounts":"Ajoute de Comptes",
            "Messages box":"Boîte de messages",
            "Head Annonces":"Tête Announcements",
            "Account Manager":"Gestionnaire de compte",
            "Manage Products":"Gérer produits",
            "Manage stores":"Gérer magasins",
            "Services center":"Centre de services",
            "Electronics" : "Électronique",
            "Clothing" : "Vêtements",
            "Groceries" : "Épicerie",
            "Home Improvement" : "Aménagement de la maison",
            "Beauty" : "Beauté",
            "Sports and Outdoors" : "Sports et plein air",
            "Books and Stationery" : "Livres et papeterie",
            "Toys and Games" : "Jouets et jeux",
            "Health and Wellness" : "Santé et bien-être",
            "Automotive" : "Automobile",
            "Furniture" : "Meubles",
            "Jewelry" : "Bijoux",
            "Pet Supplies" : "Fournitures pour animaux de compagnie",
            "Music and Instruments" : "Musique et instruments",
            "Art and Craft Supplies" : "Fournitures d'art et d'artisanat",
            "Electrical Appliances" : "Appareils électriques",
            "Kitchenware" : "Ustensiles de cuisine",
            "Baby and Maternity" : "Bébé et maternité",
            "Party Supplies" : "Fournitures de fête",
            "Travel and Luggage" : "Voyages et bagages",
            "Office Supplies" : "Fournitures de bureau",
            "Movies and Entertainment" : "Films et divertissement",
            "Tools and Hardware" : "Outils et quincaillerie",
            "Tech Gadgets" : "Gadgets technologiques",
            "Fitness and Exercise" : "Fitness et exercice",
            "Food and Beverages" : "Aliments et boissons",
            "Footwear" : "Chaussures",
            "Hobbies and Collectibles" : "Loisirs et objets de collection",
            "Home Decor" : "Décoration intérieure",
            "Outdoor Equipment" : "Équipement extérieur",
            "Vintage and Antiques" : "Vintage et antiquités",
            "School Supplies" : "Fournitures scolaires",
            "Outdoor Furniture" : "Meubles d'extérieur",
            "Bedding and Linens" : "Literie et linge de maison",
            "DIY Materials" : "Matériaux de bricolage",
            "Camping Gear" : "Équipement de camping",
            "Cookware" : "Ustensiles de cuisine",
            "Personal Care Products" : "Produits de soins personnels",
            "Gardening Supplies" : "Fournitures de jardinage",
            "Appliances" : "Appareils électroménagers",
            "Party Decorations" : "Décorations de fête",
            "Baby Gear" : "Équipement pour bébé",
            "Watches" : "Montres",
            "Board Games" : "Jeux de société",
            "Musical Instruments" : "Instruments de musique",
            "Smart Home Devices" : "Appareils domestiques intelligents",
            "Fitness Equipment" : "Équipement de fitness",
            "Puzzles and Brainteasers" : "Puzzles et casse-têtes",
            "Bathroom Essentials" : "Articles essentiels pour la salle de bain",
            "Craft Kits" : "Kits d'artisanat",
            "Wearable Technology" : "Technologie portable",
            "Collectible Toys" : "Jouets de collection",
            "Baking Supplies" : "Fournitures de pâtisserie",
            "Auto Parts" : "Pièces d'auto",
            "Educational Toys" : "Jouets éducatifs",
            "Outdoor Toys" : "Jouets d'extérieur",
            "Cycling Gear" : "Équipement de cyclisme",
            "Home Office Supplies" : "Fournitures de bureau à domicile",
            "Cleaning Supplies" : "Produits de nettoyage",
            "Skincare Products" : "Produits de soins de la peau",
            "Haircare Products" : "Produits capillaires",
            "Pet Food" : "Nourriture pour animaux de compagnie",
            "Cooking Utensils" : "Ustensiles de cuisine",
            "Fashion Accessories" : "Accessoires de mode",
            "Camping Supplies" : "Fournitures de camping",
            "Fitness Apparel" : "Vêtements de fitness",
            "Party Favors" : "Faveurs de fête",
            "Novels" : "Romans",
            "DIY Kits" : "Kits de bricolage",
            "Outdoor Clothing" : "Vêtements d'extérieur",
            "Home Security Systems" : "Systèmes de sécurité domestique",
            "Laptop Accessories" : "Accessoires d'ordinateur portable",
            "Camera Gear" : "Équipement photo",
            "Home Entertainment Systems" : "Systèmes de divertissement à domicile",
            "Swimwear" : "Maillots de bain",
            "Plant Care Supplies" : "Fournitures pour soins des plantes",
            "Coffee and Tea" : "Café et thé",
            "DIY Tools" : "Outils de bricolage",
            "Collectible Cards" : "Cartes de collection",
            "Baby Clothes" : "Vêtements pour bébé",
            "Outdoor Games" : "Jeux d'extérieur",
            "Camping Accessories" : "Accessoires de camping",
            "Cookbooks" : "Livres de cuisine",
            "Car Accessories" : "Accessoires pour voiture",
            "Collectible Figurines" : "Figurines de collection",
            "Painting Supplies" : "Fournitures de peinture",
            "Sewing Supplies" : "Fournitures de couture",
            "Patio Furniture" : "Meubles de terrasse",
            "Hiking Gear" : "Équipement de randonnée",
            "Fishing Gear" : "Équipement de pêche",
            "Archery Supplies" : "Fournitures de tir à l'arc",
            "Costumes" : "Costumes",
            "Yoga Accessories" : "Accessoires de yoga",
            "Board Game Accessories" : "Accessoires de jeux de société",
            "Photography Equipment" : "Équipement de photographie",
            "Outdoor Lighting" : "Éclairage extérieur",
            "Vintage Clothing" : "Vêtements vintage",
            "Running Gear" : "Équipement de course",
            "Television Accessories" : "Accessoires de télévision",
            "Board Game Expansions" : "Extensions de jeux de société",
            "Fencing Equipment" : "Équipement d'escrime",
            "Model Kits" : "Kits de modélisme",
            "Audio Equipment" : "Équipement audio",
            "Pottery Supplies" : "Fournitures de poterie",
            "Astrology Services" : "Services d'astrologie",
            "Building Blocks" : "Blocs de construction",
            "Comic Books" : "Bandes dessinées",
            "Stuffed Animals" : "Peluches",
            "Scuba Diving Gear" : "Équipement de plongée sous-marine",
            "Virtual Reality Gear" : "Équipement de réalité virtuelle",
            "Coin Collecting Supplies" : "Fournitures de collection de pièces de monnaie",
            "Magic Supplies" : "Fournitures de magie",
            "Quilting Supplies" : "Fournitures de quilting",
            "Aviation Gear" : "Équipement d'aviation",
            "Robotics Kits" : "Kits de robotique",
            "Model Trains" : "Trains miniatures",
            "Rock Climbing Equipment" : "Équipement d'escalade",
            "Skiing and Snowboarding Gear" : "Équipement de ski et de snowboard",
            "Leatherworking Supplies" : "Fournitures de travail du cuir",
            "Home Brewing Kits" : "Kits de brassage à domicile",
            "Plant Seeds and Bulbs" : "Graines et ampoules de plantes",
            "Chess Sets" : "Jeux d'échecs",
            "Golf Equipment" : "Équipement de golf",
            "Wine and Spirits" : "Vin et spiritueux",
            "Cosplay Supplies" : "Fournitures de cosplay",
            "Tarot Cards" : "Cartes de tarot",
            "Candle Making Supplies" : "Fournitures de fabrication de bougies",
            "Metalworking Tools" : "Outils de travail des métaux",
            "Stargazing Telescopes" : "Télescopes d'observation des étoiles",
            "Calligraphy Kits" : "Kits de calligraphie",
            "Beekeeping Supplies" : "Fournitures d'apiculture",
            "Bonsai Tree Kits" : "Kits de bonsaï",
            "Muscle Building Supplements" : "Compléments pour la construction musculaire",
            "Cancel":"Annuler",
            "max recommanded is 6":"La recommandation maximale est de 6",
            "By":"Par",
            "Bronze Package":"Forfait Bronze",
            "Gold Package":"Forfait Or",
            "Platinum Package":"Forfait Platine",
            "Month":"Mois",
            "Year":"Année",
            "Oops, it’s empty! Select the articles you want": "Oups, c'est vide ! Sélectionnez les articles que vous voulez",
            "Oops, it’s empty!": "Oups, c'est vide !",
            "sub title": "sous-titre",
            "create at": "créé à",
            "updated at": "mis à jour à",
            "url store": "URL du magasin",
            "clients orders": "commandes clients",
            "Clients Orders": "Commandes Clients",
            "comments": "commentaires",
            "Comments": "Commentaires",
            "My Orders": "Mes Commandes",
            "my orders": "mes commandes",
            "Teams": "Équipes",
            "teams": "équipes",
            "Number of Orders by Stores": "Nombre de Commandes par Magasins",
            "Global Orders In Stores": "Commandes Globales dans les Magasins",
            "Personal Information": "Informations Personnelles",
            "Full Name": "Nom Complet",
            "Phone Number": "Numéro de Téléphone",
            "Email Address": "Adresse Email",
            "Save": "Enregistrer",
            "Change password": "Changer le mot de passe",
            "New Password": "Nouveau Mot de Passe",
            "Repeat New Password": "Répéter le Nouveau Mot de Passe",
            "SMTP": "SMTP",
            "Domaine Name": "Nom de Domaine",
            "Email Address": "Adresse Email",
            "Port": "Port",
            "Settings": "Paramètres",
            "Sign out": "Déconnexion",
            "mobile": "mobile",
            "company": "entreprise",
            "There are no records to display": "Aucun enregistrement à afficher",
            "message": "message",
            "Feature": "Fonctionnalité",
            "feature": "fonctionnalité",
            "Features": "Fonctionnalités",
            "Toppings": "Garnitures",
            "Grediens": "Ingrédients",
            "Categoraisation": "Catégorisation",
            "Catalogue Images": "Images de Catalogue",
            "Social Média": "Réseaux Sociaux",
            "Information": "Information",
            "Arabic": "Arabe",
            "France": "France",
            "English": "Anglais",
            "Spanish": "Espagnol",
            "List of products": "Liste des produits",
            "Selected products": "Produits sélectionnés",
            "List of categories": "Liste des catégories",
            "Selected categories": "Catégories sélectionnées",
            "web": "web",
            "Espace Client": "Espace Client",
            "All Rights Reserved": "Tous droits réservés",
            "Product Options": "Options de produit",
            "statics": "statistiques",
            "Statics": "Statistiques",

            "Store Options": "Options du magasin",
            "Product Options": "Options de produit",
            "Call": "Appeler",
            "Categorie Options": "Options de catégorie",
            "Store": "Magasin",
            "Options": "Options",
            "Product": "Produit",
            "Gredien": "Ingrédient",
            "Topping": "Garniture",
            "Annonce": "Annonce",
            "Feature": "Fonctionnalité", 
            "Categorie": "Catégorie",
            "Order": "Commande",
            "Client": "Client",
            "Articles": "Articles",
            "Rejected Grediens": "Ingrédients rejetés",
            "login": "Connexion",
            "Civility title": "Titre de civilité",
            "Gender": "Sexe",
            "Upload": "Télécharger",
            "Mal": "Homme",
            "Femal": "Femme",
            "Enable": "Activer",
            "Disable": "Désactiver",
            "Team": "Équipe",
            "Poste": "Poste",
            "TicketServices Componnent": "Composant TicketServices",
            "TicketRef Componnent": "Composant de référence de ticket",
            "Model deleted successfully:": "Modèle supprimé avec succès :",
            "Deleted!": "Supprimé !",
            "Your model has been deleted.": "Votre modèle a été supprimé.",
            "success": "succès",
            "Error deleting model:" : "Erreur lors de la suppression du modèle :",
            "Bad Request. Please check your input.": "Requête incorrecte. Veuillez vérifier votre saisie.",
             "Unauthorized. Please log in.": "Non autorisé. Veuillez vous connecter.",
             "Payment Required. Please update your payment method.": "Paiement requis. Veuillez mettre à jour votre méthode de paiement.",
             "Forbidden. You don't have permission.": "Interdit. Vous n'avez pas la permission.",
             "Not Found. The resource doesn't exist.": "Introuvable. La ressource n'existe pas.",
             "Method Not Allowed. The HTTP method is not allowed for this resource.": "Méthode non autorisée. La méthode HTTP n'est pas autorisée pour cette ressource.",
             "Not Acceptable. The requested resource is not available in the requested format.": "Non acceptable. La ressource demandée n'est pas disponible dans le format demandé.",
             "Request Timeout. The server timed out waiting for the request.": "Délai d'attente de la requête dépassé. Le serveur a expiré en attendant la requête.",
             "Conflict. There was a conflict with the current state of the resource.": "Conflit. Il y a eu un conflit avec l'état actuel de la ressource.",
             "Gone. The resource is no longer available.": "Disparu. La ressource n'est plus disponible.",
             "Unsupported Media Type. The request format is not supported by the server.": "Type de média non supporté. Le format de la requête n'est pas pris en charge par le serveur.",
             "Too Many Requests. You have sent too many requests in a given amount of time.": "Trop de requêtes. Vous avez envoyé trop de requêtes dans un laps de temps donné.",
             "Internal Server Error. Please try again later.": "Erreur interne du serveur. Veuillez réessayer ultérieurement.",
             "Not Implemented. The server does not support the functionality required to fulfill the request.": "Non implémenté. Le serveur ne prend pas en charge la fonctionnalité requise pour répondre à la demande.",
             "Bad Gateway. The server received an invalid response from the upstream server.": "Passerelle incorrecte. Le serveur a reçu une réponse invalide du serveur amont.",
             "Service Unavailable. The server is currently unable to handle the request.": "Service non disponible. Le serveur ne peut actuellement pas traiter la demande.",
             "Gateway Timeout. The upstream server failed to send a request in the time allowed by the server.": "Délai d'attente de la passerelle. Le serveur amont n'a pas réussi à envoyer une demande dans le délai imparti par le serveur.",
             "HTTP Version Not Supported. The server does not support the HTTP protocol version used in the request.": "Version HTTP non supportée. Le serveur ne prend pas en charge la version du protocole HTTP utilisée dans la requête.",
             "- Network Error": "- Erreur Réseau",
             "Network Error": "Erreur Réseau",
             "Sell price":"Sell price",
             "Service Center": "Centre de Service",
             "Service senter": "centre de service",

             "List of Teams": "Liste des Équipes",
             "Selected teams": "Équipes Sélectionnées",
             "Visiters": "Visiteurs",
             "client name": "Nom du client",
             "visited at": "Visité à",
             "List of grediens": "Liste des ingrédients",
             "Selected grediens": "Ingrédients Sélectionnés",
             "Principales grediens": "Ingrédients Principaux",
             "store title": "Titre du magasin",
             "comment": "Commentaire",
             
             "civility title": "Titre de civilité",
             "email": "Courriel",
             "subject": "Objet",
             "Avis by products": "Avis par produits",
             "Stars by stores": "Étoiles par magasins",
             "Stars by products": "Étoiles par produits",
             "Start date": "Date de début",
             "End date": "Date de fin",
             "List of annonces": "Liste des annonces",
             "Selected Annonces": "Annonces sélectionnées",
             "Promence price": "Prix promotionnel",
             "Reel price": "Prix réel",
             "Messages":"Messages",
             "Send To": "Envoyer à",
             "Body": "Corps du message",
             "Test": "Test",
             "SMTP Test": "Test SMTP",
             "Successfully sent": "Envoyé avec succès",
             "Send Error": "Erreur d'envoi",
             "Repeat password": "Répéter le mot de passe",
             "Forgot password?":"Mot de passe oublié ?",
             "Return to login":"Retour à la connexion",             
             "Account activated try to login": "Compte activé, essayez de vous connecter",
             "OTP verified successfully":"Code OTP vérifié avec succès",
             "Invalid OTP, please try again":"Code OTP invalide, veuillez réessayer",
             "The Email doesn't exist":"L'e-mail n'existe pas",
             "The OTP Code sent successfully":"Le code OTP a été envoyé avec succès",
             "Enter The OTP Code":"Entrez le code OTP",
             "Enter the 6-digit code sent to your email":"Entrez le code à 6 chiffres envoyé à votre e-mail",
             "Verify OTP":"Vérifier le code OTP",
             "Resend Code":"Renvoyer le code",
             
            
             
             
             
             
        },
      
     
      
      },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng:  "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;