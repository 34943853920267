


import React, { useEffect, useState } from 'react';
import { FacebookSelector  } from '@charkour/react-reactions';
import haha from 'Assets/emojis/haha.svg'
import angry from 'Assets/emojis/angry.svg'
import like from 'Assets/emojis/like.svg'
import love from 'Assets/emojis/love.svg'
import sad from 'Assets/emojis/sad.svg'
import wow from 'Assets/emojis/wow.svg'
import { BsFillHandThumbsUpFill } from 'react-icons/bs';
import { ReactionAvisAction } from './Actions';
import Frequence from './Frequence';
import { getLocalstorage } from 'Assistances/GlobalFunctions';
import { isTokenExpired } from 'Assistances/GlobalFunctions';

export default function Reaction(props) {
  const [Affiche_raction_block,setAfficheReactionBlock]=useState(false);
  // const [react_selected,setReactSelected]=useState(props.MyreactionsAvis.length>0?props.MyreactionsAvis[0]['value']:"");
  const [numbereaction,setNumbereaction]=useState(0);

  useEffect(()=>{
    let total_reaction=0;
    props.reactionsAvis.map(e=>{
      total_reaction+=e.number
    })
    setNumbereaction(total_reaction)
  },[props.reactionsAvis])


  const affich_reaction_in_button=(react_selected)=>{
    var icon;
    switch (react_selected) {
      case "like":
         icon=   like
            break; 
      case "angry":
        icon= angry
        break; 
      case "haha":
          icon=  haha
          break; 
      case "love":
          icon= love
          break; 
      case "sad":
        icon= sad;
        break; 
      case "wow":
        icon= wow
        break; 
      default:
        return < BsFillHandThumbsUpFill  className="do-reactions-icon" color= "#B5B5C3" size={props.size?props.size:"18px"}/>
    }
    return <img src={icon} height={props.size?props.size:"18px"} alt='reaction'/>

  }

  var click_rection_button=()=>{
    
   if(!isTokenExpired()){
    if(props.MyreactionsAvis.length>0){
      // setReactSelected("");
      setNumbereaction(numbereaction-1);
      modifyReactionValue(null)

    }
    else
    {
      // setReactSelected("like")
      setNumbereaction(numbereaction+1);
      modifyReactionValue("like")

    }         
   }
   else{
     props.showModuleLogin()

   }
   
  }

  function select_emoji(e){
   if(!isTokenExpired()){

    // setReactSelected(e);
    if(props.MyreactionsAvis.length<=0){ 
      setNumbereaction(numbereaction+1);
    }; 
    setAfficheReactionBlock(false);
    modifyReactionValue(e)
  }
  else{
    props.showModuleLogin()

  }
  }

  function modifyReactionValue(value){
      ReactionAvisAction(props.rowid_avis_product,value,props.store_id)
        .then(function (response) {
          //console.log(props.rowid_avis_product,value)
          props.modifyInReactionInList(props.rowid_avis_product,value)

        })
        .catch(function (error) {
          //console.log(error)
            //console.log(error.message);
        });
  }
  return (
      <div className="reaction-block-container" 
      key={props.key} onMouseLeave={()=>{setTimeout(() => setAfficheReactionBlock(false), 1500)}} >
       <div> 
           <div className="reaction-emojis-container">
           {
             Affiche_raction_block?
               <div className="Emoji-container">
               <FacebookSelector height="30px" className="facedbook" onSelect={(e)=>{select_emoji(e)} }/>
               </div>
             :
             <></>
           }
           </div>
           <span onMouseMove={()=>{setTimeout(() => setAfficheReactionBlock(true), 1000);}} className=' reactions-and-frequence' >
              <span id="btn-reactions" className=""  onClick={()=>{click_rection_button()} }>
                {affich_reaction_in_button(props.MyreactionsAvis.length>0?props.MyreactionsAvis[0]['value']:"")}                
                </span>
                <span>
                <Frequence reactionsAvis={props.reactionsAvis} MyreactionsAvis={props.MyreactionsAvis} numbereaction={numbereaction>0?numbereaction:''}/>
                </span>
            </span>
        </div>
  </div>
  )
}

