import { getLocalstorage } from "Assistances/GlobalFunctions";
import axios from "axios"

export function getAccountInfo(){

    return axios({
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/getAccountInfo',
        data: {}, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}

export function updateAccountInfoAction(data){

    return axios({ 
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/updateAccountInfo',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}



export function updateAccountPassword(data){

    return axios({ 
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/updateAccountPassword',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}


export function updateSmtp(data){

    return axios({ 
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/smtp/update',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}






export function getSmtp(data){

    return axios({ 
        method: 'get',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/smtp/get',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}

export function updateProfileImage(data){

    return axios({ 
        method: 'post',
        url: process.env.REACT_APP_APP_URL_BACKEND+'/updateprofileImage',
        data: data, 
        headers: { 'Authorization': 'Bearer ' +getLocalstorage('access_token')}

    }) 
}



export function TestSendMailAction(data){
    return axios({
        method: 'post', 
        url: process.env.REACT_APP_APP_URL_BACKEND+'/email/sendtest',
        data:data, 
        headers: { 'Authorization': 'Bearer ' + getLocalstorage('access_token')},
    }) 
}

