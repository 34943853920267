import React, { useEffect, useState } from 'react'
import { boardtableStatics } from './BoardTableActions'
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { abbreviateNumber } from 'Assistances/GlobalFunctions';
import TotalStaticsItem from './TotalStaticsItem';
export default function TotalsStatics(props) {
    const { t, i18n } = useTranslation();

    const [TotalStatics,setTotalStatics]=useState(props.TotalStatics)
    // 
  return (
    <div>
        <div className='row'> 
            {Object.keys(TotalStatics).map((key) => (
                    <div key={key} className=' col-lg-2 col-md-3 col-sm-6 m-1'>
                      <TotalStaticsItem number={abbreviateNumber(TotalStatics[key])} title={key.charAt(0).toUpperCase() + key.slice(1)}/>

                    </div>
            ))}
        </div>

        
    </div>
  )
}
