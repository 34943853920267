


import React, { useEffect, useState } from 'react'
import {  toast } from 'react-toastify';    
import { useTranslation } from 'react-i18next';
import Datatable from 'Componnents/DataTables/DataTables';
// import FormHeadProducts from './FormHeadProducts';
import ModalHelper from 'Componnents/Modals/ModalHelper';
import { getAllProducts } from './Actions';
import FormProducts from './FormProducts';
import { setLocalstorage } from 'Assistances/GlobalFunctions';
import { useNavigate  } from 'react-router-dom';
import Spinner from 'Spinner/Spinner';
import { handleError } from 'Assistances/ErrorHandling';

export default function DataTableProducts() {
    const [Products,setProducts]=useState([]);
    const { t } = useTranslation();
    const history=useNavigate()

    const columns=['id','title','description',"created_at","updated_at"];
    const [showModalFormHeadProducts,setShowModalFormHeadProducts]=useState(false)
    const [spinner,setSpinner]=useState(true)

    useEffect(()=>{
      getProducts()
    },[])
    function getProducts(){
      getAllProducts()
      .then(function (response) {
          setProducts(response.data)
      }) 
      .catch(function (error) {
        handleError(error,t,t('Products'));
      })
      .finally(() => {
        setSpinner(false)
      });
    }
    function handleRowClicked(row){
      setLocalstorage('product_id',row.id);
      history("/dashboard/products/fiche")
    }
  return (
    <div>
       {spinner?
      <Spinner/> 
      :

      <Datatable 
      data={Products} 
      columns={columns}
      handleRowClicked={handleRowClicked}
      title="Products"
      total={Products.length}

      addbutton={true}
      ShowModal={() => {setShowModalFormHeadProducts(true)}}
      />
       }
      
      <ModalHelper  
        show={showModalFormHeadProducts} 
        title={t("Add")+" "+ t("Products")}
        size='lg'
        onHide={() => setShowModalFormHeadProducts(false)} 
        content={<FormProducts modifycontent='' onHide={() => setShowModalFormHeadProducts(false)} getProducts={getProducts} />} />
        
      
      </div>
  )
}
