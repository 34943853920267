import React from 'react';
import image from "Assets/Images/nophoto.png" 
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLang } from '../../Redux/ReduxSlices/langs/LangsSlicer';
import { getTextByLanguage } from 'Assistances/GlobalFunctions';

export default function Footer(props) {
  const { t } = useTranslation();
  const langs = useSelector(selectLang);
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  return (
        <footer className="bg-white w-full flex justify-center dark:bg-gray-800 m-3">

                <div className="block w-full text-center justify-center  flex text-sm text-center text-gray-500 dark:text-gray-400">
                    © {previousYear}-{currentYear} {getTextByLanguage(props.info?props.info.title:"",langs)}™. All Rights Reserved. 
                    
                </div>
            
        </footer>
      )
    }
