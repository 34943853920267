import React, { useEffect, useState } from 'react'
import image from "Assets/Images/nophoto.png" 
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function ProductImageBuy(props) {
    
    const [shownimage,setshownimage]=useState(props.catalogueImage.length>0?props.catalogueImage[0]['image']:props.productimage)
   
  return (
    <div className='product-size-image m-3'>
        {!props.catalogueImage || props.catalogueImage==null?
            <>
                <div className='row row-image-product'>
                    <div className='col-12 col-image-product'>
                        <img src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/products/"+props.productimage}  className={"product-img-size  product-size-image-meduim"}   onError={(e) => {e.target.src =image}}  />

                    </div>
                </div>
            </>
            :
            <>
                <div className='row row-image-product'>
                    <div className='col-12 col-image-product'>
                        <Zoom>

                        <img src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/products/"+shownimage}  className={"product-img-size  product-size-image-meduim"}    onError={(e) => {e.target.src =image}} />
                        </Zoom>

                    </div>
                </div>
                <div className='row catalogue-image-product-container'>
                    {
                         props.catalogueImage.map((item)=>{
                            return(
                            <div className='col-md-6 col-sm-4 col-lg-3 col-3 mt-2'>
                            <img src={process.env.REACT_APP_APP_URL_BACKEND_IMAGES+"/products/"+item['image']}  onClick={()=>setshownimage(item['image'])}className="catalogue-item-image"    onError={(e) => {e.target.src =image}} />
                            
                            </div>
                            )
                        })
                    }
                </div>
            </>
        }
       

    </div>
  )
}

